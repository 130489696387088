import {Component, OnInit} from '@angular/core';
import {ServiceAPIService} from './services/service-api.service';
import {TimelineElement} from './classes/timeline-element';
import {Router} from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import * as AOS from 'aos';
import { Article } from './classes/article';
import { Opportunite } from './classes/opportunite';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'AENI.fr';
  timelineElements: TimelineElement[];

  BASE_URL = 'amazones.aaeni.fr:1337/';

  constructor( private router: Router, private titleService: Title, private metaService: Meta, private serviceAPI: ServiceAPIService) {
  }

  ngOnInit() {
    AOS.init();
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'ENI, Fianarantsoa, Hybride,Genie Logiciel,Systeme et Reseaux, Madagadascar,Informatique,Ecole Nationale,Andrainjato,Ecole Nationale d\'Informatique'},
      {name: 'description', content: 'Ecole Nationale d\'Informatique, Pépinière des Elites Informatiques Malgaches'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
