import {Component, OnInit} from '@angular/core';
import {ConnexionApiService} from '../../services/connexion-api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Utilisateur} from '../../classes/utilisateur';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth-service.service';
import {AuthEtudiantService} from '../../services/auth-etudiant.service';
import {ServiceAPIService} from '../../services/service-api.service';
import {Profile} from '../../classes/profile';
import {AuthEnseignantService} from '../../services/auth-enseignant.service';
import {EmailService} from '../../services/email.service';
import { HttpClient } from '@angular/common/http';

interface ProfileAndId {
  profile: Profile;
  id: number;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public newUser = new Utilisateur();
  public id;
  form: FormGroup;
  emailForm: FormGroup;
  utilisateur: Utilisateur;
  profileList: Array<Profile>;
  tentative: number;

  returnUrl: string;
  error = '';

  erreur = false;
  erreurMachine = false;
  mailSent = false;
  tropDeTentative = false;
  champsVide = false;
  erreurMail = false;
  tropDeTentativeMachine = false;
  canConnect = true;

  passwordRecovery = false;
  mailError = false;
  submitted = false;

  constructor(
    private _emailService: EmailService,
    private router: Router,
    public fb: FormBuilder,
    private connexionAPIService: ConnexionApiService,
    private authEtudiantService: AuthEtudiantService,
    private authEnseignantService: AuthEnseignantService,
    private authService: AuthService,
    private serviceAPI: ServiceAPIService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.emailForm = this.fb.group({
      email: ['', Validators.required],
    });
    this.form = this.fb.group({
      numero_matricule: ['', Validators.required],
      mot_de_passe: ['', Validators.required]
    });
    if (localStorage.getItem('currentUser')) {
      await this.connexionAPIService.redirectToPage();
    }
  }

  async EnvoyerEmail(email) {
    const formData = new FormData();
    formData.append('mail', email);
    const data = await this.serviceAPI.checkMailExists(formData).toPromise();
    if (data.mailExist === true) {
      this._emailService.sendEmailLogin({
          from: email,
          to: 'eni.noreply.univfianar@gmail.com',
          password: data.mdp
      }
      ).subscribe(
        () => {
        },
        err => {
          if (err){
            this.erreurMail = true;
            this.mailSent = false;
            this.mailError = false;
          }
        }
      );
      this.mailSent = true;
      this.erreur = false;
      this.tropDeTentative = false;
      this.champsVide = false;
      this.erreurMachine = false;
      this.erreurMail = true;
      this.mailError = false;
    } else {
      this.mailError = true;
      this.erreur = false;
      this.mailSent = false;
      this.tropDeTentative = false;
      this.champsVide = false;
      this.erreurMachine = false;
    }
    this.emailForm.reset();
  }

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.form.invalid){
      this.champsVide = true;
      this.erreur = false;
      this.tropDeTentative = false;
      return;
    } else {
      const formValue = this.form.value;
      const data = await this.serviceAPI.log(formValue).toPromise();
      if (data.blocked === true) {
        this.champsVide = false;
        this.erreur = false;
        this.tropDeTentative = true;
        this.erreurMachine = false;
      } else if (data.isLoggingOk === false) {
        if (data.tentative) {
          this.tentative = data.tentative;
          this.champsVide = false;
          this.erreur = true;
          this.tropDeTentative = false;
          this.erreurMachine = false;
          const update = {
            numero_matricule: formValue.numero_matricule,
            tentative: data.tentative
          };
          await this.serviceAPI.updateLog(update).toPromise();
        } else {
          this.champsVide = false;
          this.erreur = false;
          this.tropDeTentative = false;
          this.erreurMachine = true;
        }
      } else if (data.token){
        localStorage.setItem('currentUser', data.token);
        window.location.reload();
      }
    }
  }
}

