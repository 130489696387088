import {Component, OnInit, ViewChild} from '@angular/core';
import {ServiceAPIService} from '../../services/service-api.service';
import {Article} from '../../classes/article';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as $ from 'jquery';
import {ServiceEtudiantService} from '../../services/service-espace-etudiant/service-etudiant.service';

@Component({
  selector: 'app-actus-etudiant',
  templateUrl: './actus-etudiant.component.html',
  styleUrls: ['./actus-etudiant.component.scss']
})
export class ActusEtudiantComponent implements OnInit {
  @ViewChild('articleModal') articleModal: any;

  articleToShow: any;
  articlesList: any;
  public image;
  public imagep;
  public show;
  form: FormGroup;
  notFound = false;
  search = false;
  prevLinkActive = false;
  nextLinkActive = false;

  constructor(private serviceAPI: ServiceEtudiantService, private formBuilder: FormBuilder) { }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getArticles();
  }

  initForm(){
    this.form = this.formBuilder.group({
      titre: ['']
    });
  }

  // tslint:disable-next-line:typedef
  async getArticles() {
    this.notFound = false;
    this.search = false;
    const arts: Article[] = [];
    this.articlesList = await this.serviceAPI.getActualite().toPromise();
  }

  rechercher() {
    this.search = true;
    const titre = this.form.get('titre').value;
    const search = (titre.toString()).toLowerCase();
    const res = this.articlesList.filter( art => ((art.titre).toLowerCase()).includes(search));
    this.articlesList = res;
    if (this.articlesList.length === 0) {
      this.notFound = true;
    }
  }

  showArticle(article: any) {
    this.articleToShow = article;
    this.loadModal();
    this.show = false;
  }

  loadModal() {
    $('#detailarticle').removeClass('animate__fadeOutTopLeft');
    $('#detailarticle').addClass('animate__fadeIn');
    this.articleModal.nativeElement.style.display = 'block';
    document.body.classList.add('modal-open');
  }

  next(article: Article) {
      if (this.articlesList[this.articlesList.indexOf(article) + 1]) {
        if (!this.articlesList[this.articlesList.indexOf(article) + 2]) {
          this.nextLinkActive = true;
        }
        this.articleToShow = this.articlesList[this.articlesList.indexOf(article) + 1];
        this.prevLinkActive = false;
      }
  }

  prev(article: Article) {
      if (this.articlesList[this.articlesList.indexOf(article) - 1]) {
        if (!this.articlesList[this.articlesList.indexOf(article) - 2]) {
          this.prevLinkActive = true;
        }
        this.articleToShow = this.articlesList[this.articlesList.indexOf(article) - 1];
        this.nextLinkActive = false;
      }
  }

  close() {
    $('.modal-open').removeClass('modal-open');
    $('.modal-backdrop').removeClass('show');
    $('.modal-backdrop').removeClass('modal-backdrop');
    this.articleModal.nativeElement.style.display = 'none';
    this.show = false;
  }
}
