<div class="container mb-5">
  <div class="row">
    <div class="col-md-3 col-sm-12 pl-5">

      <dx-scroll-view id="scroll">

        <button class="btn btn-primary mb-5 item dx-card btn-block" (click)="valider()">Enregistrer</button>

        <dx-draggable id="list" data="dropArea" [group]="draggingGroupName" (onDragStart)="onListDragStart($event)">
          <dx-draggable class="item dx-card dx-theme-text-color dx-theme-background-color" [clone]="true"
                        [group]="draggingGroupName" [data]="assign" (onDragStart)="onItemDragStart($event)"
                        (onDragEnd)="onItemDragEnd($event)" *ngFor="let assign of assignationMatiere">
            {{assign.UE.libelle}}
          </dx-draggable>
        </dx-draggable>
      </dx-scroll-view>
    </div>
    <div class="col-md-9 col-sm-12" id="print-section">
      <dx-scheduler [startDayHour]="7" [endDayHour]="19" timeZone="Indian/Antananarivo"
                    [dataSource]="emploisDuTemps" currentView="Semaine" [height]="600" [editing]="true"
                    [firstDayOfWeek]="1"
                    (onAppointmentDeleted)="onAppointmentDeleted($event)"
                    (onAppointmentUpdated)="onAppointmentUpdated($event)"
                    (onCellClick)="onCellClick($event)"
                    [views]='["day", "week", "month"]'
                    #targetDataGrid


      >
        <dxi-resource fieldExpr="typeUeId" label="Type UE" [allowMultiple]="true" [dataSource]="tableTypeUe">
        </dxi-resource>

        <dxi-view type="week" name="Semaine">
        </dxi-view>
        <dxi-view type="month" name="Mois">
        </dxi-view>


        <dxo-appointment-dragging [group]="draggingGroupName" [onAdd]="onAppointmentAdd">
        </dxo-appointment-dragging>
      </dx-scheduler>

    </div>
  </div>
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4">
    </div>
    <div class="col-4">
      <button class=" btn btn-secondary float-right mt-3" printSectionId="print-section" [useExistingCss]="true"
              ngxPrint>Imprimer
      </button>
    </div>
  </div>
</div>


<notifier-container></notifier-container>
