<section id="articleEnseignant" class="font-roboto " *ngIf="utilisateurConnecte">
      <div class="card user-card">
        <div class="card-header">
          <h5></h5>
        </div>
        <div class="card-block">
          <div class="user-image">
            <img src="/api/{{utilisateurConnecte.photo}}" class="img-radius" alt="User-Profile-Image">
          </div>
          <p class="text-muted">  {{utilisateurConnecte.numero_matricule}}</p>
          <h6 class="f-w-600 m-t-25 m-b-10">{{utilisateurConnecte.nom }}  {{utilisateurConnecte.prenom}}</h6>
          <p class="text-muted">  {{utilisateurConnecte.titre}}</p>
          <p class="text-muted">  {{utilisateurConnecte.permanence}}</p>
          <p class="text-muted">Adresse electronique : {{utilisateurConnecte.adresse_mail}}</p>
        </div>
      </div>
</section>






