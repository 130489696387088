<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 class="card-title" *ngIf="ajouter">Ajouter un étudiant</h5>
      <h5 class="card-title" *ngIf="modifier">Modifier l'étudiant</h5>
    </div>
    <div class="card-body">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-10 mx-auto py-4">
        <div id="image" [ngStyle]="{'width': '100%','max-width': '250px' , 'height': '250px', 'background-size': 'cover', 'background-position': 'center', 'background-image': image }"></div>
        <div class="mb-3 row" *ngIf="ajouter">
          <label for="photo" class="col-lg-3 col-md-10 col-form-label">Photo</label>
          <div class="col-sm-9">
            <div class="mb-3">
              <input  type="file" id="photo" class="form-control-file" (change)="onFileSelect($event)" style="width: 100%">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-sm-10 mx-auto px-0">
        <div class="card-body">
          <form [formGroup]="updateForm" (ngSubmit)="update()">
            <input type="input" class="form-control" formControlName="id" hidden>
            <div class="mb-3 row">
              <label class="col-lg-3 col-md-10 col-form-label">N° matricule</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" formControlName="numero_matricule" *ngIf="ajouter" [ngClass]="{ 'is-invalid': submitted && f.numero_matricule.errors }">
                <input type="input" class="form-control" formControlName="numero_matricule" *ngIf="modifier" readonly>
                <div *ngIf="submitted && f.numero_matricule.errors" class="invalid-feedback">
                  <div *ngIf="f.numero_matricule.errors.required">Veuillez renseigner le numéro matricule</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="nom" class="col-lg-3 col-md-10 col-form-label">Nom</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" id="nom" formControlName="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }">
                <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                  <div *ngIf="f.nom.errors.required">Veuillez renseigner le nom de l'étudiant</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="prenom" class="col-lg-3 col-md-10 col-form-label">Prénom</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" id="prenom" formControlName="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }">
                <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
                  <div *ngIf="f.prenom.errors.required">Veuillez renseigner le prénom de l'étudiant</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="adresse_mail" class="col-lg-3 col-md-10 col-form-label">Adresse email</label>
              <div class="col-sm-9">
                <input type="email" class="form-control" id="adresse_mail" formControlName="adresse_mail" [ngClass]="{ 'is-invalid': submitted && f.adresse_mail.errors }">
                <div *ngIf="submitted && f.adresse_mail.errors" class="invalid-feedback">
                  <div *ngIf="f.adresse_mail.errors.required">Veuillez renseigner l'adresse mail</div>
                  <div *ngIf="f.adresse_mail.errors.email">Veuillez entrer une addresse email valide</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="parcours" class="col-lg-3 col-md-10 col-form-label">Parcours</label>
              <div class="col-sm-9">
                <select class="form-control" id="parcours"  formControlName="parcours" [ngClass]="{ 'is-invalid': submitted && f.parcours.errors }">
                  <option *ngFor="let parcours of parcoursList" [ngValue]="parcours.id">{{parcours.libelle}}</option>
                </select>
                <div *ngIf="submitted && f.parcours.errors" class="invalid-feedback">
                  <div *ngIf="f.parcours.errors.required">Veuillez renseigner un parcours</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="niveau" class="col-lg-3 col-md-10 col-form-label">Niveau</label>
              <div class="col-sm-9">
                <select class="form-control" id="niveau" formControlName="niveau" [ngClass]="{ 'is-invalid': submitted && f.niveau.errors }">
                  <option *ngFor="let niveau of niveauList" [ngValue]="niveau.id">{{niveau.libelle}}</option>
                </select>
                <div *ngIf="submitted && f.niveau.errors" class="invalid-feedback">
                  <div *ngIf="f.niveau.errors.required">Veuillez renseigner un niveau</div>
                </div>
              </div>
            </div>
            <button class="btn bg-forth text-clear mr-2 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
            <button class="btn btn-secondary mb-3" type="reset"><i class="fa fa-save mr-2"></i>Annuler</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="example"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="etudiantsList.length">
        <thead>
        <tr>
          <th>Numero matricule</th>
          <th>Nom et prénoms</th>
          <th>Parcours</th>
          <th>Niveau</th>
          <th>Profile</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let utilisateur of etudiantsList">
          <td>{{ utilisateur.numero_matricule }}</td>
          <td>
            <div class="">
              <p class="font-weight-bold mb-0">{{utilisateur.nom}}  {{utilisateur.prenom}}</p>
              <p class="text-muted mb-0">{{utilisateur.adresse_mail}}</p>
            </div>
          </td>
          <td><div *ngIf="utilisateur.parcours">{{ utilisateur.parcours.libelle }}</div></td>
          <td><div  *ngIf="utilisateur.niveau">{{ utilisateur.niveau.libelle }}</div></td>
          <td>
            <div *ngFor="let profile of utilisateur.profiles; let index = index">{{ profile.libelle }}
              <span *ngIf="index!== utilisateur.profiles.length-1"> - </span>
            </div>
          </td>
          <td>
            <button class="btn btn-primary mr-2" (click)="updating(utilisateur)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(utilisateur)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal" id="delete" data-backdrop="false" role="dialog" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="etudiantToDelete"> {{etudiantToDelete.nom}} {{etudiantToDelete.prenom}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUtilisateur()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
</div>
<notifier-container></notifier-container>
