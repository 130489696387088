
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Utilisateur } from 'src/app/classes/utilisateur';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import { DatePipe } from '@angular/common';
import {ServiceEtudiantService} from '../../services/service-espace-etudiant/service-etudiant.service';
import {UtilisateurConnecte} from '../../classes/utilisateur-connecte';
import {PassClubService} from '../../services/pass-club.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-wysywig',
  templateUrl: './wysywig.component.html',
  styleUrls: ['./wysywig.component.scss']
})
export class WysywigComponent implements OnInit {
  Date: Date = new Date();
  public theDate;
  private readonly notifier: NotifierService;
  public submitted=false;
  Wysywigform: FormGroup;
  utilisateurConnecte: UtilisateurConnecte;
  public Modid;
  public Modlabel;
  public clubid;
  public clublabel;
  public clubs;
  public audiences;
  public types;
  image: string;
  constructor( public fb: FormBuilder,
  private serviceAPI: ServiceAPIService,
  notifierService: NotifierService,
               private datePipe: DatePipe,
               private serviceEtudiantAPI: ServiceEtudiantService,
               private pass: PassClubService,
               private router: Router
    ){
    this.initForm();
    this.notifier = notifierService;
    const club = this.pass.getClub();
    if (club) {
      this.clubid = club.id_club;
      this.clublabel = club.libelle;
      this.Modlabel = club.nom + ' ' + club.prenom;
      this.Modid = club.idutilisateur;
    } else {
      this.serviceEtudiantAPI.getUtilisateurConnecte().subscribe( utilisateur => {
        if ((utilisateur.profile.filter(profile => profile.libelle === 'Etudiant')).length > 0) {
          this.router.navigate(['/espace-etudiant/clubs']);
        } else if ((utilisateur.profile.filter(profile => profile.libelle === 'Ancien')).length > 0) {
          this.router.navigate(['/espace-ancien/clubs']);
        }else if ((utilisateur.profile.filter(profile => profile.libelle === 'Enseignant')).length > 0) {
          this.router.navigate(['/espace-enseignant/clubs']);
        }
      });
    }

  }
  async ngOnInit(): Promise<void> {
    await this.getUtilisateurConnecte();
    this.initForm()
    this.GetClub();
    this.GetAudience();
  }

  initForm(){
    this.theDate = this.datePipe.transform(this.Date, 'yyyy-MM-dd');
    this.Wysywigform = this.fb.group({
      titre: ['',Validators.required],
      contenu: ['',Validators.required],
      date: [{value:this.theDate,disabled: true},Validators.required,],
      moderateur: [{value: this.Modlabel,disabled: true},Validators.required],
      club: [{value:this.clublabel,disabled: true},Validators.required,],
      audiences: [''],
      photo: ['',Validators.required],
    });
  }
  get f() { return this.Wysywigform.controls; }
  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.serviceEtudiantAPI.getUtilisateurConnecte().toPromise();
    this.Modid=this.utilisateurConnecte.id;
  }

  Publier(){
    this.submitted=true;
  const formData = new FormData();
  // const formDataG = new FormData();

  formData.append('titre', this.Wysywigform.get('titre').value);
  formData.append('date', this.Wysywigform.get('date').value);
  formData.append('contenu', this.Wysywigform.get('contenu').value);
  formData.append('utilisateur', this.Modid);
  formData.append('typearticle', '4');
  formData.append('club', this.clubid);
  formData.append('photo', this.Wysywigform.get('photo').value);

  if (this.Wysywigform.invalid) {
    this.NotificationShowWrong();
    return;
  }
  else{
    this.serviceAPI.postArticle(formData).subscribe();
    this.NotificationShowSucces();
    this.Wysywigform.reset();
    this.Wysywigform.reset();
    this.submitted = false;
    window.location.reload();
  }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {

      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        this.image = 'url(' + reader.result.toString() + ')';

      };
      reader.readAsDataURL(file);

      this.Wysywigform.get('photo').setValue(file);

    }
  }
  GetClub(){
    this.serviceAPI.getClub().subscribe(data => {
      this.clubs = data;
    });
  }
  GetAudience(){
    this.serviceAPI.getAudience().subscribe(data => {
      this.audiences = data;
    });
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'danger',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
}
