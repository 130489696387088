
<div class="container">
  <div class="row py-5">
    <div class="col-12">
      <table class="table table-striped" style="width:100%"  datatable [dtOptions]="dtOptions" *ngIf="formationList">
        <thead>
        <tr>
          <th>Nom de la formation</th>
          <th>A propos</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let formation of formationList">
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p>{{ formation.libelle }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p>{{ formation.explication }}</p>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
