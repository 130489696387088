<section id="motDirecteur" *ngIf="motDuDirecteur" class="bg-clair ">
  <div class=" aos-init aos-animate " data-aos="fade-up">
    <header class="section-header wow fadeInUp mb-5">
      <h3>Mot du Président
      </h3>
    </header>
    <div class="container">
      <div class="row mdd-content mb-5">
        <div class="col-md-4 m-0 p-0 mdd-img-item">
          <img src="{{photo}}" class="img-fluid" style="border-top-left-radius: 7px;"/>
        </div>
        <div class="col-md-8">
          <blockquote class="mt-5 m-0">
            <p class="text-justify text-grey" [innerHTML]="motDuDirecteur.contenu"></p>
            <cite>
              <img src="/assets/signature.png" alt="signature">
              <p class="font-roboto ">
                {{ motDuDirecteur.titre }}
              </p>
            </cite>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</section>
