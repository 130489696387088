import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json',
    Authorization: '@3kpSs+7Q5n6t-RH5ai4M@=Qp83c89nAw8nVn<3tP9#Tmg23-Q?*t539/fn8MDqh<e9Cx$55R@65Rh2C3%p.q8Y8V&53h_Sxr'
  });

  constructor(private http: HttpClient) { }

  sendEmail(data) {
    return this.http.post('/api/email/', data, {headers: this.headers})
      .map(res => {})
      .catch(this._errorHandler);
  }

  sendEmailLogin(data) {
    return this.http.post('/api/email/emailLogin', data, {headers: this.headers})
      .map(res => {})
      .catch(this._errorHandler);
  }

  private _errorHandler(error: Response) {
    console.error(error);
    return Observable.throw(error || 'Server Error')
  }
}
