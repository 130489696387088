
<div class="font-text">
  <app-enseignant-header></app-enseignant-header>
  <div class="container-fluid mb-5 px-5 font-text">
    <section>
      <header class="section-header wow fadeInUp">
        <h3>
          EMPLOI DU TEMPS
        </h3>
      </header>
    </section>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12">
        <app-schedule-enseignant></app-schedule-enseignant>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12">
        <app-profile-enseignant></app-profile-enseignant>
      </div>
    </div>
  </div>
  <div class="container ">
    <app-article-enseignant></app-article-enseignant>
  </div>
</div>





