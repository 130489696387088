import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  isAuth = false;

  signIn() {
    this.isAuth = true;
    this.router.navigate(['/toor/dashboard']);
  }

  signOut() {
    this.isAuth = false;
    localStorage.clear();
    this.router.navigate(['/toor/dashboard']);
  }

}
