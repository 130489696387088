import { Component, OnInit } from '@angular/core';
import {Schedule} from '../../../../classes/schedule';
import {NotifierService} from 'angular-notifier';
import {ServiceAPIService} from '../../../../services/service-api.service';

@Component({
  selector: 'app-sr',
  templateUrl: './sr.component.html',
  styleUrls: ['./sr.component.scss']
})
export class SrComponent implements OnInit {
  draggingGroupName: string = 'appointmentsGroup';
  public fetchOk = false;



  public actifL1 = true;
  public actifL2 = false;
  public actifL3 = false;
  public actifM1 = false;
  public actifM2 = false;

  public datas;

  public tableTypeUe = []

  public emploisDuTempsL1Sr = []
  public emploisDuTempsL2Sr = []
  public emploisDuTempsL3Sr = []
  public emploisDuTempsM1Sr = []
  public emploisDuTempsM2Sr = []


  public assignationMatiereL1Sr=[];
  public assignationMatiereL2Sr=[];
  public assignationMatiereL3Sr=[];
  public assignationMatiereM1Sr=[];
  public assignationMatiereM2Sr=[];

  public attributNiveauL1
  public attributNiveauL2
  public attributNiveauL3
  public attributNiveauM1
  public attributNiveauM2
  public attributParcours
  notifierService: NotifierService;

  constructor(private api: ServiceAPIService,
              notifierService: NotifierService,

  ) {

  }

  ngOnInit(): void {
    this.getAssignation();
    this.getTypeuE();
    this.getParcours();
    this.getNiveaux();
  }
  active(niveau) {
    this.actifL2=false
    this.actifL1=false
    this.actifL3=false
    this.actifM1=false
    this.actifM2=false

    if (niveau === 'L1') {
      this.actifL1 = true;
    }
    if (niveau === 'L2') {
      this.actifL2 = true;
    }
    if (niveau === 'L3') {
      this.actifL3 = true;
    }
    if (niveau === 'M1') {
      this.actifM1 = true;
    }
    if (niveau === 'M2') {
      this.actifM2 = true;
    }
  }
  getNiveaux(){
    var attributNiveauL1
    var attributNiveauL2
    var attributNiveauL3
    var attributNiveauM1
    var attributNiveauM2


    this.api.getNiveau().subscribe(data=>{
      data.forEach(element=>{
        if(element.libelle=='L1'){
          attributNiveauL1=element
        }
        if(element.libelle=='L2'){
          attributNiveauL2=element
        }
        if(element.libelle=='L3'){
          attributNiveauL3=element
        }
        if(element.libelle=='M1'){
          attributNiveauM1=element
        }
        if(element.libelle=='M2'){
          attributNiveauM2=element
        }

      })
      this.attributNiveauL1=attributNiveauL1.id
      this.attributNiveauL2=attributNiveauL2.id
      this.attributNiveauL3=attributNiveauL3.id
      this.attributNiveauM1=attributNiveauM1.id
      this.attributNiveauM2=attributNiveauM2.id

    })

  }
  getParcours(){
    var attributParcours
    this.api.getParcours().subscribe(data=>{
      data.forEach(element=>{
        if(element.libelle=='Système et réseaux'){
          attributParcours=element
        }
      })
      this.attributParcours=attributParcours.id;
    })
  }
  updatedEmploisDuTemps($event) {
    this.getAssignation();
  }
  getAssignation() {
    this.getTypeuE();
    var tabL1Sr=[]
    var tabL2Sr=[]
    var tabL3Sr=[]
    var tabM1Sr=[]
    var tabM2Sr=[]

    this.api.getAssignations().subscribe(data => {
      data.forEach(el=>{
        if(el.parcours.libelle=="Système et réseaux" && el.niveau.libelle=="L1"){
          tabL1Sr.push(el)
        }
        if(el.parcours.libelle=="Système et réseaux" && el.niveau.libelle=="L2"){
          tabL2Sr.push(el)
        }
        if(el.parcours.libelle=="Système et réseaux" && el.niveau.libelle=="L3"){
          tabL3Sr.push(el)
        }
        if(el.parcours.libelle=="Système et réseaux" && el.niveau.libelle=="M1"){
          tabM1Sr.push(el)
        }
        if(el.parcours.libelle=="Système et réseaux" && el.niveau.libelle=="M2"){
          tabM2Sr.push(el)
        }
      })

      this.assignationMatiereL1Sr=tabL1Sr;
      this.assignationMatiereL2Sr=tabL2Sr;
      this.assignationMatiereL3Sr=tabL3Sr;
      this.assignationMatiereM1Sr=tabM1Sr;
      this.assignationMatiereM2Sr=tabM2Sr;

      this.datas = data;

      var emploisduTempsL1 = [];
      var emploisduTempsL2 = [];
      var emploisduTempsL3 = [];
      var emploisduTempsM1 = [];
      var emploisduTempsM2 = [];

      this.datas.forEach(el => {
        if(el.niveau.libelle=="L1"&& el.parcours.libelle=="Système et réseaux"){
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsL1.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            )
          });
        }

        if(el.niveau.libelle=="L2"&& el.parcours.libelle=="Système et réseaux"){
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsL2.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            )
          });
        }

        if(el.niveau.libelle=="L3"&& el.parcours.libelle=="Système et réseaux"){
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsL3.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            )
          });
        }

        if(el.niveau.libelle=="M1"&& el.parcours.libelle=="Système et réseaux"){
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsM1.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            )
          });
        }
        if(el.niveau.libelle=="M2"&& el.parcours.libelle=="Système et réseaux"){
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsM2.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            )
          });
        }

      });
      this.emploisDuTempsL1Sr = emploisduTempsL1;
      this.emploisDuTempsL2Sr = emploisduTempsL2;
      this.emploisDuTempsL3Sr = emploisduTempsL3;
      this.emploisDuTempsM1Sr = emploisduTempsM1;
      this.emploisDuTempsM2Sr = emploisduTempsM2;

      this.fetchOk = true;
    })
  }
  getTypeuE() {
    this.api.getTypeue().subscribe(typeue => {
      typeue.forEach(el => {
        this.tableTypeUe.push({
          id: el.id,
          color: el.couleur,
          text: el.libelle
        })
      })
    })

  }

}
