import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../classes/utilisateur';
import {ServiceAPIService} from "../services/service-api.service";

@Component({
  selector: 'app-espace',
  templateUrl: './espace.component.html',
  styleUrls: ['./espace.component.scss']
})
export class EspaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  getUtilisateur(){
    return localStorage.getItem('utilisateurConnecte');
  }
}
