import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {NotifierService} from 'angular-notifier';
import {VieEtudiant} from "../../../../classes/vie-etudiant";
import {Partenaire} from "../../../../classes/partenaire";

@Component({
  selector: 'app-partenaire',
  templateUrl: './partenaire.component.html',
  styleUrls: ['./partenaire.component.scss']
})
export class PartenaireComponent implements OnInit {

  APIimage =  this.api.APIURL;
  image: string;
  formPartenaire: FormGroup;
  partenaireToDelete: Partenaire;
  Media: any;
  galeries: string;
  public imagetoshow;
  private readonly notifier: NotifierService;

  public url: any;
  public photos: any;
  public photo;


  constructor(
    private api: ServiceAPIService,
    public fb: FormBuilder,
    notifierService: NotifierService
  ) {
    this.formPartenaire = this.fb.group({
      photo: ['', Validators.required]
    });
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.getPartenaire();
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        this.image = 'url(' + reader.result.toString() + ')';

      };
      reader.readAsDataURL(file);
      this.formPartenaire.get('photo').setValue(file);
    }
  }

  // tslint:disable-next-line:typedef
  postPartenaire(){
    const formData = new FormData();
    formData.append('photo', this.formPartenaire.get('photo').value);
    this.api.postPartenaires(formData).subscribe(data => {
      this.NotificationShowSucces();
      this.url = data.chemin;
      this.formPartenaire.reset();
      window.location.reload();
    });
  }

  // tslint:disable-next-line:typedef
  getPartenaire(){

    this.api.getPartenaires().subscribe(data => {
      this.photos = data;
      for (const photo of this.photos) {
        photo.imageData = this.APIimage + photo.photo;
        this.photo = photo.imageData;
      }
    });
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',
    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',
    });
  }

  loadDeleteConfirm(partenaire: Partenaire) {
    this.partenaireToDelete = partenaire;
  }

  delete() {
    this.api.deletePartenaires(this.partenaireToDelete).subscribe( () => {
      window.location.reload();
    });
  }

}
