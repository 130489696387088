<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container bootstrap snippets bootdey">
  <div class="row">

    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-dark panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">UE</p>
          <i class="fa fa-book fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbUes.length}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-danger panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">Type UE</p>
          <i class="fa fa-newspaper-o fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbTypeUe.length}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-primary panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">Assignations</p>
          <i class="fa fa-tasks fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbAssignation.length}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-info panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">Enseignants</p>
          <i class="fa fa-users fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbProffesseurs.length}}</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container">
  <div class="row">
    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-blue">
        <div class="inner">
          <h3>{{NbEtudiants.length}}</h3>
          <p>Etudiants</p>
        </div>
        <div class="icon">
          <i class="fa fa-users" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-green">
        <div class="inner">
          <h3>{{NbEtudiantsSR.length}}</h3>
          <p>SR</p>
        </div>
        <div class="icon">
          <i class="fa fa-users" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-orange">
        <div class="inner">
          <h3>{{NbEtudiantsIG.length}}</h3>
          <p>  IG</p>
        </div>
        <div class="icon">
          <i class="fa fa-users" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-red">
        <div class="inner">
          <h3>{{NbEtudiantsGB.length}}</h3>
          <p> GB </p>
        </div>
        <div class="icon">
          <i class="fa fa-users"></i>
        </div>
      </div>
    </div>
  </div>
</div>


