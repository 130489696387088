import {Parcours} from './parcours';
import {Niveau} from './niveau';
import {Profile} from './profile';
import {Club} from './club';
import {Opportunite} from './opportunite';

export class Utilisateur {
  id: number;
  numero_matricule: string;
  matricule:string;
  nom: string;
  prenom: string;
  adresse_mail: string;
  mot_de_passe: string;
  photo: string;
  disponible: boolean;
  niveau: Niveau;
  parcours: Parcours;
  profiles: Profile[];
  club: Club[];
  opportunites: Opportunite[];
  biographie: string;
  titre: string;
  token?: string;
  active: boolean;
  permanence: string;
  tentative: number;
  profile;
}
