import {Enseignant} from './enseignant';
import {Typeue} from './typeue';
import {Schedule} from './schedule';

export class UE {
  id: number;
  libelle: string;
  typeue: Typeue;
  shedule: Schedule;
}
