<div class="container">
  <div class="card">
    <div class="card">
      <div class="card-header bg-forth text-clear text-center">
        <h4 *ngIf="ajouter">Assigner</h4>
        <h4 *ngIf="modifier">Modifier l' assignation</h4>
      </div>
      <div class="card-body px-5">
        <div class="">
          <form [formGroup]="updateForm" (ngSubmit)="update()">
            <input type="text" class="form-control" formControlName="id" hidden>
            <div class="form-group row">
              <label for="ue" class="col-md-4">Unité d'enseignement</label>
              <div class="col-lg-8 col-md-8">
                <select class="form-control" id="ue"  formControlName="ue" [ngClass]="{ 'is-invalid': submitted && f.ue.errors }">
                  <option *ngFor="let ue of ueList" [ngValue]="ue.id">{{ue.libelle}}</option>
                </select>
                <div *ngIf="submitted && f.ue.errors" class="invalid-feedback">
                  <div *ngIf="f.ue.errors.required">Veuillez renseigner un ue</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="parcours" class="col-md-4">Parcours</label>
              <div class="col-lg-8 col-md-8">
                <select class="form-control" id="parcours"  formControlName="parcours" [ngClass]="{ 'is-invalid': submitted && f.parcours.errors }">
                  <option *ngFor="let parcours of parcoursList" [ngValue]="parcours.id">{{parcours.libelle}}</option>
                </select>
                <div *ngIf="submitted && f.parcours.errors" class="invalid-feedback">
                  <div *ngIf="f.parcours.errors.required">Veuillez renseigner un parcours</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="niveau" class="col-md-4">Niveau</label>
              <div class="col-lg-8 col-md-8">
                <select class="form-control" id="niveau"  formControlName="niveau" [ngClass]="{ 'is-invalid': submitted && f.niveau.errors }">
                  <option *ngFor="let niveau of niveauList" [ngValue]="niveau.id">{{niveau.libelle}}</option>
                </select>
                <div *ngIf="submitted && f.niveau.errors" class="invalid-feedback">
                  <div *ngIf="f.niveau.errors.required">Veuillez renseigner un niveau</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="enseignant" class="col-md-4">Enseignant</label>
              <div class="col-lg-8 col-md-8">
                <select class="form-control" id="enseignant"  formControlName="utilisateur" [ngClass]="{ 'is-invalid': submitted && f.utilisateur.errors }">
                  <option *ngFor="let enseignant of enseignantList" [ngValue]="enseignant.id">{{enseignant.nom}} {{enseignant.prenom}}</option>
                </select>
                <div *ngIf="submitted && f.utilisateur.errors" class="invalid-feedback">
                  <div *ngIf="f.utilisateur.errors.required">Veuillez renseigner un niveau</div>
                </div>
              </div>
            </div>
            <button class="btn bg-forth text-clear mr-2 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
            <button class="btn btn-secondary mb-3" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive">
        <table class="table table-striped" style="width:100%"   datatable [dtOptions]="dtOptions" *ngIf="assignationList">
          <thead>
          <tr>
            <th>UE</th>
            <th>Niveau</th>
            <th>Parcours</th>
            <th>Enseignant</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let assignation of assignationList">
            <td>
              <p *ngIf="assignation.UE">{{ assignation.UE.libelle }}</p>
            </td>
            <td>
              <p>{{ assignation.niveau.libelle }}</p>
            </td>
            <td>
              <p class="text-uppercase">{{ assignation.parcours.routerlink }}</p>
            </td>
            <td>
              <p>{{ assignation.utilisateur.nom }} {{ assignation.utilisateur.prenom }}</p>
            </td>
            <td>
              <button class="btn btn-primary mr-2" href="#" (click)="updating(assignation)"><i class="fa fa-edit"></i></button>
              <button class="btn btn-danger" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(assignation)"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal" id="delete" data-backdrop="false" role="dialog" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
