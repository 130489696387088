<div class="container" *ngIf="counter">
  <h3>Numerana : </h3>
  <form [formGroup]="updateForm" (ngSubmit)="update()">
    <input type="text" class="form-control" id="id" formControlName="id" hidden>
    <div class="row my-3">
      <div class="col">
        <label for="etudiant">Etudiants</label>
        <input type="text" class="form-control" id="etudiant" formControlName="etudiant" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.etudiant.errors }">
        <div *ngIf="submitted && f.etudiant.errors" class="invalid-feedback">
          <div *ngIf="f.etudiant.errors.required">Veuillez renseigner le nombre des étudiants</div>
        </div>
      </div>
      <div class="col">
        <label for="diplome">Diplômés</label>
        <input type="text" class="form-control" id="diplome" formControlName="diplome" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.diplome.errors }">
        <div *ngIf="submitted && f.diplome.errors" class="invalid-feedback">
          <div *ngIf="f.diplome.errors.required">Veuillez renseigner le nombre des diplômés</div>
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <label for="prix">Prix</label>
        <input type="text" class="form-control" id="prix" formControlName="prix" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.prix.errors }">
        <div *ngIf="submitted && f.prix.errors" class="invalid-feedback">
          <div *ngIf="f.prix.errors.required">Veuillez renseigner les prix obtenus</div>
        </div>
      </div>
      <div class="col">
        <label for="partenaire">Partenaires</label>
        <input type="text" class="form-control" id="partenaire" formControlName="partenaire" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.partenaire.errors }">
        <div *ngIf="submitted && f.partenaire.errors" class="invalid-feedback">
          <div *ngIf="f.partenaire.errors.required">Veuillez renseigner le nombre des partenaires</div>
        </div>
      </div>
    </div>
    <button class="btn bg-forth text-white mb-3" data-toggle="modal" data-target="#activerModif"><i class="fa fa-edit mr-2"></i>Activer la modification</button>
    <div class="pull-right mb-4 row">
      <button class="btn bg-forth text-white mr-2 mb-3" type="submit"><i class="fa fa-save mr-3"></i>Enregistrer</button>
    </div>
  </form>
</div>


<!-- Modal -->
<div class="modal fade" id="activerModif" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="activerModifLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="activerModifLabel">Modification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment activer les modifications?<br>Les changements que vous ferez seront vu dans le site d'accueil de ENI
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="activerModification()">Activer</button>
      </div>
    </div>
  </div>
</div>
