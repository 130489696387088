import { Component, OnInit } from '@angular/core';
import {AuthEtudiantService} from '../../../../services/auth-etudiant.service';
import {ConnexionApiService} from '../../../../services/connexion-api.service';
import {ServiceEtudiantService} from '../../../../services/service-espace-etudiant/service-etudiant.service';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NotifierService} from "angular-notifier";
import {ServiceAPIService} from "../../../../services/service-api.service";
import {Utilisateur} from "../../../../classes/utilisateur";

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent implements OnInit {
  utilisateurConnecte: any;
  editUtilisateurForm: FormGroup;
  utilisateurProfile: any;
  confirmedFalse = false;
  btnDisable = false;
  mdpFalse = false;
  private readonly notifier: NotifierService;

  constructor(
    private authService: AuthEtudiantService,
    private serviceAPIEtudiant: ServiceEtudiantService,
    private connexion: ConnexionApiService,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private serviceAPI: ServiceAPIService,
    ) {

    this.editUtilisateurForm = this.formBuilder.group({
      nom: '',
      prenom: '',
      adresse_mail: '',
      nouveau_mot_de_passe: '',
      confirmer_nouveau_mot_de_passe: ''
    });
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    await this.getUtilisateurConnecte();

  }
  NotificationShowSucces() {
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuée avec success',
    });
  }

  NotificationShowWrong() {
    this.notifier.show({
      type: 'warning',
      message: 'Une erreur a été comise, veuillez recommencer',
    });
  }

  NotificationShowMailExist(){
    this.notifier.show({
      type: 'warning',
      message: 'L\'adresse mail appartient à un autre compte',
    });
  }
  signOut() {
    this.connexion.logout();
    this.authService.signOut();
    window.location.reload();
  }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.serviceAPIEtudiant.getUtilisateurConnecte().toPromise();
    // const utilisateur = await this.utilisateurConnecte.getUtilisateurConnecte().toPromise();
    this.utilisateurProfile = await this.serviceAPIEtudiant.getProfilOf(this.utilisateurConnecte.id).toPromise();
    console.log(this.utilisateurProfile,'eto')
  }

  updating() {
    this.editUtilisateurForm = this.formBuilder.group({
      nom: this.utilisateurProfile.nom,
      prenom: this.utilisateurProfile.prenom,
      adresse_mail: this.utilisateurProfile.adresse_mail,
      nouveau_mot_de_passe: '',
      confirmer_nouveau_mot_de_passe: ''
    });
  }
  update() {
    const value = this.editUtilisateurForm.value;
    const user = new Utilisateur();
    user.id = this.utilisateurProfile.id;
    user.nom = value.nom;
    user.prenom = value.prenom;
    if (value.adresse_mail) {
      user.adresse_mail = value.adresse_mail;
    }
    if (value.nouveau_mot_de_passe && value.confirmer_nouveau_mot_de_passe) {
      if ((value.nouveau_mot_de_passe) === (value.confirmer_nouveau_mot_de_passe)) {
        user.mot_de_passe = value.nouveau_mot_de_passe;
        this.serviceAPI.updateUtilisateurConnecte(user).subscribe( data => {
          if (data.mailExist) {
            this.NotificationShowMailExist();
          } else {
            if (data.token) {
              this.NotificationShowSucces();
              localStorage.setItem('currentUser', data.token);
              //     window.location.reload();
            }
            this.editUtilisateurForm.reset();
          }
        });
      } else {
        this.btnDisable = true;
        this.confirmedFalse = true;
      }
    }

  }

}
