import {element} from 'protractor';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Header} from 'src/app/classes/header';
import {ServiceAPIService} from 'src/app/services/service-api.service';
import {Article} from "../../../classes/article";
import {Router} from "@angular/router";
import {HeaderToActualiteService} from "../../../services/header-to-actualite.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as $ from 'jquery';
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";


@Component({
  selector: 'app-accueil-banner',
  templateUrl: './accueil-banner.component.html',
  styleUrls: ['./accueil-banner.component.scss']
})

export class AccueilBannerComponent implements OnInit, AfterViewInit {


  @ViewChildren('carousel_item') carousel_item: QueryList<ElementRef>;

  // bannerList: Array<Header>;
  bannerList: Article[] = [];
  titreList: String[] = [];
  public image;

  //chars = '!<>-_\\/[]{}—=+*^?#________';

  constructor(private serviceAPI: ServiceAccueilService,
              private router: Router,
              private headerToActualite: HeaderToActualiteService,
              private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.getHeader();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    var Messenger = function (el, texte: String) {
      'use strict';
      var m = this;

      m.init = function () {
        m.codeletters = "0101010101010101010________";
        m.message = 0;
        m.current_length = 0;
        m.fadeBuffer = false;
        m.messages = [texte];

        setTimeout(m.animateIn, 100);
      };

      m.generateRandomString = function (length) {
        var random_text = '';
        while (random_text.length < length) {
          random_text += m.codeletters.charAt(Math.floor(Math.random() * m.codeletters.length));
        }

        return random_text;
      };

      m.animateIn = function () {
        if (m.current_length < m.messages[m.message].length) {
          m.current_length = m.current_length + 2;
          if (m.current_length > m.messages[m.message].length) {
            m.current_length = m.messages[m.message].length;
          }

          var message = m.generateRandomString(m.current_length);
          $(el).html(message);

          setTimeout(m.animateIn, 35);
        } else {
          setTimeout(m.animateFadeBuffer, 20);
        }
      };

      m.animateFadeBuffer = function () {
        if (m.fadeBuffer === false) {
          m.fadeBuffer = [];
          for (var i = 0; i < m.messages[m.message].length; i++) {
            m.fadeBuffer.push({c: (Math.floor(Math.random() * 12)) + 1, l: m.messages[m.message].charAt(i)});
          }
        }

        var do_cycles = false;
        var message = '';

        for (var i = 0; i < m.fadeBuffer.length; i++) {
          var fader = m.fadeBuffer[i];
          if (fader.c > 0) {
            do_cycles = true;
            fader.c--;
            message += m.codeletters.charAt(Math.floor(Math.random() * m.codeletters.length));
          } else {
            message += fader.l;
          }
        }

        $(el).html(message);

        if (do_cycles === true) {
          setTimeout(m.animateFadeBuffer, 50);
        } else {
          setTimeout(m.cycleText, 2000);
        }
      };

      m.cycleText = function () {
        m.message = m.message + 1;
        if (m.message >= m.messages.length) {
          return;
          //m.message = 0;
        }

        m.current_length = 0;
        m.fadeBuffer = false;
        $(el).html('');

        setTimeout(m.animateIn, 200);
      };

      m.init();
    }
    var messageList = this.titreList;
    this.carousel_item.changes.subscribe(() => {
      //const elements = this.carousel_item_h2.toArray().map(i => i.nativeElement);
      // Here I can have access to the li elements when DOM updated
      this.carousel_item.toArray().forEach(function (element, index) {
        new Messenger(element.nativeElement.getElementsByClassName('heroTitle')[0], messageList[index]);
        var elemToObserve = element.nativeElement;
        var prevClassState = elemToObserve.classList.contains('active');
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName == "class") {
              var currentClassState = (<HTMLElement>mutation.target).classList.contains('active');
              if (prevClassState !== currentClassState) {
                prevClassState = currentClassState;
                if (currentClassState) {
                  new Messenger(element.nativeElement.getElementsByClassName('heroTitle')[0], messageList[index]);
                }
              }
            }
          });
        });
        observer.observe(elemToObserve, {attributes: true});
      });
    });
  }

  getHeader() {
    this.SpinnerService.show();
    this.serviceAPI.getHeader().subscribe(data => {
      this.bannerList = data;
      data.forEach(art => {
          this.titreList.push(art.titre);
      });
      this.SpinnerService.hide();
    });
  }

  // tslint:disable-next-line:typedef
  sendArticle(article: Article) {
    this.headerToActualite.sendMessage(article);
  }
}
