<header id="header" class="fixed-top d-flex align-items-center header-transparent">
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center">
      <div class="col-xl-11 d-flex align-items-center justify-content-between">
        <h1 class="logo">
          <a href="#"><img src="assets/aenifront.png" alt="" class="img-fluid">
            Anciens de l’Ecole Nationale d’Informatique
          </a>
        </h1>
        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link active scrollto nav-accueil" href="#hero">Accueil</a></li>
            <li><a class="" href="https://aaeni.fr/radio">Radio</a></li>
            <li><a class="nav-link scrollto nav-accueil" href="#actualite">Actualites</a></li>
            <li><a class="nav-link scrollto nav-accueil" href="#polaroid">Polaroid</a></li>
            <li><a class="nav-link scrollto nav-accueil" href="#temoignages">Bureau</a></li>
            <li><a class="nav-link scrollto nav-accueil" href="#historique">Historique</a></li>
<!--            <li><a id="aboutUs" data-toggle="modal" class="pointeur" data-target="#aboutUsModal">A propos</a></li>-->
            <li class="dropdown"><a><span><i class="fa fa-user-circle medium-icon pointeur"></i></span> <i
                  class="bi bi-chevron-down pointeur"></i></a>
              <ul>
                <li class="dropdown-menu pointeur"><a href="/Login">Connexion</a></li>
              </ul>
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
      </div>
    </div>
  </div>
</header>
