<div class="container-fluid aos-init aos-animate">
  <section class="mx-auto">
    <header class="section-header wow fadeInUp">
      <h3>Opportunités
      </h3>
    </header>
    <div class="container" *ngIf="canPostOpportunite">
      <button class="btn btn-secondary my-2 btn-block " data-toggle="modal"
              data-target="#ajoutMOdal">Creer
      </button>
    </div>
    <div class="row">
      <div class="container">
        <div class="multi-button mx-auto" *ngIf="opportunities">
          <button class="filter-button paste" (click)="GetOpportunite()" [ngClass]="{'focuson': filtre.indexOf('Tous') > -1}">Tous</button>
          <button class="filter-button paste" *ngFor="let typeCounted of typesopporWithCount" (click)="filterType(typeCounted.type.libelle)" [ngClass]="{'focuson': filtre.indexOf(typeCounted.type.libelle) > -1}">{{typeCounted.type.libelle}}  ({{typeCounted.count}})</button>
          <button class="filter-button paste" *ngFor="let niveauCounted of niveauxWithCount" (click)="filterNiveau(niveauCounted.niveau.niveau)" [ngClass]="{'focuson': filtre.indexOf(niveauCounted.niveau.niveau) > -1}">{{niveauCounted.niveau.niveau}} ({{niveauCounted.count}})</button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="  col-lg-4 col-md-6 col-sm-12 my-3 d-flex align-items-stretch" *ngFor="let oppor of opportunities | paginate: { itemsPerPage: 25, currentPage: p }">
        <div class="job-card shadow-lg bg-white rounded"
             [ngClass]="{'bg-stage': oppor.typeopportunite==='Stage', 'bg-emploi' : oppor.typeopportunite==='Emploi', 'bg-bourse': oppor.typeopportunite==='Bourse' }">
          <div class="searched-jobs">
            <div class="card-body">
              <div class="job-card-title type">{{oppor.typeopportunite}}</div>
              <div class="job-card-header ">
                <div class="col-8"></div>
                <div class="col-2 float-right" *ngIf="id==oppor.utilisateur">
                  <a data-toggle="modal" (click)="updating(oppor)"
                     data-target="#modifier"><i class="fa fa-edit small-icon text-warning"></i></a>
                </div>
                <div class="col-2 float-right" *ngIf="id==oppor.utilisateur">
                  <a data-toggle="modal" (click)=" passData(oppor)" data-target="#suppression"><i
                    class="fa fa-trash small-icon text-danger"></i></a>
                </div>
                <div class="menu-dot"></div>
              </div>
              <div class="job-card-title">{{oppor.titre}}</div>
              <div class="job-card-subtitle text-justify">
                {{oppor.description}}
              </div>
            </div>
            <div class="card-footer">
              <div class="list-group list-group-flush  mt-3 ">
                <div href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
                  <div class="d-flex w-100 justify-content-between">
                    <i class="fa fa-map-marker text-best"></i>
                    {{oppor.localite}}
                  </div>
                </div>
                <div href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
                  <div class="d-flex w-100 justify-content-between">
                    <i class="fa fa-briefcase text-best"></i>
                    {{oppor.institution}}
                  </div>
                </div>
                <div href="#" class="list-group-item list-group-item-action flex-column align-items-start  ">
                  <div class="d-flex w-100 justify-content-between">
                    <i class="fa fa-graduation-cap text-best"></i>
                    {{oppor.niveau}}
                  </div>
                </div>
                <div href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
                  <div class="d-flex w-100 justify-content-between">
                    <i class="fa fa-calendar text-best"></i>
                    {{oppor.date_fin}}
                  </div>
                </div>
                <div href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
                  <div class="mt-3">
                    <div class="row">
                      <div class="col">
                        <a href="mailto:{{oppor.email}}?body=Your message within Main Body"
                           style="font-size: 0.7rem;color: #044973;">
                          Contacter</a>
                      </div>
                      <div class="col">
                        <a class="search-buttons detail-button" style="font-size: 0.7rem;" data-toggle="modal"
                           (click)="getInfo(oppor)"
                           [href]="fileUrl"
                           data-target="#plus">Détails</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="text-center">
      <pagination-controls *ngIf="opportunities && opportunities.length>0"
                           (pageChange)="p = $event"
                           [directionLinks]="directionLinks"
                           [autoHide]="autoHide"
                           [responsive]="responsive"
                           [previousLabel]="labels.previousLabel"
                           [nextLabel]="labels.nextLabel"
                           [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                           [screenReaderPageLabel]="labels.screenReaderPageLabel"
                           [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel">
      </pagination-controls>
    </div>
  </section>
</div>

<div class="modal fade" id="ajoutMOdal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Creation d'une opportunite</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="opportuniteForm">
          <div class="form-group  m-2">

            <label class="" for="type">Type</label><br/>
            <select class="form-control" formControlName="typeopportunite" id="type"
                    [ngClass]="{ 'is-invalid': submitted && f.typeopportunite.errors }">
              <option *ngFor="let type of typesopporWithCount" [value]="type.type.id">{{type.type.libelle}} </option>
            </select>
            <div *ngIf="submitted && f.typeopportunite.errors" class="invalid-feedback">
              <div *ngIf="f.typeopportunite.errors.required">Veuillez renseigner un type</div>
            </div>
            <label for="localite">Localite</label>
            <input type="text" formControlName="localite" class="form-control" id="localite"
                   [ngClass]="{ 'is-invalid': submitted && f.localite.errors }">
            <div *ngIf="submitted && f.localite.errors" class="invalid-feedback">
              <div *ngIf="f.localite.errors.required">Veuillez renseigner la localite</div>
            </div>
            <label for="institution">Institution</label>
            <input type="text" formControlName="institution" class="form-control" id="institution"
                   [ngClass]="{ 'is-invalid': submitted && f.institution.errors }">
            <div *ngIf="submitted && f.institution.errors" class="invalid-feedback">
              <div *ngIf="f.institution.errors.required">Veuillez renseigner l'institution</div>
            </div>
            <label for="titre">Titre</label>
            <input type="text" formControlName="titre" class="form-control" id="titre"
                   [ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
            <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
              <div *ngIf="f.titre.errors.required">Veuillez renseigner un titre</div>
            </div>
            <label for="description">Description</label>
            <textarea class="form-control" formControlName="description" id="description" rows="3"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Veuillez renseigner une description</div>
            </div>
            <label for="profile">Profiles</label>
            <textarea class="form-control" formControlName="profile" id="profile" rows="3"
                      [ngClass]="{ 'is-invalid': submitted && f.profile.errors }"></textarea>
            <div *ngIf="submitted && f.profile.errors" class="invalid-feedback">
              <div *ngIf="f.profile.errors.required">Veuillez renseigner le profil</div>
            </div>
            <label for="mission">Missions</label>
            <textarea class="form-control" formControlName="mission" id="mission" rows="3"
                      [ngClass]="{ 'is-invalid': submitted && f.mission.errors }"></textarea>
            <div *ngIf="submitted && f.mission.errors" class="invalid-feedback">
              <div *ngIf="f.mission.errors.required">Veuillez renseigner les missions</div>
            </div>

            <label for="niveau">Niveau</label>
            <select class="form-control" formControlName="niveau" id="niveau"
                    [ngClass]="{ 'is-invalid': submitted && f.niveau.errors }">
              <option *ngFor="let niveau of niveauxWithCount"
                      [value]="niveau.niveau.id">{{niveau.niveau.niveau}}</option>
            </select>
            <div *ngIf="submitted && f.niveau.errors" class="invalid-feedback">
              <div *ngIf="f.niveau.errors.required">Veuillez renseigner un niveau</div>
            </div>

            <label for="email">Email</label>
            <input type="email" formControlName="email" class="form-control" id="email"
                   [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Veuillez renseigner votre email</div>
              <div *ngIf="f.email.errors.email">Veuillez entrer une addresse email valide</div>
            </div>
            <label for="date_fin">Date Fin</label>
            <input type="date" formControlName="date_fin" class="form-control mb-3" id="date_fin"  min="{{dateMinDate | date:'yyyy-MM-dd'}}"
                   [ngClass]="{ 'is-invalid': submitted && f.date_fin.errors }">
            <div *ngIf="submitted && f.date_fin.errors" class="invalid-feedback">
              <div *ngIf="f.date_fin.errors.required">Veuillez renseigner une date</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="Poster()">Poster</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modifier" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Modification d'une opportunite</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="opportuniteForm">
          <div class="form-group  m-2">
            <label class="">Type</label><br/>
            <select class="form-control" #type formControlName="typeopportunite"
                    [ngClass]="{ 'is-invalid': submitted && f.typeopportunite.errors }">
              <option *ngFor="let type of typesopporWithCount" [ngValue]="type.type.id">{{type.type.libelle}}</option>
            </select>
          </div>
          <div *ngIf="submitted && f.typeopportunite.errors" class="invalid-feedback">
            <div *ngIf="f.typeopportunite.errors.required">Veuillez renseigner un type</div>
          </div>
          <label for="localite">Localite</label>
          <input type="text" formControlName="localite" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.localite.errors }">
          <div *ngIf="submitted && f.localite.errors" class="invalid-feedback">
            <div *ngIf="f.localite.errors.required">Veuillez renseigner la localite</div>
          </div>
          <label for="institution">Institution</label>
          <input type="text" formControlName="institution" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.institution.errors }">
          <div *ngIf="submitted && f.institution.errors" class="invalid-feedback">
            <div *ngIf="f.institution.errors.required">Veuillez renseigner l'institution</div>
          </div>
          <div class="form-group  m-2">
            <input type="input" formControlName="id" class="form-control" hidden>
            <label for="titre">Titre</label>
            <input type="input" formControlName="titre" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
            <div *ngIf="submitted && f.typeopportunite.errors" class="invalid-feedback">
              <div *ngIf="f.typeopportunite.errors.required">Veuillez renseigner un titre</div>
            </div>

            <label for="description">Description</label>
            <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1" rows="3"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Veuillez renseigner une description</div>
            </div>

            <label for="niveau">Niveau</label>
            <select class="form-control" #niveau formControlName="niveau"
                    [ngClass]="{ 'is-invalid': submitted && f.niveau.errors }">
              <option *ngFor="let niveau of niveauxWithCount"
                      [ngValue]="niveau.niveau.id">{{niveau.niveau.niveau}}</option>
            </select>
            <div *ngIf="submitted && f.niveau.errors" class="invalid-feedback">
              <div *ngIf="f.niveau.errors.required">Veuillez renseigner un niveau</div>
            </div>
            <label for="profile">Profiles</label>
            <textarea class="form-control" formControlName="profile" rows="3"
                      [ngClass]="{ 'is-invalid': submitted && f.profile.errors }"></textarea>
            <div *ngIf="submitted && f.profile.errors" class="invalid-feedback">
              <div *ngIf="f.profile.errors.required">Veuillez renseigner le profil</div>
            </div>
            <label for="mission">Missions</label>
            <textarea class="form-control" formControlName="mission" rows="3"
                      [ngClass]="{ 'is-invalid': submitted && f.mission.errors }"></textarea>
            <div *ngIf="submitted && f.mission.errors" class="invalid-feedback">
              <div *ngIf="f.mission.errors.required">Veuillez renseigner les missions</div>
            </div>
            <label for="email">Email</label>
            <input type="email" formControlName="email" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Veuillez renseigner votre email</div>
              <div *ngIf="f.email.errors.email">Veuillez entrer une addresse email valide</div>
            </div>
            <label for="date_fin">Date Fin</label>
            <input type="date" formControlName="date_fin" class="form-control mb-3"
                   [ngClass]="{ 'is-invalid': submitted && f.date_fin.errors }">
            <div *ngIf="submitted && f.date_fin.errors" class="invalid-feedback">
              <div *ngIf="f.date_fin.errors.required">Veuillez renseigner la date</div>
            </div>

          </div>

          <div class="modal-footer">

            <button type="button" class="btn btn-primary" (click)="Update(niveau.value,type.value)">Modifier</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal" role="dialog" id="suppression">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Supprimer cette opportunite?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteOpportunite()">Confirmer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade " id="plus" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informations</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label><strong>Description</strong></label>
        <div class="mb-3">{{description}}</div>
        <label><strong>Profile</strong></label>
        <div class="mb-3">{{profile}}</div>
        <label><strong>Missions</strong></label>
        <div class="mb-3">{{mission}}</div>

      </div>
    </div>
  </div>
</div>


<notifier-container></notifier-container>
