<section id="timelineEnseignant" class="font-roboto ">
      <dx-scheduler
        *ngIf="utilisateurConnecte"
        [showCurrentTimeIndicator]='showCurrentTimeIndicator'
        [dataSource]="aff"
        [showAllDayPanel]="false"
        [views]="[{type:'day', name:'Ce jour'} , {type:'week', name:'Semaine'},{type:'timelineDay', name:'Niveaux'},{type:'month', name:'Mois'} ]"
        (onContentReady)='onContentReady($event)'
        [editing]="false"
        [startDayHour]="8"
        [endDayHour]="23"
        [height]="500"
        appointmentTemplate="appointmentTemplate"
        appointmentTooltipTemplate="tooltip-template"
        dateCellTemplate="dateCellTemplate"
      >
        <dxi-resource

          fieldExpr="niveauId"
          [dataSource]="tableNiveau">
        </dxi-resource>

        <dxi-resource
          fieldExpr="typeUeId"
          [useColorAsDefault]="true"
          [dataSource]="tableTypeUe">
        </dxi-resource>
        <div *dxTemplate="let model of 'appointmentTemplate'">
          <p>{{model.appointmentData.niveau}}</p>
          <p>{{model.appointmentData.parcours}}</p>
          <p>{{model.appointmentData.UE}}</p>
          <div class='dropdown-appointment-dates'>
            <div>{{(model.appointmentData.startDate | date:'shortTime')}}<br></div>
            <div> {{(model.appointmentData.endDate | date:'shortTime')}}</div>

          </div>
        </div>
        <div *dxTemplate="let model of 'tooltip-template'">
          <div class='movie-tooltip'>
            <img src="assets/logo-ENl.png">
            <div class='movie-info'>
              <div class='movie-title'>
                {{model.appointmentData.parcours}}
              </div>
              <div>
                {{'UE: ' + model.appointmentData.UE}}
              </div>
              <div>
                <div>{{(model.appointmentData.startDate | date:'shortTime')}}<br></div>
                <div> {{(model.appointmentData.endDate | date:'shortTime')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div *dxTemplate="let data of 'dateCellTemplate'">
          <div class="name">{{['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'][data.date.getDay()]}}</div>
          <div class="number">{{data.date.getDate()}}</div>
        </div>
      </dx-scheduler>
</section>

