<form [formGroup]="formGalerie" class="text-center border border-dark p-3">
    <p class="h4 mb-4">Ajout de photos</p>
    <label>Description</label>
    <input type="text" class="form-control" formControlName="libelle" id="libelle">
    <div class="mb-3 mt-3"id ="image" [ngStyle]="{'background-image': image, 'background-size': 'cover', 'width': '100px', 'height': '100px'}"></div>

    <input class="mb-5 ml-5 mx-auto" type="file"(change)="onFileSelect($event)" style="width: 100%">

    <button class="btn btn-prim btn-block bg-forth text-clear"  type="submit" (click)="postGalerie()">Enregistrer</button>

  </form>
  <div id="gallery" class="mt-5">
    <div *ngFor="let photo of GaleriesList">
         <img src="{{photo.photo}}" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(photo)">
     </div>

   </div>

<!-- Modal -->
<div class="modal fade" id="delete" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous supprimer la photo?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
