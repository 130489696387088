import { Component, OnInit } from '@angular/core';
import {ServiceAPIService} from "../../../services/service-api.service";
import {Article} from '../../../classes/article';
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";

@Component({
  selector: 'app-mot-directeur',
  templateUrl: './mot-directeur.component.html',
  styleUrls: ['./mot-directeur.component.scss']
})
export class MotDirecteurComponent implements OnInit {
  discoursList: Array<Article>;
  motDuDirecteur: Article;
  photo: string;

  APIimage =  this.serviceAPI.APIURL;

  constructor(private serviceAPI: ServiceAccueilService) { }

  ngOnInit(): void {
    this.getModDuDirecteur();
  }

  getModDuDirecteur() {

    // @ts-ignore
    this.serviceAPI.getMotduDirecteur().subscribe( data => {
      this.motDuDirecteur = data[0];
      this.photo = this.APIimage + this.motDuDirecteur.photo;
    });
    //   data.forEach( art => {
    //     if (art.typearticle.libelle === 'Mot du directeur' && art.afficher === true){
    //       this.motDuDirecteur = art;
    //       this.photo = this.APIimage + this.motDuDirecteur.photo;
    //     }
    //   });
    // });
  }

}
