import {Component, HostListener, OnInit, Output} from '@angular/core';
import * as $ from 'jquery';
import {Router} from "@angular/router";
import {Utilisateur} from "../../../classes/utilisateur";
import {Article} from "../../../classes/article";
import {ServiceAPIService} from "../../../services/service-api.service";
import {Enseignant} from "../../../classes/enseignant";
@Component({
  selector: 'app-menu-accueil',
  templateUrl: './menu-accueil.component.html',
  styleUrls: ['./menu-accueil.component.scss']
})
export class MenuAccueilComponent implements OnInit {
  enseignantList: Array<Enseignant>;
  aproposENI: Article[] = [];
  aproposToShow: Article;
  photo: string;
  activeCard = false;
  bannerImage = '';
  showEnseignant = false;
  enseignantToShow: Utilisateur;
  show = false;
  organigramme = false;
  enseignant = false;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {


  }

  constructor(private router: Router, private serviceAPI: ServiceAPIService) {
    $(function() {
      $(window).on('scroll', function () {
        // @ts-ignore
        if ($(window).scrollTop() > 100) {
          $('#header').addClass('header-scrolled');
        } else {
          $('#header').removeClass('header-scrolled');
        }
      });

      $('.mobile-nav-toggle').click(function (e) {
        $('#navbar').toggleClass('navbar-mobile');
        $(this).toggleClass('bi-list')
        $(this).toggleClass('bi-x')
      });
      $(".nav-accueil").click(function (e) {
        // Prevent a page reload when a link is pressed
        e.preventDefault();
        // Call the scroll function
        //alert($(this).attr('href'));
        $('html,body').animate({
          scrollTop: $($(this).attr('href')).offset().top
        }, 'slow');
      });


      /**
       * Mobile nav dropdowns activate
       */
      $('.navbar .dropdown > a').click(function (e) {
        if ($('#navbar').hasClass('navbar-mobile')) {
          e.preventDefault()
          $(this).next().toggleClass('dropdown-active');
        }
      });

    });
    $(document).ready(function() {
      $('a').click(function () {
        $('a').removeClass("active");
        $(this).addClass("active");
      });
      const navbarlinks = document.querySelectorAll('#navbar .scrollto');
      const navbarlinksActive = () => {
        const position = window.scrollY + 200;
        navbarlinks.forEach(navbarlink => {
          if (!navbarlink.getAttribute('href')) {
            return;
          }
          const section = document.querySelector(navbarlink.getAttribute('href')) as HTMLElement;
          if (!section) {
            return;
          }
          if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
            navbarlink.classList.add('active');
          } else {
            navbarlink.classList.remove('active');
          }
        });
      };
      window.addEventListener('scroll', navbarlinksActive);
      /**
       * Navbar links active state on scroll
       */

    });
  }

  async ngOnInit(): Promise<void> {
   // await this.getApropos();
  //  await this.getEnseignants();
  }

  connecter() {
    this.router.navigate(['/espace-etudiant']);
  }

  // Apropos

  // async getApropos() {
  //   let data = await this.serviceAPI.getArticle().toPromise();
  //   data.forEach( art => {
  //     if (art.typearticle.libelle === 'A propos de l\'Ecole' && art.afficher === true){
  //       this.photo = this.serviceAPI.APIURL + art.photo;
  //       art.photo = this.photo;
  //       this.aproposENI.push(art);
  //     }
  //   });
  //   this.bannerImage = this.aproposENI[0].photo;
  //   this.aproposToShow = this.aproposENI[0];
  // }

  async getEnseignants(){
    const enseignants: Array<Enseignant> = [];
    const data = await this.serviceAPI.getEnseignants().toPromise();
    data.forEach( enseignant => {
      enseignant.profiles.forEach(profile => {
        if (profile.libelle === 'Enseignant') {
          enseignant.photo = this.serviceAPI.APIURL + enseignant.photo;
          enseignants.push(enseignant);
        }
      });
    });
    this.enseignantList = enseignants;
  }


  activer(id: number, apropos: Article){
    this.showEnseignant = false;
    $('.card').removeClass('active');

    const i = id.toString();
    const element = document.getElementById(i);
    element.classList.add('active');

    this.bannerImage = apropos.photo;
    this.aproposToShow = apropos;
    this.organigramme = false;
  }

  goToAccueil(){
    this.router.navigate(['/']);
  }

  gotoEnseignant() {
    $('.card').removeClass('active');

    const element = document.getElementById('enseignant');
    element.classList.add('active');
    this.showEnseignant = true;
    this.organigramme = false;
  }

  goToOrganigramme(){
    $('.card').removeClass('active');

    const element = document.getElementById('organigramme');
    element.classList.add('active');
    this.organigramme = true;
    this.showEnseignant = false;
  }
}
