<div class="card user-card" *ngIf="utilisateurConnecte">
  <div class="card-block">
    <div class="user-image">
      <img src="/api/{{utilisateurConnecte.photo}}" class="img-radius" alt="User-Profile-Image">
    </div>
    <h6 class="f-w-600 m-t-25 m-b-10"> {{utilisateurConnecte.matricule}}
      <br> {{utilisateurConnecte.nom }}  {{utilisateurConnecte.prenom}}</h6>
    <p class="text-muted">{{utilisateurConnecte.niveau}}  {{utilisateurConnecte.parcours}} </p>
    <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-forth mr-2">assignment</i>Compétences</h6>
    <div class="overflow" [class.show]="show">
      <div *ngFor="let skill of utilisateurConnecte.skills" class="text-left">
        <small>{{skill.techno}}</small>
        <div class="progress mb-3" style="height: 5px">
          <div class="progress-bar" role="progressbar"
               [ngStyle]="{'width' : skill.pourcentage + '%' , 'background-color' : skill.couleur}" aria-valuenow="80"
               aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
    <a *ngIf="utilisateurConnecte.skills.length > 4" (click)="show = !show" type="button">{{ show ? 'Voir moins' : 'Voir Plus' }}</a>
  </div>
</div>
