import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ServiceAPIService} from '../../../services/service-api.service';
import {Utilisateur} from '../../../classes/utilisateur';
import {Historiqueexperiencepro} from '../../../classes/historiqueexperiencepro';
import {Typeexperience} from '../../../classes/typeexperience';
import {Techno} from '../../../classes/techno';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {Profile} from '../../../classes/profile';
import {NotifierService} from 'angular-notifier';
import {ConnexionApiService} from '../../../services/connexion-api.service';
import {PassUtilisateurService} from '../../../services/pass-utilisateur.service';
import {Subscription} from 'rxjs';
import {ServiceEtudiantService} from '../../../services/service-espace-etudiant/service-etudiant.service';

@Component({
  selector: 'app-modifier-profil',
  templateUrl: './modifier-profil.component.html',
  styleUrls: ['./modifier-profil.component.scss']
})
export class ModifierProfilComponent implements OnInit {
  value;
  dateMin;
  dateMax;
  dateMinDate: Date;
  dateMaxDate = new Date();
  editUtilisateurForm: FormGroup;
  addExperienceForm: FormGroup;
  categorie = 'senior';
  subscription: Subscription;

  utilisateurProfile: any;
  experiencesList: Array<Historiqueexperiencepro>;
  technoList: Array<Techno> = [];
  typeexperienceList: Array<Typeexperience> = [];
  experienceColor = new Map();
  skillsPourcentage = new Map();
  skillsList: Array<string> = [];
  technos: Array<number> = [];
  expToDelete: any;
  pageDeRetour: number;
  filtreDeRetour: string;

  private readonly notifier: NotifierService;

  APIURL = this.espaceEtudiantAPI.APIURL;
  keyword = 'libelle';

  afficherVieEtudiant = true;
  visiteur = false;
  submitted = false;
  notEnseignant = true;

  public show = false;
  modifier = false;
  confirmedFalse = false;

  profile: Profile;

  constructor(
              private formBuilder: FormBuilder,
              private serviceAPI: ServiceAPIService,
              notifierService: NotifierService,
              private router: Router,
              private passUtilisateur: PassUtilisateurService,
              private connexion: ConnexionApiService,
              private espaceEtudiantAPI: ServiceEtudiantService) {
    this.addExperienceForm = this.formBuilder.group({
      id: [''],
      utilisateur: [''],
      date_debut: ['', Validators.required],
      date_fin: ['', Validators.required],
      description: ['', Validators.required],
      typeexperience: ['', Validators.required],
      technos: this.formBuilder.array([])
    });
    this.editUtilisateurForm = this.formBuilder.group({
      nom: '',
      prenom: '',
      adresse_mail: '',
      biographie: '',
      nouveau_mot_de_passe: '',
      confirmer_nouveau_mot_de_passe: ''
    });
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    await this.getUtilisateurProfile();
    this.getTechnos();
    this.getTypeExperience();
  }

  onChange(event) {
    this.value = event.target.value;
  }

  onChangeMin(event) {
    this.dateMin = event.target.value;
    this.dateMinDate = new Date(this.dateMin);
  }

  onChangeMax(event) {
    this.dateMax = event.target.value;
    this.dateMaxDate = new Date(this.dateMax);
  }

  get f() {
    return this.addExperienceForm.controls;
  }

  onAddTechno() {
    const newTechnoControl = this.formBuilder.control('', Validators.required);
    this.getTechno().push(newTechnoControl);
  }

  getTechno() {
    return this.addExperienceForm.get('technos') as FormArray;
  }

  async getUtilisateurProfile(){
    if (this.passUtilisateur.getUtilisateur) {
      const utilisateur = this.passUtilisateur.getUtilisateur;
      this.pageDeRetour = utilisateur.page;
      this.filtreDeRetour = utilisateur.filtre;
      this.utilisateurProfile = await this.espaceEtudiantAPI.getProfilOf(utilisateur.id).toPromise();
      this.visiteur = true;
    }
    else {
      this.utilisateurProfile = await this.espaceEtudiantAPI.getUtilisateurConnecteProfil().toPromise();
      if ((this.utilisateurProfile.profiles.filter(profile => profile.libelle === 'Enseignant')).length > 0) {
        this.router.navigate(['/espace-enseignant/profil']);
        this.notEnseignant = false;
      } else if ((this.utilisateurProfile.profiles.filter(profile => profile.libelle === 'Etudiant')).length > 0) {
        this.router.navigate(['/espace-etudiant/profil']);
      } else if ((this.utilisateurProfile.profiles.filter(profile => profile.libelle === 'Ancien')).length > 0) {
        this.router.navigate(['/espace-ancien/profil']);
      }
      this.visiteur = false;
    }
  }

  getTechnos() {
    this.espaceEtudiantAPI.getTechnos().subscribe( data => { this.technoList = data; });
  }

  checkValueTechno(event, value ){
    if (event.target.checked) {
      this.technos.push(value);
    }else{
      const index = this.technos.indexOf(value);
      if (index > -1) {
        this.technos.splice(index, 1);
      }
    }
  }

  getTypeExperience() {
    this.espaceEtudiantAPI.getTypeExperiences().subscribe( data => { this.typeexperienceList = data; });
  }

  deleteAddressGroup(index: number) {
    const add = this.addExperienceForm.get('technos') as FormArray;
    add.removeAt(index);
  }

  confirmMotDePasse(utilisateur: Utilisateur){
    this.serviceAPI.checkingUtilisateurPassword(utilisateur).subscribe();
  }

  updating() {
    this.editUtilisateurForm = this.formBuilder.group({
      nom: this.utilisateurProfile.nom,
      prenom: this.utilisateurProfile.prenom,
      adresse_mail: this.utilisateurProfile.adresse_mail,
      biographie: this.utilisateurProfile.biographie,
      nouveau_mot_de_passe: '',
      confirmer_nouveau_mot_de_passe: ''
    });
  }

  update() {
    const value = this.editUtilisateurForm.value;
    const user = new Utilisateur();
    user.id = this.utilisateurProfile.id;
    user.nom = value.nom;
    user.prenom = value.prenom;
    if (value.adresse_mail) {
      user.adresse_mail = value.adresse_mail;
    }
    if (value.biographie) {
      user.biographie = value.biographie;
    }
    if (value.nouveau_mot_de_passe && value.confirmer_nouveau_mot_de_passe) {
      if ((value.nouveau_mot_de_passe) === (value.confirmer_nouveau_mot_de_passe)) {
        user.mot_de_passe = value.nouveau_mot_de_passe;
      } else {
      }
    }
    this.serviceAPI.updateUtilisateurConnecte(user).subscribe( data => {
      if (data.mailExist) {
        this.NotificationShowMailExist();
      } else {
        if (data.token) {
          this.NotificationShowSucces();
          localStorage.setItem('currentUser', data.token);
          window.location.reload();
        }
        this.editUtilisateurForm.reset();
      }
    });
  }

  async addExperience() {
    this.submitted = true;
    if (this.addExperienceForm.invalid) {
      return;
    }
    else if (this.modifier) {
      const value = this.addExperienceForm.value;
      const technos = [];
      value.technos.forEach( val => {
        if (val.id) {
          technos.push(val.id);
        } else {
          const tec = this.technoList.filter( techno => techno.libelle === val);
          technos.push(tec[0].id);
        }
      });
      const exp = new Historiqueexperiencepro();
      exp.id = value.id;
      exp.description = value.description;
      exp.date_debut = value.date_fin;
      exp.date_fin = value.date_fin;
      exp.utilisateur = value.utilisateur;
      exp.technos = technos;
      exp.typeexperience = value.typeexperience;
      this.espaceEtudiantAPI.updateHistorique(exp).subscribe(() => {
        this.submitted = false;
        this.addExperienceForm.reset();
        this.modifier = false;
        window.location.reload();
      });
    }
    else if (!this.modifier){
      const value = this.addExperienceForm.value;
      // tslint:disable-next-line:variable-name
      const formData = new FormData();
      const technos = [];
      value.technos.forEach( val => {
        technos.push(val.id);
      });

      formData.append('description', value.description);
      formData.append('date_debut', value.date_debut);
      formData.append('date_fin', value.date_fin);
      formData.append('technos', JSON.stringify(technos));
      formData.append('utilisateur', (this.utilisateurProfile.id).toString());
      formData.append('typeexperience', value.typeexperience);

      let data = await this.espaceEtudiantAPI.postHistorique(formData).toPromise();
      if (data.added === true) {
        this.NotificationShowSucces();
        this.submitted = false;
        this.addExperienceForm.reset();
        this.modifier = false;
        window.location.reload();
      }
    }
  }

  updateDisponibilite() {
    const etudiant = new Utilisateur();
    etudiant.id = this.utilisateurProfile.id;
    if (this.utilisateurProfile.disponible === true) {
      etudiant.disponible = false;
    } else {
      etudiant.disponible = true;
    }
    this.serviceAPI.updateUtilisateurConnecte(etudiant).subscribe( data => {
      if (data.token) {
        localStorage.setItem('currentUser', data.token);
        window.location.reload();
      }
      this.editUtilisateurForm.reset();
    });
  }

  async updatingExp(exp: any) {
    const typeExp = this.typeexperienceList.filter( type => type.libelle === exp.type);
    this.addExperienceForm = this.formBuilder.group({
      id: [exp.id, Validators.required],
      utilisateur: [this.utilisateurProfile.id, Validators.required],
      date_debut: [exp.date_debut, Validators.required],
      date_fin: [exp.date_fin, Validators.required],
      description: [exp.description, Validators.required],
      typeexperience:  [typeExp[typeExp.length - 1].id, Validators.required],
    });
    this.addExperienceForm.setControl('technos', this.formBuilder.array(exp.technos || []));
    this.modifier = true;
  }

  loadDeleteconfirm(exp: any) {
    this.expToDelete = exp;
  }

  deleteExp(){
    this.espaceEtudiantAPI.deleteHistorique(this.expToDelete).subscribe( () => {
      window.location.reload();
    });
  }

  NotificationShowSucces() {
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuée avec success',
    });
  }

  NotificationShowWrong() {
    this.notifier.show({
      type: 'warning',
      message: 'Une erreur a été comise, veuillez recommencer',
    });
  }

  NotificationShowMailExist(){
    this.notifier.show({
      type: 'warning',
      message: 'L\'adresse mail appartient à un autre compte',
    });
  }

  reset() {
    this.editUtilisateurForm.reset();
    this.addExperienceForm.reset();
  }

  async retour() {
    this.passUtilisateur.sendUtilisateur({page : this.pageDeRetour, filtre: this.filtreDeRetour});
    this.utilisateurProfile = await this.espaceEtudiantAPI.getUtilisateurConnecteProfil().toPromise();
    if ((this.utilisateurProfile.profiles.filter(profile => profile.libelle === 'Enseignant')).length > 0) {
      this.router.navigate(['/espace-enseignant/disponibilites']);
      this.notEnseignant = false;
    } else if ((this.utilisateurProfile.profiles.filter(profile => profile.libelle === 'Etudiant')).length > 0) {
      this.router.navigate(['/espace-etudiant/disponibilites']);
    } else if ((this.utilisateurProfile.profiles.filter(profile => profile.libelle === 'Ancien')).length > 0) {
      this.router.navigate(['/espace-ancien/disponibilites']);
    }
  }
}
