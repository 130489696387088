import { Component, OnInit } from '@angular/core';
import {ServiceAPIService} from '../../../services/service-api.service';
import {Club} from '../../../classes/club';
import {BlogClub} from '../../../classes/blog-club';
import {PassClubService} from "../../../services/pass-club.service";
import {ServiceEtudiantService} from "../../../services/service-espace-etudiant/service-etudiant.service";

@Component({
  selector: 'app-clubs',
  templateUrl: './clubs.component.html',
  styleUrls: ['./clubs.component.scss']
})
export class ClubsComponent implements OnInit {
  clubList: BlogClub[];

  constructor(private serviceAPI: ServiceEtudiantService, private pass: PassClubService) { }

  async ngOnInit(): Promise<void> {
     this.getClub();
  }

  blog(club: BlogClub) {
    this.pass.sendClub(club);
  }
  getClub(){
    this.serviceAPI.getClub().subscribe( (data) => {
      this.clubList = data;
    });
  }
}
