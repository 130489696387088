<div class="container  mt-5">
  <div class="row justify-content-md-center">
    <div class="col-12 col-md-auto mb-4 mt-4">
      <h1> ARTICLE</h1>
    </div>
  </div>
</div>
<div class=" container px-auto mb-5"  >

  <form [formGroup]="Wysywigform">
    <div class="row">
      <div class="col-9">
        <label>Image de couverture</label>
        <input type="file" class="form-control-file"   (change)="onFileSelect($event)" [ngClass]="{ 'is-invalid': submitted && f.photo.errors }">
        <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
          <div *ngIf="f.photo.errors.required">Veuillez ajouter une photo</div>
        </div>
        <div class="mb-3"id ="image" [ngStyle]="{'background-image': image, 'background-size': 'cover', 'width': '100px', 'height': '100px'}"></div>

        <label for="Title">Titre</label>
        <input formControlName="titre" class="form-control mb-3" id="Title" aria-describedby="Title"[ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
        <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
          <div *ngIf="f.titre.errors.required">Veuillez renseigner un titre</div>
        </div>

        <label for="Contenu">Contenu</label>
        <ckeditor id="Contenu" data="<p>Hello, world!</p>"  formControlName="contenu" [ngClass]="{ 'is-invalid': submitted && f.contenu.errors }"></ckeditor>
        <div *ngIf="submitted && f.contenu.errors" class="invalid-feedback">
          <div *ngIf="f.contenu.errors.required">Veuillez renseigner un contenu</div>
        </div>
      </div>
      <div class="col-3">


        <label class="">Clubs</label><br />
<!--        <select class="browser-default custom-select mb-3"  formControlName="club" >-->
<!--          <option *ngFor="let club of clubs" [value]="club.id">{{club.libelle}}</option>-->
<!--        </select>-->
        <input type="text" formControlName="club" class="form-control mb-3">
        <label class="">Moderateur</label><br />
        <input type="text" formControlName="moderateur" class="form-control mb-3">
        <label for="date">Date</label>
        <input type="text"formControlName="date" class="form-control mb-3" id="date" aria-describedby="date">
        <!-- <div id ="image" [ngStyle]="{'background-image': image, 'background-size': 'cover', 'width': '100px', 'height': '100px'}"></div>
        <input  class="mb-5" mdbInput type="file"  (change)="onFileSelect($event)" >
         -->
        <div class="row mb-5">
          <div class="col"></div>
          <div class="col"></div>
          <div class="col">
            <button type="button" class="btn btn-dark text-white py-2 btn-block" type="submit" (click)="Publier()" >Publier</button>
          </div>
        </div>

      </div>
    </div>


  </form>

</div>
<notifier-container></notifier-container>


