import { Component, OnInit } from '@angular/core';
import {ServiceEtudiantService} from '../../../services/service-espace-etudiant/service-etudiant.service';
import {Article} from '../../../classes/article';
import { Router } from '@angular/router';
import {UtilisateurConnecte} from '../../../classes/utilisateur-connecte';
import {Profile} from '../../../classes/profile';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {NotifierService} from 'angular-notifier';
import {Typearticle} from '../../../classes/typearticle';
import {ActusNotification} from '../../../classes/actus-notification';

@Component({
  selector: 'app-actualite-etudiant',
  templateUrl: './actualite-etudiant.component.html',
  styleUrls: ['./actualite-etudiant.component.scss']
})
export class ActualiteEtudiantComponent implements OnInit {
  utilisateurConnecte: UtilisateurConnecte;
  notes: ActusNotification[];
  articles: Array<Article>;
  articleToShow: Article;
  profile: Profile;
  typeArticle: Typearticle;
  canPost = false;
  noteToDelete: ActusNotification;

  Date: Date = new Date();
  dateMinDate = new Date();
  theDate;

  private readonly notifier: NotifierService;

  form: FormGroup;
  submitted = false;
  mycontent: string;
  ckeConfig: any;
  constructor(
              private serviceEtudiant: ServiceEtudiantService,
              private router: Router,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              notifierService: NotifierService) {
    this.initForm();
    this.notifier = notifierService;
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms']},
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About'
    };
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
   // await this.getUtilisateurConnecte();
    await this.getNotesEtudiant();
    // await this.getAccueilArticles();
    await this.getTypearticle();

  }

  async getNotesEtudiant() {
    const p = await this.serviceEtudiant.getNotesEtudiant().toPromise();
    p.forEach(el => {
      this.notes = el.notesEtudiant;
      this.canPost = el.Delegue;
      this.utilisateurConnecte = el.utilisateurConnecte;
    });
    this.utilisateurConnecte.profile.forEach(el => {
      if (el.libelle === 'Delegue'){
        this.canPost = true;
      }
    });

  }

  get f() { return this.form.controls; }

  goToArticle(article: Article){
    localStorage.setItem('BlogToGo', (article.club.id).toString());
    this.router.navigate(['espace-etudiant/blog']);
  }

  async getTypearticle(){
    const t = await this.serviceEtudiant.getTypeArticleByLibelle('Note delegue').toPromise();
    this.typeArticle = t[0];
  }

  initForm(){
    this.theDate = this.datePipe.transform(this.Date, 'YYYY-MM-dd');
    this.form = this.formBuilder.group({
      titre: ['', Validators.required],
      contenu: ['', Validators.required],
      fin: ['', Validators.required],
    });
  }

  Publier(){
    this.submitted = true;
    const formData = new FormData();
    // const formDataG = new FormData();
    let parcours_niveau = [];
    parcours_niveau.push({parcours: Number(this.utilisateurConnecte.parcours), niveau: Number(this.utilisateurConnecte.niveau)});

    formData.append('titre', this.form.get('titre').value);
    formData.append('contenu', this.form.get('contenu').value);
    formData.append('utilisateur', (this.utilisateurConnecte.id).toString());
    formData.append('typearticle', (this.typeArticle.id).toString());
    formData.append('fin', this.form.get('fin').value);
    formData.append('parcours_niveau', JSON.stringify(parcours_niveau));

    formData.append('date', this.theDate);
    if (this.form.invalid) {
      this.NotificationShowWrong();
      return;
    }
    else {
      this.serviceEtudiant.postArticleFromEnseignant(formData).subscribe();
      this.NotificationShowSucces();
      // window.location.reload();
    }
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  loadDeleteConfirm(article: ActusNotification) {
    this.noteToDelete = article;
  }

  deleteArticle(){
    this.serviceEtudiant.deleteNotesEtudiant(this.noteToDelete).subscribe();
    window.location.reload();
  }

  reset() {
    this.form.reset();
    this.submitted = false;
  }

}
