<div class="container">
  <div class="row aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
    <div class=" col-lg-12">
      <ul id="team-filters">
        <li data-filter=".team-item" class="filter-active">Tous</li>
        <li data-filter=".Vacataire" class="">Vacataires</li>
        <li data-filter=".Permanent" class="">Permanents</li>
      </ul>
    </div>
  </div>
  <div class="row team-container aos-init aos-animate" data-aos="fade-up">
    <!--team-1-->
    <div class="col-lg-3 col-md-4 col-sm-6 team-item {{enseignant.permanence}}" *ngFor="let enseignant of enseignantList;"
       #team_list_item>
      <div class="our-team-main">
        <div class="team-front">
          <div class="img" [ngStyle]="{'background-image' : 'url(' +'/api/'+ enseignant.photo + ')'}"></div>
          <h3>{{enseignant.nom}} {{enseignant.prenom}}</h3>
          <p>{{enseignant.titre}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
