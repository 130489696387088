import { Club } from './club';
import {Typearticle} from './typearticle';
import {Utilisateur} from './utilisateur';
import {Audience} from "./audience";
import {ActusNotification} from "./actus-notification";
import {UtilisateurConnecte} from "./utilisateur-connecte";

export class Article {
  id: number;
  titre: string;
  libelle: string;
  date: Date;
  fin: Date;
  contenu: string;
  photo: string;
  typearticle: Typearticle;
  utilisateur: Utilisateur;
  club: Club;
  header: boolean;
  afficher: boolean;
  audiences: Audience[];


  nom: string;
  prenom: string;
  notesEtudiant: ActusNotification[];
  Delegue: boolean;
  niveau : string;

  utilisateurConnecte: UtilisateurConnecte;


}
