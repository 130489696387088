import {Component, OnInit} from '@angular/core';
import {Utilisateur} from '../../../classes/utilisateur';
import {Schedule} from '../../../classes/schedule';
import {NotifierService} from 'angular-notifier';
import {ServiceAPIService} from '../../../services/service-api.service';
import {locale} from 'devextreme/localization';
import {NgxSpinnerService} from 'ngx-spinner';
import {ServiceEnseignantService} from '../../../services/service-espace-enseignant/service-enseignant.service';

@Component({
  selector: 'app-schedule-enseignant',
  templateUrl: './schedule-enseignant.component.html',
  styleUrls: ['./schedule-enseignant.component.scss']
})
export class ScheduleEnseignantComponent implements OnInit {

  utilisateurConnecte: Utilisateur;
  public ues;
  public datas;
  public et = new Schedule();
  public modifier = false;
  public post = [];
  public updtade = [];
  public delete = [];
  public tableTypeUe = [];
  public tableParcours = [];
  public tableNiveau = [];
  public aff = [];


  public enseignantId;
  public parcourE;

  public niveauS;
  public parcourS;
  public niveaux;
  public enseignants;
  public parcours;

  public actif = false;
  assignation = [];
  notifierService: NotifierService;
  showCurrentTimeIndicator = true;
  zonerempli = [];


  views: any = [{
    type: 'day',
    name:'Ce jour',
    groups: ['niveauId'],
    dateCellTemplate: 'dateCellTemplate'
  }, {
    type: 'week',
    name:'Semaine',
    groups: ['niveauId'],
    dateCellTemplate: 'dateCellTemplate'
  },{
    type: 'timelineDay',
    name:'Semaine',
    groups: ['niveauId'],
    dateCellTemplate: 'dateCellTemplate'
  }];
  constructor(private api: ServiceAPIService,
              private serviceEnsegnant: ServiceEnseignantService,
              notifierService: NotifierService,
              private SpinnerService: NgxSpinnerService
  )
  {
    locale("fr");
  }
  onContentReady(e) {
    var currentHour = new Date().getHours() - 1;

    e.component.scrollToTime(currentHour, 30, new Date());
  }
  async ngOnInit(): Promise<void> {
    setTimeout(() => this.SpinnerService.show(), 10);
    await this.getUtilisateurConnecte();
    this.getEmplois();
    this.getParcour();
    this.getTypeuE()
  }
  async getUtilisateurConnecte() {
    this.actif = true;
    this.utilisateurConnecte = await this.serviceEnsegnant.getUtilisateurConnecte().toPromise();
    this.enseignantId = this.utilisateurConnecte.id;
  }
  getParcour(){
    this.api.getParcours().subscribe(parcours => {
      parcours.forEach(el => {
        el.niveaus.forEach( element =>{
          var niveau=element.id
          this.tableParcours.push({
            id: el.id,
            text: el.libelle,
            NiveauId:niveau
          });
        })
      });

    });
  }
  getNiveau(){
    this.api.getNiveau().subscribe(niveau => {
      niveau.forEach(el => {
        this.tableNiveau.push({
          id: el.id,
          text: el.libelle,
          parcoursId: el.parcourss
        });
      });
    });
  }
  getTypeuE() {
    this.api.getTypeue().subscribe(typeue => {
      typeue.forEach(el => {
        this.tableTypeUe.push({
          id: el.id,
          color: el.couleur,
          text: el.libelle
        });
      });

    });
  }

  getEmplois(){
    this.actif = true;
    this.getParcour();
    this.getNiveau();
    this.getTypeuE();
    var tab = [];
    this.api.getAssignations().subscribe(data => {
      this.assignation = tab;
      this.datas = data;
      this.datas.forEach(el => {
        if (el.utilisateur.id == this.enseignantId ) {
          el.schedules.forEach(element => {
            //push les element dans aff qui est cense apparaitre dans Datasource
            this.aff.push(
              {
                id: element.id,
                text: el.niveau.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                UE: el.UE.libelle,
                parcours:el.parcours.libelle,
                niveau:el.niveau.libelle,
                typeUeId: el.UE.typeue,
                niveauId:el.niveau.id,
              }
            );
          });
        }
      });
    });
  }

  getHorsZone(date: Date) {
    const localeDate = date.toLocaleDateString();

    this.api.getAssignations().subscribe(data => {
      data.forEach(el => {
        if (el.utilisateur.id !== this.enseignantId) {
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            this.zonerempli.push(
              {

                endDate: element.date_fin,
                startDate: element.date_debut,

              }
            );
          });
        }
      });

    })


  }
}
