import { Component, OnInit } from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Utilisateur} from '../../../classes/utilisateur';
import {ServiceAPIService} from '../../../services/service-api.service';
import {DatePipe} from '@angular/common';
import {Niveau} from '../../../classes/niveau';
import {Parcours} from '../../../classes/parcours';
import {ServiceEnseignantService} from '../../../services/service-espace-enseignant/service-enseignant.service';

@Component({
  selector: 'app-article-enseignant',
  templateUrl: './article-enseignant.component.html',
  styleUrls: ['./article-enseignant.component.scss']
})
export class ArticleEnseignantComponent implements OnInit {

  Date: Date = new Date();
  dateMinDate = new Date();
  public theDate;
  private readonly notifier: NotifierService;
  public submitted = false;
  Wysywigform: FormGroup;
  utilisateurConnecte: Utilisateur;
  public Modid;
  public Modlabel;
  public clubid;
  public clublabel;
  public clubs;
  public types;
  public niveauList: Array<Niveau>;
  public parcoursList: Array<Parcours>;
  image: string;
  niveaux: Array<number> = [];
  parcours: Array<number> = [];
  parcoursDansNiveau: any = [];
  parcours_niveau: any = [];
  mycontent: string;
  ckeConfig: any;
  constructor( public fb: FormBuilder,
               private serviceAPI: ServiceAPIService,
               private serviceEnsegnant: ServiceEnseignantService,
               notifierService: NotifierService,
               private datePipe: DatePipe

  ){
    this.initForm();
    this.notifier = notifierService;
    this.GetNiveau();
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms']},
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About'
    };
  }
  async ngOnInit(): Promise<void> {
    await this.getUtilisateurConnecte();
    this.initForm();
    this.GetClub();
    this.GetAudience();
  }
  initForm(){
    this.theDate = this.datePipe.transform(this.Date, 'yyyy-MM-dd');
    this.Wysywigform = this.fb.group({
      titre: ['', Validators.required],
      contenu: ['', Validators.required],
      date: [{value: this.theDate, disabled: true}, Validators.required, ],
      fin: ['', Validators.required],
      moderateur: [{value: this.Modlabel, disabled: true}, Validators.required],
    });
  }
  get f() { return this.Wysywigform.controls; }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.serviceEnsegnant.getUtilisateurConnecte().toPromise();
    this.Modid = this.utilisateurConnecte.id;
    this.Modlabel = this.utilisateurConnecte.nom + ' ' + this.utilisateurConnecte.prenom;
  }

  GetNiveau(){
    this.serviceAPI.getNiveau().subscribe(data => {
      this.niveauList = data;
    });
  }
  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  Publier(){
    this.submitted = true;
    const formData = new FormData();
    // const formDataG = new FormData();
    if (this.parcours) {
      for (const parcours of this.parcours) {
        for (const niveau of this.niveauList) {
          this.parcours_niveau.push({parcours: parcours, niveau: niveau.id});
        }
      }
    }
    if (this.parcours.length === 0 && this.parcours_niveau.length === 0) {
      this.NotificationShowWrong();
    } else {
      formData.append('titre', this.Wysywigform.get('titre').value);
      formData.append('contenu', this.Wysywigform.get('contenu').value);
      formData.append('utilisateur', this.Modid);
      formData.append('typearticle', '6');
      formData.append('fin', this.Wysywigform.get('fin').value);
      if (this.parcours_niveau.length > 0) {
        formData.append('parcours_niveau', JSON.stringify(this.parcours_niveau));
      }

      formData.append('date', this.Wysywigform.get('date').value);
      if (this.Wysywigform.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else {
        this.serviceAPI.postArticleFromEnseignant(formData).subscribe();
        this.NotificationShowSucces();
        window.location.reload();
      }
    }
  }

  checkValueNiveaux(event, value ){
    if (event.target.checked) {
      const selecteur = '\'.' + value + '\'';
      $('.' + value).css('display','inline');
      for (const parcours of this.parcoursList) {
        const data = {parcours : parcours.id, niveau: value};
        this.parcours_niveau.push(data);
      }
      this.niveaux.push(value);
    }else{
      $('.' + value).css('display','none');
      const index = this.niveaux.indexOf(value);
      if (index > -1) {
        this.niveaux.splice(index, 1);
      }
      for (const parcours of this.parcoursList) {
        const index2 = this.parcours_niveau.indexOf({parcours : parcours.id, niveau: value});
        this.parcours_niveau.splice(index2, 1);
      }
    }
  }

  checkValueParcours(event, value ){
    if (event.target.checked) {
      this.parcours.push(value);
    }else{
      const index = this.parcours.indexOf(value);
      if (index > -1) {
        this.parcours.splice(index, 1);
      }
    }
  }

  checkValueParcoursDansNiveau(event, value , niveau){
    if (event.target.checked) {
      this.parcours_niveau.push({parcours : value, niveau: niveau});
    }else{
      const data = {parcours : value, niveau: niveau};
      this.parcours_niveau.forEach( (pn, index) => {
        if (pn.parcours === value && pn.niveau === niveau) {
          this.parcours_niveau.splice(index, 1);
        }
      });
    }
  }

  GetClub(){
    this.serviceAPI.getClub().subscribe(data => {
      this.clubs = data;
    });
  }

  GetAudience(){
    this.serviceAPI.getNiveau().subscribe( data => this.niveauList = data);
    this.serviceAPI.getParcours().subscribe( data => this.parcoursList = data);
  }
  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  reset() {
    this.Wysywigform.reset();
    this.submitted = false;
  }
}
