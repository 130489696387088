import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuAccueilComponent } from './components/accueil/menu-accueil/menu-accueil.component';
import { SiteComponent } from './site/site.component';
import { MotDirecteurComponent } from './components/accueil/mot-directeur/mot-directeur.component';
import { FormationsComponent } from './components/accueil/formations/formations.component';
import { CounterComponent } from './components/accueil/counter/counter.component';
import { TemoignagesComponent } from './components/accueil/temoignages/temoignages.component';
import { ActualitesComponent } from './components/accueil/actualites/actualites.component';
import { LocalisationComponent } from './components/accueil/localisation/localisation.component';
import { ContactComponent } from './components/accueil/contact/contact.component';
import { GalerieComponent } from './components/accueil/galerie/galerie.component';
import { AccueilFooterComponent } from './components/accueil/accueil-footer/accueil-footer.component';


import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);


import { EtudiantHeaderComponent } from './components/espace-etudiant/etudiant-header/etudiant-header.component';
import { EspaceComponent } from './espace/espace.component';
import { AccueilEtudiantComponent } from './pages/accueil-etudiant/accueil-etudiant.component';
import { WysywigComponent } from './pages/wysywig/wysywig.component';
import { GalerieEtudiantComponent } from './pages/galerie-etudiant/galerie-etudiant.component';
import { AccueilOpportuniteComponent } from './pages/accueil-opportunite/accueil-opportunite.component';
import { TimelineComponent } from './components/espace-etudiant/timeline/timeline.component';
import { ActualiteEtudiantComponent } from './components/espace-etudiant/actualite-etudiant/actualite-etudiant.component';

import { ProfilEtudiantComponent } from './components/espace-etudiant/profil-etudiant/profil-etudiant.component';
import { MenuEtudiantComponent } from './components/espace-etudiant/menu-etudiant/menu-etudiant.component';
import { ModifierProfilComponent } from './components/espace-etudiant/modifier-profil/modifier-profil.component';
import {CKEditorModule} from 'ckeditor4-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccueilBannerComponent } from './components/accueil/accueil-banner/accueil-banner.component';
import {ArticleComponent} from './pages/admin/views/article/article.component';
import {HeaderAdminComponent} from './pages/admin/layout/header-admin/header-admin.component';
import {FormationComponent} from './pages/scolarite/views/formation/formation.component';
import {ClubComponent} from './pages/admin/views/club/club.component';
import {CoordonneesComponent} from './pages/admin/views/coordonnees/coordonnees.component';
import {AdminComponent} from './pages/admin/views/admin/admin.component';

import {ModerateueComponent} from './pages/admin/views/moderateue/moderateue.component';
import {NiveauComponent} from './pages/scolarite/views/niveau/niveau.component';
import {DashboardComponent} from './pages/admin/views/dashboard/dashboard.component';
import {ParcoursComponent} from './pages/scolarite/views/parcours/parcours.component';
import {SidebarAdminComponent} from './pages/admin/layout/sidebar-admin/sidebar-admin.component';
import {DataTablesModule} from 'angular-datatables';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { EnseignantComponent } from './pages/scolarite/views/enseignant/enseignant.component';
import { LoginComponent } from './pages/login/login.component';
import {AuthGuardEtudiantService} from './services/auth-guard-etudiant.service';
import { HeaderScolariteComponent } from './pages/scolarite/layout/header-scolarite/header-scolarite.component';
import { SidebarScolariteComponent } from './pages/scolarite/layout/sidebar-scolarite/sidebar-scolarite.component';
import { UeComponent } from './pages/scolarite/views/ue/ue.component';
import { ScolariteComponent } from './pages/scolarite/scolarite.component';
import { DasboardScolariteComponent } from './pages/scolarite/views/dasboard-scolarite/dasboard-scolarite.component';
import { TypeueComponent } from './pages/scolarite/views/typeue/typeue.component';
import {Utilisateur} from './classes/utilisateur';
import { TypearticleComponent } from './pages/admin/views/typearticle/typearticle.component';
import {  LOCALE_ID } from '@angular/core';


import { ClubAccueilComponent } from './components/accueil/club-accueil/club-accueil.component';
import { DisponibiliteComponent } from './components/espace-etudiant/disponibilite/disponibilite.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { PhotosComponent } from './pages/admin/views/photos/photos.component';
import { TechnoComponent } from './pages/admin/views/techno/techno.component';
import { TypetechnoComponent } from './pages/admin/views/typetechno/typetechno.component';
import {EmailService} from './services/email.service';
import {DatePipe} from '@angular/common';
import { BlogComponent } from './pages/blog/blog.component';
import { AssignationComponent } from './pages/scolarite/views/assignation/assignation.component';
import { EtudiantComponent } from './pages/scolarite/views/etudiant/etudiant.component';

import { DxSchedulerModule, DxDraggableModule, DxScrollViewModule } from 'devextreme-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { AboutUsComponent } from './components/accueil/about-us/about-us.component';
import { HorizontalTimelineComponent } from './pages/horizontal-timeline/horizontal-timeline.component';
import { HistoriqueEniComponent } from './pages/admin/views/historique-eni/historique-eni.component';
import { AncienComponent } from './pages/admin/views/ancien/ancien.component';
import { HeaderPageAccueilComponent } from './pages/admin/views/header-page-accueil/header-page-accueil.component';

import { IgComponent } from './pages/scolarite/views/ig/ig.component';
import { GbComponent } from './pages/scolarite/views/gb/gb.component';
import { SrComponent } from './pages/scolarite/views/sr/sr.component';

import { DxSankeyModule } from 'devextreme-angular';

import { EmploisDuTempsComponent } from './pages/scolarite/views/emplois-du-temps/emplois-du-temps.component';
import {NgxPrintModule} from 'ngx-print';
import { ScheduleEnseignantComponent } from './components/enseignant/schedule-enseignant/schedule-enseignant.component';
import { ProfileEnseignantComponent } from './components/enseignant/profile-enseignant/profile-enseignant.component';
import { ArticleEnseignantComponent } from './components/enseignant/article-enseignant/article-enseignant.component';
import {AccueilEnseignantComponent} from './pages/accueil-enseignant/accueil-enseignant.component';
import { EnseignantHeaderComponent } from './components/enseignant/enseignant-header/enseignant-header.component';
import { ProfsComponent } from './pages/profs/profs.component';
import { MenuEnseignantComponent } from './components/enseignant/menu-enseignant/menu-enseignant.component';
import { CounterAdminComponent } from './pages/admin/views/counter-admin/counter-admin.component';
import { OpportunitesComponent } from './pages/admin/views/opportunites/opportunites.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FooterEspaceComponent} from './pages/footer-espace/footer-espace.component';
import { ClubsComponent } from './components/espace-etudiant/clubs/clubs.component';
import { PartenaireComponent } from './pages/admin/views/partenaire/partenaire.component';
import { PartenaireAccueilComponent } from './components/accueil/partenaire-accueil/partenaire-accueil.component';
import { MenuAproposComponent } from './components/accueil/menu-apropos/menu-apropos.component';
import { ActusEtudiantComponent } from './pages/actus-etudiant/actus-etudiant.component';
import {FormationAccueilComponent} from './pages/admin/views/formation-accueil/formation-accueil.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {AngularTiltModule} from 'angular-tilt';
import {NgxSpinnerModule} from 'ngx-spinner';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { SomethingWrongComponent } from './pages/something-wrong/something-wrong.component';
import {JwtInterceptor} from './services/jwt-interceptor.service';
import {ErrorInterceptor} from './services/error-interceptor.service';
import { OrganizationComponent } from './components/accueil/organization/organization.component';
import { EnseignantEniComponent } from './components/accueil/enseignant-eni/enseignant-eni.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import { UtilisateurComponent } from './pages/admin/views/utilisateur/utilisateur.component';
import { OrganigrammeComponent } from './pages/admin/views/organigramme/organigramme.component';
import { EspaceAncienComponent } from './components/espace-ancien/espace-ancien.component';
import { MenuAncienComponent } from './components/espace-ancien/menu-ancien/menu-ancien.component';
import {OrgChartModule} from 'angular-org-chart';

const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'right',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  declarations: [
    AppComponent,
    MenuAccueilComponent,
    SiteComponent,
    MotDirecteurComponent,
    FormationsComponent,
    CounterComponent,
    TemoignagesComponent,
    ActualitesComponent,
    LocalisationComponent,
    ContactComponent,
    GalerieComponent,
    AccueilFooterComponent,
    EtudiantHeaderComponent,
    EspaceComponent,
    AccueilEtudiantComponent,
    WysywigComponent,
    GalerieEtudiantComponent,
    AccueilOpportuniteComponent,
    TimelineComponent,
    ActualiteEtudiantComponent,
    ProfilEtudiantComponent,
     MenuEtudiantComponent,
     ModifierProfilComponent,

     AccueilBannerComponent,
    MenuEtudiantComponent,
    ModifierProfilComponent,
    ArticleComponent,
    ClubComponent,
    CoordonneesComponent,
    DashboardComponent,
    FormationComponent,
    ModerateueComponent,
    NiveauComponent,
    ParcoursComponent,

    HeaderAdminComponent,
    SidebarAdminComponent,
    AdminComponent,
    EnseignantComponent,
    LoginComponent,
    HeaderScolariteComponent,
    SidebarScolariteComponent,
    UeComponent,
    ScolariteComponent,
    DasboardScolariteComponent,
    TypeueComponent,
    TypearticleComponent,
    ClubAccueilComponent,
    DisponibiliteComponent,
    PhotosComponent,
    TechnoComponent,
    TypetechnoComponent,
    BlogComponent,
    AssignationComponent,
    EtudiantComponent,
    AboutUsComponent,
    HorizontalTimelineComponent,
    HistoriqueEniComponent,
    AncienComponent,
    HeaderPageAccueilComponent,
    EtudiantComponent,
    IgComponent,
    GbComponent,
    SrComponent,
    EmploisDuTempsComponent,
    ScheduleEnseignantComponent,
    ProfileEnseignantComponent,
    ArticleEnseignantComponent,
    AccueilEnseignantComponent,
    EnseignantHeaderComponent,
    ProfsComponent,
    MenuEnseignantComponent,
    CounterAdminComponent,
    OpportunitesComponent,
    FooterEspaceComponent,
    PartenaireComponent,
    PartenaireAccueilComponent,
    FooterEspaceComponent,
    ClubsComponent,
    MenuAproposComponent,
    ActusEtudiantComponent,
    FormationAccueilComponent,
    NotFoundComponent,
    ForbiddenComponent,
    SomethingWrongComponent,
    OrganizationComponent,
    EnseignantEniComponent,
    UtilisateurComponent,
    OrganigrammeComponent,
    EspaceAncienComponent,
    MenuAncienComponent
  ],
  imports: [
    AngularTiltModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CKEditorModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    BrowserModule,
    DxSchedulerModule,
    DxDraggableModule,
    DxScrollViewModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    DxScrollViewModule,
    NgxPrintModule,
    DxScrollViewModule,
    CarouselModule,
    AutocompleteLibModule,
    NgxSpinnerModule,
    NgxCaptchaModule,
    OrgChartModule
  ],
  providers: [
    AuthGuardEtudiantService,
    EmailService,
    DatePipe,
    {provide: LOCALE_ID, useValue: 'fr-CA' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
