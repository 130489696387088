import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimelineElement } from 'src/app/classes/timeline-element';
import { Utilisateur } from 'src/app/classes/utilisateur';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-historique-eni',
  templateUrl: './historique-eni.component.html',
  styleUrls: ['./historique-eni.component.scss']
})
export class HistoriqueEniComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  historiqueList: Array<TimelineElement>;
  histoToDelete: TimelineElement;

  updateForm: FormGroup;

  ajouter = true;
  modifier = false;

  image = 'url(assets/img/fond.jpg)';
  APIURL = this.serviceAPI.APIURL;

  Enregistrer = 'Ajouter';

  submitted=false;
  mycontent: string;
  ckeConfig: any;
  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms']},
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About'
    };
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getHistorique();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
  }

  async getHistorique() {
    this.historiqueList = await this.serviceAPI.getHistoriqueENI().toPromise();
  }

  initForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      legende: ['', Validators.required],
      date: ['', Validators.required],
      titre: ['', Validators.required],
      contenu: ['', Validators.required],
      photo: ['', Validators.required]
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  update() {
    this.submitted = true;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formData = new FormData();
        formData.append('legende', this.updateForm.value.legende);
        formData.append('date', this.updateForm.value.date);
        formData.append('titre', this.updateForm.value.titre);
        formData.append('contenu', this.updateForm.value.contenu);
        formData.append('photo', this.updateForm.value.photo);
        this.image = '';
        this.serviceAPI.postHistoriqueENI(formData).subscribe( data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.updateForm.get('photo').setValue('');
          this.submitted = false;
          window.location.reload();
        });
      }
    }
    if (this.modifier){
      this.submitted = true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const histo = new TimelineElement();
        histo.id = this.updateForm.value.id;
        histo.legende = this.updateForm.value.legende;
        histo.date = this.updateForm.value.date;
        histo.titre = this.updateForm.value.titre;
        histo.contenu = this.updateForm.value.contenu;
        this.serviceAPI.updateHistoriqueENI(histo).subscribe( data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.ajouter = true;
          this.modifier = false;
          this.submitted = false;
          window.location.reload();
        });
      }
    }
  }

  delete() {
    this.serviceAPI.deleteHistoriqueENI(this.histoToDelete).subscribe( () => {
      this.getHistorique();
      this.NotificationShowSucces();
      window.location.reload();
    });
  }

  updating(histo: TimelineElement) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.updateForm = this.formBuilder.group({
      id: [histo.id],
      legende: [histo.legende, Validators.required],
      date: [histo.date, Validators.required],
      titre: [histo.titre, Validators.required],
      contenu: [histo.contenu, Validators.required],
    });
    this.image = 'url(' + this.APIURL + histo.photo + ')';
    this.Enregistrer = 'Modifier';
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm.get('photo').setValue(file);
    }
  }

  loadDeleteConfirm(histo: TimelineElement) {
    this.histoToDelete = histo;
  }

  reset() {
    this.submitted=false;
    this.updateForm.reset();
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
}
