import { Typeopportunite } from "./typeopportunite";
import { Utilisateur } from "./utilisateur";
import {Niveau} from "./niveau";

export class Opportunite {
  id: number;

  chemin_fichier: string;
  email: string;
  typeopportunite: Typeopportunite;
  utilisateur: Utilisateur[];
  titre: string;
  description:string;
  niveau: Niveau;
  localite: string;
  mission: string;
  profile: string;
  institution: string;
  afficher: boolean;

  date_fin: string;

}
