import { Component, OnInit } from '@angular/core';
import {Counter} from "../../../../classes/counter";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ServiceAPIService} from "../../../../services/service-api.service";
import {Browser} from "leaflet";
import win = Browser.win;

@Component({
  selector: 'app-counter-admin',
  templateUrl: './counter-admin.component.html',
  styleUrls: ['./counter-admin.component.scss']
})
export class CounterAdminComponent implements OnInit {
  counter: Counter;

  updateForm: FormGroup;

  readonly = true;
  submitted = false;

  constructor(private serviceAPI: ServiceAPIService, private formBuilder: FormBuilder) { }

  async ngOnInit(): Promise<void> {
    await this.getCounter();
    this.initForm();
  }

  async getCounter() {
    var count = await this.serviceAPI.getCounter().toPromise();
    this.counter = count[count.length - 1];
  }

  initForm() {
    this.updateForm = new FormGroup({
      id: new FormControl(this.counter.id),
      etudiant: new FormControl(this.counter.etudiant , Validators.required),
      diplome: new FormControl(this.counter.diplome, Validators.required),
      prix: new FormControl(this.counter.prix, Validators.required),
      partenaire: new FormControl(this.counter.partenaire, Validators.required)
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  update() {
    this.submitted = true;
    if (this.updateForm.invalid) {
      return;
    }
    else {
      var count = new Counter();
      const value = this.updateForm.value;
      count.id = value.id;
      count.etudiant = value.etudiant;
      count.diplome = value.diplome;
      count.prix = value.prix;
      count.partenaire = value.partenaire;
      this.serviceAPI.updateCounter(count).subscribe(() => {
        this.submitted = false;
        this.getCounter();
        this.readonly = true;
      });
    }
  }

  activerModification() {
    this.readonly = false;
  }

}
