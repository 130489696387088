import { Component, OnInit } from '@angular/core';
import {Typearticle} from '../../../../classes/typearticle';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-typearticle',
  templateUrl: './typearticle.component.html',
  styleUrls: ['./typearticle.component.scss']
})
export class TypearticleComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  typearticleList: Array<Typearticle>;
  typeToDelete: Typearticle;
  private readonly notifier: NotifierService;

  ajouter = true;
  modifier = false;

  boutonEnregistrer = 'Ajouter';

  updateForm: FormGroup;

  Enregistrer = 'Ajouter';

  submitted=false;

  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService,private formBuilder: FormBuilder) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initAddForm();
    await this.getTypesArticles();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
  }

  get f() {
    return this.updateForm.controls;
  }

  async getTypesArticles(){
    this.typearticleList = await this.serviceAPI.getTypeArticle().toPromise();
  }

  updateTypeArticle() {
    this.submitted = true;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        let value = this.updateForm.value;
        let type = new Typearticle();
        type.libelle = value.libelle;
        this.serviceAPI.postTypeArticle(type).subscribe( () => {
          this.getTypesArticles();
          this.modifier = false;
          this.ajouter = true;
          this.submitted=false;
          this.updateForm.reset();
          this.NotificationShowSucces();
        });
      }
    }
    if (this.modifier){
      this.submitted=true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        let value = this.updateForm.value;
        let type = new Typearticle();
        type.libelle = value.libelle;
        type.id = value.id;
        this.serviceAPI.updateTypeArticle(type).subscribe( () => {
          this.getTypesArticles();
          this.modifier = false;
          this.ajouter = true;
          this.submitted=false;
          this.updateForm.reset();
          this.NotificationShowSucces();
        });
      }
    }
    window.location.reload();
  }

  initAddForm() {
    this.updateForm = new FormGroup({
      id: new FormControl(''),
      libelle: new FormControl('', Validators.required)
    });
  }

  deleteTypeArticle(){
      this.serviceAPI.deleteTypeArticle(this.typeToDelete).subscribe( () => {
        this.getTypesArticles();
        this.NotificationShowSucces();
      });
  }

  updating(type: Typearticle) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.boutonEnregistrer = 'Modifier';
    this.updateForm = this.formBuilder.group({
      id: type.id,
      // tslint:disable-next-line:no-unused-expression
      libelle: type.libelle
    });
    this.Enregistrer = 'Modifier';
  }

  loadDeleteConfirm(type: Typearticle) {
    this.typeToDelete = type;
  }

  reset() {
    this.Enregistrer = 'Ajouter';
    this.submitted = false;
    this.updateForm.reset();
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
}
