<app-menu-accueil></app-menu-accueil>
<app-accueil-banner></app-accueil-banner>
<app-mot-directeur></app-mot-directeur>
<app-actualites></app-actualites>
<app-counter></app-counter>
<app-galerie></app-galerie>
<app-temoignages></app-temoignages>
<app-partenaire-accueil></app-partenaire-accueil>
<div *ngIf="timeline">
  <app-horizontal-timeline [timelineElements]="timeline" [showContent]="true"></app-horizontal-timeline>
</div>
<!--<app-localisation></app-localisation>-->
<app-contact></app-contact>
<app-accueil-footer></app-accueil-footer>
<app-about-us></app-about-us>
<a fragment="hero" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>


