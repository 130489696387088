<section id="profileEnseignant" class="font-roboto ">
  <div class="container-fluid aos-init aos-animate" data-aos="fade-up">
    <header class="section-header wow fadeInUp">
      <h3>
        PUBLICATION
      </h3>
    </header>
    <div class="container ">
      <form [formGroup]="Wysywigform">
        <div class="row">
          <div class="col-9">

            <label for="Title">Titre</label>
            <input formControlName="titre" class="form-control mb-3" id="Title" aria-describedby="Title"
                   [ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
            <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
              <div *ngIf="f.titre.errors.required">Veuillez renseigner un titre</div>
            </div>

            <label for="Contenu">Contenu</label>
            <ckeditor [(ngModel)]="mycontent"  [config]="ckeConfig"  id="Contenu" data="<p>Hello, world!</p>" formControlName="contenu"
                      [ngClass]="{ 'is-invalid': submitted && f.contenu.errors }"></ckeditor>
            <div *ngIf="submitted && f.contenu.errors" class="invalid-feedback">
              <div *ngIf="f.contenu.errors.required">Veuillez renseigner un contenu</div>
            </div>
          </div>
          <div class="col-3">


            <label class="">Audiences</label><br/>
            <div class="form-check" *ngFor="let niveau of niveauList">
              <input class="form-check-input" type="checkbox" id="niveau" (change)="checkValueNiveaux($event, niveau.id)">
              <label class="form-check-label mr-2" for="niveau">{{niveau.libelle}}</label>
              <div class="form-check form-check-inline hidden-one {{niveau.id}}" *ngFor="let parcours of parcoursList">
                <input class="form-check-input" type="checkbox" id="parcoursdansniveau"
                       (change)="checkValueParcoursDansNiveau($event, parcours.id, niveau.id)" checked="true">
                <label class="form-check-label text-uppercase" for="parcoursdansniveau">{{parcours.routerlink}}</label>
              </div>
            </div>
            <div class="form-check" *ngFor="let parcours of parcoursList">
              <input class="form-check-input" type="checkbox" id="parcours"
                     (change)="checkValueParcours($event, parcours.id)">
              <label class="form-check-label mr-2" for="parcours">Tous les <span class="text-uppercase">{{parcours.routerlink}}</span></label>
            </div>
            <label class="">Enseignant</label><br/>
            <input type="text" formControlName="moderateur" class="form-control mb-3">
            <label for="date">Date</label>
            <input type="text" formControlName="date" class="form-control mb-3" id="date" aria-describedby="date">
            <label for="fin">Fin</label>
            <input type="date" class="form-control mb-3" id="fin" aria-describedby="fin" formControlName="fin" min="{{dateMinDate | date:'yyyy-MM-dd'}}" [ngClass]="{ 'is-invalid': submitted && f.fin.errors }">
            <div *ngIf="submitted && f.fin.errors" class="invalid-feedback">
              <div *ngIf="f.fin.errors.required">Veuillez renseigner la date de fin</div>
            </div>
            <div class="row mb-5">
              <div class="col"></div>
              <div class="col"></div>
              <div class="col">
                <button type="button" class="btn btn-dark text-white py-2 btn-block" type="submit" (click)="Publier()">
                  Publier
                </button>
                <button type="button" class="btn btn-outline-dark py-2 btn-block" (click)="reset()">Annuler</button>
              </div>
            </div>

          </div>
        </div>


      </form>
    </div>
  </div>
</section>

<notifier-container></notifier-container>


