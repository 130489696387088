import {Component, ViewChild, OnInit, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {Schedule} from 'src/app/classes/schedule';
import {ServiceAPIService} from 'src/app/services/service-api.service';
import {Utilisateur} from '../../../classes/utilisateur';
import config from 'devextreme/core/config';
import { locale, loadMessages } from 'devextreme/localization';
import { formatDate } from 'devextreme/localization';
import {NgxSpinnerService} from 'ngx-spinner';
import {ServiceEtudiantService} from '../../../services/service-espace-etudiant/service-etudiant.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimelineComponent implements AfterViewInit, OnInit {
  public tnow: Date = new Date();
  utilisateurConnecte: Utilisateur;
  public ues;
  public datas;
  public et = new Schedule();
  public modifier = false;
  public post = [];
  public updtade = [];
  public delete = [];
  public tableTypeUe = [];
  public aff = [];

  public niveauE;
  public parcourE;
  public niveauS;
  public parcourS;
  public niveaux;
  public enseignants;
  public parcours;

  public actif = false;
  assignation = [];
  notifierService: NotifierService;
  showCurrentTimeIndicator = true;

  constructor(private api: ServiceAPIService,
              private serviceApi: ServiceEtudiantService,
              notifierService: NotifierService,
              private SpinnerService: NgxSpinnerService
  ) {
    setInterval(() => {
      this.tnow = new Date();
      this.tnow.getHours()
    }, 1);
   // this.getAssignation();
   // this.getTypeuE();
     locale("fr");

  }
  onContentReady(e) {
    var currentHour = new Date().getHours() - 1;
    e.component.scrollToTime(currentHour, 30, new Date());
  }

  async ngOnInit(): Promise<void> {
    //await this.getUtilisateurConnecte();
    //nthis.getAssignation();
    //this.getTypeuE();
    this.getEmploisDuTemps();
  }

  ngAfterViewInit(): void {
    // afterViewInit code.
    this.init();
    //this.getTypeuE();
  }

  init(): void {
    // init code.
    //this.getTypeuE();
  }

  // getTypeuE() {
  //   this.api.getTypeue().subscribe(typeue => {
  //     typeue.forEach(el => {
  //       this.tableTypeUe.push({
  //         id: el.id,
  //         color: el.couleur,
  //         text: el.libelle
  //       });
  //     });
  //   });
  // }

  // getAssignation() {
  //   this.SpinnerService.show();
  //   this.actif = true;
  //   //this.getTypeuE();
  //   var tab = [];
  //   this.api.getAssignations().subscribe(data => {
  //     data.forEach(el => {
  //       if (el.parcours.id == this.parcourE && el.niveau.id == this.niveauE) {
  //         tab.push(el);
  //       }
  //     });
  //
  //     this.assignation = tab;
  //
  //     this.datas = data;
  //
  //     this.datas.forEach(el => {
  //       if (el.parcours.id == this.parcourE && el.niveau.id == this.niveauE) {
  //         el.schedules.forEach(element => {
  //
  //           //push les element dans aff qui est cense apparaitre dans Datasource
  //           this.aff.push(
  //             {
  //               id: element.id,
  //               text: el.UE.libelle,
  //               endDate: element.date_fin,
  //               startDate: element.date_debut,
  //               ueUserParcoursNiveau: element.ueUserParcoursNiveau,
  //               description: el.utilisateur.nom,
  //               typeUeId: el.UE.typeue
  //             }
  //           );
  //           this.SpinnerService.hide();
  //         });
  //       }
  //     });
  //   });
  // }
getEmploisDuTemps(){
    this.actif = true;
    this.serviceApi.getSchedule().subscribe( data =>{
      data.forEach( el => {
        this.tableTypeUe.push({
          id: el.typeueid,
          color: el.couleur,
          text: el.libelle
        });
      });
      data.forEach( el =>{
        this.aff.push(
          {
            id: el.id,
            text: el.libelle,
            endDate: el.date_fin,
            startDate: el.date_debut,
            description: el.nom,
            typeUeId: el.typeueid
          }
        );
      });

    });
}
}
