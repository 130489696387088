import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Header } from 'src/app/classes/header';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import {Article} from '../../../../classes/article';

@Component({
  selector: 'app-header-page-accueil',
  templateUrl: './header-page-accueil.component.html',
  styleUrls: ['./header-page-accueil.component.scss']
})
export class HeaderPageAccueilComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  headerList: Array<Article>;

  ajouter = true;
  modifier = false;

  APIURL = this.serviceAPI.APIURL;
  image = 'url(assets/img/fond.jpg)';
  updateForm: FormGroup;

  Enregistrer = 'Ajouter';

  submitted = false;
  constructor(private serviceAPI: ServiceAPIService, private formBuilder: FormBuilder) {}

  async ngOnInit(): Promise<void> {
    await this.getHeader();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
  }

  async getHeader() {
    let articles = [];
    this.serviceAPI.getArticle().subscribe(data => {
      data.forEach(art => {
        if (art.header === true && art.afficher === true) {
          this.image = this.serviceAPI.APIURL + art.photo ;
          art.photo = this.image;
          articles.push(art);
        }
      });
    });
    this.headerList = articles;
  }

}
