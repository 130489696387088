import { Component, OnInit } from '@angular/core';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {Coordonnees} from '../../../../classes/coordonnees';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-coordonnees',
  templateUrl: './coordonnees.component.html',
  styleUrls: ['./coordonnees.component.scss']
})
export class CoordonneesComponent implements OnInit {
  coordonnees: Coordonnees;
  private readonly notifier: NotifierService;

  updateForm: FormGroup;

  readonly = true;
  submitted = false;

  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService,  private formBuilder: FormBuilder) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    await this.getCoordonnees();
    this.initForm();
  }

  async getCoordonnees() {
    const coord = await this.serviceAPI.getCoordonnees().toPromise();
    this.coordonnees = coord[coord.length - 1 ];
  }

  initForm() {
    this.updateForm = new FormGroup({
      id: new FormControl(this.coordonnees.id),
      telephone: new FormControl(this.coordonnees.telephone , Validators.required),
      mail: new FormControl(this.coordonnees.mail, Validators.required),
      boite_postale: new FormControl(this.coordonnees.boite_postale, Validators.required),
      adresse: new FormControl(this.coordonnees.adresse, Validators.required),
      longitude: new FormControl(this.coordonnees.longitude, Validators.required),
      latitude: new FormControl(this.coordonnees.latitude , Validators.required),
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  update() {
    this.submitted = true;
    if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
    else {
      var coord = new Coordonnees();
      const value = this.updateForm.value;
      coord.id = value.id;
      coord.telephone = value.telephone;
      coord.mail = value.mail;
      coord.boite_postale = value.boite_postale;
      coord.adresse = value.adresse;
      coord.longitude = value.longitude;
      coord.latitude = value.latitude;
      this.serviceAPI.updateCoordonnees(coord).subscribe(() => {
        this.submitted = false;
        this.getCoordonnees();
        this.readonly = true;
        this.NotificationShowSucces();
      });
    }
  }

  activerModification() {
    this.readonly = false;
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
}
