import {Utilisateur} from './utilisateur';

export class Club {
  id: number;
  sigle: string;
  libelle: string;
  apropos: string;
  president: string;
  photo: string;
  utilisateur: Utilisateur;
  historique: string;
  missions: string;
  nom;
  prenom;
}
