import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Article} from '../../../classes/article';
import {ServiceAPIService} from '../../../services/service-api.service';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import * as xml2js from 'xml2js';
import {AccueilBannerComponent} from '../accueil-banner/accueil-banner.component';
import {HeaderToActualiteService} from '../../../services/header-to-actualite.service';
import {Subscription} from 'rxjs';
import * as $ from 'jquery';
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";

@Component({
  selector: 'app-actualites',
  templateUrl: './actualites.component.html',
  styleUrls: ['./actualites.component.scss']
})
export class ActualitesComponent implements OnInit {
  @ViewChild('articleModal') articleModal: any;
  @ViewChild('eventModal') eventModal: any;

  subscription: Subscription;
  receiveArticle: Article;
  public show = false;
  fromBanner = false;
  articlesOtherList:Article[]=[];
  articlesLastList:Article[]=[];
  articlesList: Article[] =[];
  evenementList: Article[] = [];
  bannerList: Article[] = [];
  public image;
  public imagep;
  public imageevent;
  public imageDetailevent;
  articleToShow: Article;
  eventToShow: Article;
  imageToShow;
  config: any;
  p: any;
  public heightSection;
  public heightShow;
  public showDetailEvent;
  public imageEvent;
  public maxSize = 7;
  public directionLinks = true;
  public autoHide = false;
  public responsive = true;
  public labels: any = {
    previousLabel: 'Precedent',
    nextLabel: 'Suivant',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  prevLinkActive = false;
  nextLinkActive = false;

  // tslint:disable-next-line:typedef
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const article = document.querySelector('#showArticle');
    if (article) {
      const positionArticle = article.getBoundingClientRect();
      if (positionArticle.bottom <= 0 || positionArticle.top >= window.innerHeight) {
        this.show = false;
      }
    }

    const event = document.querySelector('#showEvent');
    if (event) {
      const positionEvent = event.getBoundingClientRect();
      if (positionEvent.bottom <= 0 || positionEvent.top >= window.innerHeight) {
        this.showDetailEvent = false;
      }
    }

    const echelle = window.scrollY;
  }

  constructor(private serviceAPI: ServiceAccueilService, private http: HttpClient,
              private headerToActualite: HeaderToActualiteService) {

    $(document).ready(function () {
      $('.hover').mouseleave(
        function () {
          $(this).removeClass('hover');
        }
      );
    });
    let PhotoActu;
    let PhotoEvent;
    this.subscription = this.headerToActualite.getMessage().subscribe(message => {
      if (message) {
        this.showArticle(message);
        this.fromBanner = true;
      }
    });
  }

  ngOnInit(): void {
    this.getArticles();
    this.getEvenements();
    this.getHeader();
    this.getLastArticle();
    this.getOtherActus();
  }

  // tslint:disable-next-line:typedef
  showDiv() {

  }

  // tslint:disable-next-line:typedef
  getArticles() {
    this.serviceAPI.getActualite().subscribe(data => {
      this.articlesList = data;
     });
  }
  getLastArticle() {
    this.serviceAPI.getLastActualite().subscribe(data => {
      this.articlesLastList = data;
    });
  }
  getOtherActus() {
    this.serviceAPI.getOtherActus().subscribe(data => {
      this.articlesOtherList = data;
      console.log(this.articlesOtherList);
    });
  }

  getEvenements() {
    this.serviceAPI.getEvenement().subscribe(data => {
      this.evenementList = data;
    });
  }

// tslint:disable-next-line:typedef
  showDetail() {
    this.show = false;
  }

  close() {
    $('.modal-open').removeClass('modal-open');
    $('.modal-backdrop').removeClass('show');
    $('.modal-backdrop').removeClass('modal-backdrop');
    this.articleModal.nativeElement.style.display = 'none';
    this.eventModal.nativeElement.style.display = 'none';
    this.show = false;
    this.showDetailEvent = false;
    this.fromBanner = false;
  }

  // tslint:disable-next-line:typedef
  showArticle(article: Article) {
    this.show = !this.show;
    this.showDetailEvent = false;
    this.articleToShow = article;
    this.loadModal();

  }

  // tslint:disable-next-line:typedef
  showEvent(article: Article) {
    this.eventToShow = article;
    this.loadEventModal();
    this.showDetailEvent = !this.showDetailEvent;
    this.show = false;
  }

  getArticleHeader(article: Article) {
    this.show = !this.show;
    this.receiveArticle = article;
  }

  loadModal() {
    $('#showArticle').removeClass('animate__fadeOutTopLeft');
    $('#showArticle').addClass('animate__fadeIn');
    this.articleModal.nativeElement.style.display = 'block';
    document.body.classList.add('modal-open');
  }

  loadEventModal() {
    $('#detailsEvent').removeClass('animate__rotateInDownLeft');
    $('#detailsEvent').addClass('animate__fadeIn');
    this.eventModal.nativeElement.style.display = 'block';
    document.body.classList.add('modal-open');
  }
  getHeader() {
    this.serviceAPI.getHeader().subscribe(data => {
      this.bannerList = data;
    });
  }

  next(article: Article) {
    if (this.fromBanner) {
      if (this.bannerList[this.bannerList.indexOf(article) + 1]) {
        if (!this.bannerList[this.bannerList.indexOf(article) + 2]) {
          this.nextLinkActive = true;
        }
        this.articleToShow = this.bannerList[this.bannerList.indexOf(article) + 1];
        this.prevLinkActive = false;
      }
    }  else if (this.evenementList.indexOf(article) > -1) {
      if (this.evenementList[this.evenementList.indexOf(article) + 1]) {
        if (!this.evenementList[this.evenementList.indexOf(article) + 2]) {
          this.nextLinkActive = true;
        }
        this.eventToShow = this.evenementList[this.evenementList.indexOf(article) + 1];
        this.prevLinkActive = false;
      }
    } else if (this.articlesList.indexOf(article) > -1) {
      if (this.articlesList[this.articlesList.indexOf(article) + 1]) {
        if (!this.articlesList[this.articlesList.indexOf(article) + 2]) {
          this.nextLinkActive = true;
        }
        this.articleToShow = this.articlesList[this.articlesList.indexOf(article) + 1];
        this.prevLinkActive = false;
      }
    }
  }

  prev(article: Article) {
    if (this.fromBanner) {
      if (this.bannerList[this.bannerList.indexOf(article) - 1]) {
        if (!this.bannerList[this.bannerList.indexOf(article) - 2]) {
          this.prevLinkActive = true;
        }
        this.articleToShow = this.bannerList[this.bannerList.indexOf(article) - 1];
        this.nextLinkActive = false;
      }
    } else if (this.evenementList.indexOf(article) > -1) {
      if (this.evenementList[this.evenementList.indexOf(article) - 1]) {
        if (!this.evenementList[this.evenementList.indexOf(article) - 2]) {
          this.prevLinkActive = true;
        }
        this.eventToShow = this.evenementList[this.evenementList.indexOf(article) - 1];
        this.nextLinkActive = false;
      }
    } else if (this.articlesList.indexOf(article) > -1) {
      if (this.articlesList[this.articlesList.indexOf(article) - 1]) {
        if (!this.articlesList[this.articlesList.indexOf(article) - 2]) {
          this.prevLinkActive = true;
        }
        this.articleToShow = this.articlesList[this.articlesList.indexOf(article) - 1];
        this.nextLinkActive = false;
      }
    }
  }
}
