<div class="modal fade" id="aboutUsModal" tabindex="-1" role="dialog" aria-labelledby="aboutUsModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background: #e1eef7">
      <div class="modal-body pl-0">
        <div class="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
              <div class="custom-menu">
                <button type="button" id="sidebarCollapse" class="btn btn-primary">
                  <i class="fa fa-bars"></i>
                  <span class="sr-only">Toggle Menu</span>
                </button>
              </div>
              <div class="toscroll">
                <div class="img bg-wrap text-center py-4" style="background-image: url(/assets/APROPOS.png);">
                  <div class="user-logo">
                    <div class="img" style="background-image: url(/assets/aenifront.png);"></div>
                  </div>
                </div>
                <ul class="list-unstyled components mb-5">
                <li [ngClass]="{'active': i==0}" [id]="i" (click)="activer(i, apropos)" *ngFor="let apropos of aproposENI; let i=index">
                  <a class="pointeur">
                    <span class="fa fa-book mr-3" *ngIf="i===0"></span>
                    <span class="fa fa-user-secret mr-3" *ngIf="i%2 !== 0"></span>
                    <span class="fa fa-university mr-3" *ngIf="i !==0 && i%2 ===0"></span>
                    {{apropos.titre}}
                  </a>
                </li>
                <li id="organigramme">
                  <a class="pointeur" (click)="goToOrganigramme()"><span class="fa fa-cubes mr-3"></span>
                    Organigramme</a>
                </li>
                <li id="enseignant">
                  <a class="pointeur" (click)="gotoEnseignant()"><span class="fa fa-users mr-3"></span>
                    Enseignants</a>
                </li>
                <li>
                  <a href="#" data-dismiss="modal"><span class="fa fa-sign-out mr-3"></span> Retour</a>
                </li>
                </ul>
              </div>
            </nav>

            <!-- Page Content  -->
            <section id="content" class="p-4 p-md-5 pt-5">
              <div class=" aos-init aos-animate" data-aos="fade-up">
                <div class="about #32323287" *ngIf="aproposToShow">
                  <header class="section-header wow fadeInUp mb-5">
                    <h3 class="about__title--text" *ngIf="organigramme === false && showEnseignant === false">{{ aproposToShow.titre }}</h3>
                    <h3 class="about__title--text" *ngIf="organigramme === true">Organigramme</h3>
                    <h3 class="about__title--text" *ngIf="showEnseignant === true">Equipe pédagogique</h3>
                  </header>
                  <div class="cardModal-container" *ngIf="organigramme === false && showEnseignant === false">
                    <div class="cardModal card-1">
                      <div class="text-white"> <h5>{{aproposToShow.libelle}}</h5></div>
                      <h5 class="cardModal__title mt-3" [innerHtml]="aproposToShow.contenu">
                      </h5>
                    </div>
                  </div>
                  <app-organization *ngIf="organigramme === true"></app-organization>
                  <app-enseignant-eni *ngIf="showEnseignant === true"></app-enseignant-eni>

                  <!--                <div class="about__text text-center box-content" [ngClass]="{'box-shadow p-5' : isBox}">-->
                  <!--                  <p class="about__text&#45;&#45;content" [innerHtml]="aproposToShow.contenu" class="text-justify"-->
                  <!--                    *ngIf="organigramme === false && showEnseignant === false"></p>-->
                  <!--                    <app-organization *ngIf="organigramme === true"></app-organization>-->
                  <!--                    <app-enseignant-eni *ngIf="showEnseignant === true"></app-enseignant-eni>-->
                  <!--                </div>-->
                </div>
              </div>
            </section>
        </div>
      </div>
    </div>
  </div>
</div>
