<div class="container-fluid aos-init aos-animate">
  <section class="mx-auto">
    <header class="section-header wow fadeInUp">
      <h3>Disponibles
      </h3>
    </header>
    <div class="row">
      <div class="container">
        <div class="multi-button mx-auto" *ngIf="niveauxWithCount && etudiantsDisponiblesWithSkills">
          <button class="filter-button paste" id="tous" (click)="getDisponibles()" [ngClass]="{'focuson': f.indexOf('Tous') > -1}">Tous</button>
          <button class="filter-button paste" [id]="niveauCounted.niveau" *ngFor="let niveauCounted of niveauxWithCount" (click)="filterNiveau(niveauCounted.niveau)" [ngClass]="{'focuson': f.indexOf(niveauCounted.niveau) > -1}">{{niveauCounted.niveau}} ({{niveauCounted.count}})</button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card-deck"
             *ngFor="let etudiantWithSkills of etudiantsDisponiblesWithSkills | paginate: { itemsPerPage: 24, currentPage: p }">
          <div class="card"
               [ngClass]="{'card-begginer' : etudiantWithSkills.categorie === 'beginner', 'card-intermediate' : etudiantWithSkills.categorie === 'intermediate', 'card-senior' : etudiantWithSkills.categorie === 'senior'}">
            <div class="card-body" routerLink="profil" (click)="voirProfile(etudiantWithSkills.id)">
              <h6
                class="card-title">{{etudiantWithSkills.nom}} {{etudiantWithSkills.prenom}}</h6>
              <p class="card-text justify-content-center"
                 *ngIf="etudiantWithSkills.niveau && etudiantWithSkills.parcours">{{etudiantWithSkills.niveau}} {{etudiantWithSkills.parcours}}</p>
              <p class="card-text justify-content-center"
                 *ngIf="!etudiantWithSkills.niveau">{{etudiantWithSkills.biographie}}</p>
              <div class="img"
                   [ngStyle]="{ 'background-image' : 'url(' + APIURL + etudiantWithSkills.photo + ')'}"></div>
              <div class="skills">
                <h6 class="d-flex align-items-center mx-3"><i class="material-icons text-forth mr-2">assignment</i>Compétences
                </h6>
                <div *ngFor="let skill of etudiantWithSkills.exp">
                  <div>
                    <small>{{skill.techno}}</small>
                    <div class="progress mb-3" style="height: 5px">
                      <div class="progress-bar" role="progressbar"
                           [ngStyle]="{'width' : skill.pourcentage + '%','background-color' : skill.couleur}"
                           aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div class="text-center">
      <pagination-controls *ngIf="etudiantsDisponiblesWithSkills && etudiantsDisponiblesWithSkills.length>0"
                           (pageChange)="p = $event"
                           [directionLinks]="directionLinks"
                           [autoHide]="autoHide"
                           [responsive]="responsive"
                           [previousLabel]="labels.previousLabel"
                           [nextLabel]="labels.nextLabel"
                           [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                           [screenReaderPageLabel]="labels.screenReaderPageLabel"
                           [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel">
      </pagination-controls>
    </div>
    </div>
  </section>
</div>
