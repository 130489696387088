import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../../../classes/utilisateur';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {Niveau} from '../../../../classes/niveau';
import {Club} from '../../../../classes/club';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {Profile} from '../../../../classes/profile';
import {Parcours} from '../../../../classes/parcours';

interface NiveauAvecParcours {
  niveau: Niveau;
  parcours: Parcours[];
}

@Component({
  selector: 'app-moderateue',
  templateUrl: './moderateue.component.html',
  styleUrls: ['./moderateue.component.scss']
})
export class ModerateueComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  moderateurList: Array<Utilisateur> = [];
  niveauLibre: Array<Niveau> = [];
  clubLibre: Array<Club> = [];
  etudiantList: Array<Utilisateur> = [];
  etudiantDelegueList: Array<Utilisateur> = [];
  moderateurToDelete: Utilisateur;
  profile: Profile;
  classes: NiveauAvecParcours[];

  addModerateurClubForm: FormGroup;
  addModerateurNiveauForm: FormGroup;

  Enregistrer = 'Ajouter';

  submitted = false;

  keyword = 'nom';

  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formbuilder: FormBuilder) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    await this.getModerateurs();
    this.initAddModerateurNiveauForm();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      language: {
        emptyTable: 'Aucune donnée disponible dans le tableau',
            lengthMenu: 'Afficher _MENU_ éléments',
            loadingRecords: 'Chargement...',
            processing: 'Traitement...',
            zeroRecords: 'Aucun élément correspondant trouvé',
            paginate: {
                first: 'Premier',
                last: 'Dernier',
                previous: 'Précédent',
                next: 'Suiv'
            },
            decimal: ',',
            info: 'Affichage de _START_ à _END_ sur _TOTAL_ éléments',
            infoEmpty: 'Affichage de 0 à 0 sur 0 éléments',
            search: 'Rechercher:',
            searchPlaceholder: '...',
            thousands: '.',
            infoFiltered: '(filtrés depuis un total de _MAX_ éléments)',
      }
    };
    await this.getDelegueProfile();
    await this.getNiveausLibres();
    this.getClubsLibres();
    this.getEtudiants();
    this.initAddModerateurClubForm();
    // this.getEtudiantsDelegue();
  }

  initAddModerateurClubForm(){
    this.addModerateurClubForm = this.formbuilder.group({
      utilisateur: '',
      club: ''
    });
  }

  initAddModerateurNiveauForm(){
    // @ts-ignore
    this.addModerateurNiveauForm = this.formbuilder.group({
      utilisateurD: ''
    });
  }

  get fNiveau() {
    return this.addModerateurNiveauForm.controls;
  }

  get fClub() {
    return this.addModerateurClubForm.controls;
  }

  async getModerateurs(){
    const moderateurs: Array<Utilisateur> = [];
    const data = await this.serviceAPI.getUtilisateur().toPromise();
    data.forEach( mod => {
      mod.profiles.forEach( profile => {
        if (profile.libelle === 'Moderateur club' || profile.libelle === 'Delegue'){
          if (!(this.moderateurList.indexOf(mod) > -1)) {
            moderateurs.push(mod);
          }
        }
      });
    });
    this.moderateurList = moderateurs;
  }

  async getNiveausLibres(){
    const parcoursDelegues: Parcours[] = [];
    const niveauxDelegues: Niveau[] = [];
    var entrer = false;
    const p = await this.serviceAPI.getProfileByLibelle('Delegue').toPromise();
    this.profile = p[0];
    const delegues = this.profile.utilisateurs;
    const niveaux = await this.serviceAPI.getNiveau().toPromise();
    const parcours = await this.serviceAPI.getParcours().toPromise();
    const user = await this.serviceAPI.getUtilisateur().toPromise();
    delegues.forEach( del => {
      if (!(niveauxDelegues.indexOf(del.niveau) > -1)) {
        niveauxDelegues.push(del.niveau);
      }
    })
    user.forEach( utilisateur => {
      if (utilisateur.niveau) {
        if (niveauxDelegues.filter( niveau => Number(niveau) === utilisateur.niveau.id).length > 0) {
          var delegueDeCeNiveau = delegues.filter( delegue => Number(delegue.niveau) === utilisateur.niveau.id );
          if (!(delegueDeCeNiveau.filter( del => Number(del.parcours) === utilisateur.parcours.id).length > 0 )) {
            this.etudiantDelegueList.push(utilisateur);
          }
        } else {
          this.etudiantDelegueList.push(utilisateur);
        }
      }
    });
  }

  getClubsLibres(){
    this.serviceAPI.getClub().subscribe( data => {
      data.forEach( club => {
        if (!club.utilisateur){
          this.clubLibre.push(club);
        }
      });
    });
  }

  getEtudiants(){
    this.serviceAPI.getUtilisateur().subscribe( data => {
      data.forEach( utilisateur => {
        utilisateur.profiles.forEach( profile => {
          if ((profile.libelle === 'ETUDIANT')){
            this.etudiantList.push(utilisateur);
          }
        });
      });
    });
  }

  getEtudiantsDelegue(){
    /*this.serviceAPI.getUtilisateur().subscribe( utilisateur => {
      utilisateur.forEach( et => {
        if (et.niveau) {
          this.niveauLibre.forEach( niveau => {
            if (niveau.id === et.niveau.id) {
              this.etudiantDelegueList.push(et);
            }
          });
        }
      });
    });*/
  }

  addModerateurNiveau(){
    const value = this.addModerateurNiveauForm.value;
    const id = value.utilisateurD.id;
    this.serviceAPI.postModerateurDelegue(id).subscribe( () => {
      this.NotificationShowSucces();
      this.submitted = false;
      this.addModerateurNiveauForm.reset();
      this.getModerateurs();
      window.location.reload();
    });
  }

  deletingModerateur(){
    this.moderateurToDelete.profiles.forEach( profile => {
      if (profile.libelle === 'Moderateur club') {
        this.serviceAPI.deleteModerateur(this.moderateurToDelete).subscribe( () => {
          this.getModerateurs();
          this.NotificationShowSucces();
        });
      }
      else if ((profile.libelle).toString() === 'Delegue') {
        this.serviceAPI.deleteDelegue(this.moderateurToDelete).subscribe( () => {
          this.getModerateurs();
          this.NotificationShowSucces();
        });
      }
      window.location.reload();
    });
  }


  async getDelegueProfile() {
    const p = await this.serviceAPI.getProfileByLibelle('Delegue').toPromise();
    this.profile = p[0];
  }

  loadDeleteConfirm(mod: Utilisateur) {
    this.moderateurToDelete = mod;
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

}
