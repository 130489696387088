import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../../../classes/utilisateur';
import {Niveau} from '../../../../classes/niveau';
import {Parcours} from '../../../../classes/parcours';
import {Profile} from '../../../../classes/profile';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {Browser} from 'leaflet';
import win = Browser.win;
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-etudiant',
  templateUrl: './etudiant.component.html',
  styleUrls: ['./etudiant.component.scss']
})
export class EtudiantComponent implements OnInit {
  private readonly notifier: NotifierService;
  dtOptions: DataTables.Settings = {};

  etudiantsList: Array<Utilisateur> = [];
  niveauList: Array<Niveau> = [];
  parcoursList: Array<Parcours> = [];
  profileList: Array<Profile> = [];
  profiles: Array<number> = [];
  etudiantToDelete: Utilisateur;

  updateForm: FormGroup;

  ajouter = true;
  modifier = false;

  image = 'url(assets/img/utilisateur.png)';
  APIURL = this.serviceAPI.APIURL;

  Enregistrer = 'Ajouter';

  profile: Profile;

  submitted = false;
  errorMessage = '';

  constructor(private serviceAPI: ServiceAPIService, private formBuilder: FormBuilder, notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getEtudiants();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
    await this.getProfile();
    this.getNiveaux();
    this.getParcours();
    this.getProfiles();
  }

  initForm() {
    this.updateForm = new FormGroup({
      id: new FormControl(''),
      numero_matricule: new FormControl('', Validators.required),
      nom: new FormControl('', Validators.required),
      prenom: new FormControl('', Validators.required),
      adresse_mail: new FormControl('', Validators.required),
      parcours: new FormControl('', Validators.required),
      niveau: new FormControl('', Validators.required),
      photo: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  updating(utilisateur: Utilisateur) {
    window.scroll(0,0);
    this.ajouter = false;
    this.modifier = true;
    this.updateForm = new FormGroup({
      id: new FormControl(utilisateur.id, Validators.required),
      numero_matricule: new FormControl(utilisateur.numero_matricule, Validators.required),
      nom: new FormControl(utilisateur.nom, Validators.required),
      prenom: new FormControl(utilisateur.prenom, Validators.required),
      adresse_mail: new FormControl(utilisateur.adresse_mail, Validators.required),
      parcours:  new FormControl(utilisateur.parcours.id, Validators.required),
      niveau:  new FormControl(utilisateur.niveau.id, Validators.required),
      photo: new FormControl('')
    });
    this.image = 'url(' + this.APIURL + utilisateur.photo + ')';
    this.Enregistrer = 'Modifier';
  }

  async getProfile() {
    var p = await this.serviceAPI.getProfileByLibelle('Etudiant').toPromise();
    this.profile = p[0];
  }

  update() {
    if (this.ajouter) {
      this.submitted = true;
      if (this.updateForm.invalid) {
        return;
      }
      else{
        const formData = new FormData();
        formData.append('numero_matricule', this.updateForm.value.numero_matricule);
        formData.append('nom', this.updateForm.value.nom);
        formData.append('prenom', this.updateForm.value.prenom);
        formData.append('adresse_mail', this.updateForm.value.adresse_mail);
        formData.append('mot_de_passe', this.updateForm.value.numero_matricule);
        formData.append('parcours', this.updateForm.value.parcours);
        formData.append('niveau', this.updateForm.value.niveau);
        formData.append('disponible', 'false');
        formData.append('profiles', (this.profile.id).toString() );
        formData.append('biographie', 'Biographie');
        formData.append('photo', this.updateForm.value.photo);
        this.image = '';
        this.serviceAPI.postUtilisateur(formData).subscribe( data => {
          if (data.mailExist) {
              this.NotificationShowWrong('Erreur! L\'adresse existe déjà.');
          }
          else if (data.numMatriculeExist) {
              this.NotificationShowWrong('Erreur! Le numéro matricule existe déjà');
          } else {
            this.NotificationShowSucces();
            this.updateForm.reset();
            this.updateForm.get('photo').setValue('');
            this.submitted = false;
            this.errorMessage = '';
            this.getEtudiants();
            window.location.reload();
          }
        });
      }
    }
    if (this.modifier) {
      this.submitted = true;
      if (this.updateForm.invalid) {
        return;
      }
      else{
        let utilisateur = new Utilisateur();
        utilisateur.id = this.updateForm.value.id;
        utilisateur.nom = this.updateForm.value.nom;
        utilisateur.prenom = this.updateForm.value.prenom;
        utilisateur.adresse_mail = this.updateForm.value.adresse_mail;
        utilisateur.parcours = this.updateForm.value.parcours;
        utilisateur.niveau = this.updateForm.value.niveau;
        this.serviceAPI.chercherMail(this.updateForm.value.adresse_mail).subscribe( data => {
          if (data.length === 0 || data[0].id === utilisateur.id) {
            this.serviceAPI.updateUtilisateur(utilisateur).subscribe( data => {
              this.NotificationShowSucces();
              this.submitted = false;
              this.getEtudiants();
              this.ajouter = true;
              this.modifier = false;
              this.errorMessage = '';
              window.location.reload();
            });
          }
          else {
              this.NotificationShowMailExist();
          }
        });
      }
    }
  }

  checkValue(event, value ){
    if (event.target.checked) {
      this.profiles.push(value);
    }else{
      const index = this.profiles.indexOf(value);
      if (index > -1) {
        this.profiles.splice(index, 1);
      }
    }
  }

  async getEtudiants() {
    var data = await this.serviceAPI.getUtilisateur().toPromise();
    data.forEach( utilisateur => {
      utilisateur.profiles.forEach( async profile => {
        if (profile.libelle === 'Etudiant') {
          await this.etudiantsList.push(utilisateur);
        }
      });
    });
  }

  getNiveaux() {
    this.serviceAPI.getNiveau().subscribe( data => {
      this.niveauList = data;
    });
  }

  getParcours() {
    this.serviceAPI.getParcours().subscribe( data => {
      this.parcoursList = data;
    });
  }

  getProfiles() {
    this.serviceAPI.getProfile().subscribe( data => {
      this.profileList = data;
    });
  }

  deleteUtilisateur() {
    this.serviceAPI.deleteUtilisateur(this.etudiantToDelete).subscribe(() => {
      this.NotificationShowSucces();
      this.getEtudiants();
      window.location.reload();
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm.get('photo').setValue(file);
    }
  }

  loadDeleteConfirm(et: Utilisateur) {
    this.etudiantToDelete = et;
  }

  reset() {
    this.submitted = false;
    this.image = 'url(assets/img/utilisateur.png)';
    this.Enregistrer = 'Ajouter';
    this.updateForm.reset();
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  NotificationShowWrong(error: string){
    this.notifier.show({
      type: 'warning',
      message: error,
    });
  }

  NotificationShowMailExist(){
    this.notifier.show({
      type: 'warning',
      message: 'L\'adresse mail existe déjà',
    });
  }

  NotificationShowMatriculeExist(){
    this.notifier.show({
      type: 'warning',
      message: 'Le numero matricule existe déjà',
    });
  }

}
