import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthAncienService {
  constructor(private router: Router) { }

  isAuth = false;

  // tslint:disable-next-line:typedef
  signIn() {
    this.isAuth = true;
  }

  // tslint:disable-next-line:typedef
  signOut() {
    localStorage.clear();
    this.isAuth = false;
  }
}
