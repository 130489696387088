import { Component, OnInit } from '@angular/core';
import {ServiceEnseignantService} from '../../../services/service-espace-enseignant/service-enseignant.service';

@Component({
  selector: 'app-profile-enseignant',
  templateUrl: './profile-enseignant.component.html',
  styleUrls: ['./profile-enseignant.component.scss']
})
export class ProfileEnseignantComponent implements OnInit {

  utilisateurConnecte: any;
  imageUtilisateur: string;

  constructor(private serviceAPI: ServiceEnseignantService) { }

  async ngOnInit(): Promise<void>  {
    await this.getUtilisateurConnecte();
  }

  async getUtilisateurConnecte(): Promise<void>{
    this.serviceAPI.getUtilisateurConnecte().subscribe(async data => {
      // tslint:disable-next-line:prefer-const
      this.utilisateurConnecte = data;
    });
  }

}
