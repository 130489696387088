<section id="partenaire" >
  <div class=" container aos-init aos-animate mx-auto" data-aos="fade-up">
    <owl-carousel-o [options]="customOptionsPartenaire" >
            <ng-template carouselSlide  *ngFor="let photo of photos">
              <div style=" height:18vh;width: 110px;"class=" centre">
                <img [src]="photo.imageData" class="img-fluid pt-3 pb-3">
              </div>
            </ng-template>
    </owl-carousel-o>
  </div>
</section>
