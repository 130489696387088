<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5  *ngIf="ajouter">Ajouter une nouvelle technologie</h5>
      <h5 *ngIf="modifier">Modifier la technologie</h5>
    </div>
    <div class="card-body px-4">
      <div class="">
        <form [formGroup]="updateForm" (ngSubmit)="update()">
          <input type="text" class="form-control" formControlName="id" hidden>
          <div class="form-group row">
            <label for="libelle" class="col-md-4">Techno</label>
            <input type="text" id="libelle" class="form-control col-md-8" formControlName="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
            <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
              <div *ngIf="f.libelle.errors.required">Veuillez remplir le nom de la technologie</div>
            </div>
          </div>
          <div class="form-group row">
          <label for="typetechno" class="col-md-4">Type de la technologie</label>
            <select class="form-control col-md-8" id="typetechno"  formControlName="typetechno" [ngClass]="{ 'is-invalid': submitted && f.typetechno.errors }">
              <option *ngFor="let type of typeTechnoList" [ngValue]="type.id">{{type.libelle}}</option>
            </select>
            <div *ngIf="submitted && f.typetechno.errors" class="invalid-feedback">
              <div *ngIf="f.typetechno.errors.required">Veuillez remplir le type de technologie</div>
            </div>
          </div>
          <button class="btn bg-forth text-clear mr-5 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
          <button class="btn btn-secondary" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>
        </form>
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <h3 class="mt-5">Liste des technologies</h3>
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive">
        <table class="table table-striped" style="width:100%"   datatable [dtOptions]="dtOptions" *ngIf="technoList">
          <thead>
          <tr>
            <th>Technologie</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let techno of technoList">
            <td>
              <div class="d-flex align-items-center">
                <div class="">
                  <p class="font-weight-bold mb-0">{{ techno.libelle }}</p>
                </div>
              </div>
            </td>
            <td><span *ngIf="techno.typetechno"> {{techno.typetechno.libelle}}</span></td>
            <td>
              <button class="btn btn-primary mr-2" href="#" (click)="updating(techno)"><i class="fa fa-edit"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
