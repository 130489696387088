import { Component, OnInit } from '@angular/core';
import {Article} from '../../../../classes/article';
import {Typearticle} from '../../../../classes/typearticle';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Utilisateur} from '../../../../classes/utilisateur';
import {NotifierService} from 'angular-notifier';
import {ServiceEtudiantService} from '../../../../services/service-espace-etudiant/service-etudiant.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  private readonly notifier: NotifierService;
  mycontent: string;
  articleList: Array<Article> = [];
  typearticleList: Array<Typearticle>;
  utilisateurConnecte: any;
  articleToDelete: Article;

  ajouterArticle = true;
  modifierArticle = false;

  updateForm: FormGroup;
  headerChecked = false;
  afficherChecked = true;

  image = '';

  Enregistrer = 'Ajouter';

  submitted = false;
  ckeConfig: any;
  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder, private etudiantAPI: ServiceEtudiantService) {
    this.notifier = notifierService;
    this.ckeConfig = {
      language: 'fr',
      allowedContent: false,
      forcePasteAsPlainText: true,
      font_names: 'Arial;Times New Roman;Verdana',
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms']},
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      extraPlugins:
        'dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,' +
        'button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu,' +
        'contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup,' +
        'filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,' +
        'font,format,forms,horizontalrule,htmlwriter,iframe,indent,' +
        'indentblock,indentlist,justify,link,list,liststyle,magicline,' +
        'maximize,newpage,pagebreak,pastefromword,pastetext,preview,print,' +
        'removeformat,resize,save,menubutton,scayt,selectall,showblocks,' +
        'showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,' +
        'tabletools,templates,toolbar,undo,wsc,wysiwygarea',
      removeButtons: 'Save,NewPage,Preview,Image'
    };
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getArticles();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        emptyTable: 'Aucune donnée disponible dans le tableau',
            lengthMenu: 'Afficher _MENU_ éléments',
            loadingRecords: 'Chargement...',
            processing: 'Traitement...',
            zeroRecords: 'Aucun élément correspondant trouvé',
            paginate: {
                first: 'Premier',
                last: 'Dernier',
                previous: 'Précédent',
                next: 'Suiv'
            },
            decimal: ',',
            info: 'Affichage de _START_ à _END_ sur _TOTAL_ éléments',
            infoEmpty: 'Affichage de 0 à 0 sur 0 éléments',
            search: 'Rechercher:',
            searchPlaceholder: '...',
            thousands: '.',
            infoFiltered: '(filtrés depuis un total de _MAX_ éléments)',
      }
    };
    this.getTypeArticles();
    await this.getUtilisateurConnecte();
  }

  initForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      titre: ['', Validators.required],
      libelle: ['', Validators.required],
      contenu: ['', Validators.required],
      typearticle:  ['', Validators.required],
      date: ['', Validators.required],
      fin: [''],
      header: [false],
      afficher: [true],
      photo: ['', Validators.required],
    });
  }

  getTypeArticles() {
    this.serviceAPI.getTypeArticle().subscribe( data => this.typearticleList = data);
  }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.etudiantAPI.getUtilisateurConnecte().toPromise();
  }

  get f() {
    return this.updateForm.controls;
  }

  async getArticles() {
    this.articleList = await this.serviceAPI.getArticle().toPromise();
   // this.articleList = articles.filter(art => art.afficher === true);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm.get('photo').setValue(file);
    }
  }

  update(){
    this.submitted = true;
    if (this.ajouterArticle) {
      if (this.updateForm.invalid) {
        return;
      }
      else{
        const formData = new FormData();
        formData.append('titre', this.updateForm.get('titre').value);
        formData.append('libelle', this.updateForm.get('libelle').value);
        formData.append('date', this.updateForm.get('date').value);
        formData.append('fin', this.updateForm.get('fin').value);
        formData.append('contenu', this.updateForm.get('contenu').value);
        formData.append('typearticle', this.updateForm.get('typearticle').value);
        formData.append('utilisateur', (this.utilisateurConnecte.id).toString());
        formData.append('header',  this.updateForm.get('header').value);
        formData.append('afficher',  this.updateForm.get('afficher').value);
        formData.append('photo', this.updateForm.get('photo').value);
        this.image = '';
        this.serviceAPI.postArticleFromBack(formData).subscribe( data => {
          this.updateForm.reset();
          this.updateForm.get('photo').setValue('');
          this.submitted = false;
          this.getArticles();
          window.location.reload();
        });
      }
    }
    else if (this.modifierArticle) {
      let br = (document.getElementById('photo') as HTMLInputElement).files[0];
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      if (br)
      {
        const article = new Article();
        article.id = this.updateForm.value.id;
        const formData = new FormData();
        formData.append('id', this.updateForm.get('id').value);
        formData.append('titre', this.updateForm.get('titre').value);
        formData.append('libelle', this.updateForm.get('libelle').value);
        formData.append('date', this.updateForm.get('date').value);
        formData.append('fin', this.updateForm.get('fin').value);
        formData.append('contenu', this.updateForm.get('contenu').value);
        formData.append('typearticle', this.updateForm.get('typearticle').value);
        formData.append('utilisateur', (this.utilisateurConnecte.id).toString());
        formData.append('header',  this.updateForm.get('header').value);
        formData.append('afficher',  this.updateForm.get('afficher').value);
        formData.append('photo', this.updateForm.get('photo').value);

        this.serviceAPI.updateArticle(formData, article).subscribe( data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.ajouterArticle = true;
          this.modifierArticle = false;
          this.submitted = false;
          window.location.reload();
        } );
      }
      if (!br) {
        const article = new Article();
        article.id = this.updateForm.value.id;
        article.titre = this.updateForm.value.titre;
        article.libelle = this.updateForm.value.libelle;
        article.date = this.updateForm.value.date;
        article.fin = this.updateForm.value.fin;
        article.contenu = this.updateForm.value.contenu;
        article.typearticle = this.updateForm.value.typearticle;
        article.utilisateur = this.updateForm.value.utilisateur;
        article.header = this.updateForm.value.header;
        article.afficher = this.updateForm.value.afficher;
        this.serviceAPI.updateArticleSimple(article).subscribe(data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.ajouterArticle = true;
          this.modifierArticle = false;
          this.submitted = false;
          window.location.reload();

        });
      }
    }
  }

  loadDeleteConfirm(article: Article) {
    this.articleToDelete = article;
  }

  deleteArticle(){
    this.serviceAPI.deleteArticle(this.articleToDelete).subscribe();
    this.NotificationShowSucces();
    window.location.reload();
  }

  reset() {
    this.updateForm.reset();
    this.submitted = false;
    this.Enregistrer = 'Ajouter';
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  updating(art: Article) {
    window.scroll(0, 0);
    this.modifierArticle = true;
    this.ajouterArticle = false;
    this.updateForm = this.formBuilder.group({
      id: [art.id, Validators.required],
      titre: [art.titre, Validators.required],
      libelle: [art.libelle, Validators.required],
      date: [art.date, Validators.required],
      fin: [art.fin],
      contenu: [art.contenu, Validators.required],
      typearticle: [art.typearticle.id, Validators.required],
      afficher: [art.afficher],
      header: [art.header],
      photo: [''],
    });
    this.headerChecked = art.header;
    this.image = 'url(' + this.serviceAPI.APIURL + art.photo + ')';
    this.Enregistrer = 'Modifier';
  }
}
