import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {UtilisateurConnecte} from '../../classes/utilisateur-connecte';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Parcours} from '../../classes/parcours';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ServiceEnseignantService {
  APIURL = '/api/';
  token = localStorage.getItem('currentUser');
  allHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    Authorization:  this.token ,
  });
  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    Authorization: this.token
  });
  httpOptions = {
    headers: this.allHeaders
  };
  constructor(private http: HttpClient , private router: Router) { }

  // Utilisateur Connecte
  getUtilisateurConnecte(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'EnseignantConnecte', {headers: this.allHeaders}).pipe(
      catchError((err) => {
          if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getParcours(): Observable<Parcours[]> {
    return this.http.get<Parcours[]>(this.APIURL + 'Parcours-emploi-du-temps', {headers: this.allHeaders}).pipe(
      catchError((err) => {
          if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

}
