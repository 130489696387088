import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ServiceAPIService} from 'src/app/services/service-api.service';
import {Historiqueexperiencepro} from '../../../classes/historiqueexperiencepro';
import {PassUtilisateurService} from '../../../services/pass-utilisateur.service';
import {ServiceEtudiantService} from '../../../services/service-espace-etudiant/service-etudiant.service';

interface NiveauWithCount {
  niveau: string;
  count: number;
}

@Component({
  selector: 'app-disponibilite',
  templateUrl: './disponibilite.component.html',
  styleUrls: ['./disponibilite.component.scss']
})
export class DisponibiliteComponent implements OnInit {
  p = 1;
  f = ['Tous'];
  APIURL = this.api.APIURL;

  etudiantsDisponiblesWithExp: Array<any> = [];
  etudiantsDisponiblesWithSkills: Array<any> = [];
  allEtudiantsDisponiblesWithSkills: Array<any> = [];
  experienceColor = new Map();
  experiencesList: Array<Historiqueexperiencepro> = [];
  technos: Array<number> = [];
  senior = false;
  public niveauxWithCount: Array<NiveauWithCount> = [];

  filtre = [];

  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = true;
  public labels: any = {
    previousLabel: 'Precedent',
    nextLabel: 'Suivant',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  constructor( private router: Router, private api: ServiceAPIService, private passUtilisateur: PassUtilisateurService, private espaceEtudiantAPI: ServiceEtudiantService){
    if (this.passUtilisateur.getUtilisateur) {
      const utilisateur = this.passUtilisateur.getUtilisateur;
      this.p = utilisateur.page;
      if (utilisateur.filtre !== 'Tous') {
        this.f = [utilisateur.filtre];
      }
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getDisponibles();
    await this.GetNiveau();
  }

  async getDisponibles () {
    const data = await this.espaceEtudiantAPI.getDisponibles().toPromise();
    this.etudiantsDisponiblesWithExp = data;
    this.etudiantsDisponiblesWithSkills = data;
    this.allEtudiantsDisponiblesWithSkills = data;
    if ( this.f[this.f.length - 1 ] !== 'Tous') {
      this.filterNiveau(this.f[this.f.length - 1 ]);
    }
  }

  opportunite(){
      this.router.navigate([ '/espace-etudiant/opportunites' ]);
  }

  async GetNiveau() {
    const niveaux = await this.espaceEtudiantAPI.getNiveaux().toPromise();
    niveaux.forEach( niveau => {
      const niveauCounted: NiveauWithCount = { niveau: niveau.niveau, count: (this.allEtudiantsDisponiblesWithSkills.filter( et => et.niveau === niveau.niveau )).length };
      this.niveauxWithCount.push(niveauCounted);
    });
    this.niveauxWithCount.push({niveau: 'Ancien', count: (this.allEtudiantsDisponiblesWithSkills.filter( et => et.niveau === 'Ancien' )).length});
  }

  async voirProfile(id: number) {
    const data = { id: id, page: this.p, filtre: this.f[this.f.length - 1]};
    this.passUtilisateur.sendUtilisateur(data);
  }

  filterNiveau(niveau: string) {
    this.f = [];
    this.f.push(niveau);
    if (niveau === 'Ancien') {
      const dispoAncien = this.allEtudiantsDisponiblesWithSkills.filter( etudiant => etudiant.niveau === 'Ancien');
      this.etudiantsDisponiblesWithSkills = dispoAncien;
    } else {
      const niveauFiltre = this.allEtudiantsDisponiblesWithSkills.filter( etudiant => etudiant.niveau === niveau);
      this.etudiantsDisponiblesWithSkills = niveauFiltre;
    }
  }
}
