export class Formation {
  id: number;
  sigle: string;
  libelle: string;
  explication: string;
  resume:string;
  carriere_niveau : string;
  admission: string;
  photo: string;
  css_class: string;
  fa_icone: string;
}
