<div class="container">
  <div class="card" style="width: 100%; padding-left: -10px;">
    <div class="card-header bg-forth text-clear text-center">
      <h5  *ngIf="ajouterArticle">Ajouter un nouvel article</h5>
      <h5 *ngIf="modifierArticle">Modifier l' article</h5>
    </div>
    <div class="card-body">
      <form [formGroup]="updateForm" (ngSubmit)="update()" class="row" *ngIf="utilisateurConnecte">
        <div class="col-lg-8 col-md-6 col-sm-12">
          <input type="text" class="form-control" formControlName="id" hidden>
          <div class="form-group">
            <label for="titre">Titre (Nom et prenoms si ajout discours)</label>
            <input type="text" class="form-control" id="titre" [ngClass]="{ 'is-invalid': submitted && f.titre.errors }" formControlName="titre">
            <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
              <div *ngIf="f.titre.errors.required">Veuillez renseigner le titre</div>
            </div>
          </div>
          <div class="form-group">
            <label for="libelle">Bref explication (Poste si ajout discours)</label>
            <input type="text" class="form-control" id="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }" formControlName="libelle">
            <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
              <div *ngIf="f.libelle.errors.required">Veuillez renseigner la légende</div>
            </div>
          </div>
          <label for="contenu">Contenu</label>
          <ckeditor  [(ngModel)]="mycontent"  [config]="ckeConfig" id="contenu" data="Hello" [ngClass]="{ 'is-invalid': submitted && f.contenu.errors }" formControlName="contenu"></ckeditor>
          <div *ngIf="submitted && f.contenu.errors" class="invalid-feedback">
            <div *ngIf="f.contenu.errors.required">Veuillez renseigner le contenu</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="typearticle">Catégorie</label>
            <select class="form-control" id="typearticle" [ngClass]="{ 'is-invalid': submitted && f.typearticle.errors }" formControlName="typearticle">
              <option *ngFor="let type of typearticleList" [ngValue]="type.id">{{type.libelle}}</option>
            </select>
            <div *ngIf="submitted && f.typearticle.errors" class="invalid-feedback">
              <div *ngIf="f.typearticle.errors.required">Veuillez renseigner la Catégorie</div>
            </div>
          </div>
          <div class="form-group">
            <label for="date">Date</label>
            <input type="date" class="form-control mb-3" id="date" aria-describedby="date" [ngClass]="{ 'is-invalid': submitted && f.date.errors }" formControlName="date">
            <div class="form-group mt-2">
            <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
              <div *ngIf="f.date.errors.required">Veuillez renseigner la date</div>
            </div>
          </div>
          <div class="form-group">
            <label for="fin">Fin</label>
            <input type="date" class="form-control mb-3" id="fin" aria-describedby="fin" formControlName="fin">
          </div>
          <div class="col-sm-10 mx-auto">
            <label for="photo">Photo</label>
            <input type="file" id="photo" class="form-control-file" (change)="onFileSelect($event)" [ngClass]="{ 'is-invalid': submitted && f.photo.errors }">
            <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
              <div *ngIf="f.photo.errors.required">Veuillez renseigner la photo</div>
            </div>
          </div>
          <div id="image" [ngStyle]="{'width': '100%','max-width': '200px' , 'height': '150px', 'background-size': 'cover', 'background-image': image }"></div>
            <div class="form-group form-check">
              <input type="checkbox" formControlName="header" id="acceptTerms" class="form-check-input" checked="headerChecked"/>
              <label for="acceptTerms" class="form-check-label">Ajouter à l'header</label>
            </div>
            <div class="form-group form-check">
              <input type="checkbox" formControlName="afficher" id="afficher" class="form-check-input" checked="afficherChecked"/>
              <label for="acceptTerms" class="form-check-label">Afficher</label>
            </div>
          <button type="submit" class="btn btn-primary btn-sm btn-block mt-2" id="submit">Publier</button>
          <button type="reset" class="btn btn-outline-primary btn-sm btn-block" (click)="reset()">Annuler</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="example" class="table table-striped" datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="articleList.length">
        <thead>
        <tr>
          <th>Titre</th>
          <th>Date</th>
          <th>Catégorie</th>
          <th>Auteur</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let art of articleList">
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p class="font-weight-bold mb-0">{{ art.titre }}</p>
              </div>
            </div>
          </td>
          <td>{{ art.date }}</td>
          <td *ngIf="art.typearticle">{{ art.typearticle.libelle }}</td>
          <td><span *ngIf="art.utilisateur">{{ art.utilisateur.nom }} {{ art.utilisateur.prenom }}</span><span *ngIf="!art.utilisateur"> Ancien utilisateur</span></td>
          <td>
            <div *ngIf="art.utilisateur && utilisateurConnecte"><button class="btn btn-primary mr-2" href="#" (click)="updating(art)" *ngIf="art.utilisateur.id === utilisateurConnecte.id"><i class="fa fa-edit"></i></button></div>
            <button class="btn btn-danger"  data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(art)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal" id="delete" role="dialog" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="articleToDelete"> {{articleToDelete.titre}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteArticle()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
