<form [formGroup]="formPartenaire" class="text-center border border-dark p-5">
  <p class="h4 mb-4">Ajout de partenaires</p>
  <div class="mb-3 mt-3"id ="image" [ngStyle]="{'background-image': image, 'background-size': 'cover', 'width': '100px', 'height': '100px'}"></div>
  <input class="mb-5 ml-5 mx-auto" type="file"(change)="onFileSelect($event)" style="width: 100%">
  <button class="btn btn-primary btn-block mb-3"  type="submit" (click)="postPartenaire()">Enregistrer</button>

</form>
<div  class=" container mt-5">
  <div  class="row">
    <div class="col-md-3 mb-5"*ngFor="let photo of photos" style="width: 100%;height:200px;max-width:200px">
      <img [src]="photo.imageData" class="img-fluid" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(photo)">
    </div>
  </div>

</div>
<notifier-container></notifier-container>

<!-- Modal -->
<div class="modal fade" id="delete" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous supprimer le partenaire?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
