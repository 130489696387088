<div class="font-text">
  <app-etudiant-header></app-etudiant-header>
  <div class="container-fluid mb-5 px-5 font-text">
    <section>
      <header class="section-header wow fadeInUp">
        <h3>EMPLOI DU TEMPS
        </h3>
      </header>
    </section>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 mb-4">
        <app-timeline></app-timeline>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12">
        <app-profil-etudiant></app-profil-etudiant>
      </div>
    </div>
  </div>
  <app-actualite-etudiant></app-actualite-etudiant>
</div>







