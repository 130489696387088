import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth-service.service';
import {AuthScolariteService} from './auth-scolarite.service';
import {Observable, throwError} from 'rxjs';
import {ConnexionApiService} from './connexion-api.service';
import {ServiceAPIService} from './service-api.service';
import {ServiceEtudiantService} from './service-espace-etudiant/service-etudiant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardScolariteService{
  constructor(private router: Router, private connexionAPI: ConnexionApiService, private serviceAPI: ServiceEtudiantService) {
  }

  // tslint:disable-next-line:max-line-length
  // @ts-ignore
  // tslint:disable-next-line:max-line-length
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = await this.serviceAPI.getUtilisateurConnecte().toPromise()
    .then((data) => {
      if ( (data.profile.filter(profile => profile.libelle === 'Scolarite')).length > 0) {
        return true;
      }else {
        this.router.navigate(['/403']);
        return false;
      }
    })
      .catch((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
      });
    return currentUser;
  }
}
