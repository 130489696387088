import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {TimelineElement} from '../../classes/timeline-element';
import {ServiceAPIService} from '../../services/service-api.service';

@Component({
  selector: 'app-horizontal-timeline',
  templateUrl: 'horizontal-timeline.component.html',
  styleUrls: ['horizontal-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentState', [
      state('active', style({
        position: 'relative', 'z-index': 2, opacity: 1,
      })),
      transition('right => active', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({opacity: 0, transform: 'translateX(100%)', offset: 0}),
          style({opacity: 1, transform: 'translateX(0%)', offset: 1.0})
        ]))
      ]),
      transition('active => right', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({opacity: 1, transform: 'translateX(0%)', offset: 0}),
          style({opacity: 0, transform: 'translateX(100%)', offset: 1.0})
        ]))
      ]),
      transition('active => left', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({opacity: 1, transform: 'translateX(0%)', offset: 0}),
          style({opacity: 0, transform: 'translateX(-100%)', offset: 1.0})
        ]))
      ]),
      transition('left => active', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({opacity: 0, transform: 'translateX(-100%)', offset: 0}),
          style({opacity: 1, transform: 'translateX(0%)', offset: 1.0})
        ]))
      ]),
    ])
  ]
})
export class HorizontalTimelineComponent implements AfterViewInit {
  prevLinkInactive = true;
  nextLinkInactive = false;
  loaded = false;
  selectedIndex = 0;
  @ViewChild('timelineWidth') timelineWidth: ElementRef;
  @ViewChild('eventsWrapper') eventsWrapper: ElementRef;
  @ViewChild('fillingLine') fillingLine: ElementRef;
  @ViewChildren('timelineEvents') timelineEvents: QueryList<ElementRef>;
  eventsWrapperWidth = 0;
  prevElement: TimelineElement;
  nextElement: TimelineElement;
  currentElement: TimelineElement;
  selectedElement: TimelineElement;
  eventWrapperStart = 0;
  timelineEventsArray = [];
  background = '';
  APIimage =  this.serviceAPI.APIURL;
  arriverAuBout = false;
  width = 0;
  photoToShow: string;

  private _viewInitialized = false;

  constructor(private _cdr: ChangeDetectorRef,private serviceAPI: ServiceAPIService) {
  }

  private _timelineWrapperWidth = 800;
  eventWrapperEnd = this._timelineWrapperWidth;

  @Input()
  set timelineWrapperWidth(value: number) {
    this._timelineWrapperWidth = value;
    this._cdr.detectChanges();
  }

  private _eventsMinDistance = 80;

  @Input()
  set eventsMinDistance(value: number) {
    this._eventsMinDistance = value;
    this.initView();
  }

  private _timelineElements: TimelineElement[];

  get timelineElements(): TimelineElement[] {
    return this._timelineElements;
  }

  @Input()
  set timelineElements(value: TimelineElement[]) {
    this._timelineElements = value;
    this.initView();
  }

  private _dateFormat = 'dd.MM.yyyy';

  get dateFormat(): string {
    return this._dateFormat;
  }

  @Input()
  set dateFormat(value: string) {
    this._dateFormat = value;
    this.initView();
  }

  private _disabled = false;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  private _showContent = false;

  get showContent(): boolean {
    return this._showContent;
  }

  @Input()
  set showContent(value: boolean) {
    this._showContent = value;
    this._cdr.detectChanges();
  }

  private static pxToNumber(val: string): number {
    return Number(val.replace('px', ''));
  }

  private static getElementWidth(element: Element): number {
    const computedStyle = window.getComputedStyle(element);
    if (!computedStyle.width) {
      return 0;
    }
    return HorizontalTimelineComponent.pxToNumber(computedStyle.width);
  }

  private static parentElement(element: any, tagName: string) {
    if (!element || !element.parentNode) {
      return null;
    }

    let parent = element.parentNode;
    while (true) {
      if (parent.tagName.toLowerCase() === tagName) {
        return parent;
      }
      parent = parent.parentNode;
      if (!parent) {
        return null;
      }
    }
  }

  private static getTranslateValue(timeline: Element) {
    const timelineStyle = window.getComputedStyle(timeline);
    const timelineTranslate = timelineStyle.getPropertyValue('-webkit-transform') ||
      timelineStyle.getPropertyValue('-moz-transform') ||
      timelineStyle.getPropertyValue('-ms-transform') ||
      timelineStyle.getPropertyValue('-o-transform') ||
      timelineStyle.getPropertyValue('transform');

    let translateValue = 0;
    if (timelineTranslate.indexOf('(') >= 0) {
      const timelineTranslateStr = timelineTranslate
        .split('(')[1]
        .split(')')[0]
        .split(',')[4];
      translateValue = Number(timelineTranslateStr);
    }

    return translateValue;
  }

  private static setTransformValue(element: any, property: any, value: any) {
    element.style['-webkit-transform'] = property + '(' + value + ')';
    element.style['-moz-transform'] = property + '(' + value + ')';
    element.style['-ms-transform'] = property + '(' + value + ')';
    element.style['-o-transform'] = property + '(' + value + ')';
    element.style.transform = property + '(' + value + ')';
  }

  private static dayDiff(first: Date, second: Date): number {
    return Math.round(second.getTime() - first.getTime());
  }

  private static minLapse(elements: TimelineElement[]): number {
    if (elements && elements.length && elements.length === 1) {
      return 0;
    }

    let result = 0;
    for (let i = 1; i < elements.length; i++) {
      const distance = HorizontalTimelineComponent.dayDiff(elements[i - 1].date, elements[i].date);
      result = result ? Math.min(result, distance) : distance;
    }
    return result;
  }

  ngAfterViewInit(): void {
    this._cdr.detach();
    this._viewInitialized = true;
    this.initView();
  }

  onScrollClick(event: Event, forward: boolean) {
    event.preventDefault();
    this.updateSlide(event, this.eventsWrapperWidth, forward);
    this._cdr.detectChanges();
  }

  onEventClick(event: Event, selectedItem: TimelineElement) {
    this.selectedElement = selectedItem;
    event.preventDefault();
    if (this._disabled) {
      return;
    }
    const element = event.target;
    // detect click on the a single event - show new event content
    let visibleItem = this._timelineElements[0];
    this._timelineElements.forEach(function (item: TimelineElement) {
      if (item.selected && item !== selectedItem) {
        visibleItem = item;
        item.selected = false;
      }
    });
    this.selectedIndex = this._timelineElements.indexOf(selectedItem);
    selectedItem.selected = true;
    this.background = selectedItem.photo;
    this.photoToShow = selectedItem.photo;
    this.updateFilling(element);
    this._cdr.detectChanges();
  }

  initTimeline(timeLines: TimelineElement[]) {
    const eventsMinLapse = HorizontalTimelineComponent.minLapse(timeLines);
    // assign a left position to the single events along the timeline
    this.setDatePosition(timeLines, this._eventsMinDistance, eventsMinLapse);
    // assign a width to the timeline
    this.setTimelineWidth(timeLines, this._eventsMinDistance, eventsMinLapse);
    this.width = this.timelineWidth.nativeElement.offsetWidth - 140;
    // the timeline has been initialize - show it
    this.loaded = true;
  }

  updateSlide(event: Event, timelineTotWidth: number, forward: boolean) {
    const translateValue = HorizontalTimelineComponent.getTranslateValue(this.eventsWrapper.nativeElement);
    if (!this.selectedElement) {
      this.selectedElement = this.timelineElements[0];
    }
    let id = this.timelineElements.indexOf(this.selectedElement);
    this.currentElement = this.timelineElements[id];
    if (this.timelineEventsArray[id + 1]) {
      var tonext = -this.timelineEventsArray[id + 1].nativeElement.offsetLeft + (this.width / 2);
      var toprev;
    } else if (!this.timelineEventsArray[id + 1]) {
      this.arriverAuBout = true;
      var tonext = -this.timelineEventsArray[id].nativeElement.offsetLeft + (this.width / 2);
    }
    if (forward) {
      if (!this.timelineElements[id + 1]) {
        this.nextLinkInactive = true;
        return;
      }
      this.nextElement = this.timelineElements[id + 1];
      if (this.timelineEventsArray[id + 1]) {
        if (-tonext > this.width) {
          HorizontalTimelineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', tonext + 'px');
        }
        let element = this.timelineEventsArray[id + 1].nativeElement;
        this.selectedIndex = id;
        this.currentElement.selected = false;
        this.nextElement.selected = true;
        this.background = this.nextElement.photo;
        this.photoToShow =  this.nextElement.photo;
        this.updateFilling(element);
        this.selectedElement = this.nextElement;
        this.prevLinkInactive = false;
      }
    } else {
      if (!this.timelineEventsArray[id - 1]) {
        this.prevLinkInactive = true;
        return;
      }
      this.prevElement = this.timelineElements[id - 1];
      if (this.timelineEventsArray[id]) {
        if (!this.timelineEventsArray[id - 2]) {
          toprev = - this.timelineEventsArray[id - 1].nativeElement.offsetLeft ;
          HorizontalTimelineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', toprev + 'px');
        } else {
          toprev = - this.timelineEventsArray[id - 1].nativeElement.offsetLeft ;
          if (-toprev < this.width) {
            toprev = - this.timelineEventsArray[id - 2].nativeElement.offsetLeft;
            HorizontalTimelineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', toprev + 'px');
          }
          else {
            toprev = - this.timelineEventsArray[id - 1].nativeElement.offsetLeft + (this.width / 2);
            HorizontalTimelineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', toprev + 'px');
          }
        }
        let element = this.timelineEventsArray[id - 1].nativeElement;
        this.selectedIndex = id;
        this.currentElement.selected = false;
        this.prevElement.selected = true;
        this.selectedElement = this.prevElement;
        this.background = this.selectedElement.photo;
        this.photoToShow = this.selectedElement.photo;
        this.updateFilling(element);
        this.nextLinkInactive = false;
      }
    }
    if (this._disabled) {
      return;
    }
  }

  updateTimelinePosition(element: Element) {
    const eventStyle = window.getComputedStyle(element);
    const eventLeft = HorizontalTimelineComponent.pxToNumber(eventStyle.getPropertyValue('left'));
    const translateValue = HorizontalTimelineComponent.getTranslateValue(this.eventsWrapper.nativeElement);

    if (eventLeft > this._timelineWrapperWidth - translateValue) {
      this.translateTimeline(-eventLeft + this._timelineWrapperWidth / 2, this._timelineWrapperWidth - this.eventsWrapperWidth);
    }
  }

  translateTimeline(value: number, totWidth: number | null) {
    // only negative translate value
    value = (value > 0) ? 0 : value;
    // do not translate more than timeline width
    value = (!(totWidth === null) && value < totWidth) ? totWidth : value;
    HorizontalTimelineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', value + 'px');
    // update navigation arrows visibility
    this.prevLinkInactive = value === 0;
    this.nextLinkInactive = value === totWidth;
  }

  setTimelineWidth(elements: TimelineElement[], width: number, eventsMinLapse: number) {
    let timeSpan = 100;
    if (elements.length > 1) {
      timeSpan = HorizontalTimelineComponent.dayDiff(elements[0].date, elements[elements.length - 1].date);
    }
    let timeSpanNorm = timeSpan / eventsMinLapse;
    timeSpanNorm = Math.round(timeSpanNorm) + 4;
    this.eventsWrapperWidth = timeSpanNorm * width + this._timelineWrapperWidth;
    HorizontalTimelineComponent.setTransformValue(this.fillingLine.nativeElement, 'scaleX', 0.001);
    return this.eventsWrapperWidth;
  }

  private updateFilling(element: any) {
    // change .filling-line length according to the selected event
    const eventStyle = window.getComputedStyle(element);
    const eventLeft = eventStyle.getPropertyValue('left');
    const eventWidth = eventStyle.getPropertyValue('width');
    const eventLeftNum = HorizontalTimelineComponent.pxToNumber(eventLeft) + HorizontalTimelineComponent.pxToNumber(eventWidth) / 2;
    const scaleValue = eventLeftNum / this.eventsWrapperWidth;
    HorizontalTimelineComponent.setTransformValue(this.fillingLine.nativeElement, 'scaleX', scaleValue);
  }

  private initView(): void {
    if (!this._viewInitialized) {
      return;
    }

    if (this._timelineElements && this._timelineElements.length) {
      for (let i = 0; i < this._timelineElements.length; i++) {
        if (this._timelineElements[i].selected) {
          this.selectedIndex = i;
          break;
        }
      }
      this.initTimeline(this._timelineElements);
    }
    this.background = this._timelineElements[0].photo;
    this.photoToShow = this._timelineElements[0].photo;
    this._cdr.detectChanges();
  }

  private setDatePosition(elements: TimelineElement[], min: number, eventsMinLapse: number) {
    this.timelineEventsArray = this.timelineEvents.toArray();
    let i = 0;
    for (const component of elements) {
      const distance = HorizontalTimelineComponent.dayDiff(elements[0].date, component.date);
      const distanceNorm = Math.round(distance / eventsMinLapse);
      this.timelineEventsArray[i].nativeElement.style.left = distanceNorm * min + 'px';
      // span
      const span: HTMLSpanElement = this.timelineEventsArray[i].nativeElement.parentElement.children[1] as HTMLSpanElement;
      const spanWidth = HorizontalTimelineComponent.getElementWidth(span);
      span.style.left = distanceNorm * min + spanWidth / 2 + 'px';
      i++;
    }
  }

}
