import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { Counter } from "../../../classes/counter";
import { ServiceAPIService } from "../../../services/service-api.service";
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit, AfterViewInit {
  counter: Counter;
  @ViewChildren('counter_item') counter_item: QueryList<ElementRef>;

  constructor(private serviceAPI: ServiceAccueilService) {
  }

  async ngOnInit(): Promise<void> {
    await this.getCounter();
  }

  ngAfterViewInit() {
    this.counter_item.changes.subscribe(() => {
      var section = document.querySelector('#counter');
      var hasEntered = false;
      window.addEventListener('scroll', (e) => {
        var shouldAnimate = (window.scrollY + window.innerHeight) >= (<HTMLElement>section).offsetTop;
        if (shouldAnimate && !hasEntered) {
          hasEntered = true;
          $('.number').each(function () {
            $(this).prop('Counter', 0).animate({
              Counter: $(this).text()
            }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                $(this).text(Math.ceil(now));
              }
            });
          });
        }
      });
    });
  }

  async getCounter() {
    var count = await this.serviceAPI.getCounter().toPromise();
    this.counter = count[count.length - 1];
  }

}
