import { Component, OnInit } from '@angular/core';
import {Club} from '../../../classes/club';
import {ConnexionApiService} from '../../../services/connexion-api.service';
import {ServiceAPIService} from '../../../services/service-api.service';
import {Router} from '@angular/router';
import {PassUtilisateurService} from '../../../services/pass-utilisateur.service';
import * as $ from 'jquery';
import {AuthAncienService} from '../../../services/auth-ancien.service';

@Component({
  selector: 'app-menu-ancien',
  templateUrl: './menu-ancien.component.html',
  styleUrls: ['./menu-ancien.component.scss']
})
export class MenuAncienComponent implements OnInit {
  reduit = false;

  constructor(private authService: AuthAncienService, private connexionAPI: ConnexionApiService, private serviceAPI: ServiceAPIService, private router: Router, private passUtilisateur: PassUtilisateurService) {
    $(function() {
      $(window).on('scroll', function() {
        // @ts-ignore
        if ($(window).scrollTop() > 100) {
          $('#header').addClass('header-scrolled');
        } else {
          $('#header').removeClass('header-scrolled');
        }
      });

      $('.mobile-nav-toggle').click(function(e) {
        $('#navbarNavDropdown').toggleClass('navbar-mobile');
        $(this).toggleClass('bi-list');
        $(this).toggleClass('bi-x');
      });
      $('.nav-accueil').click(function(e) {
        // Prevent a page reload when a link is pressed
        e.preventDefault();
        // Call the scroll function
        // alert($(this).attr('href'));
        $('html,body').animate({
          scrollTop: $($(this).attr('href')).offset().top
        }, 'slow');
      });


      /**
       * Mobile nav dropdowns activate
       */
      $('.navbar .dropdown > a').click(function(e) {
        if ($('#navbar').hasClass('navbar-mobile')) {
          e.preventDefault();
          $(this).next().toggleClass('dropdown-active');
        }
      });

    });
    $(document).ready(function() {
      $('a').click(function() {
        $('a').removeClass('active');
        $(this).addClass('active');
        $('#navbarNavDropdown').removeClass('navbar-mobile');
        $('.mobile-nav-toggle').removeClass('bi-x');
        $('.mobile-nav-toggle').addClass('bi-list');
      });
      const navbarlinks = document.querySelectorAll('#navbar .scrollto');
      const navbarlinksActive = () => {
        const position = window.scrollY + 200;
        navbarlinks.forEach(navbarlink => {
          if (!navbarlink.getAttribute('href')) {
            return;
          }
          const section = document.querySelector(navbarlink.getAttribute('href')) as HTMLElement;
          if (!section) {
            return;
          }
          if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
            navbarlink.classList.add('active');
          } else {
            navbarlink.classList.remove('active');
          }
        });
      };
      window.addEventListener('scroll', navbarlinksActive);
      /**
       * Navbar links active state on scroll
       */

    });
  }

  ngOnInit(): void {
  }

  onSignOut() {
    localStorage.clear();
    this.authService.signOut();
    window.location.reload();
  }

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }
  removeUtilisateurDispo(){
    this.passUtilisateur.removeDispo();
    window.localStorage.removeItem('dispo');
  }
}
