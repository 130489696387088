<div class="container aos-init aos-animate">
  <div class="row">
    <div class="card-deck col-lg-3 col-md-4 col-sm-6 mx-auto" *ngFor="let club of clubList">
      <a class="card" routerLink="blog" (click)="blog(club)">
        <div class="card-body">
          <div class="avatar-flip">
            <img src="/api/{{club.photoclub}}" height="100" width="100">
            <img src="/api/{{club.photoclub}}" height="100" width="100">
          </div>
          <h4 class="mt-4">{{club.sigle}}</h4>
        </div>
      </a>
    </div>
  </div>
</div>
