<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container bootstrap snippets bootdey">
  <div class="row">
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-dark panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">Actualites</p>
          <i class="fa fa-pencil fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbActualites.length}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-danger panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">Evenements</p>
          <i class="fa fa-newspaper-o fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbEvenements.length}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-primary panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">Historique</p>
          <i class="fa fa-history fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbHistorique.length}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="panel panel-info panel-colorful">
        <div class="panel-body text-center">
          <p class="text-uppercase mar-btm text-sm">Photos</p>
          <i class="fa fa-picture-o fa-5x"></i>
          <hr>
          <p class="h2 text-thin">{{NbPhotos.length}}</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container">
  <div class="row">
    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-blue">
        <div class="inner">
          <h3>{{NbUtilisateurs.length}}</h3>
          <p>Utilisateurs</p>
        </div>
        <div class="icon">
          <i class="fa fa-user" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-green">
        <div class="inner">
          <h3>{{NbAnciens.length}}</h3>
          <p>Anciens </p>
        </div>
        <div class="icon">
          <i class="fa fa-graduation-cap" aria-hidden="true"></i>
        </div>
<!--        <a href="#" class="card-box-footer">Voir Plus<i class="fa fa-arrow-circle-right"></i></a>-->
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-orange">
        <div class="inner">
          <h3>{{NbModerateurs.length}}</h3>
          <p>  Moderateurs</p>
        </div>
        <div class="icon">
          <i class="fa fa-user-plus" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div class="card-box bg-red">
        <div class="inner">
          <h3>{{NbClubs.length}}</h3>
          <p> Clubs </p>
        </div>
        <div class="icon">
          <i class="fa fa-users"></i>
        </div>
      </div>
    </div>
  </div>
</div>


