import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {TimelineElement} from '../../../classes/timeline-element';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../../services/email.service';
import {ServiceAPIService} from '../../../services/service-api.service';
import {Coordonnees} from '../../../classes/coordonnees';
import {ServiceAccueilService} from '../../../services/service-accueil/service-accueil.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  coordonnees: Coordonnees;
  public show=false;
  private readonly notifier: NotifierService;
  public submitted = false;
  timeline: TimelineElement[];
  public theme: 'light' | 'dark' = 'light';
  public lang = 'fr';
  public type: 'image';


  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  contactForm: FormGroup;
  constructor(
    private _emailService: EmailService,
    notifierService: NotifierService,
    private serviceAPI: ServiceAccueilService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getCoordonnees();
  }
  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  initForm(){
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      text: ['', Validators.required],
      subject: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit(name, email, text,subject) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }
    else{
      this._emailService.sendEmail({
          from: email,
          to: 'eni.noreply.univfianar@gmail.com',
          name: name,
          text: text,
          subject: subject,

        }
      )
        .subscribe(
          () => {

          },
          err =>{
            if(err){
              this.NotificationShowWrong()
            }

          }
        );
    }
    this.NotificationShowSucces();
    this.submitted = false;
    this.contactForm.reset();
    window.location.reload();
  }
  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Operation non enregistree',

    });
  }

  async getCoordonnees() {
    var coord = await this.serviceAPI.getCoordonnees().toPromise();
    this.coordonnees = coord[0];
  }
  handleSuccess(e) {
  }
}
