import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-localisation',
  templateUrl: './localisation.component.html',
  styleUrls: ['./localisation.component.scss']
})
export class LocalisationComponent implements OnInit {


  constructor() {}


  ngOnInit(): void {
    //this.createMap();
    // Add map controls
    const map = new mapboxgl.Map({
      accessToken: 'pk.eyJ1IjoiY2FybGFwZWRyZXQiLCJhIjoiN0Z3aWQ3QSJ9.c8n4S98H7toNcSlMzwwDlQ',
      container: 'map',
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [47.0933929, -21.4551361],
      zoom: 18,
      pitch: 45,
      attributionControl:false
    });
   // map.addControl(new mapboxgl.NavigationControl());
    map.on('load', function () {
      // Start the animation.
      rotateCamera(0);

      // Add 3d buildings and remove label layers to enhance the map
      var layers = map.getStyle().layers;
      /*for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          // remove text labels
          //map.removeLayer(layers[i].id);
        }
      }*/

      map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',
          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      });
    });

    function rotateCamera(timestamp) {
      map.rotateTo((timestamp / 100) % 360, { duration: 0 });
      requestAnimationFrame(rotateCamera);
    };
  }

}
