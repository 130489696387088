import {Component, OnInit, ViewChild} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {Assignation} from 'src/app/classes/assignation';
import {Schedule} from 'src/app/classes/schedule';
import {ServiceAPIService} from 'src/app/services/service-api.service';
import {DxChartComponent} from 'devextreme-angular';
import {EmploisDuTempsComponent} from '../emplois-du-temps/emplois-du-temps.component';
import {element} from 'protractor';

@Component({
  selector: 'app-ig',
  templateUrl: './ig.component.html',
  styleUrls: ['./ig.component.scss']
})
export class IgComponent implements OnInit {
  @ViewChild(DxChartComponent, {static: false}) chart: DxChartComponent;
  @ViewChild(EmploisDuTempsComponent, {static: false}) emploisDuTempsL1: EmploisDuTempsComponent;
  draggingGroupName: string = 'appointmentsGroup';
  public fetchOk = false;

  public actifL1 = true;
  public actifL2 = false;
  public actifL3 = false;
  public actifM1 = false;
  public actifM2 = false;

  public datas;

  public tableTypeUe = [];

  public emploisDuTempsL1Ig = [];
  public emploisDuTempsL2Ig = [];
  public emploisDuTempsL3Ig = [];
  public emploisDuTempsM1Ig = [];
  public emploisDuTempsM2Ig = [];


  public assignationMatiereL1Ig = [];
  public assignationMatiereL2Ig = [];
  public assignationMatiereL3Ig = [];
  public assignationMatiereM1Ig = [];
  public assignationMatiereM2Ig = [];

  public attributNiveauL1
  public attributNiveauL2
  public attributNiveauL3
  public attributNiveauM1
  public attributNiveauM2
  public attributParcours

  notifierService: NotifierService;

  constructor(private api: ServiceAPIService,
              notifierService: NotifierService,
  ) {

  }

  ngOnInit(): void {
    this.getAssignation();
    this.getTypeuE();
    this.getParcours()
    this.getNiveaux()
  }

  active(niveau) {
    this.actifL2 = false;
    this.actifL1 = false;
    this.actifL3 = false;
    this.actifM1 = false;
    this.actifM2 = false;

    if (niveau === 'L1') {
      this.actifL1 = true;
    }
    if (niveau === 'L2') {
      this.actifL2 = true;
    }
    if (niveau === 'L3') {
      this.actifL3 = true;
    }
    if (niveau === 'M1') {
      this.actifM1 = true;
    }
    if (niveau === 'M2') {
      this.actifM2 = true;
    }
  }
  getNiveaux(){
    var attributNiveauL1;
    var attributNiveauL2;
    var attributNiveauL3;
    var attributNiveauM1;
    var attributNiveauM2;


    this.api.getNiveau().subscribe(data=>{
    data.forEach(element=>{
      if(element.libelle=='L1'){
       attributNiveauL1=element
      }
      if(element.libelle=='L2'){
        attributNiveauL2=element
      }
      if(element.libelle=='L3'){
        attributNiveauL3=element
      }
      if(element.libelle=='M1'){
       attributNiveauM1=element
      }
      if(element.libelle=='M2'){
        attributNiveauM2=element
      }

    })
      this.attributNiveauL1 = attributNiveauL1.id
      this.attributNiveauL2 = attributNiveauL2.id
      this.attributNiveauL3 = attributNiveauL3.id
      this.attributNiveauM1 = attributNiveauM1.id
      this.attributNiveauM2 = attributNiveauM2.id
    });

  }
  getParcours(){
    var attributParcours
    this.api.getParcours().subscribe(data=>{
     data.forEach(element=>{
       if(element.libelle=='Informatique générale'){
         attributParcours=element
       }
     })
      this.attributParcours=attributParcours.id;
    });
  }
  updatedEmploisDuTemps($event) {
    this.getAssignation();
  }

  getAssignation() {
    this.getTypeuE();

    var tabL1Ig = [];
    var tabL2Ig = [];
    var tabL3Ig = [];
    var tabM1Ig = [];
    var tabM2Ig = [];

    this.api.getAssignations().subscribe(data => {
      data.forEach(el => {
        if (el.parcours.libelle == 'Informatique générale' && el.niveau.libelle == 'L1') {
          tabL1Ig.push(el);
        }
        if (el.parcours.libelle == 'Informatique générale' && el.niveau.libelle == 'L2') {
          tabL2Ig.push(el);
        }
        if (el.parcours.libelle == 'Informatique générale' && el.niveau.libelle == 'L3') {
          tabL3Ig.push(el);
        }
        if (el.parcours.libelle == 'Informatique générale' && el.niveau.libelle == 'M1') {
          tabM1Ig.push(el);
        }
        if (el.parcours.libelle == 'Informatique générale' && el.niveau.libelle == 'M2') {
          tabM2Ig.push(el);
        }
      });

      this.assignationMatiereL1Ig = tabL1Ig;
      this.assignationMatiereL2Ig = tabL2Ig;
      this.assignationMatiereL3Ig = tabL3Ig;
      this.assignationMatiereM1Ig = tabM1Ig;
      this.assignationMatiereM2Ig = tabM2Ig;

      this.datas = data;
      var emploisduTempsL1 = [];
      var emploisduTempsL2 = [];
      var emploisduTempsL3 = [];
      var emploisduTempsM1 = [];
      var emploisduTempsM2 = [];

      this.datas.forEach(el => {
        if (el.niveau.libelle == 'L1' && el.parcours.libelle == 'Informatique générale') {
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsL1.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            );

          });
        }

        if (el.niveau.libelle == 'L2' && el.parcours.libelle == 'Informatique générale') {
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsL2.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            );
          });
        }

        if (el.niveau.libelle == 'L3' && el.parcours.libelle == 'Informatique générale') {
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsL3.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            );
          });
        }

        if (el.niveau.libelle == 'M1' && el.parcours.libelle == 'Informatique générale') {
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsM1.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            );
          });
        }
        if (el.niveau.libelle == 'M2' && el.parcours.libelle == 'Informatique générale') {
          el.schedules.forEach(element => {

            //push les element dans aff qui est cense apparaitre dans Datasource
            emploisduTempsM2.push(
              {
                id: element.id,
                text: el.UE.libelle,
                endDate: element.date_fin,
                startDate: element.date_debut,
                date_debut: element.date_debut,
                date_fin: element.date_fin,
                ueUserParcoursNiveau: element.ueUserParcoursNiveau,
                description: el.utilisateur.nom,
                typeUeId: el.UE.typeue
              }
            );
          });
        }

      });
      this.emploisDuTempsL1Ig = emploisduTempsL1;
      this.emploisDuTempsL2Ig = emploisduTempsL2;
      this.emploisDuTempsL3Ig = emploisduTempsL3;
      this.emploisDuTempsM1Ig = emploisduTempsM1;
      this.emploisDuTempsM2Ig = emploisduTempsM2;

      this.fetchOk = true;
    });
  }

  getTypeuE() {
    this.api.getTypeue().subscribe(typeue => {
      typeue.forEach(el => {
        this.tableTypeUe.push({
          id: el.id,
          color: el.couleur,
          text: el.libelle
        });
      });
    });

  }

}
