<section id="temoignages" class="bg-clair font-roboto ">
  <div class=" aos-init aos-animate" data-aos="fade-up">
    <header class="section-header wow fadeInUp mt-5">
      <h3>Bureau
      </h3>
    </header>
    <div id="testimonial-slider" class="px-auto">
      <owl-carousel-o [options]="customOptions">

        <ng-template carouselSlide *ngFor="let disc of discoursList" active="item.active">
          <div class="">
            <div >
              <div class="testimonial-card">
                <div class="card-deck">
                  <div class="text" >
                    <em [innerHTML]="disc.contenu"></em>
                  </div>
                  <i class="fa fa-quote-right quote"></i>
             </div>

                <div class="footer">
                  <div></div>
                  <div class="image"  [ngStyle]="{'height':'130px','width':'130px','background-size' : 'cover','background-position' : 'center','background-image': 'url('+'/api' + disc.photo + ')'}">
<!--                    <img src="{{disc.photo}}" style="border-radius: 50%">-->
                  </div>
                    <h3 class='person mt-2 '>{{disc.titre}}<br>
                      <div class="mt-3">{{disc.libelle}}</div>
                    </h3>


                </div>
              </div>
            </div>

          </div>

        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>



