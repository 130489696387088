import { Component, OnInit } from '@angular/core';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Organigramme} from '../../../../classes/organigramme';
import {NotifierService} from 'angular-notifier';
import {Utilisateur} from '../../../../classes/utilisateur';

@Component({
  selector: 'app-organigramme',
  templateUrl: './organigramme.component.html',
  styleUrls: ['./organigramme.component.scss']
})
export class OrganigrammeComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  organigrammeList: Array<Organigramme>;
  orgToDelete: Organigramme;
  organigramme: any;

  ajouter = true;
  modifier = false;
  i = 2;
  messurer = 0;

  updateForm: FormGroup;

  Enregistrer = 'Ajouter';

  image = 'url(assets/img/utilisateur.png)';
  APIURL = this.serviceAPI.APIURL;

  levelColors = [ '#243754', '#3d9cb4', '#2fc3c3', '#e64d78', '#D24726', '#ad31a1', '#b2092b'];

  submitted = false;

  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getOrganigramme();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
        "lengthMenu": "Afficher _MENU_ éléments",
        "loadingRecords": "Chargement...",
        "processing": "Traitement...",
        "zeroRecords": "Aucun élément correspondant trouvé",
        "paginate": {
          "first": "Premier",
          "last": "Dernier",
          "previous": "Précédent",
          "next": "Suiv"
        },
        "decimal": ",",
        "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
        "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
        "search": "Rechercher:",
        "searchPlaceholder": "...",
        "thousands": ".",
        "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
  }

  initForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      institution: ['' , Validators.required],
      details: ['' , Validators.required],
      parent: [''],
    });
  }

  updating(org: Organigramme){
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    if (org.parent) {
      this.updateForm = this.formBuilder.group({
        id: [org.id, Validators.required],
        institution: [org.institution , Validators.required],
        details: [org.details , Validators.required],
        parent: [org.parent.id]
      });
    } else {
      this.updateForm = this.formBuilder.group({
        id: [org.id, Validators.required],
        institution: [org.institution , Validators.required],
        details: [org.details , Validators.required],
      });
    }
    this.Enregistrer = 'Modifier';
  }

  async getOrganigramme() {
    let orgs = await this.serviceAPI.getOrganigramme().toPromise();
    this.organigrammeList = orgs;
  }

  get f() {
    return this.updateForm.controls;
  }

  async update() {
    this.submitted = true;
    this.i = 1;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const org = new Organigramme();
        org.institution = this.updateForm.value.institution;
        org.details = this.updateForm.value.details;
        if ((this.updateForm.value.parent)) {
          org.parent = this.updateForm.value.parent;
          org.couleur = this.levelColors[1];
          // tslint:disable-next-line:prefer-const
          var data = await this.serviceAPI.getParent(this.updateForm.value.parent).toPromise();
            if (data) {
              let orgp = data[0];
              while ((orgp.parent)) {
                orgp = orgp.parent;
                this.i = this.i + 1;
                org.couleur = this.levelColors[this.i];
              }
            }
        } else {
          org.couleur = this.levelColors[0];
        }
        this.serviceAPI.postOrganigramme(org).subscribe( data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.submitted = false;
          window.location.reload();
        });
      }
    }
    if (this.modifier){
      this.submitted = true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const org = new Organigramme();
        org.id = this.updateForm.value.id;
        org.institution = this.updateForm.value.institution;
        org.details = this.updateForm.value.details;

        if ((this.updateForm.value.parent)) {
          org.parent = this.updateForm.value.parent;
          org.couleur = this.levelColors[1];
          // tslint:disable-next-line:prefer-const
          var data = await this.serviceAPI.getParent(this.updateForm.value.parent).toPromise();
          if (data) {
            let orgp = data[0];
            while ((orgp.parent)) {
              orgp = orgp.parent;
              this.i = this.i + 1;
              org.couleur = this.levelColors[this.i];
            }
          }
        } else {
          org.couleur = this.levelColors[0];
        }
        this.serviceAPI.updateOrganigramme(org).subscribe( data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.ajouter = true;
          this.modifier = false;
          this.submitted = false;
          window.location.reload();
        } );
      }
    }
  }

  loadDeleteConfirm(org: Organigramme) {
    this.orgToDelete = org;
  }


  delete(){
    this.serviceAPI.deleteOrganigramme(this.orgToDelete).subscribe( () => {
      this.NotificationShowSucces();
      this.getOrganigramme();
      window.location.reload();
    });
  }

  reset(){
    this.image = 'url(assets/img/utilisateur.png)';
    this.updateForm.reset();
    this.submitted = false;
    this.Enregistrer = 'Ajouter';
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',
    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',
    });
  }
}
