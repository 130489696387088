<nav class="navbar navbar-dark bg-forth h-10">
  <span class="text-clear mb-0 h6 " *ngIf="utilisateurConnecte">
   <a class="pointeur text-white" data-toggle="modal" data-target="#editUtilisateur" (click)="updating()">{{ utilisateurConnecte.nom }}  {{ utilisateurConnecte.prenom }}</a>
    , connecté en tant qu'administrateur
  </span>

    <ul class="nav navbar-nav ml-auto">
      <a class="nav-link text-white pointeur" (click)="signOut()"><i class="fa fa-power-off mr-3"></i>Deconnexion</a>
    </ul>
</nav>
<div class="modal fade" id="editUtilisateur" tabindex="-1" role="dialog" aria-labelledby="editUtilisateurLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editUtilisateurLabel">Modifier votre profil</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editUtilisateurForm" (ngSubmit)="update()">
          <div class="form-group  m-2">

            <label for="nom">Nom</label>
            <input type="text" class="form-control" id="nom" formControlName="nom">

            <label for="prenom">Prénom</label>
            <input type="text" class="form-control" id="prenom" formControlName="prenom">

            <label for="adresse_mail">Adresse mail</label>
            <input type="text" class="form-control" id="adresse_mail" formControlName="adresse_mail">


            <label for="nouveau_mot_de_passe">Nouveau mot de passe</label>
            <input type="password" class="form-control" id="nouveau_mot_de_passe"
                   formControlName="nouveau_mot_de_passe">

            <label for="confirmer_nouveau_mot_de_passe">Confirmer le nouveau mot de passe</label>
            <input type="password" class="form-control" id="confirmer_nouveau_mot_de_passe"
                   formControlName="confirmer_nouveau_mot_de_passe">
            <div class="invalid-feedback d-block" *ngIf="confirmedFalse">
              <div >Veuillez revérifier les mots de passe </div>
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
            <button type="submit" class="btn btn-primary" >Modifier</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
