import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Utilisateur} from './../classes/utilisateur';
import {Article} from '../classes/article';
import {Audience} from '../classes/audience';
import {Club} from '../classes/club';
import {Commentaire} from '../classes/commentaire';
import {Coordonnees} from '../classes/coordonnees';
import {Counter} from '../classes/counter';
import {Formation} from '../classes/formation';
import {Localisation} from '../classes/localisation';

import {Niveau} from '../classes/niveau';
import {Notification} from '../classes/notification';

import {Parcours} from '../classes/parcours';
import {VieEtudiant} from '../classes/vie-etudiant';
import {ReseauxSociaux} from '../classes/reseaux-sociaux';
import {Schedule} from '../classes/schedule';

import {Techno} from '../classes/techno';
import {Typetechno} from '../classes/typetechno';
import {Typearticle} from '../classes/typearticle';
import {Typeopportunite} from '../classes/typeopportunite';
import {Opportunite} from '../classes/opportunite';
import {Profile} from '../classes/profile';
import {Enseignant} from '../classes/enseignant';
import {UE} from '../classes/ue';
import {Historiqueexperiencepro} from '../classes/historiqueexperiencepro';
import {Typeexperience} from '../classes/typeexperience';
import {Typeue} from '../classes/typeue';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Assignation} from '../classes/assignation';
import { TimelineElement } from '../classes/timeline-element';
import { Header } from '../classes/header';
import {Partenaire} from '../classes/partenaire';
import {Album} from '../classes/album';
import { Galerie } from '../classes/galerie';
import {Organigramme} from '../classes/organigramme';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ServiceAPIService {
  APIURL = '/api/';
  theToken = localStorage.getItem('currentUser');
  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    Authorization: '@3kpSs+7Q5n6t-RH5ai4M@=Qp83c89nAw8nVn<3tP9#Tmg23-Q?*t539/fn8MDqh<e9Cx$55R@65Rh2C3%p.q8Y8V&53h_Sxr'
  });
  allHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    Authorization: this.theToken
  });

  httpOptions = {
    headers: this.allHeaders
  };
  // allHeaders = new HttpHeaders(this.allHeadersDict);

  constructor(private http: HttpClient, private router: Router) {
  }
  private _errorHandler(error: Response) {
    return Observable.throw(error || 'Server Error') ;
  }
  getNotesEtudiant(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'NotesEtudiant', {headers: this.allHeaders}).pipe(
      catchError((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
        }
      ));
  }
  /**
   *
   * UTILISATEUR
   */
  getEnseignants(): Observable<Enseignant[]>{
    return this.http.get<Enseignant[]>(this.APIURL + 'utilisateur?limit=100000&sort=nom%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
        }
      ));
  }

  getUtilisateur(): Observable<Utilisateur[]> {
    return this.http.get<Utilisateur[]>(this.APIURL + 'utilisateur?limit=100000&sort=nom%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
        }
      ));
  }

  getOneUtilisateurByStringId(id: number): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(this.APIURL + 'utilisateur/' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
      }
    ));
  }

  getOneUtilisateur(id: string): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(this.APIURL + 'utilisateur/' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
      }
    ));
  }

  postUtilisateur(formData: FormData): Observable<any> {
    return this.http.post<Utilisateur>(this.APIURL + 'utilisateur', formData, this.httpOptions).pipe(
      catchError((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
        }
      ));
  }


  chercherParId(utilisateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(utilisateur);
    return this.http.get<Utilisateur>(this.APIURL + 'utilisateur/' + utilisateur.id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  chercherMail(mail: string): Observable<any> {
    return  this.http.get<Utilisateur>( this.APIURL + 'utilisateur?adresse_mail=' + mail, {headers: this.allHeaders}).pipe(
      catchError( (err) => {
         throw err;
      })
    );
  }

  chercherNumMatricule(num_mat: string): Observable<any> {
    return  this.http.get<Utilisateur>( this.APIURL + 'utilisateur?numero_matricule=' + num_mat, {headers: this.headers}).pipe(
      catchError( (err) => {
        throw err;
      })
    );
  }

  updateUtilisateur(utilisateur: Utilisateur): Observable<any> {

    const body = JSON.stringify(utilisateur);

    return this.http.patch<Utilisateur>(this.APIURL + 'utilisateurConnecte/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateUtilisateurLogin(utilisateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(utilisateur);
    return this.http.patch<Utilisateur>(this.APIURL + 'utilisateur/' + utilisateur.id, body, {headers: this.headers}).pipe(
      catchError((err) => {
        throw err;
        }
      ));
  }

  updateUtilisateurConnecte(utilisateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(utilisateur);
    return this.http.patch<Utilisateur>(this.APIURL + 'utilisateurConnecte/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  checkingUtilisateurPassword(utilisateur: Utilisateur) {
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(utilisateur);
    return this.http.post<Utilisateur>(this.APIURL + 'checkpassword', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  checkMailExists(formData: FormData) {
    return this.http.post<any>(this.APIURL + 'checkMailExists', formData, {headers : this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteUtilisateur(utilisateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(utilisateur);
    return this.http.delete<Utilisateur>(this.APIURL + 'utilisateur/' + utilisateur.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /*
  HISTORIQUE EXPERIENCE PRO
   */
  getHistoriqueExperience(id: number): Observable<Historiqueexperiencepro[]> {
    return this.http.get<Historiqueexperiencepro[]>(this.APIURL + 'experiencespro/?where={"utilisateur":' + id + '}', {headers: this.allHeaders}).pipe(
      catchError((err) => {
        if (err.status === 401){
          localStorage.clear();
          this.router.navigate(['/Login']);
          return throwError(err);
        }
      }
    ));
  }

  postHistorique(formData: FormData): Observable<any> {
    return this.http.post<Historiqueexperiencepro>(this.APIURL + 'historiqueexperiencepro', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateHistorique(exp: Historiqueexperiencepro): Observable<any> {
    const body = JSON.stringify(exp);
    return this.http.patch<Historiqueexperiencepro>(this.APIURL + 'experiencespro/' + exp.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteHistorique(histo: Historiqueexperiencepro): Observable<any> {
    const body = JSON.stringify(histo);
    return this.http.delete<Historiqueexperiencepro>(this.APIURL + 'experiencespro/' + histo.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   * PROFILE
   */

  getProfile(): Observable<Profile[]> {
    return this.http.get<Profile[]>(this.APIURL + 'profile?limit=50&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
             if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
      })
    );
  }

  getProfileByLibelle(libelle: string): Observable<Profile> {
    return this.http.get<Profile>(this.APIURL + 'profile?libelle=' + libelle, {headers: this.allHeaders}).pipe(
      catchError((err) => {
             if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
      })
    );
  }

  postProfile(profile: Profile): Observable<any> {
    const body = JSON.stringify(profile);
    return this.http.post<Profile>(this.APIURL + 'profile', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateProfile(profile: Profile): Observable<any> {
    const body = JSON.stringify(profile);
    return this.http.patch<Profile>(this.APIURL + 'profile/' + profile.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteProfile(profile: Profile): Observable<any> {
    const body = JSON.stringify(profile);
    return this.http.delete<Profile>(this.APIURL + 'profile/' + profile.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /*
  UE
   */

  getUEs(): Observable<UE[]> {
    return this.http.get<UE[]>(this.APIURL + 'ue?limit=100000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postUE(ue: UE): Observable<any> {
    const body = JSON.stringify(ue);
    return this.http.post<UE>(this.APIURL + 'ue', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateUE(ue: UE): Observable<any> {
    const body = JSON.stringify(ue);
    return this.http.patch<UE>(this.APIURL + 'ue/' + ue.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  deleteUE(ue: UE): Observable<any> {
    const body = JSON.stringify(ue);
    return this.http.delete<UE>(this.APIURL + 'ue/' + ue.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  /**
   *
   * ARTICLE
   */
  getArticle(): Observable<Article[]> {
    return this.http.get<Article[]>(this.APIURL + 'article?limit=100000&sort=date%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  findArticle(formData: FormData): Observable<any> {
    return this.http.post<any>(this.APIURL + 'findArticle', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getLastFourArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(this.APIURL + 'article?limit=4', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postArticle(formData: FormData): Observable<any> {
    return this.http.post<Article>(this.APIURL + 'article', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postArticleFromBack(formData: FormData): Observable<any> {
    return this.http.post<Article>(this.APIURL + 'articleFromBack', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postArticleFromEnseignant(formData: FormData): Observable<any> {
    return this.http.post<Article>(this.APIURL + 'articleEnseignant', formData, this.httpOptions).pipe(
      catchError((err) => {
          console.error('erreur', err);
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateArticleSimple(article: Article): Observable<any> {
    const body = JSON.stringify(article);
    return this.http.patch<Article>(this.APIURL + 'article/' +article.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateArticle(formData: FormData, article: Article): Observable<any> {
    const body = JSON.stringify(article);
    return this.http.patch<Article>(this.APIURL + 'modifierArticle/', formData, this.httpOptions).pipe(
      catchError((err) => {
          if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteArticle(article: Article): Observable<any> {
    return this.http.delete<Article>(this.APIURL + 'article/' + article.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   * AUDIENCE
   */

   getAudienceOf(article: Article): Observable<Audience[]> {
    return this.http.get<Audience[]>(this.APIURL + 'audience?article=' + article.id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getAudience(): Observable<Audience[]> {
    return this.http.get<Audience[]>(this.APIURL + 'audience', {headers: this.allHeaders}).pipe(
      catchError((err) => {
          console.error('erreur', err);
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postAudience(audience: Audience): Observable<any> {
    const body = JSON.stringify(audience);
    return this.http.post<Audience>(this.APIURL + 'audience', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateAudience(audience: Audience): Observable<any> {
    const body = JSON.stringify(audience);
    return this.http.patch<Audience>(this.APIURL + 'audience/' + audience.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteAudience(audience: Audience): Observable<any> {
    const body = JSON.stringify(audience);
    return this.http.delete<Audience>(this.APIURL + 'audience/' + audience.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   * CLUB
   */

  getClub(): Observable<Club[]> {
    return this.http.get<Club[]>(this.APIURL + 'club?limit=100000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
          if (err.status === 401) {
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getClubById(id: string): Observable<Club> {
    return this.http.get<Club>(this.APIURL + 'club/' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  findOneClub(id: number): Observable<Club> {
    return this.http.get<Club>(this.APIURL + 'club?id=' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
             if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
      })
    );
  }

  postClub(formData: FormData): Observable<any> {
    return this.http.post<Club>(this.APIURL + 'club', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateClub(club: Club): Observable<any> {
    const body = JSON.stringify(club);
    return this.http.patch<Club>(this.APIURL + 'club/' + club.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteClub(club: Club): Observable<any> {
    const body = JSON.stringify(club);
    return this.http.delete<Club>(this.APIURL + 'club/' + club.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   * COORDONNEES
   */
  getCoordonnees(): Observable<Coordonnees[]> {
    return this.http.get<Coordonnees[]>(this.APIURL + 'coordonnees', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postCoordonnees(coordonnees: Coordonnees): Observable<any> {
    const body = JSON.stringify(coordonnees);
    return this.http.post<Coordonnees>(this.APIURL + 'coordonnees', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateCoordonnees(coordonnees: Coordonnees): Observable<any> {
    const body = JSON.stringify(coordonnees);
    return this.http.patch<Coordonnees>(this.APIURL + 'coordonnees/' + coordonnees.id, body, this.httpOptions).pipe(
      catchError((err) => {

               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteCoordonnees(coordonnees: Coordonnees): Observable<any> {
    const body = JSON.stringify(coordonnees);
    return this.http.delete<Coordonnees>(this.APIURL + 'coordonnees/' + coordonnees.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   * COUNTER
   */
  getCounter(): Observable<Counter[]> {
    return this.http.get<Counter[]>(this.APIURL + 'counter', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postCounter(counter: Counter): Observable<any> {
    const body = JSON.stringify(counter);
    return this.http.post<Counter>(this.APIURL + 'counter', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateCounter(counter: Counter): Observable<any> {
    const body = JSON.stringify(counter);
    return this.http.patch<Counter>(this.APIURL + 'counter/' + counter.id, body, this.httpOptions).pipe(
      catchError((err) => {

               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteCounter(counter: Counter): Observable<any> {
    const body = JSON.stringify(counter);
    return this.http.delete<Counter>(this.APIURL + 'counter/' + counter.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   * Formation
   */

  getFormation(): Observable<Formation[]> {
    return this.http.get<Formation[]>(this.APIURL + 'formation?limit=10&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postFormation(formData: FormData): Observable<any> {
    return this.http.post<Formation>(this.APIURL + 'formation', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateFormation(formation: Formation): Observable<any> {
    const body = JSON.stringify(formation);
    return this.http.patch<Formation>(this.APIURL + 'formation/' + formation.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  deleteFormation(formation: Formation): Observable<any> {
    const body = JSON.stringify(formation);
    return this.http.delete<Formation>(this.APIURL + 'formation/' + formation.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getModerateur(): Observable<Utilisateur[]> {
    return this.http.get<Utilisateur[]>(this.APIURL + 'moderateur', {headers: this.allHeaders}).pipe(
      catchError((err) => {
             if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
      })
    );
  }

  postModerateurClub(idclub: number, idUtilisateur: number){
    const body = {club: idclub, utilisateur : idUtilisateur};
    // @ts-ignore
    return this.http.post<FormData>(this.APIURL + 'moderateurClub/' , body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postModerateurDelegue(idUtilisateur: number){
    const body = {utilisateur: idUtilisateur};
    // @ts-ignore
    return this.http.post<FormData>(this.APIURL + 'delegue/' , body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteModerateur(mod: Utilisateur): Observable<any> {
    const body = { utilisateur : mod.id };
    return this.http.patch<Utilisateur>(this.APIURL + 'moderateur/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteDelegue(mod: Utilisateur): Observable<any> {
    const body = { utilisateur : mod.id };
    return this.http.patch<Utilisateur>(this.APIURL + 'delegue/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postModerateur(moderateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(moderateur);
    // tslint:disable-next-line:max-line-length
    return this.http.post<Utilisateur>(this.APIURL + 'moderateur', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   * Localisation
   */
  getLocalisation(): Observable<Localisation[]> {
    return this.http.get<Localisation[]>(this.APIURL + 'localisation', this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postLocalisation(localisation: Localisation): Observable<any> {
    const body = JSON.stringify(localisation);
    return this.http.post<Localisation>(this.APIURL + 'localisation', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateLocalisation(localisation: Localisation): Observable<any> {
    const body = JSON.stringify(localisation);
    return this.http.patch<Localisation>(this.APIURL + 'localisation/' + localisation.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteLocalisation(localisation: Localisation): Observable<any> {
    const body = JSON.stringify(localisation);
    return this.http.delete<Localisation>(this.APIURL + 'localisation/' + localisation.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Niveau
   */
  getNiveau(): Observable<Niveau[]> {
    return this.http.get<Niveau[]>(this.APIURL + 'niveau?limit=10&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postNiveau(niveau: Niveau): Observable<any> {
    const body = JSON.stringify(niveau);
    return this.http.post<Niveau>(this.APIURL + 'niveau', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateNiveau(niveau: Niveau): Observable<any> {
    const body = JSON.stringify(niveau);
    return this.http.patch<Niveau>(this.APIURL + 'niveau/' + niveau.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteNiveau(niveau: Niveau): Observable<any> {
    const body = JSON.stringify(niveau);
    return this.http.delete<Niveau>(this.APIURL + 'niveau/' + niveau.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Notification
   *
   getNotification(): Observable<Notification[]> {
    return this.http.get<Notification[]>(this.APIURL + 'notification', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

   postNotification(notification: Notification): Observable<any> {
    const body = JSON.stringify(notification);
    return this.http.post<Notification>(this.APIURL + 'notification', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

   updateNotification(notification: Notification): Observable<any> {
    const body = JSON.stringify(notification);
    return this.http.patch<Notification>(this.APIURL + 'notification/' + notification.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

   deleteNotification(notification: Notification): Observable<any> {
    const body = JSON.stringify(notification);
    return this.http.delete<Notification>(this.APIURL + 'notification/' + notification.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
   */


  /**
   *
   *Parcours
   */
  getParcours(): Observable<Parcours[]> {
    return this.http.get<Parcours[]>(this.APIURL + 'parcours?limit=10&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postParcours(parcours: Parcours): Observable<any> {
    const body = JSON.stringify(parcours);
    return this.http.post<Parcours>(this.APIURL + 'parcours', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateParcours(parcours: Parcours): Observable<any> {
    const body = JSON.stringify(parcours);
    return this.http.patch<Parcours>(this.APIURL + 'parcours/' + parcours.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteParcours(parcours: Parcours): Observable<any> {
    const body = JSON.stringify(parcours);
    return this.http.delete<Parcours>(this.APIURL + 'parcours/' + parcours.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *vieEtudiant
   */
  getPhoto(): Observable<VieEtudiant[]> {
    return this.http.get<VieEtudiant[]>(this.APIURL + 'vieEtudiant?limit=100000&sort=id%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postPhoto(formData: FormData): Observable<any> {
    return this.http.post<VieEtudiant>(this.APIURL + 'vieEtudiant', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deletePhoto(vieetudiant: VieEtudiant): Observable<any> {
    const body = JSON.stringify(vieetudiant);
    return this.http.delete<VieEtudiant>(this.APIURL + 'vieEtudiant/' + vieetudiant.id, this.httpOptions).pipe(
      catchError((err) => {
          console.error('erreur', err);
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Partenaires
   */
  getPartenaires(): Observable<Partenaire[]> {
    return this.http.get<Partenaire[]>(this.APIURL + 'partenaire?limit=1000&sort=id%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postPartenaires(formData: FormData): Observable<any> {
    return this.http.post<Partenaire>(this.APIURL + 'partenaire', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deletePartenaires(partenaire: Partenaire): Observable<any> {
    const body = JSON.stringify(partenaire);
    return this.http.delete<Partenaire>(this.APIURL + 'partenaire/' + partenaire.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Schedule
   */
  getSchedule(): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(this.APIURL + 'schedule?limit=100000&sort=date%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  mergeSchedule(scheduleMerge: {niveau: any; parcours: any; data: Schedule[]}): Observable<any> {
    const body = JSON.stringify(scheduleMerge);
    return this.http.post<Schedule>(this.APIURL + 'schedule/merge', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postSchedule(schedule: Schedule): Observable<any> {
    const body = JSON.stringify(schedule);
    return this.http.post<Schedule>(this.APIURL + 'schedule', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateSchedule(schedule: Schedule): Observable<any> {
    const body = JSON.stringify(schedule);
    return this.http.patch<Schedule>(this.APIURL + 'schedule/' + schedule.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteSchedule(schedule: Schedule): Observable<any> {
    const body = JSON.stringify(schedule);
    return this.http.delete<Schedule>(this.APIURL + 'schedule/' + schedule.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }



  /**
   *
   *Technologie
   */
  getOneTechno(id: number): Observable<Techno> {
    return this.http.get<Techno>(this.APIURL + 'techno/' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getOneTypeTechno(id: number): Observable<Techno> {
    return this.http.get<Techno>(this.APIURL + 'techno/' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getTechno(): Observable<Techno[]> {
    return this.http.get<Techno[]>(this.APIURL + 'techno?limit=1000000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

// post  Technologie
  postTechno(techno: Techno): Observable<any> {
    const body = JSON.stringify(techno);
    return this.http.post<Techno>(this.APIURL + 'techno', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // update  Technologie
  updateTechno(techno: Techno): Observable<any> {
    const body = JSON.stringify(techno);
    return this.http.patch<Techno>(this.APIURL + 'techno/' + techno.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // delete  Technologie
  deleteTechno(techno: Techno): Observable<any> {
    const body = JSON.stringify(techno);
    return this.http.delete<Techno>(this.APIURL + 'techno/' + techno.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Type article
   */
  // Get liste des Type article
  getTypeArticle(): Observable<Typearticle[]> {
    return this.http.get<Typearticle[]>(this.APIURL + 'typearticle/?limit=100&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Get liste des Type article
  getTypeArticleByLibelle(libelle: string): Observable<Typearticle> {
    return this.http.get<Typearticle>(this.APIURL + 'typearticle?libelle=' + libelle, {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getTypeOfArticle(id: number): Observable<Typearticle> {
    return this.http.get<Typearticle>( this.APIURL + '.findAll().where({id:' + id + ').populate(article)', {headers: this.allHeaders}).pipe(
      catchError( (err) => {
             if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
      })
    );
  }

// post  Type
  postTypeArticle(type: Typearticle): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.post<Typearticle>(this.APIURL + 'typearticle/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // update  Type
  updateTypeArticle(type: Typearticle): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.patch<Typearticle>(this.APIURL + 'typearticle/' + type.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // delete  Type
  deleteTypeArticle(type: Typearticle): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.delete<Typearticle>(this.APIURL + 'typearticle/' + type.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Type opportunite
   */
  // Get liste des Type opportunite
  getTypeOpportunite(): Observable<Typeopportunite[]> {
    return this.http.get<Typeopportunite[]>(this.APIURL + 'typeopportunite?limit=100000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getTypeOpportunieByLibelle(libelle: string): Observable<Typeopportunite> {
    return this.http.get<Typeopportunite>(this.APIURL + 'typeopportunite?libelle=' + libelle, {headers: this.allHeaders}).pipe(
      catchError((err) => {
             if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
      })
    );
  }

// post  Type
  postTypeOpportunite(type: Typeopportunite): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.post<Typeopportunite>(this.APIURL + 'typeopportunite/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // update  Type
  updateTypeOpportunite(type: Typeopportunite): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.patch<Typeopportunite>(this.APIURL + 'typeopportunite/' + type.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // delete  Type
  deleteTypeOpportunite(type: Typeopportunite): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.delete<Typeopportunite>(this.APIURL + 'typeopportunite/' + type.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  getTypeExperience(): Observable<Typeexperience[]> {
    return this.http.get<Typeexperience[]>(this.APIURL + 'typeexperience?limit=100000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deletee(type: Typeopportunite): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.delete<Typeopportunite>(this.APIURL + 'typeopportunite/' + type.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Type techno
   */
  // Get liste des Type techno
  getTypeTechno(): Observable<Typetechno[]> {
    return this.http.get<Typetechno[]>(this.APIURL + 'typetechno?limit=100&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

// post  Type
  postTypeTechno(type: Typetechno): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.post<Typetechno>(this.APIURL + 'typetechno/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // update  Type
  updateTypeTechno(type: Typetechno): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.patch<Typetechno>(this.APIURL + 'typetechno/' + type.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // delete  Type
  deleteTypeTechno(type: Typetechno): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.delete<Typetechno>(this.APIURL + 'typetechno/' + type.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /**
   *
   *Type ue
   */
  // Get liste des Type techno
  getTypeue(): Observable<Typeue[]> {
    return this.http.get<Typeue[]>(this.APIURL + 'typeue/?limit=100&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

// post  Type
  postTypeue(type: Typeue): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.post<Typeue>(this.APIURL + 'typeue/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // update  Type
  updateTypeue(type: Typeue): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.patch<Typeue>(this.APIURL + 'typeue/' + type.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // delete  Type
  deleteTypeue(type: Typeue): Observable<any> {
    const body = JSON.stringify(type);
    return this.http.delete<Typeue>(this.APIURL + 'typeue/' + type.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getAlbum(): Observable<Album[]> {
    return this.http.get<Album[]>(this.APIURL + 'album?limit=100000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postAlbum(album: Album): Observable<any> {
    const body = JSON.stringify(album);
    return this.http.post<Album>(this.APIURL + 'album/', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateAlbum(album: Album): Observable<any> {
    const body = JSON.stringify(album);
    return this.http.patch<Album>(this.APIURL + 'album/' + album.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // delete  Type
  deleteAlbum(album: Album): Observable<any> {
    const body = JSON.stringify(album);
    return this.http.delete<Album>(this.APIURL + 'album/' + album.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  /**
   *
   // tslint:disable-next-line:jsdoc-format
   *Opportunite
   */
  // Get liste des Opportunite
  getOpportunite(): Observable<Opportunite[]> {
    return this.http.get<Opportunite[]>(this.APIURL + 'opportunite?limit=100000&sort=id%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  download(url: string): Observable<Blob> {
    return this.http.get((this.APIURL + 'opportunite'), {
      responseType: 'blob'
    });
  }

// post  Opportunite
  postOpportunite(formData: FormData): Observable<any> {
    return this.http.post<Opportunite>(this.APIURL + 'opportunite', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // update  Opportunite
  updateOpportunite(opportunite: Opportunite): Observable<any> {
    const body = JSON.stringify(opportunite);
    return this.http.patch<Opportunite>(this.APIURL + 'opportunite/' + opportunite.id, body, this.httpOptions).pipe(
      catchError((err) => {
          console.error('erreur', err);
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // delete  Opportunite
  deleteOpportunite(opportunite: Opportunite): Observable<any> {
    const body = JSON.stringify(opportunite);
    return this.http.delete<Opportunite>(this.APIURL + 'opportunite/' + opportunite.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /*
  Assignation
   */

  getAssignations(): Observable<Assignation[]> {
    return this.http.get<Assignation[]>(this.APIURL + 'UeUserParcoursNiveau?limit=100000&sort=id%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postAssignation(assignation: Assignation): Observable<any> {
    const body = JSON.stringify(assignation);
    return this.http.post<Assignation>(this.APIURL + 'UeUserParcoursNiveau', body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateAssignation(assignation: Assignation): Observable<any> {
    const body = JSON.stringify(assignation);
    return this.http.patch<Assignation>(this.APIURL + 'UeUserParcoursNiveau/' + assignation.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  deleteAssignation(assignation: Assignation): Observable<any> {
    const body = JSON.stringify(assignation);
    return this.http.delete<Assignation>(this.APIURL + 'UeUserParcoursNiveau/' + assignation.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /*
  HISTORIQUE
   */

  getHistoriqueENI(): Observable<TimelineElement[]> {
    return this.http.get<TimelineElement[]>(this.APIURL + 'historique?sort=date%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postHistoriqueENI(formData: FormData): Observable<any> {
    return this.http.post<TimelineElement>(this.APIURL + 'historique', formData, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateHistoriqueENI(histo: TimelineElement): Observable<any> {
    const body = JSON.stringify(histo);
    return this.http.patch<TimelineElement>(this.APIURL + 'historique/' + histo.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  deleteHistoriqueENI(histo: TimelineElement): Observable<any> {
    const body = JSON.stringify(histo);
    return this.http.delete<TimelineElement>(this.APIURL + 'historique/' + histo.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

 /**
   *
   *Galerie
   */
  // Get liste des Galerie
  getGalerie(): Observable<Galerie[]> {
    return this.http.get<Galerie[]>(this.APIURL + 'galerie', {headers: this.allHeaders}).pipe(
      catchError((err) => {
          console.error('erreur', err);
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

// post  Galerie
  postGalerie(formData: FormData): Observable<any> {
    return this.http.post<Galerie>(this.APIURL + 'galerie', formData, this.httpOptions).pipe(
      catchError((err) => {
          console.error('erreur', err);
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }


  // delete  galerie
  deleteGalerie(galerie: Galerie): Observable<any> {
    const body = JSON.stringify(galerie);
    return this.http.delete<Galerie>(this.APIURL + 'galerie/' + galerie.id, this.httpOptions).pipe(
      catchError((err) => {
          console.error('erreur', err);
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  /*
  ORGANIGRAMME
   */

  getOrganigramme(): Observable<Organigramme[]> {
    return this.http.get<Organigramme[]>(this.APIURL + 'organigramme?limit=1000000&sort=id%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  postOrganigramme(organigramme: Organigramme): Observable<any> {
    return this.http.post<Organigramme>(this.APIURL + 'organigramme', organigramme, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getParent(id: number): Observable<any> {
    return this.http.get<Organigramme>(this.APIURL + 'organigramme/?id=' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  updateOrganigramme(organigramme: Organigramme): Observable<any> {
    const body = JSON.stringify(organigramme);
    return this.http.patch<Organigramme>(this.APIURL + 'organigramme/' + organigramme.id, body, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteOrganigramme(organigramme: Organigramme): Observable<any> {
    return this.http.delete<Organigramme>(this.APIURL + 'organigramme/' + organigramme.id, this.httpOptions).pipe(
      catchError((err) => {
               if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  log(data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post<any>(this.APIURL + 'login' , body, {headers: this.headers}).pipe(
      catchError((err) => {
        throw err;
        }
      ));
  }

  updateLog(data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post<any>(this.APIURL + 'updateLogin' , body, {headers: this.headers}).pipe(
      catchError((err) => {
        throw err;
        }
      ));
  }

}
