import { Component, OnInit } from '@angular/core';
import {Enseignant} from "../../../../classes/enseignant";
import {Typeue} from "../../../../classes/typeue";
import {UE} from "../../../../classes/ue";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ServiceAPIService} from "../../../../services/service-api.service";
import {Techno} from "../../../../classes/techno";
import {Typetechno} from "../../../../classes/typetechno";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-techno',
  templateUrl: './techno.component.html',
  styleUrls: ['./techno.component.scss']
})
export class TechnoComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  technoList: Array<Techno>;
  typeTechnoList: Array<Typetechno>;

  ajouter = true;
  modifier = false;

  updateForm: FormGroup;

  Enregistrer = 'Ajouter';

  submitted = false;

  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getTechnos();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
    this.getTypeTechno();
  }


  initForm(){
    this.updateForm = this.formBuilder.group({
      id: '',
      // tslint:disable-next-line:no-unused-expression
      libelle: ['', Validators.required],
      typetechno: ['', Validators.required]
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  getTypeTechno(){
    this.serviceAPI.getTypeTechno().subscribe( data => this.typeTechnoList = data );
  }

  async getTechnos(){
    this.technoList = await this.serviceAPI.getTechno().toPromise();
  }

  update() {
    this.submitted=true;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const value = this.updateForm.value;
        const techno = new Techno();
        techno.libelle = value.libelle;
        techno.typetechno = value.typetechno;
        this.serviceAPI.postTechno(techno).subscribe( () => {
          this.NotificationShowSucces();
          this.getTechnos();
          this.modifier = false;
          this.ajouter = true;
          this.updateForm.reset();
          this.submitted = false;
          window.location.reload();
        });
      }
    }
    if (this.modifier){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const value = this.updateForm.value;
        const techno = new Techno();
        techno.libelle = value.libelle;
        techno.typetechno = value.typetechno;
        techno.id = value.id;
        this.serviceAPI.updateTechno(techno).subscribe( () => {
          this.NotificationShowSucces();
          this.getTechnos();
          this.modifier = false;
          this.ajouter = true;
          this.updateForm.reset();
          this.submitted = false;
          window.location.reload();
        });
      }
    }
  }

  updating(techno: Techno) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.updateForm = this.formBuilder.group({
      id: techno.id,
      // tslint:disable-next-line:no-unused-expression
      libelle: techno.libelle,
      typetechno: techno.typetechno.id
    });
  }

  reset() {
    this.updateForm.reset();
    this.submitted = false;
    this.Enregistrer = 'Ajouter';
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
}
