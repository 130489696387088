<section>
  <header class="section-header wow fadeInUp">
    <h3>ACTUALITES
    </h3>
  </header>
  <div class="container">
    <div class="d-flex justify-content-center h-100 my-2">
      <form [formGroup]="form" class="form searchbar">
        <input class="search_input" type="text" formControlName="titre" placeholder="Cherchez un article par son titre">
        <a class="search_icon pointeur"><i class="fa fa-search" (click)="rechercher()"></i></a>
      </form>
    </div>
    <p *ngIf="notFound">Aucun article ne correspond à ce titre</p>
    <p class="text-turquoise pointeur" *ngIf="search" (click)="getArticles()"><i class="fa fa-arrow-left mr-3"></i>Retour</p>
    <div class="row">
      <div class="col-md-6 col-sm-12" *ngFor="let article of articlesList">
        <div class=" aos-init aos-animate" data-aos="fade-up">
          <div class="blog-card card-deck pointeur" data-toggle="modal" data-target="#detailarticle" (click)="showArticle(article)">
            <div class="meta">
              <div class="photo img-fluid pointeur"[ngStyle]="{'background-size' : 'cover','background-image': 'url('+ '/api'+ article.photo + ')'}" data-target="#detailarticle" (click)="showArticle(article)"></div>
            </div>
            <div class="description">
              <h1>{{article.date | date: ' d MMMM  y\''}}</h1>
              <h2 class="pointeur">{{article.titre}} {{article.id}}</h2>
              <p class="overflow" [class.show]="show" [innerHTML]="article.contenu"></p>
              <p class="read-more"><a>Détails</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div id="detailarticle" class="modal animate__animated animate  animate font-roboto" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" #articleModal aria-hidden="true" style="display: none;">
  <div  *ngIf="articleToShow">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row " style="height: 100vh">
            <div class=" col-md-6 mt-5">
              <div class="icones">
                <i class=" prev fa fa-chevron-circle-left mr-3 text-turquoise pointeur" (click)="prev(articleToShow)"
                   [ngClass]="{'disabled': prevLinkActive === true}"
                ></i>
                <i class=" next fa fa-chevron-circle-right text-turquoise pointeur" (click)="next(articleToShow)"
                   [ngClass]="{'disabled': nextLinkActive === true}"
                ></i>
              </div>

              <div class="centerImg">
                <figure class="snip1206">
                  <img src="/api/{{articleToShow.photo}}" alt="{{articleToShow.libelle}}"
                       class="img-fluid imgShadow">
                  <figcaption>
                    <p>{{articleToShow.libelle}}</p>
                  </figcaption>
                </figure>

              </div>
              <div>
              </div>

            </div>

            <div class="col-md-6 ">
              <a data-dismiss="modal" class="close-button pointeur float-right mt-2" (click)="close()">
                <div class="in">
                  <div class="close-button-block"></div>
                  <div class="close-button-block"></div>
                </div>
                <div class="out">
                  <div class="close-button-block"></div>
                  <div class="close-button-block"></div>
                </div>
              </a>
              <div class="main-container">

                <header class="section-header wow fadeInUp mt-5 ">

                  <h3>{{articleToShow.titre}}</h3>

                </header>
                <div class="  height">
                  <div class="mt-5">
                    <!-- Default breadcrumb-->

                    <h6>{{articleToShow.libelle}} </h6>
                    <p [innerHTML]="articleToShow.contenu" class="italic indent"></p>
                    <p class="text-muted float-right">Ecrit par
                      {{articleToShow.nom}} {{articleToShow.prenom}} <br>
                      le {{articleToShow.date | date: ' d MMMM y\''}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
