export class Coordonnees {
  id: number;
  telephone: string;
  mail: string;
  boite_postale: string;
  adresse: string;
  longitude: string;
  latitude: string;

}
