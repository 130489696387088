import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Parcours } from 'src/app/classes/parcours';
import { Utilisateur } from 'src/app/classes/utilisateur';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import {Article} from '../../../../classes/article';
import {Profile} from '../../../../classes/profile';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-ancien',
  templateUrl: './ancien.component.html',
  styleUrls: ['./ancien.component.scss']
})
export class AncienComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  ancienList: Array<Utilisateur> = [];
  parcoursList: Array<Parcours> = [];
  ancienToDelete: Utilisateur;

  ajouter = true;
  modifier = false;

  updateForm: FormGroup;

  Enregistrer = 'Ajouter';

  image = 'url(assets/img/utilisateur.png)';
  APIURL = this.serviceAPI.APIURL;

  submitted = false;

  profile: Profile;

  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getAnciens();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
    await this.getProfile();
    this.getParcours();
  }

  async getProfile() {
    let p = await this.serviceAPI.getProfileByLibelle('Ancien').toPromise();
    this.profile = p[0];
  }

  async getAnciens(){
    let anciens: Array<Utilisateur> = [];
    let data = await this.serviceAPI.getUtilisateur().toPromise();
    data.forEach( ancien => {
      ancien.profiles.forEach(profile => {
        if (profile.libelle === 'Ancien') {
          anciens.push(ancien);
        }
      });
    });
    this.ancienList = anciens;
  }

  initForm(){
    this.updateForm = this.formBuilder.group({
      id: [''],
      numero_matricule: ['', Validators.required],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      adresse_mail: new FormControl('', Validators.required),
      parcours: new FormControl('', Validators.required),
      photo: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  getParcours() {
    this.serviceAPI.getParcours().subscribe( data => {
      this.parcoursList = data;
    });
  }

  update() {
    this.submitted = true;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formData = new FormData();
        formData.append('numero_matricule', this.updateForm.value.numero_matricule);
        formData.append('nom', this.updateForm.value.nom);
        formData.append('prenom', this.updateForm.value.prenom);
        formData.append('adresse_mail', this.updateForm.value.adresse_mail);
        formData.append('mot_de_passe', this.updateForm.value.numero_matricule);
        formData.append('parcours', this.updateForm.value.parcours);
        formData.append('profiles', (this.profile.id).toString() );
        formData.append('biographie', 'Biographie');
        formData.append('photo', this.updateForm.value.photo);
        this.image = '';
        this.serviceAPI.postUtilisateur(formData).subscribe( data => {
          if (data.mailExist === true) {
            this.NotificationShowMailExist();
          }
          if (data.numMatriculeExist === true) {
            this.NotificationShowMatriculeExist();
          } else {
            this.NotificationShowSucces();
            this.updateForm.reset();
            this.updateForm.get('photo').setValue('');
            this.submitted = false;
            window.location.reload();
          }
        });
      }
    }
    if (this.modifier){
      this.submitted = true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const utilisateur = new Utilisateur();
        utilisateur.id = this.updateForm.value.id;
        utilisateur.nom = this.updateForm.value.nom;
        utilisateur.prenom = this.updateForm.value.prenom;
        utilisateur.adresse_mail = this.updateForm.value.adresse_mail;
          utilisateur.parcours = this.updateForm.value.parcours;
          this.serviceAPI.chercherMail(this.updateForm.value.adresse_mail).subscribe( data => {
            if (data.length === 0 || data[0].id === utilisateur.id) {
              this.serviceAPI.updateUtilisateur(utilisateur).subscribe( data => {
                this.NotificationShowSucces();
                this.updateForm.reset();
                this.ajouter = true;
                this.modifier = false;
                this.submitted = false;
                window.location.reload();
              } );
            }
            else {
              this.NotificationShowMailExist();
            }
          });
      }
    }
  }

  updating(ancien: Utilisateur) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.updateForm = this.formBuilder.group({
      id: [ancien.id, Validators.required],
      numero_matricule: [ancien.numero_matricule, Validators.required],
      nom: [ancien.nom, Validators.required],
      prenom: [ancien.prenom, Validators.required],
      adresse_mail : [ancien.adresse_mail, Validators.required],
      parcours : [ancien.parcours.id, Validators.required],
      photo: ['']
    });
    this.image = 'url(' + this.APIURL + ancien.photo + ')';
    this.Enregistrer = 'Modifier';
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm.get('photo').setValue(file);
    }
  }

  loadDeleteConfirm(ancien: Utilisateur) {
    this.ancienToDelete = ancien;
  }

  delete(){
    this.serviceAPI.deleteUtilisateur(this.ancienToDelete).subscribe( () => {
      this.getAnciens();
      this.NotificationShowSucces();
      window.location.reload();
    });
  }

  reset(){
    this.image = 'url(assets/img/utilisateur.png)';
    this.updateForm.reset();
    this.submitted = false;
    this.Enregistrer = 'Ajouter';
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowMailExist(){
    this.notifier.show({
      type: 'warning',
      message: 'L\'adresse mail existe déjà',
    });
  }

  NotificationShowMatriculeExist(){
    this.notifier.show({
      type: 'warning',
      message: 'Le numero matricule existe déjà',
    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
}
