import { Component, OnInit } from '@angular/core';
import {TimelineElement} from "../../../../classes/timeline-element";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ServiceAPIService} from "../../../../services/service-api.service";
import {Formation} from "../../../../classes/formation";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-formation-accueil',
  templateUrl: './formation-accueil.component.html',
  styleUrls: ['./formation-accueil.component.scss']
})
export class FormationAccueilComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  FormationList: Array<Formation>;
  FormationToDelete: Formation;

  updateForm: FormGroup;

  ajouter = true;
  modifier = false;

  image = 'url(assets/img/fond.jpg)';
  APIURL = this.serviceAPI.APIURL;

  Enregistrer = 'Enregistrer';

  submitted = false;
  mycontent: string;
  ckeConfig: any;
  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms']},
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About'
    };

  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getFormation();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };

  }

  async getFormation() {
    this.FormationList = await this.serviceAPI.getFormation().toPromise();
  }

  initForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      sigle: ['', Validators.required],
      libelle: ['', Validators.required],
      explication: ['', Validators.required],
      admission: ['', Validators.required],
      resume: ['', Validators.required],
      carriere_niveau: ['', Validators.required],
      photo: ['', Validators.required]
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  update() {
    this.submitted = true;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formData = new FormData();
        formData.append('sigle', this.updateForm.value.sigle);
        formData.append('libelle', this.updateForm.value.libelle);
        formData.append('explication', this.updateForm.value.explication);
        formData.append('admission', this.updateForm.value.admission);
        formData.append('resume', this.updateForm.value.resume);
        formData.append('carriere_niveau', this.updateForm.value.carriere_niveau);
        formData.append('photo', this.updateForm.value.photo);
        this.image = '';
        this.serviceAPI.postFormation(formData).subscribe( data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.updateForm.get('photo').setValue('');
          this.submitted = false;
          window.location.reload();
        });
      }
    }
    if (this.modifier){
      this.submitted = true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formation = new Formation();
        formation.id = this.updateForm.value.id;
        formation.sigle = this.updateForm.value.sigle;
        formation.libelle = this.updateForm.value.libelle;
        formation.explication = this.updateForm.value.explication;
        formation.admission = this.updateForm.value.admission;
        formation.resume = this.updateForm.value.resume;
        formation.carriere_niveau = this.updateForm.value.carriere_niveau;
        this.serviceAPI.updateFormation(formation).subscribe( data => {
          this.NotificationShowSucces();
          this.updateForm.reset();
          this.ajouter = true;
          this.modifier = false;
          this.submitted = false;
          window.location.reload();
        });
      }
    }
  }

  delete() {
    this.serviceAPI.deleteFormation(this.FormationToDelete).subscribe( () => {
      this.getFormation();
      this.NotificationShowSucces();
    });
  }

  updating(formation: Formation) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.updateForm = this.formBuilder.group({
      id: [formation.id],
      sigle: [formation.sigle, Validators.required],
      libelle: [formation.libelle, Validators.required],
      explication: [formation.explication, Validators.required],
      admission: [formation.admission, Validators.required],
      resume: [formation.resume, Validators.required],
      carriere_niveau: [formation.carriere_niveau, Validators.required],
    });
    this.image = 'url(' + this.APIURL + formation.photo + ')';
    this.Enregistrer = 'Modifier';
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm.get('photo').setValue(file);
    }
  }

  loadDeleteConfirm(formation: Formation) {
    this.FormationToDelete = formation;
  }

  reset() {
    this.submitted=false;
    this.updateForm.reset();
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
}
