<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 class="card-title" *ngIf="ajouter">Ajouter une Historique</h5>
      <h5 class="card-title" *ngIf="modifier">Modifier l' historique</h5>
    </div>
    <div class="card-body">
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-10 mx-auto py-4 mb-5" style="min-height: 15vh">
        <div id="image" [ngStyle]="{'max-width': '100%' , 'height': '100%', 'top': '0px', 'background-size': 'cover', 'background-position': 'center', 'background-image': image }"></div>
        <div class="mb-3" *ngIf="ajouter">
          <label for="photo">Photo</label>
          <input type="file" id="photo" (change)="onFileSelect($event)" style="width: 100%">
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-sm-10 mx-auto">
        <div class="card-body">
          <form [formGroup]="updateForm" (ngSubmit)="update()">
            <input type="input" class="form-control" id="id" formControlName="id" hidden>
            <div class="mb-3 row">
              <label for="legende" class="col-lg-2 col-md-10 col-form-label">Légende </label>
              <div class="col-sm-10">
                <input type="input" class="form-control" id="legende" formControlName="legende"
                       [ngClass]="{ 'is-invalid': submitted && f.legende.errors }">
                <div *ngIf="submitted && f.legende.errors" class="invalid-feedback">
                  <div *ngIf="f.legende.errors.required">Veuillez renseigner la légende</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="date" class="col-lg-2 col-md-10 col-form-label">Date</label>
              <div class="col-sm-10">
                <input type="date" class="form-control" id="date" formControlName="date"
                       [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
                <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                  <div *ngIf="f.date.errors.required">Veuillez renseigner la date</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="titre" class="col-lg-2 col-md-10 col-form-label">Titre</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="titre" formControlName="titre"
                       [ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
                <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
                  <div *ngIf="f.titre.errors.required">Veuillez renseigner le titre</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="contenu" class="col-lg-2 col-md-10 col-form-label">Contenu</label>
              <div class="col-sm-10">
                <ckeditor [(ngModel)]="mycontent" [config]="ckeConfig" id="contenu" formControlName="contenu"
                          [ngClass]="{ 'is-invalid': submitted && f.contenu.errors }"></ckeditor>
                <div *ngIf="submitted && f.contenu.errors" class="invalid-feedback">
                  <div *ngIf="f.contenu.errors.required">Veuillez renseigner le contenu</div>
                </div>
              </div>
            </div>
            <button class="btn bg-forth text-clear mr-3 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}
            </button>
            <button class="btn btn-secondary mb-3" type="reset" (click)="reset"><i class="fa fa-save mr-2"></i>Annuler
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>


  <div class="container mt-5">
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive">
        <table id="example" class="table table-striped" datatable [dtOptions]="dtOptions" style="width:100%"
               *ngIf="historiqueList">
          <thead>
          <tr>
            <th>Date</th>
            <th>Titre</th>
            <th>Contenu</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let histo of historiqueList">
            <td>{{ histo.date }}</td>
            <td>{{ histo.titre}} </td>
            <td [innerHTML]=" histo.contenu"></td>
            <td>
              <button class="btn btn-primary mr-2" href="#" (click)="updating(histo)"><i class="fa fa-edit"></i>
              </button>
              <button class="btn btn-danger" data-toggle="modal" data-target="#delete"
                      (click)="loadDeleteConfirm(histo)"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <!-- Modal -->
  <div class="modal fade" id="delete" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteLabel">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Voulez vous vraiment supprimer<span *ngIf="histoToDelete"> {{histoToDelete.titre}}</span> ?
          Cette action est irreverssible.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
