<div class="container">
  <h5 class="card-title">Niveau</h5>
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table class="table table-striped" style="width:100%"  datatable [dtOptions]="dtOptions" *ngIf="niveauList">
        <thead>
        <tr>
          <th>Nom du niveau</th>
          <th>A propos</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let niveau of niveauList">
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p>{{ niveau.libelle }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p>{{ niveau.explication }}</p>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
