import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-espace',
  templateUrl: './footer-espace.component.html',
  styleUrls: ['./footer-espace.component.scss']
})
export class FooterEspaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
