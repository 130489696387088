<div class="container">
  <h5 class="card-title">Parcours</h5>
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table class="table table-striped" style="width:100%"  datatable [dtOptions]="dtOptions" *ngIf="parcoursList">
        <thead>
        <tr>
          <th>Nom du parcours</th>
          <th>A propos</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let parcours of parcoursList">
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p>{{ parcours.libelle }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p>{{ parcours.explication }}</p>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
