/* tslint:disable */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteComponent } from './site/site.component';
import {EspaceComponent} from './espace/espace.component';
import {AccueilEtudiantComponent} from './pages/accueil-etudiant/accueil-etudiant.component';
import {WysywigComponent} from './pages/wysywig/wysywig.component';
import {GalerieEtudiantComponent} from './pages/galerie-etudiant/galerie-etudiant.component';
import {AccueilOpportuniteComponent} from './pages/accueil-opportunite/accueil-opportunite.component';
import {ModifierProfilComponent} from './components/espace-etudiant/modifier-profil/modifier-profil.component';
import {AdminComponent} from './pages/admin/views/admin/admin.component';
import {ArticleComponent} from './pages/admin/views/article/article.component';
import {FormationComponent} from './pages/scolarite/views/formation/formation.component';
import {ClubComponent} from './pages/admin/views/club/club.component';
import {NiveauComponent} from './pages/scolarite/views/niveau/niveau.component';
import {CoordonneesComponent} from './pages/admin/views/coordonnees/coordonnees.component';
import {ParcoursComponent} from './pages/scolarite/views/parcours/parcours.component';
import {DashboardComponent} from './pages/admin/views/dashboard/dashboard.component';
import {EnseignantComponent} from './pages/scolarite/views/enseignant/enseignant.component';
import {ModerateueComponent} from './pages/admin/views/moderateue/moderateue.component';
import {LoginComponent} from './pages/login/login.component';
import {AuthGuardEtudiantService} from './services/auth-guard-etudiant.service';
import {DasboardScolariteComponent} from './pages/scolarite/views/dasboard-scolarite/dasboard-scolarite.component';
import {UeComponent} from './pages/scolarite/views/ue/ue.component';
import {ScolariteComponent} from './pages/scolarite/scolarite.component';
import {TypeueComponent} from './pages/scolarite/views/typeue/typeue.component';
import {TypearticleComponent} from './pages/admin/views/typearticle/typearticle.component';
import {AuthGuardService} from './services/auth-guard.service';
import { DisponibiliteComponent } from './components/espace-etudiant/disponibilite/disponibilite.component';
import {AuthGuardScolariteService} from './services/auth-guard-scolarite.service';
import { PhotosComponent } from './pages/admin/views/photos/photos.component';
import {TechnoComponent} from './pages/admin/views/techno/techno.component';
import {TypetechnoComponent} from './pages/admin/views/typetechno/typetechno.component';
import {BlogComponent} from './pages/blog/blog.component';
import {AssignationComponent} from './pages/scolarite/views/assignation/assignation.component';
import {EtudiantComponent} from './pages/scolarite/views/etudiant/etudiant.component';
import { AboutUsComponent } from './components/accueil/about-us/about-us.component';
import { HorizontalTimelineComponent } from './pages/horizontal-timeline/horizontal-timeline.component';
import { HistoriqueEniComponent } from './pages/admin/views/historique-eni/historique-eni.component';
import { AncienComponent } from './pages/admin/views/ancien/ancien.component';
import { HeaderPageAccueilComponent } from './pages/admin/views/header-page-accueil/header-page-accueil.component';
import { IgComponent } from './pages/scolarite/views/ig/ig.component';
import { SrComponent } from './pages/scolarite/views/sr/sr.component';
import { GbComponent } from './pages/scolarite/views/gb/gb.component';
import {AccueilEnseignantComponent} from './pages/accueil-enseignant/accueil-enseignant.component';
import {AuthGuardEnseignantService} from './services/auth-guard-enseignant.service';
import {ProfsComponent} from './pages/profs/profs.component';
import { CounterAdminComponent } from './pages/admin/views/counter-admin/counter-admin.component';
import {OpportunitesComponent} from './pages/admin/views/opportunites/opportunites.component';
import {ClubsComponent} from "./components/espace-etudiant/clubs/clubs.component";
import {PartenaireComponent} from "./pages/admin/views/partenaire/partenaire.component";
import {ActusEtudiantComponent} from './pages/actus-etudiant/actus-etudiant.component';
import {FormationAccueilComponent} from "./pages/admin/views/formation-accueil/formation-accueil.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {ForbiddenComponent} from "./pages/forbidden/forbidden.component";
import {SomethingWrongComponent} from './pages/something-wrong/something-wrong.component';
import { UtilisateurComponent } from './pages/admin/views/utilisateur/utilisateur.component';
import {OrganigrammeComponent} from "./pages/admin/views/organigramme/organigramme.component";
import {AuthGuardAncienService} from "./services/auth-guard-ancien.service";
import {EspaceAncienComponent} from "./components/espace-ancien/espace-ancien.component";

const routes: Routes = [
  {path: '' ,  component: SiteComponent },
  {path: '403', component: ForbiddenComponent},
  {path: '401', component: SomethingWrongComponent},
  {
    path: 'espace-etudiant', canActivate: [AuthGuardEtudiantService],
    component: EspaceComponent,
    children: [
      { path: '', component: AccueilEtudiantComponent },
      { path: 'blog' , component: BlogComponent },
      { path: 'nouveau', component: WysywigComponent },
      { path: 'galeries', component: GalerieEtudiantComponent },
      { path: 'clubs/blog' , component: BlogComponent },
      { path: 'opportunites', component: AccueilOpportuniteComponent },
      { path: 'profil', component: ModifierProfilComponent },
      { path: 'disponibilites/profil', component: ModifierProfilComponent },
      { path: 'disponibilites', component: DisponibiliteComponent },
      { path: 'clubs', component: ClubsComponent },
      { path: 'actualites', component: ActusEtudiantComponent },
    ]
  },
  {
    path: 'espace-ancien', canActivate: [AuthGuardAncienService],
    component: EspaceAncienComponent,
    children: [
      { path: '', component: ActusEtudiantComponent },
      { path: 'blog' , component: BlogComponent },
      { path: 'nouveau', component: WysywigComponent },
      { path: 'galeries', component: GalerieEtudiantComponent },
      { path: 'clubs/blog' , component: BlogComponent },
      { path: 'opportunites', component: AccueilOpportuniteComponent },
      { path: 'profil', component: ModifierProfilComponent },
      { path: 'disponibilites/profil', component: ModifierProfilComponent },
      { path: 'disponibilites', component: DisponibiliteComponent },
      { path: 'clubs', component: ClubsComponent },
      { path: 'actualites', component: ActusEtudiantComponent }
    ]
  },
  {
    path: 'espace-enseignant', canActivate: [AuthGuardEnseignantService],
    component: ProfsComponent,
    children: [
      { path: '' , component: AccueilEnseignantComponent },
      { path: 'galeries', component: GalerieEtudiantComponent },
      { path: 'opportunites', component: AccueilOpportuniteComponent },
      { path: 'disponibilites', component: DisponibiliteComponent },
      { path: 'actualites', component: ActusEtudiantComponent },
      { path: 'clubs/blog' , component: BlogComponent },
      { path: 'opportunites', component: AccueilOpportuniteComponent },
      { path: 'profil', component: ModifierProfilComponent },
      { path: 'disponibilites/profil', component: ModifierProfilComponent },
      { path: 'clubs', component: ClubsComponent },
      { path: 'actualites', component: ActusEtudiantComponent }
    ]
  },
  {
    path: 'toor', canActivate: [AuthGuardService],
    component: AdminComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path : 'article', component: ArticleComponent },
      { path: 'club' , component: ClubComponent },
      { path: 'formation' , component: FormationComponent },
      { path: 'parcours' , component: ParcoursComponent },
      { path: 'niveau' , component: NiveauComponent },
      { path: 'coordonnees' , component: CoordonneesComponent },
      { path: 'dashboard' , component: DashboardComponent },
      { path: 'enseignant' , component: EnseignantComponent },
      { path: 'moderateur', component: ModerateueComponent },
      { path: 'typearticle', component: TypearticleComponent},
      { path: 'photos', component: PhotosComponent },
      { path: 'techno' , component: TechnoComponent },
      { path: 'typetechno' , component: TypetechnoComponent },
      { path: 'historiqueENI', component: HistoriqueEniComponent },
      { path: 'ancien', component: AncienComponent },
      { path: 'partenaire', component: PartenaireComponent },
      { path: 'header', component: HeaderPageAccueilComponent },
      { path: 'counter', component: CounterAdminComponent },
      { path: 'opportunite', component: OpportunitesComponent },
      { path: 'formationAccueil', component: FormationAccueilComponent },
      { path: 'utilisateur', component: UtilisateurComponent },
      { path: 'organigramme', component: OrganigrammeComponent}
    ],
  },
  {
    path: 'scolarite', canActivate: [AuthGuardScolariteService],
    component: ScolariteComponent,
    children: [
      { path: '', component: DasboardScolariteComponent},
      { path: 'dashboard', component: DasboardScolariteComponent},
      { path: 'enseignant', component: EnseignantComponent},
      { path: 'parcours', component: ParcoursComponent},
      { path: 'formation', component: FormationComponent},
      { path: 'niveau', component: NiveauComponent},
      { path: 'ue', component: UeComponent},
      { path: 'typeue', component: TypeueComponent},
      { path: 'assignation' , component: AssignationComponent },
      { path: 'etudiant' , component: EtudiantComponent },
      { path: 'ig' , component: IgComponent },
      { path: 'sr' , component: SrComponent },
      { path: 'gb' , component: GbComponent },
    ]
  },
  {
    path: 'Login',
    children: [
      { path: '' , component: LoginComponent},
    ]
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
