import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utilisateur } from './../classes/utilisateur';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthEtudiantService} from './auth-etudiant.service';
import {AuthEnseignantService} from './auth-enseignant.service';
import {AuthService} from './auth-service.service';
import {ServiceAPIService} from './service-api.service';
import {ServiceEtudiantService} from './service-espace-etudiant/service-etudiant.service';

@Injectable({
  providedIn: 'root'
})
export class ConnexionApiService {
  private currentUserSubject: BehaviorSubject<Utilisateur>;
  // public currentUser: Observable<Utilisateur>;
  public currentUser: Utilisateur;

  APIURL = '/api/';

  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json',
    Authorization: '@3kpSs+7Q5n6t-RH5ai4M@=Qp83c89nAw8nVn<3tP9#Tmg23-Q?*t539/fn8MDqh<e9Cx$55R@65Rh2C3%p.q8Y8V&53h_Sxr'
  });

  constructor(private http: HttpClient,
                    private router: Router,
                    private authEtudiantService: AuthEtudiantService,
                    private authEnseignantService: AuthEnseignantService,
                    private authService: AuthService,
                    private serviceAPI: ServiceEtudiantService) {
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/Login']);
    }
  }

  postByEmail(utilisateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(utilisateur);
    return this.http.post<Utilisateur>(this.APIURL + 'login', body, {headers: this.headers}).pipe(
      catchError((err) => {
          console.error('erreur', err);
          throw err;
        }
      ));
  }

  postEtudiant(utilisateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(utilisateur);
    return this.http.post<any>(this.APIURL + 'loginEtudiant', body, {headers: this.headers}).pipe(
      catchError((err) => {
          console.error('erreur', err);
          throw err;
        }
      )
    );
  }

  logout() {
    // remove user from local storage to log user out
    window.localStorage.removeItem('currentUser');
  }

  async redirectToPage() {
    if (localStorage.getItem('currentUser')) {
      const user = await this.serviceAPI.getUtilisateurConnecte().toPromise();
      if (user) {
        user.profile.forEach(
          profile => {
            if (profile.libelle === 'Administrateur') {
              this.authService.signIn();
            } else if (profile.libelle === 'Scolarite') {
              this.router.navigate(['/scolarite/dashboard']);
            } else if (profile.libelle === 'Enseignant') {
              this.authEnseignantService.signIn();
            } else if (profile.libelle === 'Ancien') {
              this.router.navigate(['/espace-ancien']);
            } else if (profile.libelle === 'Etudiant') {
              this.router.navigate(['/espace-etudiant']);
            }
          });
      }
    } else {
      this.router.navigate(['/Login']);
    }
  }
}
