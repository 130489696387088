<section id="polaroid" class="desk font-roboto ">
  <div class=" aos-init aos-animate" data-aos="fade-up">
    <header class="section-header wow fadeInUp">
      <h3>POLAROID
      </h3>
    </header>
    <div class="photos ">
      <div class="drag" *ngFor="let gallerie of Photolist ; let i = index"  [attr.data-i]="i" #gallery_item>
        <figure class="spin">
          <img src="{{gallerie.photo}}" alt="{{gallerie.libelle}}" onerror="this.parentElement.parentElement.style.display = 'none';"/>
          <figcaption>{{gallerie.libelle}}</figcaption>
        </figure>
      </div>
    </div>
  </div>
</section>
