<section class="blog-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8" *ngIf="articles && clubDeLaPage">
        <h5>Historique : </h5>
        <p class="text-justify" [innerHTML]="clubDeLaPage.historique"></p>
        <h5>Missions : </h5>
        <p class="text-justify" [innerHTML]="clubDeLaPage.missions"></p>
        <div class="blog-single-item" *ngFor="let art of articles | paginate: { itemsPerPage: 10, currentPage: p }">
          <div>
            <div class="blog-img_block">
              <div [ngStyle]="{'background-repeat' : 'no-repeat', 'height' : '350px' ,'background-size' : 'cover', 'background-position' : 'center' , 'background-image' : 'url(' +'/api/'+ art.photo + ')' }"></div>
              <div class="blog-date">
                <span>{{art.date}}</span>
              </div>
            </div>
            <div class="blog-tiltle_block mb-5" *ngIf="utilisateurConnecte">
              <h4><a href="blog-post.html">{{art.titre}}</a></h4>
              <h6 *ngIf="art.idutilisateur"> <a href="#"><i class="fa fa-user" aria-hidden="true"></i><span>{{art.nom}} {{art.prenom}}</span></a></h6>
              <p [innerHTML]="art.contenu" class="text-justify overflow" [class.show]="show"></p>
              <a (click)="show = !show" type="button">{{ show ? 'Voir moins': 'Voir Plus' }}</a>
              <div class="blog-icons" *ngIf="art.idutilisateur === utilisateurConnecte.id">
                <div class="blog-share_block">
                  <ul>
                    <li>
                      <a (click)="passData(art)" data-toggle="modal" data-target="#suppression"><i class="fa fa-trash small-icon text-danger"></i></a>
                    </li>
                    <li>
                      <a data-toggle="modal" (click)="updating(art)"
                         data-target="#modifier"><i class="fa fa-edit small-icon text-warning"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="clubDeLaPage && utilisateurConnecte">
          <div class="row" *ngIf="clubDeLaPage.idutilisateur === utilisateurConnecte.id">
            <button routerLinkActive="active" class="btn btn-secondary my-2 btn-block" (click)="posterArticle(clubDeLaPage)">Creer</button>
          </div>
        </div>
        <pagination-controls *ngIf="articles.length>0" class="float-right"
                             (pageChange)="p = $event"
                             [directionLinks]="directionLinks"
                             [autoHide]="autoHide"
                             [responsive]="responsive"
                             [previousLabel]="labels.previousLabel"
                             [nextLabel]="labels.nextLabel"
                             [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                             [screenReaderPageLabel]="labels.screenReaderPageLabel"
                             [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel">
        </pagination-controls>
      </div>
      <div class="col-md-4">
        <form [formGroup]="form" class="row mx-1">
          <input type="text" formControlName="titre" placeholder="Cherchez un article" class="blog-search col-md-8">
          <button type="submit" class="btn btn-dark btn-blogsearch text-white py-2 mb-1 col-md-4"  (click)="rechercher()">CHERCHER</button>
        </form>
        <div class="container" *ngIf="clubDeLaPage">
          <div class="avatar-flip">
            <img src="/api/{{clubDeLaPage.photoclub}}"
                 height="150" width="150">
            <img src="/api/{{clubDeLaPage.photoclub}}"
                 height="150" width="150">
          </div>
          <h2>{{clubDeLaPage.sigle}}</h2>
          <h2>{{clubDeLaPage.libelle}}</h2>
          <h4>{{clubDeLaPage.apropos}}</h4>
          <div class="row">
            <div class="col-sm-5 mx-auto">
              <div class="nav-item dropdown">
                <a class="nav-link dropdown-toggle btn btn-dark text-white" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Clubs
                </a>
                <div class="dropdown-menu mr-3" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/espace-etudiant/clubs">Tous</a>
                  <a class="dropdown-item pointeur" *ngFor="let club of clubList" (click)="blog(club)">{{club.sigle}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="modifier" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modification d'une article</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="Wysywigform">
          <div class="row px-3">
            <div class="">
              <label for="Title">Titre</label>
              <input formControlName="titre" class="form-control mb-3" id="Title" aria-describedby="Title" [ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
              <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
                <div *ngIf="f.titre.errors.required">Veuillez renseigner une description</div>
              </div>

              <label for="Contenu">Contenu</label>
              <ckeditor id="Contenu" data="<p>Hello, world!</p>"  formControlName="contenu"></ckeditor>

              <button type="button" class="btn btn-dark text-white py-2 btn-block mt-3" type="submit" (click)="Update()" [disabled]="!Wysywigform.valid">Modifier</button>
            </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal" role="dialog" id="suppression">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Supprimer cette Article?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger"(click)="deleteArticle()">Confirmer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
