<div class="container" *ngIf="coordonnees">
  <h3>Coordonnées : </h3>
  <form [formGroup]="updateForm" (ngSubmit)="update()">
    <input type="text" class="form-control" id="id" formControlName="id" hidden>
    <div class="form-group">
      <label for="telephone">Telephone</label>
      <input type="text" class="form-control" id="telephone" formControlName="telephone" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }">
      <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
        <div *ngIf="f.telephone.errors.required">Veuillez renseigner le numéro téléphone</div>
      </div>
    </div>
    <div class="form-group my-3">
      <label for="email">Adresse email</label>
      <input type="email" class="form-control" id="email" formControlName="mail" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.mail.errors }">
      <div *ngIf="submitted && f.mail.errors" class="invalid-feedback">
        <div *ngIf="f.mail.errors.required">Veuillez renseigner l'adresse mail</div>
        <div *ngIf="f.mail.errors.email">Veuillez entrer une addresse email valide</div>
      </div>
    </div>
    <div class="form-group my-3">
      <label for="adresse">Adresse</label>
      <input type="text" class="form-control" id="adresse" formControlName="adresse" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.adresse.errors }">
      <div *ngIf="submitted && f.adresse.errors" class="invalid-feedback">
        <div *ngIf="f.adresse.errors.required">Veuillez l'adresse</div>
      </div>
    </div>
    <div class="form-group my-3">
      <label for="boite_postale">Boite postale</label>
      <input type="text" class="form-control" id="boite_postale" formControlName="boite_postale" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.boite_postale.errors }">
      <div *ngIf="submitted && f.boite_postale.errors" class="invalid-feedback">
        <div *ngIf="f.boite_postale.errors.required">Veuillez renseigner la boite postale</div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <label for="longitude">Longitude</label>
        <input type="text" class="form-control" id="longitude" formControlName="longitude" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.longitude.errors }">
        <div *ngIf="submitted && f.longitude.errors" class="invalid-feedback">
          <div *ngIf="f.longitude.errors.required">Veuillez renseigner la longitude</div>
        </div>
      </div>
      <div class="col">
        <label for="latitude">Latitude</label>
        <input type="text" class="form-control" id="latitude" formControlName="latitude" [readonly]="readonly" [ngClass]="{ 'is-invalid': submitted && f.latitude.errors }">
        <div *ngIf="submitted && f.latitude.errors" class="invalid-feedback">
          <div *ngIf="f.latitude.errors.required">Veuillez renseigner la latitude</div>
        </div>
      </div>
    </div>
    <button class="btn bg-forth text-clear mb-3" data-toggle="modal" data-target="#activerModif"><i class="fa fa-edit mr-2"></i>Activer la modification</button>
    <div class="pull-right mb-4 row">
      <button class="btn bg-forth text-clear mr-2 mb-3" type="submit"><i class="fa fa-save mr-3"></i>Enregistrer</button>
    </div>
  </form>
</div>


<!-- Modal -->
<div class="modal fade" id="activerModif" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="activerModifLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="activerModifLabel">Modification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment activer les modifications?<br>Les changements que vous ferez seront vu dans le site d'accueil de ENI
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="activerModification()">Activer</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
