<section id="actualite" class="font-roboto ">
  <div class="container-fluid aos-init aos-animate" data-aos="fade-up">
    <header id="header" class="section-header wow fadeInUp">
      <h3>ACTUALITES
      </h3>
    </header>
    <div class="row" *ngIf="!show">
      <div class="col-12 ">
        <!--SECTION START-->
        <section class="row">
          <!--Start slider news-->
          <div class="col-12 col-md-6 mb-3">
            <div id="featured" class="carousel slide carousel" data-ride="carousel">
              <!--dots navigate-->
              <ol class="carousel-indicators top-indicator" *ngFor="let article of articlesList;">
                <li data-target="#featured" data-slide-to=article[i] class="active"></li>
              </ol>
              <!--carousel inner-->
              <div class="carousel-inner">
                <!--Item slider-->
                <div class="carousel-item " [ngClass]="{active:isFirst}"
                     *ngFor="let article of articlesLastList; index as i;first as isFirst">
                  <div class="card border-0 rounded-0 text-light overflow zoom">
                    <div class="position-relative">
                      <!--thumbnail img-->
                      <div class="ratio_left-cover-1 image-wrapper conteneurImage">
                        <a class="pointeur" (click)="showArticle(article)">

                          <img class="img-fluid image" src="/api/{{article.photo}}" alt="#">
                        </a>
                      </div>
                      <div class="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                        <!--title-->
                        <div (click)="showArticle(article)">
                          <h2 class="h3 post-title text-white my-1 pointeur" (click)="showArticle(article)">
                            {{article.titre}}</h2>
                        </div>
                        <!-- meta title -->
                        <div class="news-meta">
                          <span class="news-author">Par <span
                            class="text-white font-weight-bold">{{article.nom}}
                            {{article.prenom}}</span></span><br>
                          <span class="news-date">le {{article.date | date: ' d MMMM y\''}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <!--navigation-->
            </div>
            <!--End slider news-->
            <!--Start box news-->

          </div>
          <div class="col-12 col-md-6" [ngStyle]=" articlesOtherList.length === 0 && {'display': 'table'}">
            <div>
              <div class="card-media"
                   *ngFor="let event of articlesOtherList | paginate: { itemsPerPage: 3, currentPage: p }">
                <!-- media container -->
                <div class="card-media-object-container">
                  <div class="card-media-object pointeur btnOpenDetails" (click)="showEvent(event)"
                       [ngStyle]="{'background-size' : 'cover','background-image': 'url(' +'/api/'+ event.photo + ')'}"></div>
                </div>
                <!-- body container -->
                <div class="card-media-body">
                  <div class="card-media-body-top">
                    <span class="card-media-body-heading pointeur" (click)="showEvent(event)">{{event.titre}}</span>
                    <span class="subtle"><i class="fa fa-calendar mr-2"></i> Le {{event.date | date: 'longDate'}}
<!--                      au {{event.fin | date: 'longDate'}}--></span>
                    <div class="postcard__bar"></div>
                  </div>
                  <div class="card-media-body-supporting-bottom">
                    <span class="card-media-body-supporting-bottom-text subtle">AENI France</span>
                  </div>
                  <div class="card-media-body-supporting-bottom card-media-body-supporting-bottom-reveal">
                    <span class="card-media-body-supporting-bottom-text subtle">{{event.libelle}}</span>
                    <a class="card-media-body-supporting-bottom-text card-media-link u-float-right pointeur"
                       (click)="showEvent(event)">Détails</a>
                  </div>
                </div>
              </div>
            </div>
            <pagination-controls class="float-right" *ngIf="articlesOtherList.length > 0" (pageChange)="p = $event" [directionLinks]="directionLinks"
                                 [autoHide]="autoHide" [responsive]="responsive" [previousLabel]="labels.previousLabel"
                                 [nextLabel]="labels.nextLabel"
                                 [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                 [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                 [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel">
            </pagination-controls>

          </div>
        </section>
      </div>
    </div>

  </div>
</section>
<div id="showArticle" class="modal animate__animated animate font-roboto " tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" #articleModal style="display: none;">
  <div *ngIf="show && articleToShow">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-body">

          <div class="row " style="height: 100vh;overflow: auto;padding: 20px">
            <div class=" col-md-6 mt-5">
<!--              <div class="icones">-->
<!--                <i class=" prev fa fa-chevron-circle-left mr-3 text-turquoise pointeur" (click)="prev(articleToShow)"-->
<!--                   [ngClass]="{'disabled': prevLinkActive === true}"-->
<!--                ></i>-->
<!--                <i class=" next fa fa-chevron-circle-right text-turquoise pointeur" (click)="next(articleToShow)"-->
<!--                   [ngClass]="{'disabled': nextLinkActive === true}"-->
<!--                ></i>-->
<!--              </div>-->

              <div class="centerImg">
                <figure class="snip1206">
                  <img src="/api/{{articleToShow.photo}}" alt="{{articleToShow.libelle}}"
                       class="img-fluid imgShadow">
                  <figcaption>
                    <p>{{articleToShow.libelle}}</p>
                  </figcaption>
                </figure>

              </div>
              <div>
              </div>
            </div>
            <div class="col-md-6 ">
              <a data-dismiss="modal" class="close-button pointeur float-right mt-2" (click)="close()">
                <div class="in">
                  <div class="close-button-block"></div>
                  <div class="close-button-block"></div>
                </div>
                <div class="out">
                  <div class="close-button-block"></div>
                  <div class="close-button-block"></div>
                </div>
              </a>
              <div class="main-container">

                <header class="section-header wow fadeInUp mt-5 ">

                  <h3>{{articleToShow.titre}}</h3>

                </header>
                <div class="  height">
                  <div class="mt-5">
                    <!-- Default breadcrumb-->

                    <h6 class="text-blue-second">{{articleToShow.libelle}} </h6>
                    <p [innerHTML]="articleToShow.contenu" class="italic indent"></p>

                    <p class=" float-right">
                      <i class="fa fa-user mr-2  text-blue-second"></i>
                      <small class=" text-blue-second"> {{articleToShow.nom}} {{articleToShow.prenom}}</small>  <br>
                      <i class="fa fa-calendar mr-2  text-blue-second"></i>
                      <small class=" text-blue-second"> {{articleToShow.date | date: ' d MMMM y\''}}</small>

                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="detailsEvent" class="modal animate__animated animate animate font-roboto" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" #eventModal aria-hidden="true" style="display: none;">
  <div *ngIf="showDetailEvent && eventToShow">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row " style="height: 100vh;padding: 20px">
            <div class=" col-md-6 mt-5">
              <div class="icones">
                <i class=" prev fa fa-chevron-circle-left mr-3 text-turquoise pointeur" (click)="prev(eventToShow)"
                   [ngClass]="{'disabled': prevLinkActive === true}"
                ></i>
                <i class=" next fa fa-chevron-circle-right text-turquoise pointeur" (click)="next(eventToShow)"
                   [ngClass]="{'disabled': nextLinkActive === true}"
                ></i>
              </div>

              <div class="centerImg">
                <figure class="snip1206">
                  <img src="/api/{{eventToShow.photo}}" alt="{{eventToShow.libelle}}"
                       class="img-fluid imgShadow">
                </figure>

              </div>
              <div>
              </div>

            </div>

            <div class="col-md-6 ">
              <a data-dismiss="modal" class="close-button pointeur float-right mt-2" (click)="close()">
                <div class="in">
                  <div class="close-button-block"></div>
                  <div class="close-button-block"></div>
                </div>
                <div class="out">
                  <div class="close-button-block"></div>
                  <div class="close-button-block"></div>
                </div>
              </a>
              <div class="main-container">

                <header class="section-header wow fadeInUp mt-5 ">

                  <h3>{{eventToShow.titre}}</h3>

                </header>
                <div class="  height">
                  <div class="mt-5">
                    <!-- Default breadcrumb-->

                    <h6 class="text-blue-second">{{eventToShow.libelle}} </h6>
                    <p [innerHTML]="eventToShow.contenu" class="italic indent"></p>

                    <p class=" float-right">
                      <i class="fa fa-user mr-2  text-blue-second"></i>
                    <small class=" text-blue-second"> {{eventToShow.nom}} {{eventToShow.prenom}}</small>  <br>
                      <i class="fa fa-calendar mr-2  text-blue-second"></i>
                      <small class=" text-blue-second"> {{eventToShow.date | date: ' d MMMM y\''}}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
