import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {Utilisateur} from '../classes/utilisateur';

@Injectable({
  providedIn: 'root'
})
export class PassUtilisateurService {
  private subject = new Subject<any>();

  private profileUserSubject: BehaviorSubject<any>;
  public profileUser: Observable<any>;

  constructor() { }

  sendUtilisateur(utilisateur: any) {
    this.profileUserSubject = new BehaviorSubject<any>(JSON.parse(JSON.stringify(utilisateur)));
    this.profileUser = this.profileUserSubject.asObservable();
  }

  public get getUtilisateur(): any {
    if (this.profileUserSubject) {
      return this.profileUserSubject.value;
    } else {
      return null;
    }
  }

  removeDispo() {
    if (this.profileUserSubject) {
      this.profileUserSubject.next(null);
    }
  }
}
