<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 *ngIf="ajouter">Ajouter un club</h5>
      <h5 *ngIf="modifier">Modifier le club</h5>
    </div>
    <form [formGroup]="updateForm" (ngSubmit)="update()">
    <div class="card-body row px-4">
    <div class="col-lg-4 col-md-6 col-sm-10 mx-auto py-4">
      <div id="image" [ngStyle]="{'width': '100%','max-width': '250px' , 'height': '250px', 'background-position' : 'center','background-size': 'cover', 'background-image': image }"></div>
      <div class="mb-3" *ngIf="ajouter">
        <label for="apropos">Logo</label>
        <div class="mb-3">
          <input type="file" id="photo" (change)="onFileSelect($event)" [ngClass]="{ 'is-invalid': submitted && f.photo.errors }" style="width: 100%">
          <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
            <div *ngIf="f.photo.errors.required">Veuillez renseigner la photo</div>
          </div>
        </div>
      </div>
      <input type="input" class="form-control" id="id" formControlName="id" hidden>
      <div class="mb-3">
        <label for="libelle">Libelle</label>
        <input type="input" class="form-control" id="libelle" formControlName="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
        <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
          <div *ngIf="f.libelle.errors.required">Veuillez renseigner le nom du club</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="apropos">A propos</label>
        <input type="input" class="form-control" id="apropos" formControlName="apropos" [ngClass]="{ 'is-invalid': submitted && f.apropos.errors }">
        <div *ngIf="submitted && f.apropos.errors" class="invalid-feedback">
          <div *ngIf="f.apropos.errors.required">Veuillez remplir l'à propos</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="mod">Moderateur</label>
        <select  class="form-control" formControlName="utilisateur" [ngClass]="{ 'is-invalid': submitted && f.utilisateur.errors }">
          <option *ngFor="let utilisateur of utilisateurList" value="{{ utilisateur.id }}">{{utilisateur.nom}}  {{utilisateur.prenom}}</option>
        </select>
        <div *ngIf="submitted && f.utilisateur.errors" class="invalid-feedback">
          <div *ngIf="f.utilisateur.errors.required">Veuillez renseigner le Moderateur</div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-md-6 col-sm-10 mx-auto">
      <div class="card-body">
          <div class="mb-3">
            <label for="historique">Historique</label>
              <ckeditor [(ngModel)]="mycontent"  [config]="ckeConfig"  id="historique" formControlName="historique" [ngClass]="{ 'is-invalid': submitted && f.historique.errors }"></ckeditor>
              <div *ngIf="submitted && f.historique.errors" class="invalid-feedback">
                <div *ngIf="f.historique.errors.required">Veuillez renseigner l'historique du club</div>
              </div>
          </div>
          <div class="mb-3">
            <label for="missions">Missions</label>
              <ckeditor [(ngModel)]="mycontent2"  [config]="ckeConfig" id="missions" formControlName="missions" [ngClass]="{ 'is-invalid': submitted && f.missions.errors }"></ckeditor>
              <div *ngIf="submitted && f.missions.errors" class="invalid-feedback">
                <div *ngIf="f.missions.errors.required">Veuillez renseigner les missions</div>
              </div>
          </div>
          <button class="btn bg-forth text-clear mr-3 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
          <button class="btn btn-secondary mb-3" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>

      </div>
    </div>
  </div>
    </form>
  </div>

  <h3>Liste des clubs</h3>

<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="example"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="clubList">
        <thead>
        <tr>
          <th>Nom</th>
          <th>A propos</th>
          <th>Moderateur</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let club of clubList">
          <td>{{ club.libelle }}</td>
          <td>{{ club.apropos }}</td>
          <td>
            <span *ngIf="club.utilisateur"> {{ club.utilisateur.nom }}  {{ club.utilisateur.prenom }}</span>
          </td>
          <td>
            <button class="btn btn-primary mr-2" href="#" (click)="updating(club)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger"  data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(club)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="delete" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="clubToDelete"> {{clubToDelete.libelle}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
</div>
<notifier-container></notifier-container>
