
<section id="contact" class="font-roboto ">
  <div class=" aos-init aos-animate" data-aos="fade-up">
    <header class="section-header wow fadeInUp">
      <h3>CONTACT
      </h3>
    </header>
  <div class="container-fluid">
    <app-localisation></app-localisation>
  </div>
  <div class="row contact-info" *ngIf="coordonnees">
    <div class="col-md-4">
      <div class="contact-address">
        <i class="bi bi-geo-alt "></i>
        <h3>Addresse</h3>
        <address>{{coordonnees.adresse}}</address>
      </div>
    </div>
    <div class="col-md-4">
      <div class="contact-phone">
        <i class="bi bi-phone"></i>
        <h3>T&eacute;l&eacute;phone</h3>
        <p><a href="tel:+261207550801">{{coordonnees.telephone}}</a></p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="contact-email">
        <i class="bi bi-envelope"></i>
        <h3>Email</h3>
        <p><a href="mailto:info@example.com">{{coordonnees.mail}}</a></p>
      </div>
    </div>
  </div>
    <div class="container-fluid">
      <div class="form ">

        <form action="" method="post" role="form" class="php-email-form"[formGroup]="contactForm">
          <div class="row">
            <div class="form-group col-md-6 container">
              <input type="text" name="name" class="form-control" id="name"#name placeholder="Votre Nom" required=""formControlName="name"[ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Veuillez renseigner votre nom</div>
              </div>
            </div>

            <div class="form-group col-md-6">
              <input type="email" class="form-control" name="email" id="email" #email placeholder="Votre adresse mail" required=""formControlName="email"[ngClass]="{ 'is-invalid': submitted && f.email.errors }">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Veuillez renseigner votre email</div>
                <div *ngIf="f.email.errors.email">Veuillez entrer une addresse email valide</div>
              </div>
            </div>

          </div>
          <div class="form-group">
            <input type="text" class="form-control" name="subject" id="subject" #subject placeholder="Objet" required=""formControlName="subject"[ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
            <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
              <div *ngIf="f.subject.errors.required">Veuillez renseigner votre Objet</div>
            </div>
          </div>

          <div class="form-group">
            <textarea class="form-control" name="message" rows="5" placeholder="Message"#text required=""formControlName="text"[ngClass]="{ 'is-invalid': submitted && f.text.errors }"></textarea>
            <div *ngIf="submitted && f.text.errors" class="invalid-feedback">
              <div *ngIf="f.text.errors.required">Veuillez renseigner votre Message</div>
            </div>
          </div>

          <div class="my-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Votre message a bien &eacute;t&eacute; envoy&eacute;. Merci!</div>
          </div>

          <div class="justify-content-center d-flex">
            <div class="text-xs-center mb-5 px-auto ">
              <div class="g-recaptcha" >
                <ngx-recaptcha2  #captchaElem
                                 [siteKey]="'6LdJVHAcAAAAAEHc5IZNaZ3PR7gtPwHpf9GJ-4hI'"
                                 (success)="handleSuccess($event)"
                                 [useGlobalDomain]="false"
                                 [hl]="lang" [theme]="theme" [type]="type"
                                 (expire)="handleExpire()"
                                 formControlName="recaptcha"
                                 [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }"
                >
                </ngx-recaptcha2>
              </div>

            </div>
          </div>
          <div class="text-center w-auto"><button class="shadow-sm p-3 mb-5 bg-white rounded w-25"type="submit" (click)="onSubmit(name.value, email.value, text.value,subject.value)">Envoyer <i class="bi bi-cursor-fill"></i></button></div>

        </form>

      </div>
    </div>

      </div>

</section>
<notifier-container></notifier-container>
