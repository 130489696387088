import { Assignation } from "./assignation";
import { Enseignant } from "./enseignant";
import { Niveau } from "./niveau";
import { Parcours } from "./parcours";
import { UE } from "./ue";

export class Schedule {
  id: number;
  date_debut: string;
  date_fin: string;
  ueUserParcoursNiveau:Assignation;
  libelle:string;

}
