<div class="container mb-3">
  <h3>Utilisateur actifs</h3>
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="actifs"  class="table table-striped" datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="activeList">
        <thead>
        <tr>
          <th>Numero matricule</th>
          <th>Nom et prénoms</th>
          <th>Parcours</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let utilisateur of activeList">
          <td>{{ utilisateur.numero_matricule }}</td>
          <td>
            <div class="">
              <p class="font-weight-bold mb-0">{{utilisateur.nom}}  {{utilisateur.prenom}}</p>
              <p class="text-muted mb-0">{{utilisateur.adresse_mail}}</p>
            </div>
          </td>
          <td *ngIf="utilisateur.parcours">{{ utilisateur.parcours.libelle }}</td>
          <td *ngIf="!utilisateur.parcours"> </td>
          <td>
            <button class="btn btn-success mr-2"  data-toggle="modal" data-target="#edit" (click)="loadActivation(utilisateur)"><i class="fa fa-check"></i> Active</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    </div>
</div>

<div class="container mt-5">
    <h3>Utilisateur innactifs</h3>
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive">
      <table id="inactifs"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="innactiveList">
        <thead>
        <tr>
          <th>Numero matricule</th>
          <th>Nom et prénoms</th>
          <th>Parcours</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let utilisateur of innactiveList">
          <td>{{ utilisateur.numero_matricule }}</td>
          <td>
            <div class="">
              <p class="font-weight-bold mb-0">{{utilisateur.nom}}  {{utilisateur.prenom}}</p>
              <p class="text-muted mb-0">{{utilisateur.adresse_mail}}</p>
            </div>
          </td>
          <td *ngIf="utilisateur.parcours">{{ utilisateur.parcours.libelle }}</td>
          <td *ngIf="!utilisateur.parcours"> </td>
          <td>
            <button class="btn btn-danger mr-2"  data-toggle="modal" data-target="#edit" (click)="loadActivation(utilisateur)"><i class="fa fa-edit"></i> Innactive</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    </div>
</div>

<div class="container my-5">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 *ngIf="ajouter">Ajouter un administrateur</h5>
      <h5 *ngIf="modifier">Modifier l'administrateur</h5>
    </div>
    <div class="card-body row px-4">
      <div class="col-lg-4 col-md-6 col-sm-10 mx-auto py-4">
        <div id="image" [ngStyle]="{'width': '100%','max-width': '250px', 'height': '250px', 'background-position':'center', 'background-size': 'cover', 'background-image': image }"></div>
        <div class="mb-3 row" *ngIf="ajouter">
          <label for="photo" class="col-lg-3 col-md-10 col-form-label">Photo</label>
          <div class="col-sm-9">
            <div class="mb-3">
              <input type="file" id="photo" (change)="onFileSelect($event)" [ngClass]="{ 'is-invalid': submitted && f.photo.errors }" style="width: 100%">
              <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
                <div *ngIf="f.photo.errors.required">Veuillez inserer la photo de l'administrateur</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-sm-10 mx-auto px-0">
        <div class="card-body">
          <form [formGroup]="updateForm" (ngSubmit)="update()">
            <input type="input" class="form-control" formControlName="id" hidden>
            <div class="mb-3 row">
              <label class="col-lg-3 col-md-10 col-form-label">N° matricule</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" formControlName="numero_matricule" *ngIf="ajouter" [ngClass]="{ 'is-invalid': submitted && f.numero_matricule.errors }">
                <input type="input" class="form-control" formControlName="numero_matricule" *ngIf="modifier" readonly>
                <div *ngIf="submitted && f.numero_matricule.errors" class="invalid-feedback">
                  <div *ngIf="f.numero_matricule.errors.required">Veuillez renseigner le numéro matricule</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="nom" class="col-lg-3 col-md-10 col-form-label">Nom</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" id="nom" formControlName="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }">
                <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                  <div *ngIf="f.nom.errors.required">Veuillez renseigner le nom de l'administrateur</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="prenom" class="col-lg-3 col-md-10 col-form-label">Prénom</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" id="prenom" formControlName="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }">
                <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
                  <div *ngIf="f.prenom.errors.required">Veuillez renseigner le prénom de l'administrateur</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="adresse_mail" class="col-lg-3 col-md-10 col-form-label">Adresse email</label>
              <div class="col-sm-9">
                <input type="email" class="form-control" id="adresse_mail" formControlName="adresse_mail" [ngClass]="{ 'is-invalid': submitted && f.adresse_mail.errors }">
                <div *ngIf="submitted && f.adresse_mail.errors" class="invalid-feedback">
                  <div *ngIf="f.adresse_mail.errors.required">Veuillez renseigner l'adresse mail</div>
                  <div *ngIf="f.adresse_mail.errors.email">Veuillez entrer une addresse email valide</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="parcours" class="col-lg-3 col-md-10 col-form-label">Parcours</label>
              <div class="col-sm-9">
                <select class="form-control" id="parcours"  formControlName="parcours" [ngClass]="{ 'is-invalid': submitted && f.parcours.errors }">
                  <option *ngFor="let parcours of parcoursList" [ngValue]="parcours.id">{{parcours.libelle}}</option>
                </select>
                <div *ngIf="submitted && f.parcours.errors" class="invalid-feedback">
                  <div *ngIf="f.parcours.errors.required">Veuillez renseigner un parcours</div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary mr-5 mb-1" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
            <button class="btn btn-secondary" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-5">
  <h3>Comptes administrateurs</h3>
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="admin"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="adminList">
        <thead>
        <tr>
          <th>Numero matricule</th>
          <th>Nom et prénoms</th>
          <th>Parcours</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let admin of adminList">
          <td>{{ admin.numero_matricule }}</td>
          <td>
            <div class="">
              <p class="font-weight-bold mb-0">{{admin.nom}}  {{admin.prenom}}</p>
              <p class="text-muted mb-0">{{admin.adresse_mail}}</p>
            </div>
          </td>
          <td><span  *ngIf="admin.parcours">{{ admin.parcours.libelle }}</span></td>
          <td>
            <button class="btn btn-primary mr-2" href="#" (click)="updating(admin)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger"  data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(admin)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal" id="edit" role="dialog" data-backdrop="false" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true" *ngIf="utilisateurToEdit">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteLabel">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="utilisateurToEdit.active === true">
          Voulez vous vraiment désactiver<span *ngIf="utilisateurToEdit"> {{utilisateurToEdit.nom}} {{utilisateurToEdit.prenom}}</span> ?
       </div>
       <div class="modal-body" *ngIf="utilisateurToEdit.active === false">
         Voulez vous vraiment activer<span *ngIf="utilisateurToEdit"> {{utilisateurToEdit.nom}} {{utilisateurToEdit.prenom}}</span> ?
      </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="activer()">Activer</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="delete" role="dialog" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="adminToDelete"> {{adminToDelete.nom}} {{adminToDelete.prenom}}</span> ? <br>
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal" id="delete2" role="dialog" data-backdrop="false" tabindex="-1" aria-labelledby="delete2Label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="delete2Label">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="scolariteToDelete"> {{scolariteToDelete.nom}} {{scolariteToDelete.prenom}}</span> ? <br>
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete2()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>

<div class="container my-5">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 *ngIf="ajouter2">Ajouter un compte scolarite</h5>
      <h5 *ngIf="modifier2">Modifier le compte scolarite</h5>
    </div>
    <div class="card-body row px-4">
      <div class="col-lg-4 col-md-6 col-sm-10 mx-auto py-4">
        <div id="image2" [ngStyle]="{'width': '100%','max-width': '250px' , 'height': '250px', 'background-position':'center', 'background-size': 'cover', 'background-image': image2 }"></div>
        <div class="mb-3 row" *ngIf="ajouter2">
          <label for="photo" class="col-lg-3 col-md-10 col-form-label">Photo</label>
          <div class="col-sm-9">
            <div class="mb-3">
              <input type="file" id="photo2" (change)="onFileSelect2($event)" [ngClass]="{ 'is-invalid': submitted && f2.photo.errors }" style="width: 100%;">
              <div *ngIf="submitted && f2.photo.errors" class="invalid-feedback">
                <div *ngIf="f2.photo.errors.required">Veuillez inserer la photo</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-sm-10 mx-auto px-0">
        <div class="card-body">
          <form [formGroup]="updateForm2" (ngSubmit)="updateScolarite()">
            <input type="input" class="form-control" formControlName="id" hidden>
            <div class="mb-3 row">
              <label class="col-lg-3 col-md-10 col-form-label">N° matricule</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" formControlName="numero_matricule" *ngIf="ajouter" [ngClass]="{ 'is-invalid': submitted && f2.numero_matricule.errors }">
                <input type="input" class="form-control" formControlName="numero_matricule" *ngIf="modifier" readonly>
                <div *ngIf="submitted2 && f2.numero_matricule.errors" class="invalid-feedback">
                  <div *ngIf="f2.numero_matricule.errors.required">Veuillez renseigner le numéro matricule</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="nom" class="col-lg-3 col-md-10 col-form-label">Nom</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" id="nom2" formControlName="nom" [ngClass]="{ 'is-invalid': submitted && f2.nom.errors }">
                <div *ngIf="submitted2 && f2.nom.errors" class="invalid-feedback">
                  <div *ngIf="f2.nom.errors.required">Veuillez renseigner le nom</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="prenom" class="col-lg-3 col-md-10 col-form-label">Prénom</label>
              <div class="col-sm-9">
                <input type="input" class="form-control" id="prenom2" formControlName="prenom" [ngClass]="{ 'is-invalid': submitted && f2.prenom.errors }">
                <div *ngIf="submitted2 && f2.prenom.errors" class="invalid-feedback">
                  <div *ngIf="f2.prenom.errors.required">Veuillez renseigner le prénom</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="adresse_mail" class="col-lg-3 col-md-10 col-form-label">Adresse email</label>
              <div class="col-sm-9">
                <input type="email" class="form-control" id="adresse_mail2" formControlName="adresse_mail" [ngClass]="{ 'is-invalid': submitted && f2.adresse_mail.errors }">
                <div *ngIf="submitted2 && f2.adresse_mail.errors" class="invalid-feedback">
                  <div *ngIf="f2.adresse_mail.errors.required">Veuillez renseigner l'adresse mail</div>
                  <div *ngIf="f2.adresse_mail.errors.email">Veuillez entrer une addresse email valide</div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary mr-5 mb-1" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer2}}</button>
            <button class="btn btn-secondary" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-5">
  <h3>Comptes scolarite</h3>
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="sco"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="scolariteList">
        <thead>
        <tr>
          <th>Numero matricule</th>
          <th>Nom et prénoms</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let scolarite of scolariteList">
          <td>{{ scolarite.numero_matricule }}</td>
          <td>
            <div class="">
              <p class="font-weight-bold mb-0">{{scolarite.nom}}  {{scolarite.prenom}}</p>
              <p class="text-muted mb-0">{{scolarite.adresse_mail}}</p>
            </div>
          </td>
          <td>
            <button class="btn btn-primary mr-2" href="#" (click)="updating2(scolarite)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger"  data-toggle="modal" data-target="#delete2" (click)="loadDeleteConfirm2(scolarite)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
