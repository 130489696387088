import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as go from 'gojs';
import {Organigramme} from '../../../classes/organigramme';
import {ServiceAPIService} from '../../../services/service-api.service';
import {OrgData} from 'angular-org-chart/src/app/modules/org-chart/orgData';
import {ServiceAccueilService} from '../../../services/service-accueil/service-accueil.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
  organigrammeList: Array<any>;
  orgData: OrgData;

  mesurer = 0;

  constructor(private serviceAPI: ServiceAccueilService) {
  }

  async ngOnInit(): Promise<void> {
    await this.getOrganigramme();
    $(document).ready(function(){
      'use strict';
      $('.org-chart-entity-box').css('background-color', '#1362AB')
        .css('align-items', 'center')
        .css('border', '1px solid #408697')
        .css('border-radius', '3px')
        .css('box-shadow', '0 .5rem 2rem #777!important')
        .css('height', '3rem')
        .css('font-size', '14px');
      /*$('.org-chart-entity-border').css('border-left', '1px solid #4b4f5a')
        .css('border-top', '1px solid #4b4f5a');
      $('.org-chart-entity-connector').css('border-left', '1px solid #4b4f5a')
        .css('border-top', '1px solid #4b4f5a');*/
    });
  }

  async getOrganigramme() {
    const organigramme = [];
    const orgs = await this.serviceAPI.getOrganigramme().toPromise();
    this.organigrammeList = orgs;
    const premiers = orgs.filter(org => org.parent === null);
    const autres = orgs.filter(org => org.parent !== null);
    premiers.forEach( premier => {
      this.organigrammeList.splice(this.organigrammeList.indexOf(premier), 1);
      const data = {
        id: premier.id,
        name: premier.institution,
        type: premier.details,
        children: []
      };
      organigramme.push(data);
      this.mesurer ++;
    });
    const orgChart = await this.buildOrg(organigramme);
    this.orgData = organigramme[0];
  }

  buildOrg(organigramme: any) {
    const orga = organigramme;
    let next;
    const autres = this.organigrammeList.filter( org => org.parent !== null );
    for ( let i = 0; i < orga.length; i++) {
      const suivants = autres.filter( org => Number(org.parent) === orga[i].id);
      if (suivants.length > 0) {
        for (let j = 0; j < suivants.length; j ++) {
          this.organigrammeList.splice(this.organigrammeList.indexOf(suivants[j]), 1);
          const data = {
            id: suivants[j].id,
            name: suivants[j].institution,
            type: suivants[j].details,
            children: []
          };
          orga[i].children.push(data);
          this.mesurer ++ ;
          next = orga[i].children;
        }
      }
    }
    if (this.organigrammeList.length === 0) {
      return orga;
    } else {
      return this.buildOrg(next);
    }
  }

}
