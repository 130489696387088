<section id="counter" class="bg-clair font-roboto ">
  <div id="projectFacts" class="sectionClass wow fadeIn animated " style="visibility: visible; animation-name: fadeIn;"
       *ngIf="counter" #counter_item>
    <div class="fullWidth eight columns mx-auto">
      <div class="projectFactsWrap d-flex justify-content-center flex-wrap flex-row ">
        <div class="item wow fadeInUpBig animated animated" style="visibility: visible;">
          <i class="fa fa-user"></i>
          <p id="number1" class="number">{{counter.etudiant}}</p>
          <span></span>
          <p>Membres</p>
        </div>
        <div class="item wow fadeInUpBig animated animated" style="visibility: visible;">
          <i class="fa fa-building-o" aria-hidden="true"></i>
          <p id="number2" class="number">{{counter.diplome}}</p>
          <span></span>
          <p>Départements</p>
        </div>
        <div class="item wow fadeInUpBig animated animated" style="visibility: visible;">
          <i class="fa fa-briefcase" aria-hidden="true"></i>
          <p id="number3" class="number">{{counter.prix}}</p>
          <span></span>
          <p>Sociétés</p>
        </div>
      </div>
    </div>
  </div>
</section>
