import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject, Subject} from 'rxjs';
import {Club} from '../classes/club';
import {Utilisateur} from '../classes/utilisateur';
import {BlogClub} from "../classes/blog-club";

@Injectable({
  providedIn: 'root'
})
export class PassClubService {
  private subject = new Subject<any>();

  private clubSubject: BehaviorSubject<BlogClub>;
  public clubToPass: Observable<BlogClub>;

  sendClub(club: BlogClub) {
    this.clubSubject = new BehaviorSubject<BlogClub>(JSON.parse(JSON.stringify(club)));
    this.clubToPass = this.clubSubject.asObservable();
  }

  getClub(): BlogClub {
    if (this.clubSubject) {
      return this.clubSubject.value;
    } else {
      return null;
    }
  }

}
