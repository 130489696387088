import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ConnexionApiService} from '../../../../services/connexion-api.service';
import {ServiceEtudiantService} from '../../../../services/service-espace-etudiant/service-etudiant.service';
import {UtilisateurConnecte} from '../../../../classes/utilisateur-connecte';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  currentUser: UtilisateurConnecte;

  constructor( private router: Router, private connexionAPI: ConnexionApiService, private servideAPI: ServiceEtudiantService) {
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.servideAPI.getUtilisateurConnecte().toPromise();
  }

  logout() {
    this.connexionAPI.logout();
    this.router.navigate(['/login']);
  }
}
