<div class="wrapper" *ngIf="utilisateurProfile">
  <a class="text-turquoise pointeur" *ngIf="visiteur" (click)="retour()" routerLink="/disponibilites"><i class="fa fa-arrow-left mr-3 mb-4"></i>Retour</a>
  <div class="row">
    <div class="col-lg-4 col-md-5 col-sm-12 p-2">
      <div class="">
        <img [src]="APIURL + utilisateurProfile.photo" style="max-width: 100%;">
      </div>
    </div>
    <div class="col-lg-8 col-md-7 col-sm-12 py-2">
      <input type="radio" name="slider" checked id="infos">
      <input type="radio" name="slider" id="skills">
      <nav>
        <label for="infos" class="infos">Informations</label>
        <label for="skills" class="skills">Compétences</label>
        <div class="slider"></div>
      </nav>
      <section>
        <div class="content content-1">
          <div class="col-2 float-right pointeur" data-toggle="modal" data-target="#editUtilisateur" *ngIf="!visiteur" (click)="updating()">
            <i class="fa fa-edit small-icon text-white bouton"></i>
          </div>
              <div class="row"><div class="title col-md-4 col-sm-6">Numero matricule : </div><p class="col-md-8 col-sm-6">{{ utilisateurProfile.numero_matricule }} </p></div>
              <div class="row"><div class="title col-md-4 col-sm-6">Nom et prénoms : </div><p class="col-md-8 col-sm-6">{{ utilisateurProfile.nom }}  {{ utilisateurProfile.prenom }} </p></div>
              <div class="row"><div class="title col-md-4 col-sm-6" *ngIf="utilisateurProfile.niveau">Niveau : </div><p class="col-md-8 col-sm-6" *ngIf="utilisateurProfile.niveau">{{ utilisateurProfile.niveau }} </p></div>
              <div class="row"><div class="title col-md-4 col-sm-6" *ngIf="utilisateurProfile.parcours">Parcours : </div><p class="col-md-8 col-sm-6" *ngIf="utilisateurProfile.parcours">{{ utilisateurProfile.parcours }} </p></div>
              <div class="row"><div class="title col-md-4 col-sm-6">Adresse email : </div><p class="col-md-8 col-sm-6">{{ utilisateurProfile.adresse_mail }} </p></div>
              <div class="row"><div class="title col-md-4 col-sm-6" *ngIf="notEnseignant">Disponibilité : </div><p class="col-md-8 col-sm-6">
                <span class="text-success pointeur" *ngIf="utilisateurProfile.disponible === true && visiteur && notEnseignant">Disponible pour stage / emploi </span>
                <span class="text-danger pointeur" *ngIf="utilisateurProfile.disponible === false && visiteur && notEnseignant">Indisponible pour stage / emploi </span>
                <span class="text-success pointeur" data-toggle="modal" data-target="#updateDispo" *ngIf="utilisateurProfile.disponible === true && !visiteur && notEnseignant">Disponible pour stage / emploi </span>
                <span class="text-danger pointeur" data-toggle="modal" data-target="#updateDispo" *ngIf="utilisateurProfile.disponible === false && !visiteur && notEnseignant">Indisponible pour stage / emploi </span>
              </div>
              <div class="row"><div class="title col-md-4 col-sm-6">Biographie : </div><p class="col-md-8 col-sm-6">{{ utilisateurProfile.biographie }} </p></div>
        </div>
        <div class="content content-2">
          <h6 *ngIf="utilisateurProfile.skills && utilisateurProfile.skills.length === 0">Aucune compétences pour mentionnée.</h6>
          <div class="overflow" [class.show]="show">
            <div *ngFor="let skill of utilisateurProfile.skills">
            <small>{{skill.techno}}</small>
            <div class="progress mb-3" style="height: 5px">
              <div class="progress-bar bg-forth" role="progressbar"
                   [ngStyle]="{'width' : skill.pourcentage + '%' , 'background-color' : skill.couleur}"
                   aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
          <a *ngIf="utilisateurProfile.skills.length > 2" (click)="show = !show"
           type="button">{{ show ? 'Voir moins' : 'Voir Plus' }}</a>
        </div>
      </section>
    </div>
  </div>
</div>
<div class="container" *ngIf="notEnseignant">
    <div style="text-align: center;">
      <h4>Experiences professionnelles</h4>
      <h6 *ngIf="utilisateurProfile && utilisateurProfile.exp.length === 0">Aucune experience professionnelle pour l'instant.</h6>
      <p class="text-clear my-2 bg-forth" data-toggle="modal" data-target="#addExperience"
                            *ngIf="!visiteur" style="max-width: 50%;margin: auto; border-radius: 10px;">Ajouter un experience professionnelle
    </p>
      <div class="row my-2">
        <div class="timeline" *ngIf="utilisateurProfile">
          <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1"
               *ngFor="let exp of utilisateurProfile.exp">
            <div class="timeline__event__icon"
                 [ngClass]="{'border-emploi' : exp.type === 'Emploi', 'border-stage' : exp.type === 'Stage'}">
              <i *ngIf="exp.type === 'Emploi'" class="fa fa-briefcase color-emploi"></i>
              <i *ngIf="exp.type === 'Stage'" class="fa fa-graduation-cap color-stage"></i>
            </div>
            <div class="timeline__event__date"
                 [ngClass]="{'background-emploi' : exp.type === 'Emploi', 'background-stage' : exp.type === 'Stage'}">
              De {{exp.date_debut | date: 'MMMM y' }} <br>- à {{exp.date_fin | date: 'MMMM y'}}
              <div class="col-2 float-right" *ngIf="!visiteur">
                <a data-toggle="modal" (click)="updatingExp(exp)"
                   data-target="#addExperience"><i class="fa fa-edit small-icon text-warning"></i></a>
              </div>
              <div class="col-2 float-right" *ngIf="!visiteur">
                <a data-toggle="modal" (click)=" loadDeleteconfirm(exp)" data-target="#delete"><i
                  class="fa fa-trash small-icon text-danger"></i></a>
              </div>
            </div>
            <div class="timeline__event__content">
              <div class="timeline__event__title"
                   [ngClass]="{'color-emploi' : exp.type === 'Emploi', 'color-stage' : exp.type === 'Stage'}"
                   *ngFor="let tec of exp.technos; let index = index">{{ tec }}
                <span *ngIf="index!== exp.technos.length-1"> - </span>
              </div>
              <div class="timeline__event__description text-justify">
                <p>{{exp.description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="modal fade" id="editUtilisateur" tabindex="-1" role="dialog" aria-labelledby="editUtilisateurLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editUtilisateurLabel">Modifier votre profil</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editUtilisateurForm" (ngSubmit)="update()">
          <div class="form-group  m-2">

            <label for="nom">Nom</label>
            <input type="text" class="form-control" id="nom" formControlName="nom">

            <label for="prenom">Prénom</label>
            <input type="text" class="form-control" id="prenom" formControlName="prenom">

            <label for="adresse_mail">Adresse mail</label>
            <input type="text" class="form-control" id="adresse_mail" formControlName="adresse_mail">

            <label for="biographie">Biographie</label>
            <input type="text" class="form-control" id="biographie" formControlName="biographie">

            <label for="nouveau_mot_de_passe">Nouveau mot de passe</label>
            <input type="password" class="form-control" id="nouveau_mot_de_passe"
                   formControlName="nouveau_mot_de_passe">

            <label for="confirmer_nouveau_mot_de_passe">Confirmer le nouveau mot de passe</label>
            <input type="password" class="form-control" id="confirmer_nouveau_mot_de_passe"
                   formControlName="confirmer_nouveau_mot_de_passe">
            <p class="text-danger" *ngIf="confirmedFalse">Les mots de passes entrés ne sont pas égaux.</p>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
            <button type="submit" class="btn btn-primary" >Modifier</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addExperience" tabindex="-1" role="dialog" aria-labelledby="addExperienceLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addExperienceLabel" *ngIf="!modifier">Ajouter un experience professionnel</h5>
        <h5 class="modal-title" id="editExperienceLabel" *ngIf="modifier">Modifier l' experience professionnel</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reset()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addExperienceForm">
          <div class="form-group  m-2">
            <input type="input" class="form-control" id="id" formControlName="id" hidden>

            <select class="form-control" formControlName="utilisateur" *ngIf="utilisateurProfile" hidden>
              <option [ngValue]="utilisateurProfile.id">{{utilisateurProfile.nom}}</option>
            </select>

            <label for="typeexperience" class="form-label">Type de l'experience</label>
            <select class="form-control" id="typeexperience" formControlName="typeexperience"
                    [ngClass]="{ 'is-invalid': submitted && f.typeexperience.errors }">
              <option *ngFor="let type of typeexperienceList" [ngValue]="type.id">{{type.libelle}}</option>
            </select>
            <div *ngIf="submitted && f.typeexperience.errors" class="invalid-feedback">
              <div *ngIf="f.typeexperience.errors.required">Veuillez renseigner le type de l'experience</div>
            </div>
            <label for="date_debut">Date de début</label>
            <input type="date" class="form-control" id="date_debut" formControlName="date_debut"
                   max="{{ dateMaxDate | date:'yyyy-MM-dd'}}" (change)="onChangeMin($event)">
            <div *ngIf="submitted && f.date_debut.errors" class="invalid-feedback">
              <div *ngIf="f.date_debut.errors.required">Veuillez renseigner la date de début</div>
            </div>
            <label for="date_fin">Date de fin</label>
            <input type="date" class="form-control" id="date_fin" formControlName="date_fin"
                   min="{{dateMinDate | date:'yyyy-MM-dd'}}" max="{{ dateMaxDate | date:'yyyy-MM-dd'}}"
                   (change)="onChangeMax($event)">
            <div *ngIf="submitted && f.date_fin.errors" class="invalid-feedback">
              <div *ngIf="f.date_fin.errors.required">Veuillez renseigner la date de fin</div>
            </div>

            <label for="description">Description</label>
            <input type="text" class="form-control" id="description" formControlName="description"
                   [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Veuillez renseigner la description</div>
            </div>

            <label class="col-form-label">Technologie utilisé</label>
            <div formArrayName="technos" class="row">
              <div class="form-group mb-2 col-lg-4 col-md-6 col-sm-12 row" *ngFor="let technoControls of getTechno().controls; let i = index">
                <div class="col-md-9"><div class="ng-autocomplete">
                  <ng-autocomplete [formControlName]="i"
                                   [data]="technoList"
                                   [searchKeyword]="keyword"
                                   placeholder="Tapez le nom"
                                   historyIdentifier="technos"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate">>
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.libelle"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div>Introuvable</div>
                  </ng-template>
                </div></div>
                <div class="col-md-3"><button (click)="deleteAddressGroup(i)" class="btn btn-outline-danger"><i class="fa fa-trash-o"></i></button></div>
              </div>
            </div>
            <button type="button" class="btn btn-outline-success b-0" (click)="onAddTechno()"><i
              class="bi bi-plus-circle-fill"></i> Ajouter
            </button>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reset()">Annuler</button>
            <button type="button" class="btn btn-primary" (click)="addExperience()">Ajouter</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updateDispo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Disponibilié</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="utilisateurProfile">
        Voulez - vous changer votre disponibilité en "<span
        *ngIf="utilisateurProfile.disponible === true"> Indisponible </span> <span
        *ngIf="utilisateurProfile.disponible === false"> Disponible </span> " ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updateDisponibilite()">Enregistrer
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" aria-labelledby="deleteLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteExp()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
