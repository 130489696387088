<section class="Grid">
  <div class="Grid-row row">
    <div class="flip-card col-md-3" *ngIf="canPostPhoto">
      <div class="flip-card-inner">
        <div class="card flip-card-front">
          <h5 class="my-5 mx-3 text-center"><i class="fa fa-plus-square"></i><br>Ajouter un nouveau album</h5>
        </div>
        <div class="card flip-card-back" *ngIf="canPostPhoto">
          <div class="card-body">
            <h4 class="text-center">Ajouter</h4>
            <form [formGroup]="albumForm" (ngSubmit)="addAlbum()" class="form-group">
              <input class="form-control" placeholder="Nouvel album" formControlName="libelle">
              <button class="btn btn-outline-success btn-sm  btn-block my-2" type="submit" [disabled]="albumForm.invalid">Ajouter</button>
              <button class="btn btn-outline-secondary btn-sm  btn-block my-2" type="reset">Annuler</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <a class="Card my-2" (click)="openGallery(album)" id="card-{{album.id}}" *ngFor="let album of albumList" [ngClass]="{'Card--active' : albumActive === album}">
    <div class="Card-thumb">
      <div class="Card-shadow"></div>
      <div class="Card-shadow"></div>
      <div class="Card-shadow"></div>
      <div class="Card-image" *ngIf="album.galeries.length >0" [ngStyle]="{'background-size': 'cover','background-image': 'url(' + APIimage + album.galeries[0].photo + ')'}"></div>
      <div class="Card-image" *ngIf="album.galeries.length === 0"></div>
    </div>
    <div class="Card-title"><span>{{album.libelle}}</span></div>
    <div class="Card-explore"><span>Cliquez pour voir tous</span></div>
    </a>
  </div>
</section>
<section class="Gallery Gallery--active" id="gallery-{{albumActive.id}}" *ngIf="albumActive && galerieActive">
  <div class="Gallery-header"><a class="Gallery-close" (click)="closeAll()">×</a></div>
  <div class="Gallery-images row">
    <div class="col-md-5 col-sm-12">
      <div id="bg_active" [ngStyle]="{'height': '500px', 'background-color': 'grey', 'background-position': 'center', 'background-size': 'cover', 'background-image': image }">
        <a class="remove-image" href="#" style="display: inline;" *ngIf="canPostPhoto"  data-toggle="modal" data-target="#deleteImage"><i class="fa fa-trash"></i></a>
      </div>
      <div class="button" *ngIf="canPostPhoto"  data-toggle="modal" data-target="#add"><span>Ajouter</span></div>
      <div class="button text-danger" *ngIf="canPostPhoto"  data-toggle="modal" data-target="#deleteAlbum" (click)="loadDeleteAlbumConfirm(albumActive)"><span>Supprimer l'album</span></div>
    </div>
    <div class="col-md-7 col-sm-12">
      <div class="row" *ngIf="albumActive.galeries.length>0">
        <div class="Gallery-image col-lg-3 col-md-4 col-sm-6" *ngFor="let photo of albumActive.galeries" [ngStyle]="{'background-size' : 'cover','background-image' : 'url(' + APIimage + photo.photo + ')'}" (click)="selectImage(photo)"></div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-labelledby="addLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addLabel">Ajouter une ou plusieurs photos</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formGalerie" class="text-center">
          <input class="mb-5 ml-5 mx-auto" type="file" (change)="onFileSelect($event,showFileNames)" multiple accept="image/x-png,image/jpeg">
          <input #showFileNames style="border: 0px; width: 100%"/>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-primary" (click)="postGalerie()">Ajouter</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="deleteAlbum" role="dialog" tabindex="-1" aria-labelledby="modalLabel1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabel1">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="deleteAnAlbum && albumToDelete">
        <div class="modal-body">
          Voulez vous vraiment supprimer l'album : <span *ngIf="albumToDelete"> {{albumToDelete.libelle}}</span> ?
          Cette action est irreverssible.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteAlbum()">Supprimer</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="deleteImage" role="dialog" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="galerieToDelete">
        <div class="modal-body">
          Voulez vous vraiment supprimer?<br>
          Cette action est irreverssible.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteImage()">Supprimer</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        </div>
      </div>
      <div *ngIf="!galerieToDelete">
        <div class="modal-body">
          Aucune image selectionné. <br>
        </div>
      </div>
    </div>
  </div>
</div>
