<div class="container-fluid">
  <div class="left">
    <div class="header">
      <div class="row" href="#">
        <a class="col-sm-6 mx-auto" href="#">
          <div class="avatar-flip">
            <img src="assets/aenifront.png" height="100" width="100">
            <img src="assets/aenifront.png" height="100" width="100">
          </div>
        </a>
      </div>
      <h2 class="animation a1 text-center mt-2">Connexion</h2>
      <h4 class="animation a2 text-center" *ngIf="!passwordRecovery">Entrez votre numéro matricule/adresse email et votre mot de passe</h4>
      <h4 class="animation a2 text-center" *ngIf="passwordRecovery">Entrez votre adresse mail</h4>
    </div>
    <form class="form row" [formGroup]="form" *ngIf="!passwordRecovery">
      <input type="text" class="form-field animation a3" placeholder="Numero matricule" formControlName="numero_matricule">
      <input type="password" class="form-field animation a4" placeholder="Mot de passe" formControlName="mot_de_passe">
      <div class="alert alert-danger alert-white rounded my-2" *ngIf="erreur">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close" (click)="erreur = false">×</button>
        <div class="icon">
            <i class="fa fa-times-circle"></i>
        </div>
        <strong>Erreur </strong>
        Numero matricule ou mot de passe incorrect! Il vous reste {{tentative}} tentatives.
      </div>
      <div class="alert alert-danger alert-white rounded my-2" *ngIf="erreurMachine === true">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
        <div class="icon">
            <i class="fa fa-times-circle"></i>
        </div>
        <strong>Erreur </strong>
        Numero matricule ou mot de passe incorrect!
      </div>
      <div class="alert alert-danger alert-white rounded my-2" *ngIf="tropDeTentative">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close" (click)="tropDeTentative = false">×</button>
        <div class="icon">
            <i class="fa fa-times-circle"></i>
        </div>
        <strong>Trop de tentatives </strong> <br>
        Vous votre compte est bloqué, envoyer une demande à l'administration pour l'activer.
      </div>
      <div class="alert alert-danger alert-white rounded my-2" *ngIf="champsVide">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close" (click)="champsVide = false">×</button>
        <div class="icon">
            <i class="fa fa-times-circle"></i>
        </div>
        <strong>Champs vides </strong> <br>
        Veuillez remplir tous les champs.
      </div>
      <h4 class="my-2 animation a6" (click)="this.passwordRecovery = true">Mot de passe oublié ?</h4>
      <button class="animation a6" type="submit" (click)="onSubmit()">Se connecter</button>
    </form>
    <form class="form row mt-3" [formGroup]="emailForm" (ngSubmit)="EnvoyerEmail(email.value)" *ngIf="passwordRecovery">
      <input type="email" class="form-field animation a3" name="email" id="email" #email placeholder="Votre adresse mail" formControlName="email">
      <div class="alert alert-danger alert-white rounded my-2" *ngIf="mailError">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
        <div class="icon">
          <i class="fa fa-times-circle"></i>
        </div>
        <strong>Erreur : </strong>
        L'adresse mail n'existe pas!
      </div>
      <div class="alert alert-success alert-white rounded my-2" *ngIf="mailSent">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
        <div class="icon">
          <i class="fa fa-check"></i>
        </div>
        <strong>Nouveau mot de passe : </strong><br>
        Un nouveau mot de passe temporaire a été envoyé à votre adresse email!
      </div>
      <div class="alert alert-danger alert-white rounded my-2" *ngIf="erreurMail">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
        <div class="icon">
          <i class="fa fa-check"></i>
        </div>
        <strong>Erreur : </strong><br>
        Un erreur est due à la connexion veuillez réessayer plus tard!
      </div>
      <button class="animation a6" type="submit">Confirmer</button>
      <h4 class="my-2 animation a6 text-center" (click)="passwordRecovery = false">Retourner à l'authentification</h4>
    </form>
  </div>
  <div class="right"></div>
</div>
