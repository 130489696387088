<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h4 *ngIf="ajouterUE">Ajouter un UE</h4>
      <h4 *ngIf="modifierUE">Modifier l' UE</h4>
    </div>
    <div class="card-body px-5">
      <div class="">
        <form [formGroup]="updateUEForm" (ngSubmit)="updateUE()">
          <input type="text" class="form-control" formControlName="id" hidden>
          <div class="form-group row">
            <label for="libelle" class="col-md-4">Nom</label>
            <input type="text" id="libelle" class="form-control col-md-8" formControlName="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
            <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
              <div *ngIf="f.libelle.errors.required">Veuillez renseigner nom de l'UE</div>
            </div>
          </div>
          <div class="form-group row">
            <label for="typeue" class="col-md-4">Type d'unité d'enseignement</label>
            <select class="form-control col-md-8" id="typeue"  formControlName="typeue" [ngClass]="{ 'is-invalid': submitted && f.typeue.errors }">
              <option *ngFor="let typeue of typeueList" [ngValue]="typeue.id">{{typeue.libelle}}</option>
            </select>
            <div *ngIf="submitted && f.typeue.errors" class="invalid-feedback">
              <div *ngIf="f.typeue.errors.required">Veuillez renseigner un type d'unité d'enseignement</div>
            </div>
          </div>
          <button class="btn bg-forth text-clear mr-2 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
          <button class="btn btn-secondary mb-3" type="reset"><i class="fa fa-save mr-2"></i>Annuler</button>
        </form>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive">
        <table class="table table-striped" style="width:100%" datatable [dtOptions]="dtOptions" *ngIf="UEList">
          <thead>
          <tr>
            <th>Nom</th>
            <th>Catégorie</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let UE of UEList">
            <td>
              <div class="d-flex align-items-center">
                <div class="">
                  <p class="font-weight-bold mb-0">{{ UE.libelle }}</p>
                </div>
              </div>
            </td>
            <td><span *ngIf="UE.typeue">{{UE.typeue.libelle}}</span></td>
            <td>
              <button class="btn btn-primary mr-2" href="#" (click)="updatingUE(UE)"><i class="fa fa-edit"></i></button>
              <button class="btn btn-danger" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(UE)"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal" id="delete" data-backdrop="false" role="dialog" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="ueToDelete"> {{ueToDelete.libelle}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUE()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
