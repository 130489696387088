import { Component, OnInit } from '@angular/core';
import {Typearticle} from "../../../../classes/typearticle";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ServiceAPIService} from "../../../../services/service-api.service";
import {Typeue} from "../../../../classes/typeue";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-typeue',
  templateUrl: './typeue.component.html',
  styleUrls: ['./typeue.component.scss']
})
export class TypeueComponent implements OnInit {
  private readonly notifier: NotifierService;
  submitted=false;

  dtOptions: DataTables.Settings = {};
  typeueList: Array<Typeue>;
  typeToDelete: Typeue = null;

  ajouter = true;
  modifier = false;

  Enregistrer = 'Ajouter';

  updateForm: FormGroup;

  constructor(private serviceAPI: ServiceAPIService, private formBuilder: FormBuilder, notifierService: NotifierService,) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initAddForm();
    await this.getTypeue();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
  }

  async getTypeue(){
    this.typeueList = await this.serviceAPI.getTypeue().toPromise();
  }
  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Operation non enregistree',

    });
  }
  updateTypeue() {
    this.submitted=true;
    if(this.ajouter){
      if (this.updateForm.invalid) {
        return;
      }
      else{
        let value = this.updateForm.value;
        let type = new Typeue();
        type.libelle = value.libelle;
        type.couleur = value.couleur;
        this.serviceAPI.postTypeue(type).subscribe( () => {
         this.NotificationShowSucces();
          this.getTypeue();
          this.modifier = false;
          this.ajouter = true;
          this.updateForm.reset();
          this.submitted=false;
          window.location.reload();
        });
      }
    }
    if(this.modifier){
      this.submitted=true;
      if (this.updateForm.invalid) {
        return;
      }
      else{
        let value = this.updateForm.value;
        let type = new Typeue();
        type.libelle = value.libelle;
        type.couleur = value.couleur;
        type.id = value.id;
        this.serviceAPI.updateTypeue(type).subscribe( () => {
          this.NotificationShowSucces();
          this.getTypeue();
          this.modifier = false;
          this.ajouter = true;
          this.updateForm.reset();
          this.submitted=false;
          window.location.reload();
        });
      }
    }
  }

  initAddForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      libelle: ['', Validators.required],
      couleur: ['', Validators.required],
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  deleteTypeue(){
    this.serviceAPI.deleteTypeue(this.typeToDelete).subscribe( () => {this.NotificationShowSucces(); this.getTypeue(); window.location.reload(); });
  }

  updating(type: Typeue) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.Enregistrer = 'Modifier';
    this.updateForm = this.formBuilder.group({
      id: [type.id, Validators.required],
      // tslint:disable-next-line:no-unused-expression
      libelle: [type.libelle, Validators.required],
      couleur: [type.couleur, Validators.required],
    });
  }

  loadDelete(type: Typeue) {
    this.typeToDelete = type;
  }

  reset() {
    this.submitted = false;
    this.updateForm.reset();
    this.Enregistrer = 'Ajouter';
  }
}
