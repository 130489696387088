import { Component, HostListener, OnInit } from '@angular/core';
import { ServiceAPIService } from 'src/app/services/service-api.service';

@Component({
  selector: 'app-sidebar-scolarite',
  templateUrl: './sidebar-scolarite.component.html',
  styleUrls: ['./sidebar-scolarite.component.scss']
})
export class SidebarScolariteComponent implements OnInit {
  public parcours;
  reduit = false;
  public innerWidth: any;

  constructor(private api: ServiceAPIService) { }

  async ngOnInit(): Promise<void> {
    await this.GetNiveaux();
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 600) {
        if (!this.reduit) {
          this.reduit = true;
          $('#sidebar').addClass('active');

          $('.libelle').css('display', 'none');
          $('.sidebar-header').css('display', 'none');
        }
      }
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 600) {
        if (!this.reduit) {
          this.reduit = true;
          $('#sidebar').addClass('active');

          $('.libelle').css('display', 'none');
          $('.sidebar-header').css('display', 'none');
        }
      }
    }

  async GetNiveaux(){
  this.parcours = await this.api.getParcours().toPromise();
  }

  reduire() {
    this.reduit = !this.reduit;
    $('#sidebar').toggleClass('active');
    if (this.reduit) {
      $('.libelle').css('display', 'none');
      $('.sidebar-header').css('display', 'none');
    } else {
      $('.libelle').css('display', 'inline-block');
      $('.sidebar-header').css('display', 'block');
    }
  }
}
