<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 class="card-title" *ngIf="ajouter">Ajouter un type d'unité d'enseignement</h5>
      <h5 class="card-title" *ngIf="modifier">Modifier le type d'unité d'enseignement</h5>
    </div>
    <div class="card-body px-5">
      <div class="">
        <form [formGroup]="updateForm" (ngSubmit)="updateTypeue()">
          <input type="text" class="form-control" formControlName="id" hidden>
          <div class="form-group row">
            <label for="libelle" class="col-md-4">Libelle </label>
            <input type="text" id="libelle" class="form-control col-md-8" formControlName="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
            <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
              <div *ngIf="f.libelle.errors.required">Veuillez renseigner le type d'unité d'enseignement</div>
            </div>
          </div>
          <div class="form-group row">
            <label for="couleur" class="col-md-4">Couleur </label>
            <input type="color" id="couleur" value="#0000ff"  formControlName="couleur" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
            <div *ngIf="submitted && f.couleur.errors" class="invalid-feedback">
              <div *ngIf="f.couleur.errors.required">Veuillez selectionner la couleur</div>
            </div>
          </div>
          <button class="btn bg-forth text-clear mr-2 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
          <button class="btn btn-secondary mb-3" type="reset"><i class="fa fa-save mr-2"></i>Annuler</button>
        </form>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive">
        <table class="table table table-striped" style="width:100%"  datatable [dtOptions]="dtOptions" *ngIf="typeueList">
          <thead>
          <tr>
            <th>Catégorie</th>
            <th>Couleur</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let type of typeueList">
            <td>
              {{ type.libelle }}
            </td>
            <td>
              <div [ngStyle]="{'height': '20px', 'width': '120px', 'background-color' : type.couleur}"></div>
            </td>
            <td>
              <button class="btn btn-primary mr-2" href="#" (click)="updating(type)"><i class="fa fa-edit"></i></button>
              <button class="btn btn-danger" data-toggle="modal" data-target="#delete" (click)="loadDelete(type)"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal" id="delete" data-backdrop="false" role="dialog" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="typeToDelete"> {{typeToDelete.libelle}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteTypeue()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
