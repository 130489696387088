<div class="">
  <div class="">
    <div class="">

      <!-- Nav tabs -->
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs justify-content-center" role="tablist">
            <li class="nav-item  ">
              <a class="nav-link  active" data-toggle="tab" href="#l1" role="tab" (click)="active('L1')">
                L1
              </a>
            </li>
            <li class="nav-item ">
              <a class="nav-link" data-toggle="tab" href="#l2" role="tab" (click)="active('L2')">
                L2
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#l3" role="tab" (click)="active('L3')">
                L3
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#m1" role="tab" (click)="active('M1')">
                M1
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#m2" role="tab" (click)="active('M2')">
                M2
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body">

          <div class="">
            <div id="l1" role="tabpanel "
                 *ngIf="assignationMatiereL1Gb && emploisDuTempsL1Gb && tableTypeUe && actifL1">

              <app-emplois-du-temps *ngIf="fetchOk  " (updatedEmploisDuTemps)="updatedEmploisDuTemps($event)"
                                    [niveau]="attributNiveauL1"
                                    [parcours]="attributParcours"
                                    [assignationMatiere]="assignationMatiereL1Gb"
                                    [emploisDuTemps]="emploisDuTempsL1Gb"
                                    [tableTypeUe]="tableTypeUe"
                                    #emploisDuTempsL1

              ></app-emplois-du-temps>
            </div>
            <div id="l2" role="tabpanel">
              <div *ngIf="assignationMatiereL2Gb && emploisDuTempsL2Gb && tableTypeUe&& actifL2">

                <app-emplois-du-temps *ngIf="fetchOk" (updatedEmploisDuTemps)="updatedEmploisDuTemps($event)"
                                      [niveau]="attributNiveauL2"
                                      [parcours]="attributParcours"
                                      [assignationMatiere]="assignationMatiereL2Gb"
                                      [emploisDuTemps]="emploisDuTempsL2Gb"
                                      [tableTypeUe]="tableTypeUe"></app-emplois-du-temps>
              </div>
            </div>
            <div id="l3" role="tabpanel"
                 *ngIf="assignationMatiereL3Gb && emploisDuTempsL3Gb && tableTypeUe && actifL3">

              <app-emplois-du-temps *ngIf="fetchOk" (updatedEmploisDuTemps)="updatedEmploisDuTemps($event)"
                                    [niveau]="attributNiveauL3"
                                    [parcours]="attributParcours"
                                    [assignationMatiere]="assignationMatiereL3Gb"
                                    [emploisDuTemps]="emploisDuTempsL3Gb"
                                    [tableTypeUe]="tableTypeUe"></app-emplois-du-temps>
            </div>
            <div id="m1" role="tabpanel"
                 *ngIf="assignationMatiereM1Gb&& emploisDuTempsM1Gb && tableTypeUe && actifM1">

              <app-emplois-du-temps *ngIf="fetchOk" (updatedEmploisDuTemps)="updatedEmploisDuTemps($event)"
                                    [niveau]="attributNiveauM1"
                                    [parcours]="attributParcours"
                                    [assignationMatiere]="assignationMatiereM1Gb" [emploisDuTemps]="emploisDuTempsM1Gb"
                                    [tableTypeUe]="tableTypeUe"></app-emplois-du-temps>
            </div>
            <div id="m2" role="tabpanel"
                 *ngIf="assignationMatiereM2Gb && emploisDuTempsM2Gb && tableTypeUe && actifM2">

              <app-emplois-du-temps *ngIf="fetchOk" (updatedEmploisDuTemps)="updatedEmploisDuTemps($event)"
                                    [niveau]="attributNiveauM2"
                                    [parcours]="attributParcours"
                                    [assignationMatiere]="assignationMatiereM2Gb" [emploisDuTemps]="emploisDuTempsM1Gb"
                                    [tableTypeUe]="tableTypeUe"></app-emplois-du-temps>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10 ml-auto col-xl-6 mr-auto">

      <!-- End Tabs on plain Card -->
    </div>
  </div>
</div>
