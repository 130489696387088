import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {Coordonnees} from '../classes/coordonnees';
import {ServiceAPIService} from '../services/service-api.service';
import {TimelineElement} from '../classes/timeline-element';
import {ServiceAccueilService} from "../services/service-accueil/service-accueil.service";


@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit, AfterViewInit {
  public show = false;
  timeline: TimelineElement[];

  constructor(private serviceAPI: ServiceAccueilService) {}

  ngAfterViewInit(): void {
    this.getTimelineElements();
  }

  ngOnInit(): void{
    $(window).on('scroll', function() {
      if ($(window).scrollTop() > 100) {
        $('.back-to-top').addClass('active');
      } else {
        $('.back-to-top').removeClass('active');
      }
    });
    $('.back-to-top').on('click', function(){
      $(window).scrollTop(0);
    });
  }

  getTimelineElements() {
    const timelineobjet = [];
    this.serviceAPI.getHistoriqueENI().subscribe( data => {

      data.forEach((element, index) => {
        if (index === 0){
          element.selected = true;
        }
        const date = new Date(element.date);
        element.date = date;
        const photo = this.serviceAPI.APIURL + element.photo;
        element.photo = photo;
        timelineobjet.push(element);
      });
      this.timeline = timelineobjet;
      }

    );
  }
}
