<section id="historique" [ngStyle]="{'background-image': 'url('+ background +')'}">
  <div class="container-fluid">
    <div class="container aos-init aos-animate" data-aos="fade-up" style="min-height: 90vh;">
      <header class="section-header wow fadeInUp">
        <h3>HISTORIQUE
        </h3>
      </header>
      <section class="cd-horizontal-timeline" [ngClass]="{'loaded': loaded}">
        <div class="timeline" #timelineWidth>
          <div class="events-wrapper">
            <div class="events" #eventsWrapper [style.width.px]="eventsWrapperWidth">
              <ol>
                <li *ngFor="let item of timelineElements; let index = index">
                  <a #timelineEvents href="#"
                     [ngClass]="{'selected': item.selected, 'older-event': index < selectedIndex}"
                     (click)="onEventClick($event, item)">{{item.date | date: 'longDate'}}</a>
                  <span></span>
                </li>
              </ol>
              <span class="filling-line" aria-hidden="true" #fillingLine></span>
            </div>
          </div>
          <ul class="cd-timeline-navigation">
            <li>
              <a href="#" (click)="onScrollClick($event, false)" class="prev"
                 [ngClass]="{'inactive':prevLinkInactive}">Prev</a>
            </li>
            <li>
              <a href="#" (click)="onScrollClick($event, true)" class="next"
                 [ngClass]="{'inactive':nextLinkInactive}">Next</a>
            </li>
          </ul>
        </div>
        <div class="events-content" *ngIf="showContent" data-toggle="modal" data-target="#showPhoto">
          <ol>
            <li *ngFor="let item of timelineElements; let index = index"
                [@contentState]="item.selected ? 'active' : (index < selectedIndex ? 'left' : 'right')" class="font-roboto pointeur">
              <h2 class="font-roboto">{{item.titre}}</h2>
              <em class="font-roboto">{{item.date | date: 'longDate'}}</em>
              <p [innerHTML]="item.contenu" class="font-roboto"></p>
            </li>
          </ol>
        </div>
      </section>
    </div>
  </div>
</section>


<!-- Modal -->
<div class="modal animate__animated animate__zoomIn" id="showPhoto" role="dialog" data-backdrop="false" tabindex="-1" aria-labelledby="showPhotoLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" [ngStyle]="{'background-size': 'cover', 'background-position': 'center', 'background-repeat': 'no-repeat','background-image':  'url('+ photoToShow +')'}">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>
