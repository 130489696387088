import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { TimelineElement } from 'src/app/classes/timeline-element';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import { Article } from '../../../classes/article';
import * as $ from 'jquery';
import { Router } from "@angular/router";
import DevExpress from "devextreme";
import data = DevExpress.data;
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent implements OnInit {
  @ViewChild('exampleModal') exampleModal: any;
  aproposENI: Article[] = [];
  aproposToShow: Article;
  photo: string;
  activeCard = false;
  bannerImage = '';
  showEnseignant = false;
  show = false;
  organigramme = false;
  enseignant = false;
  isBox = true;

  APIimage = this.serviceAPI.APIURL;

  constructor(private serviceAPI: ServiceAccueilService, private router: Router) {
    /*this.show = true;
    if (this.show) {
      this.showApropos();
    }*/
  }

  async ngOnInit(): Promise<void> {
    await this.getApropos();
    (function ($) {
      "use strict";
      var fullHeight = function () {

        $('.js-fullheight').css('height', $(window).height());
        $(window).resize(function () {
          $('.js-fullheight').css('height', $(window).height());
        });
      };
      fullHeight();
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
        $('.wrapper').toggleClass('large');
      });
    })(jQuery);
  }

  async getApropos() {
    // tslint:disable-next-line:no-shadowed-variable
    const data = await this.serviceAPI.getApropos().toPromise();
    // data.forEach(art => {
    //   if (art.typearticle.libelle === 'A propos de l\'Ecole' && art.afficher === true) {
    //     this.photo = this.serviceAPI.APIURL + art.photo;
    //     art.photo = this.photo;
    //     this.aproposENI.push(art);
    //   }
    // });
    this.aproposENI = data;
    this.bannerImage = this.aproposENI[0].photo;
    this.aproposToShow = this.aproposENI[0];
  }

  activer(id: number, apropos: Article) {
    this.showEnseignant = false;
    $('li').removeClass('active');

    const i = id.toString();
    const element = document.getElementById(i);
    element.classList.add('active');

    this.bannerImage = apropos.photo;
    this.aproposToShow = apropos;
    this.organigramme = false;
    this.isBox = true;
    //$('.box-content').addClass('box-shadow p-5');
  }

  gotoEnseignant() {
    $('li').removeClass('active');
    //$('.box-content').removeClass('box-shadow p-5');
    const element = document.getElementById('enseignant');
    element.classList.add('active');
    this.showEnseignant = true;
    this.organigramme = false;
    this.isBox = false;
  }

  goToOrganigramme() {
    $('li').removeClass('active');
    const element = document.getElementById('organigramme');
    element.classList.add('active');
    this.organigramme = true;
    this.showEnseignant = false;
    this.isBox = false;
  }
}
