<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 class="card-title" *ngIf="ajouter">Ajouter une Formation</h5>
      <h5 class="card-title" *ngIf="modifier">Modifier une Formation</h5>
    </div>
    <div class="card-body">
    </div><div class="row">
    <div class="col-lg-4 col-md-6 col-sm-10 mx-auto py-4 mb-5" style="min-height: 15vh">
      <div id="image" [ngStyle]="{'max-width': '100%' , 'height': '100%', 'top': '0px', 'background-size': 'cover', 'background-position': 'center', 'background-image': image }"></div>
      <div class="mb-3" *ngIf="ajouter">
        <label for="photo">Photo :</label>
        <div>
          <div class="mb-3">
            <input type="file" id="photo" (change)="onFileSelect($event)" style="width: 100%">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-md-6 col-sm-10 mx-auto">
      <div class="card-body">
        <form [formGroup]="updateForm" (ngSubmit)="update()">
          <input type="input" class="form-control" id="id" formControlName="id" hidden>
          <div class="mb-3 row">
            <label for="sigle" class="col-lg-2 col-md-10 col-form-label">Sigle </label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="sigle" formControlName="sigle" [ngClass]="{ 'is-invalid': submitted && f.sigle.errors }">
              <div *ngIf="submitted && f.sigle.errors" class="invalid-feedback">
                <div *ngIf="f.sigle.errors.required">Veuillez renseigner la sigle</div>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="libelle" class="col-lg-2 col-md-10 col-form-label">Libelle </label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="libelle" formControlName="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
              <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
                <div *ngIf="f.libelle.errors.required">Veuillez renseigner le libelle</div>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="resume" class="col-lg-2 col-md-10 col-form-label">Premiere Base </label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="resume" formControlName="resume" [ngClass]="{ 'is-invalid': submitted && f.resume.errors }">
              <div *ngIf="submitted && f.resume.errors" class="invalid-feedback">
                <div *ngIf="f.resume.errors.required">Veuillez renseigner un resume</div>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="explication" class="col-lg-2 col-md-10 col-form-label">Explication</label>
            <div class="col-sm-10">
              <textarea  type="text" class="form-control" id="explication" formControlName="explication" [ngClass]="{ 'is-invalid': submitted && f.explication.errors }"></textarea>
              <div *ngIf="submitted && f.explication.errors" class="invalid-feedback">
                <div *ngIf="f.explication.errors.required">Veuillez renseigner le titre</div>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="admission" class="col-lg-2 col-md-10 col-form-label">Admission</label>
            <div class="col-sm-10">
              <ckeditor [(ngModel)]="mycontent"  [config]="ckeConfig"   id="admission" formControlName="admission" [ngClass]="{ 'is-invalid': submitted && f.admission.errors }"></ckeditor>
              <div *ngIf="submitted && f.admission.errors" class="invalid-feedback">
                <div *ngIf="f.admission.errors.required">Veuillez renseigner les conditions d'admission</div>
              </div>
            </div>
          </div>
          <div class="mb-3 row ">
            <label for="carriere_niveau"class="col-lg-2 col-md-10 col-form-label">Carrieres</label>
            <div class="col-sm-10">
              <ckeditor  [(ngModel)]="mycontent"  [config]="ckeConfig"  id="carriere_niveau" data="<p>Hello, world!</p>"  formControlName="carriere_niveau" [ngClass]="{ 'is-invalid': submitted && f.carriere_niveau.errors }"></ckeditor>
              <div *ngIf="submitted && f.carriere_niveau.errors" class="invalid-feedback">
                <div *ngIf="f.carriere_niveau.errors.required">Veuillez renseigner les carrieres</div>
              </div>
            </div>
          </div>
          <button class="btn bg-forth text-clear mr-3 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
          <button class="btn btn-secondary mb-3" type="reset" (click)="reset"><i class="fa fa-save mr-2"></i>Annuler</button>
        </form>
      </div>
    </div>
  </div>
  </div>


  <div class="container mt-5">
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive">
        <table id="example"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="FormationList">
          <thead>
          <tr>
            <th>Sigle</th>
            <th>Explication</th>
            <th>Admission</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let formation of FormationList">
            <td>{{ formation.sigle }}</td>
            <td>{{ formation.explication}} </td>
            <td>{{ formation.admission}} </td>
            <td>
              <button class="btn btn-primary mr-2" href="#" (click)="updating(formation)"><i class="fa fa-edit"></i></button>
              <button class="btn btn-danger"  data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(formation)"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  <!-- Modal -->
  <div class="modal fade" id="delete" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteLabel">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Voulez vous vraiment supprimer<span *ngIf="FormationToDelete"> {{FormationToDelete.libelle}}</span> ?
          Cette action est irreverssible.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
