import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Club} from '../../../../classes/club';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Utilisateur} from '../../../../classes/utilisateur';
import { ThrowStmt } from '@angular/compiler';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss']
})
export class ClubComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  clubList: Array<Club>;
  utilisateurList: Array<Utilisateur>;
  clubToDelete: Club;

  ajouter = true;
  modifier = false;

  APIURL = this.serviceAPI.APIURL;
  image = 'url(assets/logoframe.png)';
  updateForm: FormGroup;

  Enregistrer = 'Ajouter';

  submitted = false;
  mycontent: string;
  mycontent2: string;
  ckeConfig: any;
  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms']},
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About'
    };
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getClub();
    this.getUtilisateurs();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
  }

  initForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      libelle: ['', Validators.required],
      apropos: ['', Validators.required],
      historique: ['', Validators.required],
      missions: ['', Validators.required],
      utilisateur: ['', Validators.required],
      photo: ['', Validators.required]
    });
  }

  async getClub() {
    this.clubList = await this.serviceAPI.getClub().toPromise();
  }

  get f() {
    return this.updateForm.controls;
  }

  getUtilisateurs() {
    this.serviceAPI.getUtilisateur().subscribe( data => this.utilisateurList = data);
  }

  update() {
    if (this.ajouter) {
      this.submitted = true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formData = new FormData();
        formData.append('libelle', this.updateForm.value.libelle);
        formData.append('apropos', this.updateForm.value.apropos);
        formData.append('historique', this.updateForm.value.historique);
        formData.append('missions', this.updateForm.value.missions);
        formData.append('utilisateur', this.updateForm.value.utilisateur);
        formData.append('photo', this.updateForm.value.photo);

        this.serviceAPI.postClub(formData).subscribe(data => {
          this.NotificationShowSucces();
          this.getClub();
          this.image = 'url(assets/logoframe.png)';
          this.ajouter = true;
          this.modifier = false;
          window.location.reload();
        });
        this.reset();
      }
    }
    if (this.modifier) {
      this.submitted = true;
      this.Enregistrer = 'Modifier';
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const newclub = new Club();
        newclub.libelle = this.updateForm.value.libelle;
        newclub.apropos = this.updateForm.value.apropos;
        newclub.historique = this.updateForm.value.historique;
        newclub.missions = this.updateForm.value.missions;
        newclub.utilisateur = this.updateForm.value.utilisateur;
        newclub.id = this.updateForm.value.id;
        this.serviceAPI.updateClub(newclub).subscribe( data => {
          this.NotificationShowSucces();
          this.getClub();
          this.ajouter = true;
          this.modifier = false;
          window.location.reload();
        } );
      }
    }
  }

  updating(club: Club) {
    window.scroll(0,0);
    this.ajouter = false;
    this.modifier = true;
    this.updateForm = this.formBuilder.group({
      id: [club.id, Validators.required],
      libelle: [club.libelle, Validators.required],
      apropos: [club.apropos, Validators.required],
      historique: [club.historique, Validators.required],
      missions: [club.missions, Validators.required],
      utilisateur: [club.utilisateur.id, Validators.required],
      photo: ['']
    });
    this.image = 'url(' + this.APIURL + club.photo + ')';
    this.Enregistrer = 'Modifier';
  }

  // tslint:disable-next-line:typedef
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm.get('photo').setValue(file);
    }
  }


  loadDeleteConfirm(club: Club) {
    this.clubToDelete = club;
  }

  delete(){
    this.serviceAPI.deleteClub(this.clubToDelete).subscribe( () => {
      this.serviceAPI.deleteModerateur(this.clubToDelete.utilisateur).subscribe(() => {
        this.NotificationShowSucces();
      });
      window.location.reload();
    });
  }

  reset() {
    this.image = 'url(assets/logoframe.png)';
    this.submitted = false;
    this.updateForm.reset();
    this.Enregistrer = 'Ajouter';
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',
    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',
    });
  }

}
