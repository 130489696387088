import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-accueil-etudiant',
  templateUrl: './accueil-etudiant.component.html',
  styleUrls: ['./accueil-etudiant.component.scss'],

})
export class AccueilEtudiantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
