import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Utilisateur} from '../../classes/utilisateur';
import {ServiceAPIService} from '../../services/service-api.service';
import {Album} from '../../classes/album';
import {Galerie} from '../../classes/galerie';
import {ServiceEtudiantService} from '../../services/service-espace-etudiant/service-etudiant.service';

@Component({
  selector: 'app-galerie-etudiant',
  templateUrl: './galerie-etudiant.component.html',
  styleUrls: ['./galerie-etudiant.component.scss']
})
export class GalerieEtudiantComponent implements OnInit {
  utilisateurConnecte: any;

  canPostPhoto = false;

  APIURL: string;
  APIimage = this.serviceAPI.APIURL;
  image: string;
  formGalerie: FormGroup;
  Media: any;
  galeries: string;
  public imagetoshow;
  myFiles: string[] = [];

  albumList: Array<Album>;
  albumToDelete: Album;
  albumActive: Album;
  galerieActive: Galerie[];
  galerieToDelete: Galerie;

  public url: any;
  public photos: any;
  public photo;

  albumForm: FormGroup;

  deleteAnAlbum = false;

  constructor(public fb: FormBuilder, private serviceAPI: ServiceAPIService, private serviceEtudiantAPI: ServiceEtudiantService) {
    this.formGalerie = this.fb.group({
      libelle: ['', Validators.required],
      // album: ['', Validators.required],
      photoToUpload: ['', Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getUtilisateurConnecte();
    this.getGalerie();
    this.getAlbum();
  }

  initForm() {
    this.albumForm = this.fb.group({
      libelle: ['', Validators.required]
    });
  }

  addAlbum() {
    const value = this.albumForm.value;
    const album = new Album();
    album.libelle = value.libelle;
    this.serviceAPI.postAlbum(album).subscribe( data => {
      this.getAlbum();
      this.albumForm.reset();
    });
  }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.serviceEtudiantAPI.getUtilisateurConnecte().toPromise();
    this.utilisateurConnecte.profile.forEach( profile => {
      if (profile.libelle === 'Moderateur club' || profile.libelle === 'Delegue') {
        this.canPostPhoto = true;
      }
    });
  }

  onFileSelect(event, input: any) {
    if (event.target.files.length > 0) {
      const files = [].slice.call(event.target.files);
      input.value = files.map(f => f.name).join(', ');
      for (let i = 0; i < event.target.files.length; i++) {
        this.myFiles.push(event.target.files[i]);
      }
    }
  }

  postGalerie(){
    const formData = new FormData();
    formData.append('libelle', this.formGalerie.get('libelle').value);
    formData.append('album', (this.albumActive.id).toString());
    formData.append('nomAlbum', this.albumActive.libelle);
    for (let i = 0; i < this.myFiles.length; i++) {
      formData.append('photo[]', this.myFiles[i]);
    }
    this.serviceAPI.postGalerie(formData).subscribe(data => {
      this.url = data.chemin;
      this.formGalerie.reset();
      window.location.reload();
    });
  }

  getGalerie(){
    this.serviceAPI.getGalerie().subscribe(data => {
      this.photos = data;
    });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imagetoshow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getAlbum(){
    this.serviceAPI.getAlbum().subscribe( data => {
      this.albumList = data;
      this.albumList.forEach( album => {
      });
    });
  }

  loadDeleteAlbumConfirm(album: Album) {
    this.albumToDelete = album;
    this.deleteAnAlbum = true;
  }

  deleteAlbum(){
    this.serviceAPI.deleteAlbum(this.albumToDelete).subscribe( () => {
      this.getAlbum();
      this.deleteAnAlbum = false;
      window.location.reload();
    });
  }

  async openGallery(album: Album) {
    this.closeAll();
    this.albumActive = album;
    this.albumToDelete = album;
    this.galerieActive = album.galeries;
    if (this.galerieActive.length > 0) {
      this.image = 'url(' + this.APIimage + this.galerieActive[0].photo + ')';
      this.galerieToDelete = this.galerieActive[0];
    }
  }

  closeAll() {
    this.albumActive = null;
    this.galerieActive = [];
    this.image = '';
    const galleryActv = document.querySelector('.Gallery--active');
    const cardActv = document.querySelector('.Card--active');
    if (galleryActv) {
      galleryActv.classList.remove('Gallery--active');
    }
    if (cardActv) {
      cardActv.classList.remove('Card--active');
    }
  }

  selectImage(photo: Galerie) {
    $('.Gallery--active').scrollTop(0);
    var newimage = new Galerie();
    newimage.id = photo.id;
    newimage.photo = photo.photo;
    newimage.libelle = photo.photo;
    this.galerieToDelete = newimage;
    this.image = 'url(' + this.APIimage + photo.photo + ')';
  }

  deleteImage() {
    this.serviceAPI.deleteGalerie(this.galerieToDelete).subscribe( () => window.location.reload() );
  }

}
