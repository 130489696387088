import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Enseignant} from "../../classes/enseignant";
import {catchError} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Article} from "../../classes/article";
import {Club} from "../../classes/club";
import {Formation} from "../../classes/formation";
import {Counter} from "../../classes/counter";
import {VieEtudiant} from "../../classes/vie-etudiant";
import {TimelineElement} from "../../classes/timeline-element";
import {Coordonnees} from "../../classes/coordonnees";
import {Organigramme} from "../../classes/organigramme";
import {Partenaire} from '../../classes/partenaire';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccueilService {
  APIURL = '/api/';
  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    Authorization: '@3kpSs+7Q5n6t-RH5ai4M@=Qp83c89nAw8nVn<3tP9#Tmg23-Q?*t539/fn8MDqh<e9Cx$55R@65Rh2C3%p.q8Y8V&53h_Sxr'
  });
  constructor(private http: HttpClient) { }

  getMotduDirecteur(): Observable<Article>{
    return this.http.get<Article>(this.APIURL + 'MotDuDirecteur', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getDiscours(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'Discours', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getActualite(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'Actualite?limit=100000&sort=nom%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getOtherActus(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'OtherActus?limit=100000&sort=nom%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getLastActualite(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'LastActualite?limit=1', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getHeader(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'Header?limit=100000&sort=nom%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getEvenement(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'Evenement?limit=100000&sort=nom%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getClubAccueil(): Observable<Club[]>{
    return this.http.get<Club[]>(this.APIURL + 'ClubAccueil?limit=100000&sort=nom%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getApropos(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'Apropos?limit=100000&sort=titre%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getEnseignant(): Observable<Enseignant[]>{
  return this.http.get<Enseignant[]>(this.APIURL + 'Enseignant?limit=100000&sort=titre%20ASC', {headers: this.headers}).pipe(
  catchError((err) => {
      throw err;
    }
  ));
}
  getFormation(): Observable<Formation[]> {
    return this.http.get<Formation[]>(this.APIURL + 'formation?limit=10&sort=libelle%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getCounter(): Observable<Counter[]> {
    return this.http.get<Counter[]>(this.APIURL + 'counter', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getPhoto(): Observable<VieEtudiant[]> {
    return this.http.get<VieEtudiant[]>(this.APIURL + 'vieEtudiant?limit=100000&sort=id%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getHistoriqueENI(): Observable<TimelineElement[]> {
    return this.http.get<TimelineElement[]>(this.APIURL + 'historique?sort=date%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getCoordonnees(): Observable<Coordonnees[]> {
    return this.http.get<Coordonnees[]>(this.APIURL + 'coordonnees', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getOrganigramme(): Observable<Organigramme[]> {
    return this.http.get<Organigramme[]>(this.APIURL + 'organigramme-accueil', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
  getPartenaires(): Observable<Partenaire[]> {
    return this.http.get<Partenaire[]>(this.APIURL + 'partenaire?limit=1000&sort=id%20ASC', {headers: this.headers}).pipe(
      catchError((err) => {
          throw err;
        }
      ));
  }
}

