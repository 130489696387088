import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Utilisateur } from 'src/app/classes/utilisateur';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Profile} from '../../../../classes/profile';
import {Parcours} from '../../../../classes/parcours';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss']
})
export class UtilisateurComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private readonly notifier: NotifierService;

  innactiveList: Array<Utilisateur>;
  activeList: Array<Utilisateur>;
  utilisateurToEdit: Utilisateur;
  adminList: Array<Utilisateur>;
  scolariteList: Array<Utilisateur>;
  parcoursList: Array<Parcours>;
  adminToDelete: Utilisateur;
  scolariteToDelete: Utilisateur;
  submitted = false;
  submitted2 = false;

  ajouter = true;
  modifier = false;

  ajouter2 = true;
  modifier2 = false;

  updateForm: FormGroup;
  updateForm2: FormGroup;

  Enregistrer = 'Ajouter';
  Enregistrer2 = 'Ajouter';

  image = 'url(assets/img/utilisateur.png)';
  image2 = 'url(assets/img/utilisateur.png)';
  APIURL = this.serviceAPI.APIURL;

  profileAdmin: Profile;
  profileScolarite: Profile;

  constructor(private serviceAPI: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.initForm2();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
    this.getParcours();
    await this.getUtilisateur();
    await this.getAdmin();
    await this.getScolarite();
    await this.getAdminProfile();
    await this.getScolariteProfile();
  }

  async getAdmin(){
    let admins: Array<Utilisateur> = [];
    let data = await this.serviceAPI.getUtilisateur().toPromise();
    data.forEach( admin => {
      admin.profiles.forEach(profile => {
        if (profile.libelle === 'Administrateur') {
          admins.push(admin);
        }
      });
    });
    this.adminList = admins;
  }

  async getScolarite(){
    const scolarites: Array<Utilisateur> = [];
    const data = await this.serviceAPI.getUtilisateur().toPromise();
    data.forEach( admin => {
      admin.profiles.forEach(profile => {
        if (profile.libelle === 'Scolarite') {
          scolarites.push(admin);
        }
      });
    });
    this.scolariteList = scolarites;
  }

  async getUtilisateur(){
    const utilisateurList = await this.serviceAPI.getUtilisateur().toPromise();
    this.innactiveList = utilisateurList.filter( utilisateur => utilisateur.active === false);
    this.activeList = utilisateurList.filter( utilisateur => utilisateur.active === true);
  }

  async getAdminProfile() {
    const p = await this.serviceAPI.getProfileByLibelle('Administrateur').toPromise();
    this.profileAdmin = p[0];
  }

  get f() {
    return this.updateForm.controls;
  }

  getParcours() {
    this.serviceAPI.getParcours().subscribe( data => {
      this.parcoursList = data;
    });
  }

  activer() {
    if (this.utilisateurToEdit.active === true) {
      const newUSer = new Utilisateur();
      newUSer.id = this.utilisateurToEdit.id;
      newUSer.active = false;
      this.serviceAPI.updateUtilisateur(newUSer).subscribe( () => {
        this.NotificationShowSucces();
        window.location.reload();
      });
    } else if (this.utilisateurToEdit.active === false) {
      const newUSer = new Utilisateur();
      newUSer.id = this.utilisateurToEdit.id;
      newUSer.active = true;
      newUSer.tentative = 5;
      this.serviceAPI.updateUtilisateur(newUSer).subscribe( () => {
        this.NotificationShowSucces();
        window.location.reload();
      });
    }
  }

  loadActivation(utilisateur: Utilisateur) {
    this.utilisateurToEdit = utilisateur;
  }

  update() {
    this.submitted = true;
    if (this.ajouter){
      alert('ajouter')
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formData = new FormData();
        formData.append('numero_matricule', this.updateForm.value.numero_matricule);
        formData.append('nom', this.updateForm.value.nom);
        formData.append('prenom', this.updateForm.value.prenom);
        formData.append('adresse_mail', this.updateForm.value.adresse_mail);
        formData.append('mot_de_passe', this.updateForm.value.numero_matricule);
        formData.append('parcours', this.updateForm.value.parcours);
        formData.append('profiles', (this.profileAdmin.id).toString() );
        formData.append('biographie', 'Biographie');
        formData.append('photo', this.updateForm.value.photo);
        this.image = '';
        this.serviceAPI.postUtilisateur(formData).subscribe( data => {
          if (data.mailExist === true) {
            this.NotificationShowMailExist();
          }
          if (data.numMatriculeExist === true) {
            this.NotificationShowMatriculeExist();
          } else {
            this.NotificationShowSucces();
            this.updateForm.reset();
            this.updateForm.get('photo').setValue('');
            this.submitted = false;
            window.location.reload();
          }
        });
      }
    }
    if (this.modifier){
      this.submitted = true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const utilisateur = new Utilisateur();
        utilisateur.id = this.updateForm.value.id;
        utilisateur.nom = this.updateForm.value.nom;
        utilisateur.prenom = this.updateForm.value.prenom;
        utilisateur.adresse_mail = this.updateForm.value.adresse_mail;
        utilisateur.parcours = this.updateForm.value.parcours;
        this.serviceAPI.chercherMail(this.updateForm.value.adresse_mail).subscribe( data => {
          if (data.length === 0 || data[0].id === utilisateur.id)
          {
            this.serviceAPI.updateUtilisateur(utilisateur).subscribe(data => {

              this.NotificationShowSucces();
              this.updateForm.reset();
              this.ajouter = true;
              this.modifier = false;
              this.submitted = false;
              window.location.reload();

            });
          }
          else {
            this.NotificationShowMailExist();
          }
        });
      }
    }
  }

  initForm(){
    this.updateForm = this.formBuilder.group({
      id: [''],
      numero_matricule: ['', Validators.required],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      adresse_mail: new FormControl('', Validators.required),
      parcours: new FormControl('', Validators.required),
      photo: new FormControl('', Validators.required)
    });
  }

  updating(admin: Utilisateur) {
    this.modifier = true;
    this.ajouter = false;
    this.updateForm = this.formBuilder.group({
      id: [admin.id, Validators.required],
      numero_matricule: [admin.numero_matricule, Validators.required],
      nom: [admin.nom, Validators.required],
      prenom: [admin.prenom, Validators.required],
      adresse_mail : [admin.adresse_mail, Validators.required],
      parcours : [admin.parcours.id, Validators.required],
      photo: ['']
    });
    this.image = 'url(' + this.APIURL + admin.photo + ')';
    this.Enregistrer = 'Modifier';
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm.get('photo').setValue(file);
    }
  }

  loadDeleteConfirm(admin: Utilisateur) {
    this.adminToDelete = admin;
  }

  delete(){
    this.serviceAPI.deleteUtilisateur(this.adminToDelete).subscribe( () => {
      this.getAdmin();
      this.NotificationShowSucces();
      window.location.reload();
    });
  }

  reset(){
    this.image = 'url(assets/img/utilisateur.png)';
    this.updateForm.reset();
    this.updateForm2.reset();
    this.submitted = false;
    this.Enregistrer = 'Ajouter';
    this.submitted2 = false;
    this.Enregistrer2 = 'Ajouter';
  }

  async getScolariteProfile() {
    const p = await this.serviceAPI.getProfileByLibelle('Scolarite').toPromise();
    this.profileScolarite = p[0];
  }

  get f2() {
    return this.updateForm2.controls;
  }

  updateScolarite() {
    this.submitted2 = true;
    if (this.ajouter2){
      if (this.updateForm2.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formData = new FormData();
        formData.append('numero_matricule', this.updateForm2.value.numero_matricule);
        formData.append('nom', this.updateForm2.value.nom);
        formData.append('prenom', this.updateForm2.value.prenom);
        formData.append('adresse_mail', this.updateForm2.value.adresse_mail);
        formData.append('mot_de_passe', this.updateForm2.value.numero_matricule);
        formData.append('profiles', (this.profileScolarite.id).toString() );
        formData.append('biographie', 'Biographie');
        formData.append('photo', this.updateForm2.value.photo);
        this.image2 = '';
        this.serviceAPI.postUtilisateur(formData).subscribe( data => {
          if (data.mailExist === true) {
            this.NotificationShowMailExist();
          }
          if (data.numMatriculeExist === true) {
            this.NotificationShowMatriculeExist();
          }
          this.NotificationShowSucces();
          this.updateForm2.reset();
          this.updateForm2.get('photo').setValue('');
          this.submitted2 = false;
          window.location.reload();
        });
      }
    }
    if (this.modifier2){
      this.submitted2 = true;
      if (this.updateForm2.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const utilisateur = new Utilisateur();
        utilisateur.id = this.updateForm2.value.id;
        utilisateur.nom = this.updateForm2.value.nom;
        utilisateur.prenom = this.updateForm2.value.prenom;
        utilisateur.adresse_mail = this.updateForm2.value.adresse_mail;

        this.serviceAPI.chercherMail(this.updateForm.value.adresse_mail).subscribe( data => {
          if (data.length === 0 || data[0].id === utilisateur.id) {
            this.serviceAPI.updateUtilisateur(utilisateur).subscribe( data => {
              this.NotificationShowSucces();
              this.updateForm2.reset();
              this.ajouter2 = true;
              this.modifier2 = false;
              this.submitted2 = false;
              window.location.reload();
            } );
          } else {
            this.NotificationShowMailExist();
          }
        });
      }
    }
  }

  initForm2(){
    this.updateForm2 = this.formBuilder.group({
      id: [''],
      numero_matricule: ['', Validators.required],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      adresse_mail: new FormControl('', Validators.required),
      photo: new FormControl('', Validators.required)
    });
  }

  updating2(scolarite: Utilisateur) {
    this.modifier2 = true;
    this.ajouter2 = false;
    this.updateForm2 = this.formBuilder.group({
      id: [scolarite.id, Validators.required],
      numero_matricule: [scolarite.numero_matricule, Validators.required],
      nom: [scolarite.nom, Validators.required],
      prenom: [scolarite.prenom, Validators.required],
      adresse_mail : [scolarite.adresse_mail, Validators.required],
      photo: ['']
    });
    this.image2 = 'url(' + this.APIURL + scolarite.photo + ')';
    this.Enregistrer2 = 'Modifier';
  }


  onFileSelect2(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        // @ts-ignore
        this.image2 = 'url(' + reader.result.toString() + ')';
      };
      reader.readAsDataURL(file);
      // @ts-ignore
      this.updateForm2.get('photo').setValue(file);
    }
  }

  loadDeleteConfirm2(scolarite: Utilisateur) {
    this.scolariteToDelete = scolarite;
  }

  delete2(){
    this.serviceAPI.deleteUtilisateur(this.scolariteToDelete).subscribe( () => {
      this.getScolarite();
      this.NotificationShowSucces();
      window.location.reload();
    });
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',
    });
  }

  NotificationShowMailExist(){
    this.notifier.show({
      type: 'warning',
      message: 'L\'adresse mail existe déjà',
    });
  }

  NotificationShowMatriculeExist(){
    this.notifier.show({
      type: 'warning',
      message: 'Le numero matricule existe déjà',
    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',
    });
  }
}
