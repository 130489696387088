import { Component, OnInit } from '@angular/core';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {Niveau} from '../../../../classes/niveau';

@Component({
  selector: 'app-niveau',
  templateUrl: './niveau.component.html',
  styleUrls: ['./niveau.component.scss']
})
export class NiveauComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  niveauList: Array<Niveau>;

  constructor(private serviceAPI: ServiceAPIService) { }

  async ngOnInit(): Promise<void> {
    await this.getNiveaux();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
  }

  async getNiveaux() {
    this.niveauList = await this.serviceAPI.getNiveau().toPromise();
  }
}
