import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../classes/utilisateur';
import {ServiceEnseignantService} from '../../services/service-espace-enseignant/service-enseignant.service';

@Component({
  selector: 'app-accueil-enseignant',
  templateUrl: './accueil-enseignant.component.html',
  styleUrls: ['./accueil-enseignant.component.scss']
})
export class AccueilEnseignantComponent implements OnInit {
  utilisateurConnecte: Utilisateur;
  barreNiveau: number;
  imageUtilisateur: string;

  constructor(private serviceAPI: ServiceEnseignantService) { }

  async ngOnInit(): Promise<void>  {
    await this.getUtilisateurConnecte();

  }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.serviceAPI.getUtilisateurConnecte().toPromise();
    let image = this.serviceAPI.APIURL + this.utilisateurConnecte.photo;
    this.utilisateurConnecte.photo = image;
  }


}
