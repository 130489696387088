<h3>Liste des modérateurs</h3>
<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5>Ajouter un délégué</h5>
    </div>
  <div class="card-body">
    <h6 class="card-subtitle mb-2 text-muted">Modérateurs en tant que delegue</h6>
    <p *ngIf="etudiantDelegueList.length === 0 ">Toutes les classes ont des délégués.</p>
    <form *ngIf="etudiantDelegueList.length>0" [formGroup]="addModerateurNiveauForm" (ngSubmit)="addModerateurNiveau()" class="mb-3">
      <div class="mb-3 row">
        <label for="etudiantM" class="col-lg-3 col-md-10 col-form-label">Etudiant</label>
        <div class="col-sm-9">
          <div class="ng-autocomplete">
            <ng-autocomplete formControlName="utilisateurD"
                             [data]="etudiantDelegueList"
                             [searchKeyword]="keyword"
                             placeholder="Tapez le nom"
                             historyIdentifier="etudiantDelegueList"
                             [itemTemplate]="itemTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.nom + ' ' + item.prenom"></a>
            </ng-template>
          </div>
        </div>

      </div>
      <div class="ml-auto">
        <button class="btn btn-primary mr-2 mb-3" type="submit">Enregistrer</button>
        <button class="btn btn-outline-primary mr-2 mb-3" type="reset">Annuler</button>
      </div>
    </form>
  </div>
</div>
<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="moderateurList.length">
        <thead>
        <tr>
          <th>Numéro matricule</th>
          <th>Nom et prénoms</th>
          <th>Profile</th>
          <th>Niveau</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let moderateur of moderateurList">
          <td>
            <p>{{ moderateur.numero_matricule }}</p>
          </td>
          <td>
            <p>{{ moderateur.nom }} {{ moderateur.prenom }}</p>
          </td>
          <td>
            <p *ngFor="let profile of moderateur.profiles"><span *ngIf="profile.libelle != 'Etudiant'">{{profile.libelle}}  </span></p>
          </td>
          <td>
            <p><span  *ngIf="moderateur.niveau">{{ moderateur.niveau.libelle }}</span> <span *ngIf="moderateur.parcours" class="text-uppercase"> {{ moderateur.parcours.routerlink }}</span></p>
          </td>
          <td>
            <button class="btn btn-danger" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(moderateur)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="delete" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="moderateurToDelete"> {{moderateurToDelete.nom}} {{moderateurToDelete.prenom}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deletingModerateur()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
</div>
<notifier-container></notifier-container>
