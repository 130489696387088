<div class="container mb-3">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5 *ngIf="ajouter">Ajouter une institution</h5>
      <h5 *ngIf="modifier">Modifier l'institution</h5>
    </div>
    <div class="card-bodypx-4">
        <div class="card-body">
          <form [formGroup]="updateForm" (ngSubmit)="update()" *ngIf="organigrammeList">
            <input type="input" class="form-control" formControlName="id" hidden>
            <div class="mb-3 row">
              <label class="col-md-3 col-sm-12 col-form-label">Institution</label>
              <div class="col-md-9 col-sm-12">
                <input type="input" class="form-control" formControlName="institution" [ngClass]="{ 'is-invalid': submitted && f.institution.errors }">
                <div *ngIf="submitted && f.institution.errors" class="invalid-feedback">
                  <div *ngIf="f.institution.errors.required">Veuillez renseigner l'institution</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-md-3 col-sm-12 col-form-label">Détails</label>
              <div class="col-md-9 col-sm-12">
                <input type="input" class="form-control" formControlName="details" [ngClass]="{ 'is-invalid': submitted && f.details.errors }">
                <div *ngIf="submitted && f.details.errors" class="invalid-feedback">
                  <div *ngIf="f.details.errors.required">Veuillez renseigner le détails</div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="parent" class="col-md-3 col-sm-12 col-form-label">Parent</label>
              <div class="col-md-9 col-sm-12">
                <select class="form-control" id="parent"  formControlName="parent">
                  <option *ngFor="let org of organigrammeList" [ngValue]="org.id">{{org.institution}}</option>
                </select>
              </div>
            </div>
            <button class="btn btn-primary mr-5 mb-1" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
            <button class="btn btn-secondary" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>
          </form>
        </div>
    </div>
  </div>
</div>

<h3>Contenu de l'organigramme</h3>
<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="example"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="organigrammeList">
        <thead>
        <tr>
          <th>Institution</th>
          <th>Détails</th>
          <th>Parent</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let org of organigrammeList">
          <td>{{ org.institution }}</td>
          <td>{{ org.details }}</td>
          <td><span *ngIf="org.parent">{{ org.parent.institution }}</span><span *ngIf="!org.parent">Aucun parent</span></td>
          <td>
            <button class="btn btn-primary mr-2" href="#" (click)="updating(org)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger"  data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(org)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal" id="delete" role="dialog" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="orgToDelete"> {{orgToDelete.institution}} </span> ? <br>
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
