import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Opportunite} from 'src/app/classes/opportunite';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {Utilisateur} from 'src/app/classes/utilisateur';
import {ServiceAPIService} from 'src/app/services/service-api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Niveau} from '../../classes/niveau';
import {Typeopportunite} from '../../classes/typeopportunite';
import {UtilisateurConnecte} from '../../classes/utilisateur-connecte';
import {ServiceEtudiantService} from '../../services/service-espace-etudiant/service-etudiant.service';
import {OpportuniteEtudiant} from '../../classes/opportunite-etudiant';

interface NiveauWithCount {
  niveau: any;
  count: number;
}

interface TypeopporWithCount {
  type: Typeopportunite;
  count: number;
}

@Component({
  selector: 'app-accueil-opportunite',
  templateUrl: './accueil-opportunite.component.html',
  styleUrls: ['./accueil-opportunite.component.scss']
})
export class AccueilOpportuniteComponent implements OnInit {

  Date: Date = new Date();
  dateMinDate = new Date();

  p: any;
  filtre = ['Tous'];

  public description;
  public fileUrl;
  public mission;
  public profile;
  public type = false;
  public niveau = false;
  public submitted = false;
  public champvide = false;
  public profil;
  public id;
  public hey = false;
  public hey1 = false;
  utilisateurConnecte: UtilisateurConnecte;
  private readonly notifier: NotifierService;

  canPostOpportunite = false;

  public opportuniteForm: FormGroup;
  public canEdit = false;
  public fichier;
  public niveauxWithCount: Array<NiveauWithCount> = [];
  public typesopporWithCount: Array<TypeopporWithCount> = [];
  public opportunities;
  public show = false;
  public tab = [];

  public delete = false;
  donnees: any;

  L1 = false;
  L2 = false;
  L3 = false;
  M1 = false;
  M2 = false;
  Stage = false;
  Emploi = false;
  Bourse = false;

  public maxSize = 7;
  public directionLinks = true;
  public autoHide = false;
  public responsive = true;
  public labels: any = {
    previousLabel: 'Precedent',
    nextLabel: 'Suivant',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`,
  };

  constructor(
    public fb: FormBuilder,
    private router: Router,
    notifierService: NotifierService,
    private serviceAPI: ServiceEtudiantService,
    private sanitizer: DomSanitizer
  ) {
    this.initForm();
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    await this.getUtilisateurConnecte();
    this.initForm();
    await this.GetType();
    await this.GetNiveau();
    this.GetOpportunite();
    this.utilisateurConnecte.profile.forEach(profile => {
      if (profile.libelle === 'Ancien') {
        this.canPostOpportunite = true;
      }
    });
  }

  test() {
    this.hey = true;
    this.hey1 = false;
  }

  test1() {
    this.hey1 = true;
    this.hey = false;
  }

  initForm() {
    this.opportuniteForm = this.fb.group({
      titre: ['', Validators.required],
      institution: ['', Validators.required],
      description: ['', Validators.required],
      typeopportunite: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      niveau: ['', Validators.required],
      date_fin: ['', Validators.required],
      id: [''],
      utilisateur: [this.id, Validators.required],
      profile: ['', Validators.required],
      mission: ['', Validators.required],
      localite: ['', Validators.required],
    });
  }

  get f() {
    return this.opportuniteForm.controls;
  }

  async getUtilisateurConnecte() {
    this.utilisateurConnecte = await this.serviceAPI.getUtilisateurConnecte().toPromise();
    this.id = this.utilisateurConnecte.id;
  }

  NotificationShowSucces() {
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  NotificationShowWrong() {
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  // tslint:disable-next-line:typedef
  Poster() {
    this.submitted = true;
    if (this.opportuniteForm.invalid) {
      return;
    } else {
      const formData = new FormData();
      // const formDataG = new FormData();
      formData.append('id', this.opportuniteForm.get('id').value);
      formData.append('titre', this.opportuniteForm.get('titre').value);
      formData.append('institution', this.opportuniteForm.get('institution').value);
      formData.append('description', this.opportuniteForm.get('description').value);
      formData.append('typeopportunite', this.opportuniteForm.get('typeopportunite').value);
      formData.append('localite', this.opportuniteForm.get('localite').value);
      formData.append('mission', this.opportuniteForm.get('mission').value);
      formData.append('profile', this.opportuniteForm.get('profile').value);
      formData.append('email', this.opportuniteForm.get('email').value);
      formData.append('niveau', this.opportuniteForm.get('niveau').value);
      formData.append('date_fin', this.opportuniteForm.get('date_fin').value);
      formData.append('utilisateur', this.opportuniteForm.get('utilisateur').value);

      this.serviceAPI.postOpportunite(formData).subscribe(data => {
        this.NotificationShowSucces();
        this.GetOpportunite();
        this.opportuniteForm.reset();
        this.submitted = false;
        window.location.reload();
      });
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {

      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        this.fichier = 'url(' + reader.result.toString() + ')';

      };
      reader.readAsDataURL(file);

      this.opportuniteForm.get('chemin_fichier').setValue(file);

    }
  }

  getInfo(opportunite: any) {
    this.description = opportunite.description;
    this.mission = opportunite.mission;
    this.profile = opportunite.profile;
  }

  updating(opportunite: any) {

    this.opportuniteForm = this.fb.group({
      id: [opportunite.id, Validators.required],
      titre: [opportunite.titre, Validators.required],
      institution: [opportunite.institution, Validators.required],
      description: [opportunite.description, Validators.required],
      typeopportunite: [opportunite.id_typeopportunite, Validators.required],
      email: [opportunite.email, [Validators.required, Validators.email]],
      niveau: [opportunite.id_niveau, Validators.required],
      localite: [opportunite.localite, Validators.required],
      mission: [opportunite.mission, Validators.required],
      profile: [opportunite.profile, Validators.required],
      date_fin: [opportunite.date_fin, Validators.required],
    });


  }

  Update(niveau, type) {
    this.submitted = true;
    if (niveau === '' || type === '') {
      this.champvide = true;
      this.NotificationShowWrong();
    }
    if (this.opportuniteForm.invalid) {
      this.NotificationShowWrong();
      return;
    }
    const opp = new Opportunite();
    opp.id = this.opportuniteForm.value.id;
    opp.institution = this.opportuniteForm.value.institution;
    opp.description = this.opportuniteForm.value.description;
    opp.titre = this.opportuniteForm.value.titre;
    opp.typeopportunite = this.opportuniteForm.value.typeopportunite;
    opp.email = this.opportuniteForm.value.email;
    opp.niveau = this.opportuniteForm.value.niveau;
    opp.localite = this.opportuniteForm.value.localite;
    opp.mission = this.opportuniteForm.value.mission;
    opp.profile = this.opportuniteForm.value.profile;
    opp.date_fin = this.opportuniteForm.value.date_fin;

    this.serviceAPI.updateOpportunite(opp).subscribe(data => {
      this.NotificationShowSucces();
      this.GetOpportunite();
      this.opportuniteForm.reset();
      this.submitted = false;
      window.location.reload();

    });

  }

  async GetType() {
    const data = await this.serviceAPI.getTypeOpportunite().toPromise();
    data.forEach(type => {
      // tslint:disable-next-line:prefer-const
      let typeCounted: TypeopporWithCount = {type, count: 0};
      this.typesopporWithCount.push(typeCounted);
    });
  }

  async GetNiveau() {
    const data = await this.serviceAPI.getNiveaux().toPromise();
    // tslint:disable-next-line:prefer-const
    data.forEach(niv => {
      const niveauCounted: NiveauWithCount = {niveau: niv, count: 0};
      this.niveauxWithCount.push(niveauCounted);
    });
  }

  async GetOpportunite() {
    this.opportunities = await this.serviceAPI.getOpportunite().toPromise();
    // this.opportunities = data.filter(opp => opp.afficher === true);
    this.typesopporWithCount.forEach(typeopporWithCount => {
      const opportunites = this.opportunities.filter(oppor => oppor.typeopportunite === typeopporWithCount.type.libelle);
      typeopporWithCount.count = opportunites.length;
    });
    this.niveauxWithCount.forEach(niveauWithCount => {
      const opportunites = this.opportunities.filter(oppor => oppor.niveau === niveauWithCount.niveau.niveau);
      niveauWithCount.count = opportunites.length;
    });
  }

  passData(opportunite: any) {
    this.donnees = opportunite;
  }

  deleteOpportunite() {
    const oppor = this.donnees;
    this.serviceAPI.deleteOpportunite(oppor).subscribe(() => {
      this.NotificationShowSucces();
      this.GetOpportunite();
      window.location.reload();
    });
  }

  disponibilites() {
    this.router.navigate(['/espace-etudiant/disponibilites']);
  }


  filterType(type: string) {
    this.filtre = [];
    this.filtre.push(type);
    const opportuniteFiltre = [];
    this.serviceAPI.getOpportunite().subscribe(data => {
      data.forEach(oppor => {
        if (oppor.typeopportunite === type) {
          opportuniteFiltre.push(oppor);
        }
      });
    });
    this.opportunities = opportuniteFiltre;
  }

  filterNiveau(niveau: string) {
    this.filtre = [];
    this.filtre.push(niveau);
    const niveauFiltre = [];
    this.serviceAPI.getOpportunite().subscribe(data => {
      data.forEach(oppor => {
        if (oppor.niveau === niveau) {
          niveauFiltre.push(oppor);
        }
      });
    });
    this.opportunities = niveauFiltre;
  }

}
