import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Article} from '../../../classes/article';
import {ServiceAPIService} from '../../../services/service-api.service';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";

declare var $: any;

@Component({
  selector: 'app-temoignages',
  templateUrl: './temoignages.component.html',
  styleUrls: ['./temoignages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemoignagesComponent implements OnInit {
  discoursList: Article[] = [];
  testimonialeList = new Array<Article>(5);
  public image;
  customOptions: OwlOptions = {
    center: true,
    autoplay: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    animateOut: 'fadeOut',
    nav: false,
    responsive: {
      280: {
        items: 1,
        nav: false
      },
      320: {
        items: 1,
        nav: false
      },
      390: {
        items: 1,
        nav: false
      },
      680: {
        items: 1,
        nav: false,
      },
      1000: {
        items: 3,
        nav: false
      },
      1400: {
        items: 3,
        nav: false
      }
    }
  };


  constructor(private serviceAPI: ServiceAccueilService) {

  }

  ngOnInit(): void {
    this.getDiscours();

  }

  // tslint:disable-next-line:typedef
  getDiscours() {

    // this.serviceAPI.getArticle().subscribe( data => {
    //   data.forEach( art => {
    //     if (art.typearticle.libelle === 'Discours' && art.afficher === true){
    //       this.image =  this.serviceAPI.APIURL + art.photo  ;
    //       art.photo = this.image;
    //       if( discours.length < 6) {
    //         discours.push(art);
    //       }
    //     }
    //   });
    // });
    this.serviceAPI.getDiscours().subscribe(data => {
      this.discoursList = data;
    });
  }

}
