<header class="navbar navbar-expand-lg bg-blue fixed-top font-text py-auto">
  <div class="container-fluid">
    <a class="navbar-brand text-white" href="#">
      <img src="/assets/logo-ENl.png" height="30px" width="30" alt="Brand" style="margin-right: 10px">
      Espace ancien
    </a>
    <nav class="navbar" id="navbarNavDropdown">
      <ul>
        <li class="nav-item">
          <a class="nav-link" routerLink="/espace-ancien/actualites" routerLinkActive="active">Actualites</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/espace-ancien/galeries" routerLinkActive="active">Galerie</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/espace-ancien/clubs" routerLinkActive="active">Club</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/espace-ancien/opportunites" routerLinkActive="active">Opportunites</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/espace-ancien/disponibilites" routerLinkActive="active">Disponibilités</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://moodle2.univ-fianar.mg/course/index.php?categoryid=2" target="_blank">ENT</a>
        </li>
        <li class="nav-item" *ngIf="reduit">
          <a class="nav-link" href="/espace-ancien/profil">Profil</a>
        </li>
        <li class="nav-item" *ngIf="reduit">
          <a class="nav-link"  (click)="onSignOut()">Deconnexion</a>
        </li>
        <li class="nav-item dropdown" *ngIf="!reduit">
          <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  (click)="removeUtilisateurDispo()">
            Compte
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="/espace-ancien/profil">Profil</a>
            <a class="dropdown-item" (click)="onSignOut()">Deconnexion</a>
          </div>
        </li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle" (click)="reduit = !reduit"></i>
    </nav>
  </div>
</header>

