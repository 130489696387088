import { Component, OnInit } from '@angular/core';
import {Coordonnees} from '../../../classes/coordonnees';
import {ServiceAPIService} from '../../../services/service-api.service';
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";

@Component({
  selector: 'app-accueil-footer',
  templateUrl: './accueil-footer.component.html',
  styleUrls: ['./accueil-footer.component.scss']
})
export class AccueilFooterComponent implements OnInit {
  coordonnees: Coordonnees;
  constructor(private serviceAPI: ServiceAccueilService) { }

  async ngOnInit(): Promise<void> {
   await this.getCoordonnees();
  }
  async getCoordonnees() {
    const coord = await this.serviceAPI.getCoordonnees().toPromise();
    this.coordonnees = coord[coord.length - 1 ];
  }
}
