import { Component, OnInit } from '@angular/core';
import {UE} from "../../classes/ue";
import {Niveau} from "../../classes/niveau";
import {Utilisateur} from "../../classes/utilisateur";
import {Parcours} from "../../classes/parcours";

@Component({
  selector: 'app-scolarite',
  templateUrl: './scolarite.component.html',
  styleUrls: ['./scolarite.component.scss']
})
export class ScolariteComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
