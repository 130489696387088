<nav id="sidebar" class="bg-forth">
  <div class="sidebar-header text-center p-auto bg-forth">
    <img src="assets/logo-ENl.png" style="height: 70px; width: 70px;">
  </div>


  <ul class="list-unstyled components">
    <li>
      <a routerLink="dashboard" hrefActive="active"><i class="fa fa-dashboard mx-2" data-toggle="tooltip"
                                                       data-placement="right" title="Tableau de bord"></i><span
        class="libelle">Tableau de bord</span></a>
    </li>
    <li>
      <a href="#userSubMenu3" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i
        class="fa fa-home mx-2" data-toggle="tooltip" data-placement="right" title="Page d'accueil"></i><span
        class="libelle">Page d'accueil</span></a>
      <ul class="collapse list-unstyled" id="userSubMenu3">
        <li>
          <a routerLink="header" routerLinkActive="active"><i class="fa fa-header mx-2" data-toggle="tooltip"
                                                        data-placement="right" title="Header"></i><span class="libelle">Header</span></a>
        </li>
        <li>
          <a routerLink="counter" routerLinkActive="active"><i class="fa fa-calculator mx-2" data-toggle="tooltip"
                                                         data-placement="right" title="Numerana"></i><span
            class="libelle">Numerana</span></a>
        </li>
        <li>
          <a routerLink="photos" routerLinkActive="active"><i class="fa fa-image mx-2" data-toggle="tooltip"
                                                        data-placement="right" title="Photos"></i><span class="libelle">Photos</span></a>
        </li>
        <li>
          <a routerLink="historiqueENI" routerLinkActive="active"><i class="fa fa-calendar mx-2" data-toggle="tooltip"
                                                               data-placement="right"
                                                               title="Historique de l'ENI"></i><span class="libelle">Historique de l'ENI</span></a>
        </li>
        <li>
          <a routerLink="formationAccueil"><i class="fa fa-book mx-2" data-toggle="tooltip" data-placement="right"
                                              title="Formations"></i><span class="libelle">Formations</span></a>
        </li>
        <li>
          <a routerLink="coordonnees"><i class="fa fa-address-book mx-2" data-toggle="tooltip" data-placement="right"
                                         title="Coordonnées"></i><span class="libelle">Coordonnées</span></a>
        </li>
        <li>
          <a routerLink="organigramme"><i class="fa fa-bar-chart mx-2" data-toggle="tooltip" data-placement="right"
                                         title="Organigramme"></i><span class="libelle">Organigramme</span></a>
        </li>
      </ul>
    </li>
    <li>
      <a href="#userSubMenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i
        class="fa fa-newspaper-o mx-2" data-toggle="tooltip" data-placement="right" title="Publications"></i><span
        class="libelle">Publications</span></a>
      <ul class="collapse list-unstyled" id="userSubMenu">
        <li>
          <a routerLink="typearticle" routerLinkActive="active"><i class="fa fa-list-ul mx-2" data-toggle="tooltip"
                                                             data-placement="right" title="Categorie"></i><span
            class="libelle">Categorie</span></a>
        </li>
        <li>
          <a routerLink="article" routerLinkActive="active"><i class="fa fa-newspaper-o mx-2" data-toggle="tooltip"
                                                         data-placement="right" title="Articles"></i><span
            class="libelle">Articles</span></a>
        </li>
        <li>
          <a routerLink="opportunite" routerLinkActive="active"><i class="fa fa-bookmark-o mx-2" data-toggle="tooltip"
                                                             data-placement="right" title="Bourses"></i><span
            class="libelle">Bourses</span></a>
        </li>
      </ul>
    </li>
    <li>
      <a routerLink="ancien" routerLinkActive="active"><i class="fa fa-graduation-cap mx-2" data-toggle="tooltip"
                                                    data-placement="right" title="Ancien"></i><span class="libelle">Ancien</span></a>
    </li>
    <li>
      <a routerLink="utilisateur" routerLinkActive="active"><i class="fa fa-user-secret mx-2" data-toggle="tooltip"
                                                    data-placement="right" title="Utilisateur"></i><span class="libelle">Utilisateur</span></a>
    </li>
    <li>
      <a routerLink="partenaire" routerLinkActive="active"><i class="fa fa-shopping-cart mx-2" data-toggle="tooltip"
                                                        data-placement="right" title="Partenaires"></i><span
        class="libelle">Partenaires</span></a>
    </li>
    <li>
      <a routerLink="moderateur" routerLinkActive="active"><i class="fa fa-user-secret mx-2" data-toggle="tooltip"
                                                        data-placement="right" title="Modérateur"></i><span
        class="libelle">Modérateur</span></a>
    </li>
    <li>
      <a routerLink="club" routerLinkActive="active"><i class="fa fa-users mx-2" data-toggle="tooltip" data-placement="right"
                                                  title="Clubs"></i><span class="libelle">Clubs</span></a>
    </li>
    <li>
      <a href="#userSubMenu2" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i
        class="fa fa-address-book-o mx-2" data-toggle="tooltip" data-placement="right"
        title="Experiences professionnelles"></i><span class="libelle">Experiences professionnelles</span></a>
      <ul class="collapse list-unstyled" id="userSubMenu2">
        <li>
          <a routerLink="techno" routerLinkActive="active"><i class="fa fa-feed mx-2" data-toggle="tooltip"
                                                        data-placement="right" title="Technologie"></i><span
            class="libelle">Technologie</span></a>
        </li>
        <li>
          <a routerLink="typetechno" routerLinkActive="active"><i class="fa fa-list-ol mx-2" data-placement="right"
                                                            title="Type techno"></i><span
            class="libelle">Type techno</span></a>
        </li>
      </ul>
    </li>
    <div id="sidebarCollapse" class="mt-5 mx-auto" (click)="reduire()" style="text-align: center;">
      <i class="fa fa-angle-double-left pointeur" *ngIf="!reduit"></i>
      <i class="fa fa-angle-double-right pointeur" *ngIf="reduit"></i>
    </div>
  </ul>
</nav>
