<footer id="footer">
  <div class="footer-top">
    <div class="container-fluid">
      <div class="row mx-auto">
        <img src="assets/aenifront.png" class="img-fluid" id="logoUF">
        <div class="col-sm footer-info">
          <h3>AENI France</h3>
          <p>Anciens de l’Ecole Nationale d’Informatique</p>
        </div>

        <div class="col-sm footer-links">
          <h4>Liens utiles</h4>
          <ul>
            <li><i class="bi bi-chevron-right"></i> <a href="https://www.univ-fianar.mg/">Univ&eacute;rsit&eacute; de Fianarantsoa</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="https://www.eni-univ-fianar.mg/">ENI</a></li>
          </ul>
        </div>

        <div class="col-sm footer-contact" *ngIf="coordonnees">
          <h4>Nous contacter</h4>
          <p>
            <a href="https://www.facebook.com/groups/779268775565843">
              <i class="fa fa-facebook-square pointeur text-white facebook"></i>
            </a> <br>
            <a  class="text-white" href="mailto:elitesdevinfo@gmail.com?body=Your message within Main Body" >Email: <span class="pointeur coordonnees text-white">{{coordonnees.mail}}</span><br></a>
          </p>
        </div>
        <img src="assets/LogoENI.png" class="img-fluid" id="logoENI">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="copyright">
      © Copyright <strong>AENI.fr </strong> . All Rights Reserved
    </div>
  </div>
</footer>
