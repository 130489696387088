import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthEnseignantService {

  constructor(private router: Router) { }

  isAuth = false;

  signIn() {
    this.isAuth = true;
    this.router.navigate(['/espace-enseignant']);
  }

  // tslint:disable-next-line:typedef
  signOut() {
    this.isAuth = false;
    localStorage.clear();
  }
}
