import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../../../classes/utilisateur';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {Opportunite} from '../../../../classes/opportunite';
import {Typeopportunite} from '../../../../classes/typeopportunite';
import {Niveau} from '../../../../classes/niveau';
import {NotifierService} from 'angular-notifier';
import {ServiceEtudiantService} from '../../../../services/service-espace-etudiant/service-etudiant.service';

@Component({
  selector: 'app-opportunites',
  templateUrl: './opportunites.component.html',
  styleUrls: ['./opportunites.component.scss']
})
export class OpportunitesComponent implements OnInit {

  dtOptions: DataTables.Settings = {};

  private readonly notifier: NotifierService;
  niveaux: Niveau[];
  utilisateurConnecte: any;

  bourseList: Array<Opportunite> = [];
  bourseToDelete: Opportunite;

  ajouter = true;
  modifier = false;

  updateForm: FormGroup;
  fichier: string;

  APIURL = this.api.APIURL;

  submitted = false;

  constructor(private api: ServiceAPIService, notifierService: NotifierService, private formBuilder: FormBuilder, private etudiantAPI: ServiceEtudiantService) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void>{
    await this.getUtilisateurConnecte();
    this.initForm();
    this.GetNiveau();
    await this.getBourses();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        emptyTable: 'Aucune donnée disponible dans le tableau',
            lengthMenu: 'Afficher _MENU_ éléments',
            loadingRecords: 'Chargement...',
            processing: 'Traitement...',
            zeroRecords: 'Aucun élément correspondant trouvé',
            paginate: {
                first: 'Premier',
                last: 'Dernier',
                previous: 'Précédent',
                next: 'Suiv'
            },
            decimal: ',',
            info: 'Affichage de _START_ à _END_ sur _TOTAL_ éléments',
            infoEmpty: 'Affichage de 0 à 0 sur 0 éléments',
            search: 'Rechercher:',
            searchPlaceholder: '...',
            thousands: '.',
            infoFiltered: '(filtrés depuis un total de _MAX_ éléments)',
      }
    };

  }

  initForm(){
    this.updateForm = this.formBuilder.group({
      id : [''],
      titre:  ['', Validators.required],
      description:  ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      niveau: ['', Validators.required],
      date_fin:  ['', Validators.required],
      utilisateur: [this.utilisateurConnecte.id, Validators.required],
      profile: ['', Validators.required],
      localite: ['', Validators.required],
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.etudiantAPI.getUtilisateurConnecte().toPromise();
  }

  async getBourses(){
    const bourses: Array<Opportunite> = [];
    const data = await this.api.getOpportunite().toPromise();
    data.forEach( opportunite => {
      if (opportunite.typeopportunite && opportunite.typeopportunite.libelle === 'Bourse') {
        bourses.push(opportunite);
      }
    });
    this.bourseList = bourses;
  }

  GetNiveau(){
    this.api.getNiveau().subscribe(data => {
      this.niveaux = data;
    });
  }

  update() {
    this.submitted = true;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        const formData = new FormData();
        // const formDataG = new FormData();
        formData.append('id', this.updateForm.get('id').value);
        formData.append('titre', this.updateForm.get('titre').value);
        formData.append('description', this.updateForm.get('description').value);
        formData.append('typeopportunite', '3');
        formData.append('localite', this.updateForm.get('localite').value);
        formData.append('profile', this.updateForm.get('profile').value);
        formData.append('email', this.updateForm.get('email').value);
        formData.append('niveau', this.updateForm.get('niveau').value);
        formData.append('date_fin', this.updateForm.get('date_fin').value);
        formData.append('utilisateur', (this.utilisateurConnecte.id).toString());

        this.api.postOpportunite(formData).subscribe(data => {
          this.NotificationShowSucces();
          this.getBourses();
          this.updateForm.reset();
          this.submitted = false;
          window.location.reload();
        });
      }
    }
    if (this.modifier){
      this.submitted = true;
      if (this.updateForm.invalid) {
        this.NotificationShowWrong();
        return;
      }
      else{
        let type: Typeopportunite;
        this.api.getTypeOpportunieByLibelle('Bourse').subscribe( data => type = data);
        const opp = new Opportunite();
        opp.id = this.updateForm.value.id;
        opp.description = this.updateForm.value.description;
        opp.titre = this.updateForm.value.titre;
        opp.typeopportunite = type;
        opp.email = this.updateForm.value.email;
        opp.niveau = this.updateForm.value.niveau;
        opp.localite = this.updateForm.value.localite;
        opp.profile = this.updateForm.value.profile;

        opp.date_fin = this.updateForm.value.date_fin;

        this.api.updateOpportunite(opp).subscribe(data => {
          this.NotificationShowSucces();
          this.getBourses();
          this.updateForm.reset();
          this.submitted = false;
          window.location.reload();
        });
      }
    }
  }

  delete(){
    this.api.deleteOpportunite(this.bourseToDelete).subscribe( () => {
      this.getBourses();
      this.NotificationShowSucces();
    });
  }

  updating(bourse: Opportunite) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.updateForm = this.formBuilder.group({
      id:  [bourse.id, Validators.required],
      titre:  [bourse.titre, Validators.required],
      description:  [bourse.description, Validators.required],
      email:  [bourse.email, [Validators.required, Validators.email]],
      niveau: [bourse.niveau.id, Validators.required],
      localite: [bourse.localite, Validators.required],
      profile: [bourse.profile, Validators.required],

      date_fin:  [bourse.date_fin, Validators.required],
    });
  }

  loadDeleteConfirm(bourse: Opportunite) {
    this.bourseToDelete = bourse;
  }

  reset() {
    this.submitted = false;
    this.updateForm.reset();
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Veuillez remplir les champs',

    });
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',
    });
  }
}

