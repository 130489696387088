import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Schedule} from '../../../../classes/schedule';
import {NotifierService} from 'angular-notifier';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {DxChartComponent, DxSchedulerComponent, DxTemplateModule, DxButtonModule} from 'devextreme-angular';
import {element} from 'protractor';
import DeferRendering from "devextreme/ui/defer_rendering"
import {any} from 'codelyzer/util/function';
import {locale} from 'devextreme/localization';
@Component({
  selector: 'app-emplois-du-temps',
  templateUrl: './emplois-du-temps.component.html',
  styleUrls: ['./emplois-du-temps.component.scss']
})
export class EmploisDuTempsComponent implements OnInit {
  private readonly notifier: NotifierService;

  @Input() assignationMatiere;
  @Input() emploisDuTemps;
  @Input() tableTypeUe;
  @Input() niveau;
  @Input() parcours;
  @Output() updatedEmploisDuTemps: EventEmitter<any> = new EventEmitter();
  public schedules;
  public ues;
  public datas;
  public et = new Schedule();
  public modifier = false;
  public post = [];
  public updtade = [];
  public delete = [];

  public affichage = [];
  public all = [];


  draggingGroupName: string = 'appointmentsGroup';


  assignationTableau = [];
  notifierService: NotifierService;

  constructor(private api: ServiceAPIService,
              notifierService: NotifierService,
  ) {
    locale("fr");
    this.notifier = notifierService;
    this.onAppointmentAdd = this.onAppointmentAdd.bind(this);

  }
  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }
  onCellClick(e) {
    e.cancel = true;
  }
  ngOnInit() {
  }


  valider() {

    var temporaire = [];
    this.emploisDuTemps.forEach(element => {
      temporaire.push({
        ueUserParcoursNiveau: element.ueUserParcoursNiveau,
        date_fin: element.date_fin,
        date_debut: element.date_debut,
      });
    });
    this.api.mergeSchedule({
      niveau: this.niveau,
      parcours: this.parcours,
      data: temporaire
    }).subscribe(data => {
    this.NotificationShowSucces()
    });

  }

  onAppointmentAdd(assignation) {

    const schedule = new Schedule();
    schedule.ueUserParcoursNiveau = assignation.itemData.id;
    schedule.date_fin = assignation.itemData.endDate;
    schedule.date_debut = assignation.itemData.startDate;
    schedule.libelle = assignation.itemData.UE.libelle;
    var couleurId = assignation.itemData.UE.typeue;
    this.emploisDuTemps.push(
      {
        text: schedule.libelle,
        endDate: schedule.date_fin,
        startDate: schedule.date_debut,
        typeUeId: couleurId,

        ueUserParcoursNiveau: assignation.itemData.id,
        libelle: assignation.itemData.UE.libelle,
        date_fin: assignation.itemData.endDate,
        date_debut: assignation.itemData.startDate

      }
    );
  }

  onAppointmentUpdated(event) {
    const item = event.appointmentData;
    var modification = this.emploisDuTemps.indexOf(item);
    this.modifier = true;
    this.emploisDuTemps[modification].date_fin = item.endDate;
    this.emploisDuTemps[modification].date_debut = item.startDate;
  }

  onListDragStart(e) {
    e.cancel = true;
  }

  onItemDragStart(e) {
    e.itemData = e.fromData;
  }

  onItemDragEnd(e) {
    if (e.toData) {
      e.cancel = true;
    }
  }

  onAppointmentDeleted(event) {
    const item = event.appointmentData;
    const index = this.emploisDuTemps.indexOf(item);
    if (index >= 0) {
      this.emploisDuTemps.splice(index, 1);
    }
  }


}
