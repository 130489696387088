import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../../classes/utilisateur';
import {ServiceAPIService} from '../../../services/service-api.service';
import {Historiqueexperiencepro} from '../../../classes/historiqueexperiencepro';
import {ServiceEtudiantService} from "../../../services/service-espace-etudiant/service-etudiant.service";
import {UtilisateurConnecte} from "../../../classes/utilisateur-connecte";

@Component({
  selector: 'app-profil-etudiant',
  templateUrl: './profil-etudiant.component.html',
  styleUrls: ['./profil-etudiant.component.scss']
})
export class ProfilEtudiantComponent implements OnInit {
  public utilisateurConnecte: any;
  imageUtilisateur: string;

  experiencesList: Array<Historiqueexperiencepro>;
  experienceColor = new Map();
  skillsList: Array<string> = [];

  public show = false;

  constructor(private serviceAPI: ServiceEtudiantService, private etudiantService: ServiceEtudiantService) { }

  async ngOnInit(): Promise<void>  {
    await this.getUtilisateurConnecte();
  }

  async getUtilisateurConnecte() : Promise<void>{
    this.utilisateurConnecte = await this.etudiantService.getProfilAccueilOf().toPromise();
  }

  viewAllSkills() {
    this.show = true;
  }
}
