<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5  *ngIf="ajouter">Ajouter une opportunité de bourse exterieur</h5>
      <h5 *ngIf="modifier">Modifier l' opportunité</h5>
    </div>
    <div class="card-body">
      <form [formGroup]="updateForm" (ngSubmit)="update()" *ngIf="utilisateurConnecte">
        <label for="localite">Localite</label>
        <input type="text" formControlName="localite" class="form-control"id="localite"
               [ngClass]="{ 'is-invalid': submitted && f.localite.errors }">
        <div *ngIf="submitted && f.localite.errors" class="invalid-feedback">
          <div *ngIf="f.localite.errors.required">Veuillez renseigner la localite</div>
        </div>
        <label for="titre">Titre</label>
        <input type="text" formControlName="titre" class="form-control"id="titre"
               [ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
        <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
          <div *ngIf="f.titre.errors.required">Veuillez renseigner un titre</div>
        </div>
        <label for="description">Description</label>
        <textarea class="form-control" formControlName="description" id="description" rows="3"
                  [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
          <div *ngIf="f.description.errors.required">Veuillez renseigner une description</div>
        </div>
        <label for="profile">Profiles</label>
        <textarea class="form-control" formControlName="profile" id="profile" rows="3"
                  [ngClass]="{ 'is-invalid': submitted && f.profile.errors }"></textarea>
        <div *ngIf="submitted && f.profile.errors" class="invalid-feedback">
          <div *ngIf="f.profile.errors.required">Veuillez renseigner le profil</div>
        </div>

        <label for="niveau">Niveau</label>
        <select class="form-control" formControlName="niveau"id="niveau"
                [ngClass]="{ 'is-invalid': submitted && f.niveau.errors }">
          <option *ngFor="let niveau of niveaux" [ngValue]="niveau.id">{{niveau.libelle}}</option>
        </select>
        <div *ngIf="submitted && f.niveau.errors" class="invalid-feedback">
          <div *ngIf="f.niveau.errors.required">Veuillez renseigner un niveau</div>
        </div>

        <label for="email">Email</label>
        <input type="email" formControlName="email" class="form-control" id="email"
               [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Veuillez renseigner votre email</div>
          <div *ngIf="f.email.errors.email">Veuillez entrer une addresse email valide</div>
        </div>
        <label for="date_fin">Date Fin</label>
        <input type="date" formControlName="date_fin" class="form-control mb-3"id="date_fin"
               [ngClass]="{ 'is-invalid': submitted && f.date_fin.errors }">
        <div *ngIf="submitted && f.date_fin.errors" class="invalid-feedback">
          <div *ngIf="f.date_fin.errors.required">Veuillez renseigner une date</div>
        </div>

        <button type="submit" class="btn btn-primary btn-sm btn-block my-3">Publier</button>
        <button type="reset" class="btn btn-outline-primary btn-sm btn-block" (click)="reset()">Annuler</button>
      </form>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="example" class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="bourseList">
        <thead>
        <tr>
          <th>Titre</th>
          <th>Email</th>
          <th>Date fin</th>
          <th>Localité</th>
          <th>Profile</th>
          <th>Niveau</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bourse of bourseList">
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p class="font-weight-bold mb-0">{{ bourse.titre }}</p>
              </div>
            </div>
          </td>
          <td>{{ bourse.email }}</td>
          <td>{{ bourse.date_fin }}</td>
          <td>{{ bourse.localite }}</td>
          <td>{{ bourse.profile }}</td>
          <td *ngIf="bourse.niveau">{{ bourse.niveau.libelle }}</td>
          <td>
            <button class="btn btn-primary mr-2" href="#" (click)="updating(bourse)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger"  data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(bourse)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal" id="delete" role="dialog" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="bourseToDelete"> {{bourseToDelete.titre}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
