import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../../../classes/utilisateur';
import {AuthScolariteService} from '../../../../services/auth-scolarite.service';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {ConnexionApiService} from '../../../../services/connexion-api.service';
import {ServiceEtudiantService} from '../../../../services/service-espace-etudiant/service-etudiant.service';

@Component({
  selector: 'app-header-scolarite',
  templateUrl: './header-scolarite.component.html',
  styleUrls: ['./header-scolarite.component.scss']
})
export class HeaderScolariteComponent implements OnInit {
  utilisateurConnecte: any;

  constructor(private authService: AuthScolariteService, private serviceAPI: ServiceEtudiantService, private connexion: ConnexionApiService) { }

  async ngOnInit(): Promise<void> {
    await this.getUtilisateurConnecte();
  }

  signOut() {
    this.connexion.logout();
    this.authService.signOut();
    window.location.reload();
  }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.serviceAPI.getUtilisateurConnecte().toPromise();
  }

}
