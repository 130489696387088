import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {Article} from '../../classes/article';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActusNotification} from '../../classes/actus-notification';
import {Typearticle} from '../../classes/typearticle';
import {Schedule} from '../../classes/schedule';
import {EmploisDuTemps} from '../../classes/emplois-du-temps';
import {UtilisateurConnecte} from '../../classes/utilisateur-connecte';
import {Historiqueexperiencepro} from '../../classes/historiqueexperiencepro';
import {Techno} from '../../classes/techno';
import {Utilisateur} from '../../classes/utilisateur';
import {Typeexperience} from '../../classes/typeexperience';

import {BlogClub} from '../../classes/blog-club';
import {Club} from "../../classes/club";
import {Router} from "@angular/router";
import {Opportunite} from "../../classes/opportunite";
import {OpportuniteEtudiant} from "../../classes/opportunite-etudiant";
import {Typeopportunite} from "../../classes/typeopportunite";
import {Niveau} from "../../classes/niveau";
@Injectable({
  providedIn: 'root'
})
export class ServiceEtudiantService {
  APIURL = '/api/';
  token = localStorage.getItem('currentUser');
  allHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    Authorization:  this.token ,
  });
  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    Authorization: this.token
  });
  httpOptions = {
    headers: this.allHeaders
  };
  constructor(private http: HttpClient, private router: Router) { }
  //Utilisateur Connecte
  getUtilisateurConnecte(): Observable<UtilisateurConnecte> {
    return this.http.get<UtilisateurConnecte>(this.APIURL + 'UtilisateurConnecte', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Profil Utilisateur Connecte
  getUtilisateurConnecteProfil(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'UtilisateurConnecteProfil', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Profil Utilisateur Connecte dans accueil
  getProfilAccueilOf(): Observable<any> {
    const body = JSON.stringify({accueil: true});
    return this.http.patch<UtilisateurConnecte>(this.APIURL + 'UtilisateurProfile', body, {headers: this.allHeaders}).pipe(
      catchError((err) => {
           if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Notes Etudiants
  getNotesEtudiant(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'NotesEtudiant', {headers: this.headers}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  deleteNotesEtudiant(article: ActusNotification): Observable<any> {
    return this.http.delete<ActusNotification>(this.APIURL + 'article/' + article.id, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  getTypeArticleByLibelle(libelle: string): Observable<Typearticle> {
    return this.http.get<Typearticle>(this.APIURL + 'typearticle?libelle=' + libelle, {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  postArticleFromEnseignant(formData: FormData): Observable<any> {
    return this.http.post<Article>(this.APIURL + 'articleEnseignant', formData, this.httpOptions).pipe(
      catchError((err) => {
          console.error('erreur', err);
           if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          };
        }
      ));
  }

  // Schedule
  getSchedule(): Observable<EmploisDuTemps[]> {
    return this.http.get<EmploisDuTemps[]>(this.APIURL + 'EmploisDuTemps?limit=100000&sort=date%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Profile
  getHistoriqueExperience(id: number): Observable<Historiqueexperiencepro[]> {
    return this.http.get<Historiqueexperiencepro[]>(this.APIURL + 'experiencespro/?where={"utilisateur":' + id + '}', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          };
      })
    );
  }
  getOneTechno(id: number): Observable<Techno> {
    return this.http.get<Techno>(this.APIURL + 'techno/' + id, {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Actualites Etudiant
  getActualite(): Observable<Article[]>{
    return this.http.get<Article[]>(this.APIURL + 'ActualitesEtudiant', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Etudiants disponibles
  getDisponibles(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'Disponibilite', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Niveau
  getNiveaux(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'Niveaux', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Parcours
  getParcours(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'Parcours-etudiant', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Ajout experiencespro
  postHistorique(formData: FormData): Observable<any> {
    return this.http.post<Historiqueexperiencepro>(this.APIURL + 'historiqueexperiencepro', formData, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Editer experiencespro
  updateHistorique(exp: Historiqueexperiencepro): Observable<any> {
    const body = JSON.stringify(exp);
    return this.http.patch<Historiqueexperiencepro>(this.APIURL + 'experiencespro/' + exp.id, body, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Maj token après modification utilisateur
  updateUtilisateur(utilisateur: Utilisateur): Observable<any> {
    const body = JSON.stringify(utilisateur);
    return this.http.patch<Utilisateur>(this.APIURL + 'utilisateur/' + utilisateur.id, body, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Delete experiencespro
  deleteHistorique(histo: any): Observable<any> {
    const body = JSON.stringify(histo);
    return this.http.delete<Historiqueexperiencepro>(this.APIURL + 'experiencespro/' + histo.id, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // TypeExperience
  getTypeExperiences(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'Typeexperiences', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Techno
  getTechnos(): Observable<any> {
    return this.http.get<any>(this.APIURL + 'Technos', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  //Blog Club

  getBlog(): Observable<BlogClub[]>{
  return this.http.get<BlogClub[]>(this.APIURL + 'BlogClub?limit=100000&sort=nom%20ASC', {headers: this.allHeaders}).pipe(
  catchError((err) => {
       if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          };
    }
  ));
}
  deleteArticle(article: Article): Observable<any> {
    return this.http.delete<Article>(this.APIURL + 'article/' + article.id, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  updateArticle(article: Article): Observable<any> {
    const body = JSON.stringify(article);
    return this.http.patch<Article>(this.APIURL + 'article/' + article.id, article, {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getClub(): Observable<BlogClub[]> {
    return this.http.get<BlogClub[]>(this.APIURL + 'ClubEtudiant?limit=100000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
          if (err.status === 401) {
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  // Opportunite
  getOpportunite(): Observable<OpportuniteEtudiant[]> {
    return this.http.get<OpportuniteEtudiant[]>(this.APIURL + 'Opportunites?limit=100000&sort=id%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getTypeOpportunite(): Observable<Typeopportunite[]> {
    return this.http.get<Typeopportunite[]>(this.APIURL + 'TypeOpportunites?limit=100000&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  postOpportunite(formData: FormData): Observable<any> {
    return this.http.post<Opportunite>(this.APIURL + 'opportunite', formData, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
  updateOpportunite(opportunite: Opportunite): Observable<any> {
    const body = JSON.stringify(opportunite);
    return this.http.patch<Opportunite>(this.APIURL + 'opportunite/' + opportunite.id, body, this.httpOptions).pipe(
      catchError((err) => {
          console.error('erreur', err);
           if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          };
        }
      ));
  }
  getNiveau(): Observable<Niveau[]> {
    return this.http.get<Niveau[]>(this.APIURL + 'niveau?limit=10&sort=libelle%20ASC', {headers: this.allHeaders}).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  deleteOpportunite(opportunite: Opportunite): Observable<any> {
    const body = JSON.stringify(opportunite);
    return this.http.delete<Opportunite>(this.APIURL + 'opportunite/' + opportunite.id, this.httpOptions).pipe(
      catchError((err) => {
         if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }

  getProfilOf(id: number): Observable<any> {
    const body = JSON.stringify({id: id});
    return this.http.patch<UtilisateurConnecte>(this.APIURL + 'UtilisateurProfile', body, {headers: this.allHeaders}).pipe(
      catchError((err) => {
           if (err.status === 401){
            localStorage.clear();
            this.router.navigate(['/Login']);
            return throwError(err);
          }
        }
      ));
  }
}
