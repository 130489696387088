import { ServiceAPIService } from './../../../services/service-api.service';
import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Enseignant } from '../../../classes/enseignant';
import * as AOS from 'aos';
import {ServiceAccueilService} from '../../../services/service-accueil/service-accueil.service';

@Component({
  selector: 'app-enseignant-eni',
  templateUrl: './enseignant-eni.component.html',
  styleUrls: ['./enseignant-eni.component.scss']
})
export class EnseignantEniComponent implements OnInit, AfterViewInit {

  enseignantList: Array<Enseignant>;
  vacataireList: Array<Enseignant>;
  permananentList: Array<Enseignant>;
  enseignantToShow: Enseignant;
  @ViewChildren('team_list_item') team_list_item: QueryList<ElementRef>;


  constructor(private serviceAPI: ServiceAccueilService) { }

  async ngOnInit(): Promise<void> {
    await this.getEnseignants();
  }

  ngAfterViewInit(): void {

    $(document).ready(function () {
      //AOS.init();
      $("#team-filters li").click(function () {
        var filter = $(this).attr('data-filter');
        $("#team-filters li").removeClass('filter-active');
        $(this).addClass('filter-active');
        $(".team-item").addClass('d-none');
        $(filter).removeClass('d-none');
        $(filter).addClass('animate__animated animate__zoomIn');
        //AOS.refresh();
      });
      /*let elem = document.querySelector('.team-container');
      let iso = new Isotope(elem, {
        itemSelector: '.team-item',
        layoutMode: 'fitRows'
      });
      let teamFilter = document.querySelectorAll('#team-filters li');
      teamFilter.forEach(fltr => {
        fltr.addEventListener('click', function (e) {
          e.preventDefault();
          teamFilter.forEach(function (el) {
            el.classList.remove('filter-active');
          });
          this.classList.add('filter-active');

          iso.arrange({
            filter: this.getAttribute('data-filter')
          });
        });*/
      /*});
      iso.arrange({ filter: $(this).attr('data-filter') });
      iso.on('arrangeComplete', function () {
        AOS.refresh();
      });*/
    });
  }

  async getEnseignants() {
    const enseignants: Array<Enseignant> = [];
    const data = await this.serviceAPI.getEnseignant().toPromise();
    this.permananentList = data.filter(enseignant => enseignant.permanence === 'Permanent');
    this.vacataireList = data.filter(enseignant => enseignant.permanence === 'Vacataire');
    // data.forEach(enseignant => {
    //   enseignant.profiles.forEach(profile => {
    //     if (profile.libelle === 'Enseignant') {
    //       enseignant.photo = this.serviceAPI.APIURL + enseignant.photo;
    //       enseignants.push(enseignant);
    //     }
    //   });
    // });
    this.enseignantList = data;
  }
}
