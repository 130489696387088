<div class="container-fluid">
  <header class="section-header wow fadeInUp">
    <h3>NOTIFICATIONS
    </h3>
  </header>
  <section class="banner-sec mx-auto mb-2">
    <div *ngIf="notes">
      <button type="button" class="btn btn-secondary btn-sm btn-block mb-3" data-toggle="modal" data-target="#ajouterNote" *ngIf="canPost">Créer une note
      </button>
      <div class="row">
        <div class="col-md-12" *ngIf="notes.length === 0">
          <div class="card">
            <div class="card-body">
              Aucune notification disponible pour vous
            </div>
          </div>
        </div>
        <div class="card-deck mx-auto" [ngClass]="{'col-md-6' : notes.length > 1 , 'col-md-12' : notes.length === 1}"
             *ngFor="let article of notes">
          <div class="card"
               [ngClass]="{'noteProf' : !article.niveau, 'noteDelegue' : article.niveau}">
            <div class="card-img-overlay row">
              <div class="col-10">
                <span
                  class="badge badge-pill bg-third text-white py-1 ">
                  {{article.titre}}</span>
              </div>
              <div class="col-2 text-center" *ngIf="article.auteur === utilisateurConnecte.id">
                <i class="fa fa-trash small-icon text-danger ml-auto" data-toggle="modal" data-target="#delete"

                   style="cursor: pointer;" (click)="loadDeleteConfirm(article)"></i>
              </div>
            </div>
            <div class="card-body mt-3">
              <div class="news-title">
                <p [innerHTML]="article.contenu" class="text-left"></p>
              </div>
            </div>
            <div class="card-footer">
              <p class="card-text"><small class="text-time">Par
                <em>{{article.nom}} {{article.prenom}}</em></small></p>
              <p class="card-text"><small class="text-time"><em>le {{article.date | date: 'longDate'}}</em></small> ---  <small *ngIf="article.fin"><em>Valable jusqu'au {{article.fin | date: 'longDate'}}</em></small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="modal fade" id="ajouterNote" tabindex="-1" role="dialog" aria-labelledby="ajouterNoteLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mx-2" id="ajouterNoteLabel">Ajouter une note pour votre classe</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" class="mx-3">
          <label for="Title">Titre</label>
          <input formControlName="titre" class="form-control mb-3" id="Title" aria-describedby="Title"
                 [ngClass]="{ 'is-invalid': submitted && f.titre.errors }">
          <div *ngIf="submitted && f.titre.errors" class="invalid-feedback">
            <div *ngIf="f.titre.errors.required">Veuillez renseigner un titre</div>
          </div>

          <label for="Contenu">Contenu</label>
          <ckeditor [(ngModel)]="mycontent"  [config]="ckeConfig" id="Contenu" data="<p>Hello, world!</p>" formControlName="contenu"
                    [ngClass]="{ 'is-invalid': submitted && f.contenu.errors }"></ckeditor>
          <div *ngIf="submitted && f.contenu.errors" class="invalid-feedback">
            <div *ngIf="f.contenu.errors.required">Veuillez renseigner un contenu</div>
          </div>

          <label for="fin">Fin</label>
          <input type="date" class="form-control mb-3" id="fin" aria-describedby="fin" formControlName="fin" min="{{dateMinDate | date:'yyyy-MM-dd'}}">
          <div *ngIf="submitted && f.fin.errors" class="invalid-feedback">
            <div *ngIf="f.fin.errors.required">Veuillez renseigner la date de fin</div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark text-white py-2 btn-block" type="submit" (click)="Publier()">Publier
        </button>
        <button type="button" class="btn btn-outline-dark py-2 btn-block" (click)="reset()">Annuler</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="delete" role="dialog" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="noteToDelete"> {{noteToDelete.titre}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteArticle()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
