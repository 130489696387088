<nav id="sidebar" class="bg-forth">
  <div class="sidebar-header text-center p-auto bg-forth">
    <img src="assets/logo-ENl.png" style="height: 70px; width: 70px;">
  </div>

  <ul class="list-unstyled components">
    <li>
      <a routerLink="dashboard" routerLinkActive="active"><i class="fa fa-dashboard mx-2" data-toggle="tooltip" data-placement="right" title="Tableau de bord"></i><span class="libelle">Tableau de bord</span></a>
    </li>
    <li>
      <a routerLink="etudiant" routerLinkActive="active"><i class="fa fa-user mx-2" data-toggle="tooltip" data-placement="right" title="Etudiant"></i><span class="libelle">Etudiant</span></a>
    </li>
    <li>
      <a href="#userSubMenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i
        class="fa fa-book mx-2" data-toggle="tooltip" data-placement="right" title="Etudes"></i><span
        class="libelle">Etudes</span></a>
      <ul class="collapse list-unstyled" id="userSubMenu">
        <li>
          <a routerLink="niveau" routerLinkActive="active"><i class="fa fa-list-ol mx-2" data-toggle="tooltip"
                                                             data-placement="right" title="Niveau"></i><span
            class="libelle">Niveau</span></a>
        </li>
        <li>
          <a routerLink="parcours" routerLinkActive="active"><i class="fa fa-list-ul mx-2" data-toggle="tooltip"
                                                         data-placement="right" title="Parcours"></i><span class="libelle">Parcours</span></a>
        </li>
      </ul>
    </li>
    <li>
      <a href="#userSubMenu2" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i
        class="fa fa-book mx-2" data-toggle="tooltip" data-placement="right"
        title="Pédagogie"></i><span class="libelle">Pédagogie</span></a>
      <ul class="collapse list-unstyled" id="userSubMenu2">
        <li>
          <a routerLink="enseignant" routerLinkActive="active"><i class="fa fa-user-secret mx-2" data-toggle="tooltip"
                                                        data-placement="right" title="Enseignant"></i><span
            class="libelle">Enseignant</span></a>
        </li>
        <li>
          <a routerLink="ue" routerLinkActive="active"><i class="fa fa-bookmark mx-2" data-placement="right"
                                                            title="Unité d'enseignement"></i><span
            class="libelle">Unité d'enseignement</span></a>
        </li>
        <li>
          <a routerLink="typeue" routerLinkActive="active"><i class="fa fa-list-ol mx-2" data-placement="right"
                                                    title="Type d'unité d'enseignement"></i><span
            class="libelle">Type d'unité d'enseignement</span></a>
        </li>
        <li>
          <a routerLink="assignation" routerLinkActive="active"><i class="fa fa-edit mx-2" data-placement="right"
                                                    title="Assignation"></i><span
            class="libelle">Assignation</span></a>
        </li>
      </ul>
    </li>
    <li>
      <a href="#userSubMenu3" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i class="fa fa-calendar mx-2" data-placement="right" title="Emploi du temps"></i><span
        class="libelle">Emploi du temps</span></a>
      <ul class="collapse list-unstyled" id="userSubMenu3">
        <li *ngFor="let parc of parcours">
          <a [routerLink]="[parc.routerlink]">{{parc.libelle}}</a>
        </li>
      </ul>
    </li>
    <div id="sidebarCollapse" class="mt-5 mx-auto" (click)="reduire()" style="text-align: center;">
      <i class="fa fa-angle-double-left pointeur" *ngIf="!reduit"></i>
      <i class="fa fa-angle-double-right pointeur" *ngIf="reduit"></i>
    </div>
  </ul>
</nav>


