import {Component, OnInit} from '@angular/core';
import {ServiceAPIService} from "../../../../services/service-api.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public NbActualites = [];
  public NbEvenements = [];
  public NbHistorique = [];
  public NbPhotos = [];
  public NbAnciens = [];
  public NbModerateurs = [];
  public NbUtilisateurs = [];
  public NbClubs = [];

  constructor(private ServiceApi: ServiceAPIService) {
  }

  ngOnInit(): void {
    this.GetActualites();
    this.GetEvenements();
    this.GetHistorique();
    this.GetPhotos();
    this.GetAnciens();
    this.GetUtilisateurs();
    this.GetClubs();
    this.GetModerateur();
  }

  GetActualites() {
    this.ServiceApi.getArticle().subscribe(
      data => {
        data.forEach(element => {
          if (element.typearticle.libelle === 'Actualite') {
            this.NbActualites.push(element);
          }
        });
      }
    );
  }

  GetEvenements() {
    this.ServiceApi.getArticle().subscribe(
      data => {
        data.forEach(element => {
          if (element.typearticle.libelle === 'Evenement') {
            this.NbEvenements.push(element);
          }
        });
      }
    );
  }

  GetHistorique() {
    this.ServiceApi.getHistoriqueENI().subscribe(
      data => {
        data.forEach(element => {
          this.NbHistorique.push(element);
        });
      });
  }

  GetPhotos() {
    this.ServiceApi.getPhoto().subscribe(
      data => {
        data.forEach(element => {
          this.NbPhotos.push(element);
        });
      });
  }

  GetAnciens() {
    this.ServiceApi.getUtilisateur().subscribe(
      data => {
        data.forEach(ancien => {
          ancien.profiles.forEach(profile => {
            if (profile.libelle === 'Ancien') {
              this.NbAnciens.push(ancien);
            }
          });
        });
      }
    );
  }

  GetUtilisateurs() {
    this.ServiceApi.getUtilisateur().subscribe(
      data => {
        data.forEach(element => {
          this.NbUtilisateurs.push(element);
        });
      });
  }

  GetModerateur() {
    this.ServiceApi.getUtilisateur().subscribe(data => {
      data.forEach(mod => {
        mod.profiles.forEach(profile => {
          if (profile.libelle === 'Moderateur club') {
            this.NbModerateurs.push(mod);
          }
        });
      });
    });
  }

  GetClubs() {
    this.ServiceApi.getClub().subscribe(
      data => {
        data.forEach(element => {
          this.NbClubs.push(element);
        });
      });
  }
}
