import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthEtudiantService} from "../../services/auth-etudiant.service";
import {ServiceAPIService} from "../../services/service-api.service";
import {ConnexionApiService} from "../../services/connexion-api.service";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
