<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h5>Type de technologie</h5>
    </div>
    <div class="card-body">
      <form [formGroup]="updateForm" (ngSubmit)="updateTypeTechno()">
        <input type="text" class="form-control" formControlName="id" hidden>
        <div class="form-group row">
          <label for="libelle" class="col-md-4">Type de la technologie </label>
          <input type="text" id="libelle" class="form-control col-md-8" formControlName="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
          <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
            <div *ngIf="f.libelle.errors.required">Veuillez remplir le type de technologie</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="couleur" class="col-md-4">Couleur </label>
          <input type="color" id="couleur" value="#0000ff" class="form-control col-md-8" formControlName="couleur" [ngClass]="{ 'is-invalid': submitted && f.couleur.errors }">
          <div *ngIf="submitted && f.couleur.errors" class="invalid-feedback">
            <div *ngIf="f.couleur.errors.required">Veuillez remplir l'à propos</div>
          </div>
        </div>
        <div>
          <button class="btn bg-forth text-clear mr-5 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{Enregistrer}}</button>
          <button class="btn btn-secondary mb-3" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>
        </div>
      </form>

    </div>
  </div>
</div>
<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="typeTechnoList">
        <thead>
        <tr>
          <th>Nom de la catégorie</th>
          <th>Couleur</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let type of typeTechnoList">
          <td>
            <p>{{ type.libelle }}</p>
          </td>
          <td>
            <div [ngStyle]="{'height': '20px', 'width': '120px', 'background-color' : type.couleur}"></div>
          </td>
          <td>
            <button class="btn btn-primary mr-2" href="#" (click)="updating(type)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-danger" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(type)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="delete" data-backdrop="false" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="typeToDelete"> {{typeToDelete.libelle}}</span> ?
        Cette action est irreverssible.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
