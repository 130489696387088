import { Component, OnInit } from '@angular/core';
import {UE} from '../../../../classes/ue';
import {Niveau} from '../../../../classes/niveau';
import {Parcours} from '../../../../classes/parcours';
import {Utilisateur} from '../../../../classes/utilisateur';
import {ServiceAPIService} from '../../../../services/service-api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Assignation} from '../../../../classes/assignation';
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-assignation',
  templateUrl: './assignation.component.html',
  styleUrls: ['./assignation.component.scss']
})
export class AssignationComponent implements OnInit {
  private readonly notifier: NotifierService;
  dtOptions: DataTables.Settings = {};

  ueList: Array<UE>;
  niveauList: Array<Niveau>;
  parcoursList: Array<Parcours>;
  enseignantList: Array<Utilisateur> = [];
  assignationList: Array<Assignation>;
  assignationToDelete: Assignation;

  ajouter = true;
  modifier = false;

  updateForm: FormGroup;

  Enregistrer = 'Ajouter';
  submitted = false;

  constructor(private serviceAPI: ServiceAPIService, private formBuilder: FormBuilder, notifierService: NotifierService,) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getAssignations();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
    this.getUEs();
    this.getParcours();
    this.getEnseignants();
    this.getNiveaux();
  }

  getEnseignants(){
    var enseignants: Array<Utilisateur> = [];
    this.serviceAPI.getUtilisateur().subscribe( data => {
      data.forEach( enseignant => {
        enseignant.profiles.forEach(profile => {
          if (profile.libelle === 'Enseignant') {
            enseignants.push(enseignant);
          }
        });
      });
      this.enseignantList = enseignants;
    });
  }
  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Operation non enregistree',

    });
  }
  getNiveaux() {
    this.serviceAPI.getNiveau().subscribe( data => {
      this.niveauList = data;
    });
  }

  getParcours() {
    this.serviceAPI.getParcours().subscribe( data => {
      this.parcoursList = data;
    });
  }

  getUEs(){
    this.serviceAPI.getUEs().subscribe( data => this.ueList = data );
  }

  async getAssignations(){
    this.assignationList = await this.serviceAPI.getAssignations().toPromise();
  }

  initForm(){
    this.updateForm = this.formBuilder.group({
      id: [''],
      niveau: ['', Validators.required],
      parcours: ['', Validators.required],
      ue: ['', Validators.required],
      utilisateur: ['', Validators.required]
    });
  }

  get f() {
    return this.updateForm.controls;
  }

  update() {
    this.submitted = true;
    if (this.ajouter){
      if (this.updateForm.invalid) {
        return;
      }
      else{
        const value = this.updateForm.value;
        let assignation = new Assignation();
        assignation.niveau =  value.niveau;
        assignation.parcours =  value.parcours;
        assignation.UE =  value.ue;
        assignation.utilisateur =  value.utilisateur;
        this.serviceAPI.postAssignation(assignation).subscribe( () => {
          this.getAssignations();
          this.NotificationShowSucces();
          this.modifier = false;
          this.ajouter = true;
          this.updateForm.reset();
          this.submitted=false;
          window.location.reload();
        });
      }
    }
    if (this.modifier){
      this.submitted=true;
      if (this.updateForm.invalid) {
        return;
      }
      else{
        let assignation = new Assignation();
        assignation.id =  this.updateForm.value.id;
        assignation.niveau =  this.updateForm.value.niveau;
        assignation.parcours =  this.updateForm.value.parcours;
        assignation.UE =  this.updateForm.value.ue;
        assignation.utilisateur =  this.updateForm.value.utilisateur;
        this.serviceAPI.updateAssignation(assignation).subscribe( () => {
          this.getAssignations();
          this.NotificationShowSucces();
          this.modifier = false;
          this.ajouter = true;
          this.updateForm.reset();
          this.submitted=false;
          window.location.reload();
        });
      }
    }
  }


  loadDeleteConfirm(assign: Assignation) {
    this.assignationToDelete = assign;
  }

  delete(){
    this.serviceAPI.deleteAssignation(this.assignationToDelete).subscribe( () => {this.getAssignations(); this.NotificationShowSucces(); window.location.reload(); });
  }


  updating(assignation: Assignation) {
    window.scroll(0,0);
    this.modifier = true;
    this.ajouter = false;
    this.updateForm = this.formBuilder.group({
      id: assignation.id,
      UE: assignation.UE.id,
      parcours: assignation.parcours.id,
      niveau: assignation.niveau.id,
      utilisateur: assignation.utilisateur.id
    });
    this.Enregistrer = 'Modifier';
  }

  reset() {
    this.submitted = false;
    this.updateForm.reset();
    this.Enregistrer = 'Ajouter';
  }
}
