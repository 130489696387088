import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Observable} from "rxjs/Observable";
import {Article} from "../classes/article";

@Injectable({
  providedIn: 'root'
})
export class HeaderToActualiteService {
  private subject = new Subject<any>();
  constructor() { }

  // tslint:disable-next-line:typedef
  sendMessage(article: Article) {
    this.subject.next(article);
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
