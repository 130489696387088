<h1> Erreur 404</h1>
<div class='container'>

  <div class='col'>
    <h4>Page Web Introuvable</h4>
    <h5>Assurez-vous de saisir le bon URL</h5>
  </div>
  <div class='col'>
    <img src=  'https://media2.giphy.com/media/830H0uF08yM5J29qjf/source.gif' />
  </div>
</div>

