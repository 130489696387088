import {Techno} from './techno';
import {Utilisateur} from './utilisateur';
import {Typeexperience} from './typeexperience';

export class Historiqueexperiencepro {
  id: number;
  description: number;
  date_debut: string;
  date_fin: string;
  technos: Techno[];
  utilisateur: Utilisateur;
  typeexperience: Typeexperience;
}
