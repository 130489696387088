<section id="hero">
  <div class="hero-container">
    <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel" data-interval="10000">
      <ol id="hero-carousel-indicators" class="carousel-indicators">
        <li *ngFor="let banner of bannerList;let i = index;" data-target="#heroCarousel" [attr.data-slide-to]="i"
          [ngClass]="{'active' : i ===0}"></li>
      </ol>
      <div id="carousel-inner-container" class="carousel-inner" role="listbox">
        <div *ngFor="let banner of bannerList ; let i = index ;"
          [ngClass]="{'carousel-item' : true, 'active' : i ===0}" #carousel_item>
          <div class="carousel-inner-bg" [ngStyle]="{'background-image': 'url(' +'/api/'+ banner.photo + ')'}"></div>
          <div class="carousel-container ">
            <div class="container carousel-inner-text">
              <h2 id="{{'title_index_'+i}}" class="heroTitle"></h2>
              <p>{{ banner.libelle }}</p>
              <a href="#actualite" class="btn-get-started animate__fadeInUp nav-link scrollto nav-accueil"
                (click)="sendArticle(banner)">Voir Plus</a>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev"> <span
          class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      </a> <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next"> <span
          class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      </a>
    </div>
  </div>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Chargement </p>
</ngx-spinner>
