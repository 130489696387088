import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthEtudiantService} from "../../services/auth-etudiant.service";
import {ServiceAPIService} from "../../services/service-api.service";
import {ConnexionApiService} from "../../services/connexion-api.service";

@Component({
  selector: 'app-something-wrong',
  templateUrl: './something-wrong.component.html',
  styleUrls: ['./something-wrong.component.scss']
})
export class SomethingWrongComponent implements OnInit {

  constructor( private router : Router) {
  }

  ngOnInit(): void {
    localStorage.clear();
    this.router.navigate(['/Login']);
  }


}

