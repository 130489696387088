import { Component, OnInit } from '@angular/core';
import {Partenaire} from "../../../classes/partenaire";
import {OwlOptions} from "ngx-owl-carousel-o";
import {ServiceAPIService} from "../../../services/service-api.service";
import {ServiceEtudiantService} from '../../../services/service-espace-etudiant/service-etudiant.service';
import {ServiceAccueilService} from '../../../services/service-accueil/service-accueil.service';

@Component({
  selector: 'app-partenaire-accueil',
  templateUrl: './partenaire-accueil.component.html',
  styleUrls: ['./partenaire-accueil.component.scss']
})
export class PartenaireAccueilComponent implements OnInit {
  PartenaireList: Array<Partenaire> = [];
  public image;
  public photos;
  public photo;
  customOptionsPartenaire: OwlOptions = {
    autoplay:true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin:0,
    navSpeed: 300,
    // navText: ['&#8249', '&#8250;'],
    animateOut: 'fadeOut',
    responsive: {
      280: {
        items: 2,
        nav: false
      },
      320: {
        items: 2,
        nav: false
      },
      390: {
        items: 2,
        nav: false
      },
      680: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 4,
        nav: false
      },
      1400: {
        items: 6,
        nav: false
      }
    }
  };
  constructor(private serviceAPI: ServiceAccueilService) { }

  ngOnInit(): void {
    this.getPartenaires();

  }
  getPartenaires(){

    this.serviceAPI.getPartenaires().subscribe(data => {
      this.photos = data;
      for (let photo of this.photos) {
        photo.imageData = this.serviceAPI.APIURL + photo.photo;

        this.photo = photo.imageData;
      }
    });
  }

}
