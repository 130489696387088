<div class="container">
  <div class="card">
    <div class="card-header bg-forth text-clear text-center">
      <h4 *ngIf="ajouter">Ajouter une catégorie </h4>
      <h4 *ngIf="modifier">Modifier une catégorie </h4>
    </div>
    <div class="card-body px-2">
      <form [formGroup]="updateForm" (ngSubmit)="updateTypeArticle()" class="row">
        <input type="text" class="form-control" formControlName="id" hidden>
        <div class="col-sm-12 mb-2">
          <input type="text" id="libelle" class="form-control mb-2" placeholder="Nom de la catégorie" formControlName="libelle" [ngClass]="{ 'is-invalid': submitted && f.libelle.errors }">
            <div *ngIf="submitted && f.libelle.errors" class="invalid-feedback">
            <div *ngIf="f.libelle.errors.required">Veuillez remplir la catégorie de l'article</div>
          </div>
          <button class="btn bg-forth text-clear mr-5 mb-3" type="submit"><i class="fa fa-save mr-2"></i>{{boutonEnregistrer}}</button>
          <button class="btn btn-secondary mb-3" type="reset" (click)="reset()"><i class="fa fa-save mr-2"></i>Annuler</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="typearticleList">
        <thead>
        <tr>
          <th>Nom de la catégorie</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let type of typearticleList">
          <td>
            <div class="d-flex align-items-center">
              <div class="">
                <p class="font-weight-bold mb-0">{{ type.libelle }}</p>
              </div>
            </div>
          </td>
          <td>
            <button class="btn btn-primary mr-2" href="#" (click)="updating(type)" disabled><i class="fa fa-edit" ></i></button>
            <button class="btn btn-danger" data-toggle="modal" data-target="#delete" (click)="loadDeleteConfirm(type)" disabled><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal" id="delete" data-backdrop="false" role="dialog" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLabel">Suppression</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Voulez vous vraiment supprimer<span *ngIf="typeToDelete"> {{typeToDelete.libelle}}</span> ?
        <br>
        Cette action entrainera l'effacement de tous les articles de cette catégorie.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteTypeArticle()">Supprimer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>
