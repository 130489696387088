import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import {VieEtudiant} from "../../../../classes/vie-etudiant";

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  APIimage = this.serviceAPI.APIURL;
  image: string;
  formGalerie: FormGroup;
  Media: any;
  GaleriesList: VieEtudiant[] = [];
  public imagetoshow;
  imagetoDelete: VieEtudiant;

  public url: any;
  public photos: any;
  public photo;


  constructor(
    private serviceAPI:ServiceAPIService,
    public fb: FormBuilder,

  ) {

    this.formGalerie = this.fb.group({
      libelle:['',Validators.required],
      photo: ['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.getGalerie();
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:only-arrow-functions
      reader.onloadend = () => {
        this.image = 'url(' + reader.result.toString() + ')';

      };
      reader.readAsDataURL(file);
      this.formGalerie.get('photo').setValue(file);
    }
  }

  postGalerie(){
    const formData = new FormData();
    formData.append('libelle', this.formGalerie.get('libelle').value);
    formData.append('photo', this.formGalerie.get('photo').value);
    this.serviceAPI.postPhoto(formData).subscribe(data=>{
      this.url=data.chemin

      this.formGalerie.reset();
      window.location.reload();
    })
  }

  getGalerie(){

    this.serviceAPI.getPhoto().subscribe(data => {
      data.forEach( photo => {
        const image =  this.serviceAPI.APIURL + photo.photo ;
        photo.photo = image;
        this.GaleriesList.push(photo);
        });
      });

}

  loadDeleteConfirm(photo: VieEtudiant) {
    this.imagetoDelete = photo;
  }

  delete() {
    this.serviceAPI.deletePhoto(this.imagetoDelete).subscribe( () => {
      this.getGalerie();
      window.location.reload();
    });
  }

}
