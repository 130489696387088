import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.scss']
})
export class SidebarAdminComponent implements OnInit {
  reduit = false;
  public innerWidth: any;

  constructor() {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 600) {
      if (!this.reduit) {
        this.reduit = true;
        $('#sidebar').addClass('active');
  
        $('.libelle').css('display', 'none');
        $('.sidebar-header').css('display', 'none');
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 600) {
      if (!this.reduit) {
        this.reduit = true;
        $('#sidebar').addClass('active');
  
        $('.libelle').css('display', 'none');
        $('.sidebar-header').css('display', 'none');
      }
    }
  }

  reduire() {
    this.reduit = !this.reduit;
    $('#sidebar').toggleClass('active');
    if (this.reduit) {
      $('.libelle').css('display', 'none');
      $('.sidebar-header').css('display', 'none');
    } else {
      $('.libelle').css('display', 'inline-block');
      $('.sidebar-header').css('display', 'block');
    }
  }

}
