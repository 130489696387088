
<div *ngIf=" actif">
  <dx-scheduler
    [showAllDayPanel]="false"
    [showCurrentTimeIndicator]='showCurrentTimeIndicator'
    [dataSource]="aff"
    [views]="[{type:'day', name:'Ce jour'} , {type:'week', name:'Semaine'},{type:'month', name:'Mois'} ]"
    currentView="day"
    [shadeUntilCurrentTime]="true"
    [editing]="false"
    [startDayHour]="8"
    [endDayHour]="23"
    [height]="500"
    (onContentReady)='onContentReady($event)'
  >
    <dxi-resource fieldExpr="typeUeId" label="Type UE" [allowMultiple]="true" [dataSource]="tableTypeUe">
    </dxi-resource>
  </dx-scheduler>
</div>


