<div class="container">
  <div style="width: 100%; padding-left: -10px;">
    <div class="table-responsive">
      <table id="example"  class="table table-striped"  datatable [dtOptions]="dtOptions" style="width:100%" *ngIf="headerList">
        <thead>
        <tr>
          <th>Titre</th>
          <th>Libelle</th>
          <th>Contenu</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let header of headerList">
          <td>{{ header.titre }}</td>
          <td>{{ header.libelle }}</td>
          <td>{{ header.contenu }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
