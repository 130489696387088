import { Component, OnInit } from '@angular/core';
import {Enseignant} from "../../../../classes/enseignant";
import {UE} from "../../../../classes/ue";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ServiceAPIService} from "../../../../services/service-api.service";
import {Typeue} from "../../../../classes/typeue";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-ue',
  templateUrl: './ue.component.html',
  styleUrls: ['./ue.component.scss']
})
export class UeComponent implements OnInit {
  private readonly notifier: NotifierService;
  dtOptions: DataTables.Settings = {};

  typeueList: Array<Typeue>;
  UEList: Array<UE>;
  ueToDelete: UE;

  ajouterUE = true;
  modifierUE = false;

  updateUEForm: FormGroup;

  Enregistrer = 'Ajouter';

  submitted = false;

  constructor(private serviceAPI: ServiceAPIService, private formBuilder: FormBuilder, notifierService: NotifierService,) {
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initUEForm();
    await this.getUEs();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "search": "Rechercher:",
            "searchPlaceholder": "...",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
      }
    };
    this.getTypeue();
  }


  initUEForm(){
    this.updateUEForm = this.formBuilder.group({
      id: [''],
      // tslint:disable-next-line:no-unused-expression
      libelle: ['', Validators.required],
      typeue: ['', Validators.required]
    });
  }

  get f() {
    return this.updateUEForm.controls;
  }

  async getUEs(){
    this.UEList = await this.serviceAPI.getUEs().toPromise();
  }

  getTypeue(){
    this.serviceAPI.getTypeue().subscribe( data => this.typeueList = data);
  }
  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',

    });
  }

  NotificationShowWrong(){
    this.notifier.show({
      type: 'warning',
      message: 'Operation non enregistree',

    });
  }
  updateUE() {
    this.submitted=true;
    if (this.ajouterUE){
        if (this.updateUEForm.invalid) {
          return;
        }
        else{
          const ue = new UE();
          ue.libelle =  this.updateUEForm.value.libelle;
          ue.typeue =  this.updateUEForm.value.typeue;
          this.serviceAPI.postUE(ue).subscribe( () => {
            this.NotificationShowSucces();
            this.getUEs();
            this.modifierUE = false;
            this.ajouterUE = true;
            this.updateUEForm.reset();
            this.submitted=false;
            window.location.reload();
          });
        }
    }
    if (this.modifierUE){
      this.submitted=true;
      if (this.updateUEForm.invalid) {
        return;
      }
      else{
        const value = this.updateUEForm.value;
        const ue = new UE();
        ue.libelle = value.libelle;
        ue.typeue = value.typeue;
        ue.id = value.id;
        this.serviceAPI.updateUE(ue).subscribe( () => {
          this.NotificationShowSucces();
          this.getUEs();
          this.modifierUE = false;
          this.ajouterUE = true;
          this.updateUEForm.reset();
          this.submitted=false;
          window.location.reload();
        });
      }
    }
  }

  deleteUE(){
    this.serviceAPI.deleteUE(this.ueToDelete).subscribe( () => {
      this.NotificationShowSucces();
      this.getUEs();
      window.location.reload();
    });
  }

  updatingUE(ue: UE) {
    window.scroll(0,0);
    this.modifierUE = true;
    this.ajouterUE = false;
    this.updateUEForm = this.formBuilder.group({
      id: ue.id,
      // tslint:disable-next-line:no-unused-expression
      libelle: ue.libelle,
      typeue: ue.typeue.id
    });
    this.Enregistrer = 'Modifier';
  }

  loadDeleteConfirm(ue: UE) {
    this.ueToDelete = ue;
  }

  reset() {
    this.submitted = false;
    this.updateUEForm.reset();
    this.Enregistrer = 'Ajouter';
  }
}
