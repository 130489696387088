import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { VieEtudiant } from "../../../classes/vie-etudiant";
import { Formation } from "../../../classes/formation";
import * as $ from 'jquery';
import { gsap } from 'gsap';
import * as TweenMax from 'gsap';
import Draggable from 'gsap/Draggable';
import { ServiceAPIService } from 'src/app/services/service-api.service';
import {ServiceAccueilService} from "../../../services/service-accueil/service-accueil.service";

@Component({
  selector: 'app-galerie',
  templateUrl: './galerie.component.html',
  styleUrls: ['./galerie.component.scss']
})
export class GalerieComponent implements OnInit, AfterViewInit {

  @ViewChildren('gallery_item') gallery_item: QueryList<ElementRef>;


  image: string;


  Photolist: Array<VieEtudiant> = [];


  public url: any;
  public photos: any;
  public photo;



  constructor(
    private serviceAPI: ServiceAccueilService) { }

  ngOnInit(): void {
    this.getGalerie();
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.gallery_item.changes.subscribe(() => {
      function scatterPhotos() {

        var board = $('.photos');
        var drag = $('.drag');
        var spin = $('.spin');

        $(drag.get().reverse()).each(function (index) {
          TweenMax.TweenLite.to(this, .5, {
            delay: index * 0.1,
            x: getRandomInt(board.width() / 3 * -1, board.width() / 3),
            y: getRandomInt(board.height() / 3 * -1, board.height() / 3)
          });
          TweenMax.TweenLite.to($(this).find('.spin'), .5, {
            delay: index * 0.1,
            rotation: getRandomInt(-30, 30)
          });
        });

        gsap.registerPlugin(Draggable);

        Draggable.create(drag, {
          bounds: board,
          throwProps: true,
          edgeResistance: 0,
          type: 'x,y',
          zIndexBoost: false,
          onClick: function (e) {
            var $this = $(this.target);
            //alert($this.position().left);
            if ($this.hasClass('active')) {
              TweenMax.TweenLite.to($this, .2, {
                scaleX: 1,
                scaleY: 1,
                x: $this.attr('data-x'),
                y: $this.attr('data-y')
              });
              TweenMax.TweenLite.to($this.find('.spin'), .2, {
                rotation: $this.attr('data-r')
              });
              $this.removeClass('active');
              $('#galeries').removeClass('full');
            } else {

              var matchesDiv = $this.css('transform').split(',');
              var x = parseFloat(matchesDiv[4]);
              var y = parseFloat(matchesDiv[5]);
              var spinRotation = $this.children('figure').css('transform').split(',');
              var a = parseFloat(spinRotation[0].split('(')[1]);
              var b = parseFloat(spinRotation[1]);
              var r = Math.round(Math.atan2(b, a) * (180 / Math.PI));
              $this.attr('data-x', x).attr('data-y', y).attr('data-r', r);
              TweenMax.TweenLite.to($this, .2, {
                scaleX: 2,
                scaleY: 2,
                x: 0,
                y: 0
              });
              TweenMax.TweenLite.to($this.find('.spin'), .2, {
                rotation: 0
              });
              TweenMax.TweenLite.to($('.active'), .2, {
                scaleX: 1,
                scaleY: 1,
                x: $('.active').attr('data-x'),
                y: $('.active').attr('data-y')
              });
              TweenMax.TweenLite.to($('.active .spin'), .2, {
                rotation: $this.attr('data-r')
              });
              $('.drag.active').removeClass('active');
              $('#galeries').removeClass('full');
              $this.addClass('active').appendTo('.photos');
              $('#galeries').addClass('full');
            }
          },
          onDrag: function () {
            var $this = $(this.target);
            TweenMax.TweenLite.to($this, .2, {
              scaleX: 1,
              scaleY: 1
            });
            $this.removeClass('active');
            $('#galeries').removeClass('full');
          },
          onThrowComplete: function () {
            var $this = $(this.target);
            var r = this.target._gsTransform.rotation;
            if (r > 360 || r < -360) {
              r = r % 360;
              TweenMax.TweenLite.to($this.find('.spin'), 0, {
                rotation: r
              });
            }
          }
        });
        Draggable.create(spin, {
          type: 'rotation',
          throwProps: true,
          throwResistance: 25000,
          minDuration: 0
        });
      }

      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      scatterPhotos();
    });
  }



  getGalerie() {
    this.serviceAPI.getPhoto().subscribe(data => {
      data.forEach(photo => {
        let image = this.serviceAPI.APIURL + photo.photo;
        photo.photo = image;
        this.Photolist.push(photo);
      });
    });
  }
}
