<footer class="bg-blue">
  <div class="container">
    <div class="copyright">
      © Copyright <strong>ENI</strong>. All Rights Reserved
    </div>
    <div class="credits">
      Designed by
<!--      <a href="#">RAKOTONDRAMANANA Mamy Koloina &-->
<!--      RAJAONARISON Rojo Nirina</a>-->
      <a >AMAZONES</a>
    </div>
  </div>
</footer>
