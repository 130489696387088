import { Component, OnInit } from '@angular/core';
import {ServiceAPIService} from "../../../../services/service-api.service";

@Component({
  selector: 'app-dasboard-scolarite',
  templateUrl: './dasboard-scolarite.component.html',
  styleUrls: ['./dasboard-scolarite.component.scss']
})
export class DasboardScolariteComponent implements OnInit {

  public NbUes = [];
  public NbTypeUe = [];
  public NbAssignation = [];
  public NbProffesseurs = [];
  public NbEtudiants = [];
  public NbEtudiantsIG = [];
  public NbEtudiantsSR = [];
  public NbEtudiantsGB = [];
  public NbClubs = [];

  constructor(private ServiceApi: ServiceAPIService) {
  }

  ngOnInit(): void {
    this.GetUes();
    this.GetTypeUe();
    this.GetAssignation();
    this.Getproffesseurs();
    this.GetClubs();
    this.GetEtudiantIG();
    this.GetEtudiantGB();
    this.GetEtudiantSR();
    this. GetEtudiant();
  }

  GetUes() {
    this.ServiceApi.getUEs().subscribe(
      data => {
        data.forEach(element => {
          this.NbUes.push(element);
        });
      }
    );
  }

  GetTypeUe() {
    this.ServiceApi.getTypeue().subscribe(
      data => {
        data.forEach(element => {
          this.NbTypeUe.push(element);
        });
      }
    );
  }

  GetAssignation() {
    this.ServiceApi.getAssignations().subscribe(
      data => {
        data.forEach(element => {
          this.NbAssignation.push(element);
        });
      });
  }


  Getproffesseurs() {
this.ServiceApi.getUtilisateur().subscribe(data => {
  data.forEach( enseignant => {
    enseignant.profiles.forEach(profile => {
      if (profile.libelle === 'Enseignant') {
        this.NbProffesseurs.push(enseignant);
      }
    });
  });
});

  }
  GetEtudiant() {
    this.ServiceApi.getUtilisateur().subscribe(data => {
      data.forEach(etudiant => {
        etudiant.profiles.forEach(profile => {
          if (profile.libelle === 'Etudiant') {
            this.NbEtudiants.push(etudiant);
          }
        });
      });
    });
  }
  GetEtudiantIG() {
this.ServiceApi.getUtilisateur().subscribe(data => {
  data.forEach( etudiant => {
    etudiant.profiles.forEach(  profile => {
      if (profile.libelle === 'Etudiant' && etudiant.parcours.libelle === "Informatique générale") {
       this.NbEtudiantsIG.push(etudiant);
      }
    });
  });
});

  }
  GetEtudiantSR() {
    this.ServiceApi.getUtilisateur().subscribe(data => {
      data.forEach( etudiant => {
        etudiant.profiles.forEach(  profile => {
          if (profile.libelle === 'Etudiant' && etudiant.parcours.libelle === "Système et réseaux") {
            this.NbEtudiantsSR.push(etudiant);
          }
        });
      });
    });

  }
  GetEtudiantGB() {
    this.ServiceApi.getUtilisateur().subscribe(data => {
      data.forEach( etudiant => {
        etudiant.profiles.forEach(  profile => {
          if (profile.libelle === 'Etudiant' && etudiant.parcours.libelle === "Génie logiciel et base de données") {
            this.NbEtudiantsGB.push(etudiant);
          }
        });
      });
    });

  }

  GetClubs() {
    this.ServiceApi.getClub().subscribe(
      data => {
        data.forEach(element => {
          this.NbClubs.push(element);
        });
      });
  }
}
