import {UE} from './ue';
import {Niveau} from './niveau';
import {Parcours} from './parcours';
import {Utilisateur} from './utilisateur';
import { Schedule } from './schedule';

export class Assignation {
  id: number;
  UE: UE;
  niveau: Niveau;
  parcours: Parcours;
  utilisateur: Utilisateur;
  schedules:Schedule[];
}
