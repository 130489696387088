import { Component, OnInit } from '@angular/core';
import {Club} from '../../classes/club';
import {Article} from '../../classes/article';
import {Utilisateur} from '../../classes/utilisateur';
import {NotifierService} from 'angular-notifier';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServiceAPIService} from '../../services/service-api.service';
import {Token} from '../../classes/token';
import {PassClubService} from '../../services/pass-club.service';
import {ServiceEtudiantService} from '../../services/service-espace-etudiant/service-etudiant.service';
import {BlogClub} from '../../classes/blog-club';
import {UtilisateurConnecte} from "../../classes/utilisateur-connecte";
import {Router} from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  p: any;
  public id;

  public submitted = false;
  public champvide = false;

  clubDeLaPage: BlogClub;
  articles: Array<BlogClub> = [];
  clubList: Array<BlogClub> = [];

  utilisateurConnecte: UtilisateurConnecte;
  private readonly notifier: NotifierService;
  // APIimage='http://amazones.aaeni.fr:1337'

  canPostArticle = false;

  public image;
  Wysywigform: FormGroup;
  form: FormGroup;

  public clubs;
  public audiences;
  public types;
  public show = false;
  donnees: any;
  notFound = false;
  search = false;

  public maxSize = 7;
  public directionLinks = true;
  public autoHide = false;
  public responsive = true;
  public labels: any = {
    previousLabel: 'Precedent',
    nextLabel: 'Suivant',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  constructor(
              public fb: FormBuilder,
              private serviceAPI: ServiceEtudiantService,
              notifierService: NotifierService,
              private formBuilder: FormBuilder,
              private pass: PassClubService,
              private router: Router) {
    this.Wysywigform = this.fb.group({
      titre: ['', Validators.required],
      contenu: ['', Validators.required],
      date: ['', Validators.required],
      moderateur: ['', Validators.required],
      club: [''],
      audiences: [''],
      photo: ['', Validators.required],
    });
    this.clubDeLaPage = this.pass.getClub();
    this.getArticles(this.clubDeLaPage);
    this.notifier = notifierService;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getUtilisateurConnecte();
    this.getClubs();
  }

  initForm(){
    this.form = this.formBuilder.group({
      titre: ['']
    });
  }

  getArticles(club: BlogClub) {
    if (club) {
      this.serviceAPI.getBlog().subscribe( data => {
        const articlesToShow = [];
        data.forEach( art => {
          if ( club.id_club === art.club_id_in_article) {
            articlesToShow.push(art);
          }
        });
        this.articles = articlesToShow;
      });
    } else {
      this.serviceAPI.getUtilisateurConnecte().subscribe( utilisateur => {
        if (utilisateur) {
          if ((utilisateur.profile.filter(profile => profile.libelle === 'Etudiant')).length > 0) {
            this.router.navigate(['/espace-etudiant/clubs']);
          } else if ((utilisateur.profile.filter(profile => profile.libelle === 'Ancien')).length > 0) {
            this.router.navigate(['/espace-ancien/clubs']);
          }else if ((utilisateur.profile.filter(profile => profile.libelle === 'Enseignant')).length > 0) {
            this.router.navigate(['/espace-enseignant/clubs']);
          }
        } else {
          // on changera plus tard
          this.router.navigate(['403']);
        }
      });
    }
  }

  deleteArticle() {
    const art = this.donnees;
    this.serviceAPI.deleteArticle(art).subscribe(() => {
      this.NotificationShowSucces();
      this.getArticles(this.clubDeLaPage);
      window.location.reload();
    });
  }

  updating(art: BlogClub) {
    this.Wysywigform = this.fb.group({
      id:  [art.id, Validators.required],
      titre:  [art.titre, Validators.required],
      contenu:  [art.contenu, Validators.required],
    });
  }

  passData(art: BlogClub) {
    this.donnees = art;
  }

  get f() { return this.Wysywigform.controls; }

  async getUtilisateurConnecte(){
    this.utilisateurConnecte = await this.serviceAPI.getUtilisateurConnecte().toPromise();
    this.id = this.utilisateurConnecte.id;
  }

  NotificationShowSucces(){
    this.notifier.show({
      type: 'success',
      message: 'Operation effectuee avec success',
    });
  }

  Update(){
    this.submitted = true;
    const art = new Article();

    art.id = this.Wysywigform.value.id;
    art.contenu = this.Wysywigform.value.contenu;
    art.titre = this.Wysywigform.value.titre;

    this.serviceAPI.updateArticle(art).subscribe(data => {
      this.NotificationShowSucces();
      this.getArticles(this.clubDeLaPage);
      this.submitted = false;
      this.Wysywigform.reset();
      window.location.reload();
    });
  }

  rechercher() {
    this.search = true;
    const titre = this.form.get('titre').value;
    const search = (titre.toString()).toLowerCase();
    const res = this.articles.filter( art => ((art.titre).toLowerCase()).includes(search));
    this.articles = res;
    if (this.articles.length === 0) {
      this.notFound = true;
    }
  }

  getClubs() {
    this.serviceAPI.getClub().subscribe( data => {
      data.forEach( club => {
        club.photo = this.serviceAPI.APIURL + club.photo;
        this.clubList.push(club);
      });
    });
  }

  blog(club: BlogClub) {
    this.clubDeLaPage = club;
    this.getArticles(club);
  }

  posterArticle(clubDeLaPage: BlogClub) {
    this.pass.sendClub(clubDeLaPage);
    if ((this.utilisateurConnecte.profile.filter(profile => profile.libelle === 'Etudiant')).length > 0) {
      this.router.navigate(['/espace-etudiant/nouveau']);
    } else if ((this.utilisateurConnecte.profile.filter(profile => profile.libelle === 'Ancien')).length > 0) {
      this.router.navigate(['/espace-ancien/nouveau']);
    }else if ((this.utilisateurConnecte.profile.filter(profile => profile.libelle === 'Enseignant')).length > 0) {
      this.router.navigate(['/espace-enseignant/nouveau']);
    }
  }

}
